import React, { memo } from "react";
import { useDropzone } from "react-dropzone";
import { ButtonBase, Typography, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { CloudUpload } from "../icons";

const useStyles = makeStyles((theme) => ({
  root: ({ color }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    whiteSpace: "normal",
    padding: theme.spacing(3),
    color: color ? theme.palette[color].main : theme.palette.grey[200],
  }),
  icon: ({ color }) => ({
    height: "auto",
    width: "40%",
    maxWidth: 300,
    maxHeight: "100%",
    color: color ? theme.palette[color].main : theme.palette.grey[200],
  }),
}));

function DropZone({
  className,
  label,
  activeLabel,
  Icon = CloudUpload,
  disabled,
  color,
  variant,
  dataTestId,
  ...rest
}) {
  const classes = useStyles({ color });

  const { getRootProps, getInputProps } = useDropzone({ disabled, ...rest });

  return (
    <ButtonBase
      {...getRootProps()}
      disabled={disabled}
      variant={variant}
      component={Paper}
      className={classNames(className, classes.root)}
    >
      <input
        data-test-id={dataTestId}
        {...getInputProps()}
        disabled={disabled}
      />
      <Icon className={classes.icon} />
      <Typography variant="h6">{label}</Typography>
    </ButtonBase>
  );
}

export default memo(DropZone);
