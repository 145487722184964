import api from "./api";

const recipientEndpoint = `/api/v1/recipient`;

export async function updateRecipient(recipientId, body, params) {
  const { data } = await api.patch(
    `${recipientEndpoint}/${recipientId}`,
    body,
    { params }
  );
  return data;
}

export async function addRecipient(recipient, params) {
  const { data } = await api.post(recipientEndpoint, recipient, { params });
  return data;
}

export async function deleteRecipient(recipientId, params) {
  const { data } = await api.delete(`${recipientEndpoint}/${recipientId}`);
  return data;
}
