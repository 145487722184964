import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "../../../components";
import { DeleteForever, Save, Send } from "../../../icons";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0 ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.grey[200],
    marginTop: "auto",
    marginBottom: 0,
  },
}));

function EditRecipientListControls({
  onDelete,
  onSave,
  onContinueCampaign,
  disabled,
  hasErrors,
  ...rest
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const newRecipientList = useSelector((state) => state.newRecipientList);

  return (
    <Grid
      {...rest}
      container
      spacing={2}
      alignItems="center"
      justify="flex-end"
      className={classes.root}
    >
      <Grid item>
        <Button
          data-test-id="recipientList-edit-button-delete"
          color="secondary"
          startIcon={<DeleteForever />}
          onClick={onDelete}
        >
          {t("Delete")}
        </Button>
      </Grid>
      <Grid item>
        <Button
          data-test-id="recipientList-edit-button-update"
          variant="contained"
          color="primary"
          startIcon={<Save />}
          onClick={onSave}
          disabled={hasErrors || disabled}
        >
          {t("Update")}
        </Button>
      </Grid>
      {newRecipientList?.redirect && (
        <Grid item>
          <Button
            data-test-id="recipientList-edit-button-campaign-continue"
            color="primary"
            startIcon={<Send />}
            onClick={onContinueCampaign}
            variant="outlined"
          >
            {t("Continue Campaign")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default memo(EditRecipientListControls);
