import React, { memo } from "react";
import ReactDOM from "react-dom";
import {
  Button,
  Fab,
  Grid,
  Zoom,
  useScrollTrigger,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Add, AddCircle } from "../icons";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function ActionButton({
  mobileIcon = <Add />,
  desktopIcon = <AddCircle />,
  color = "primary",
  variant = "contained",
  text,
  classes,
  className,
  disabled,
  ...rest
}) {
  const ownClasses = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const scrollingDown = useScrollTrigger({ threshold: 100 });

  if (isSmallScreen) {
    return ReactDOM.createPortal(
      <Zoom appear={false} in={!scrollingDown}>
        <Fab
          {...rest}
          color={color}
          className={classNames(className, classes?.mobile, ownClasses.fab)}
        >
          {mobileIcon}
        </Fab>
      </Zoom>,
      document.body
    );
  }

  return (
    <Grid item>
      <Button
        {...rest}
        color={color}
        variant={variant}
        startIcon={desktopIcon}
        className={classNames(className, classes?.dektop)}
        disabled={disabled}
      >
        {text}
      </Button>
    </Grid>
  );
}

export default memo(ActionButton);
