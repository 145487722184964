import React, { useCallback, useEffect, memo } from "react";
import { useHistory } from "react-router-dom";
import { omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import BaseSeedPage from "./Base";
import { setSeed, clearSeed, saveSeed } from "../../../actions/seed";

function CreateSeedPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const seed = useSelector((state) => state.seed);
  const { errors = {} } = seed || {};

  useEffect(() => {
    dispatch(
      setSeed({
        name: "",
        email: [],
        sms: [],
        voice: [],
        tty: [],
        permission: "",
      })
    );
    return () => dispatch(clearSeed());
  }, [dispatch]);

  const onFieldChange = useCallback(
    (ev) => {
      dispatch(
        setSeed({
          [ev.target.name]: ev.target.value,
          errors: omit(errors, ev.target.name),
          modified: true,
        })
      );
    },
    [dispatch, errors]
  );

  const onArrayFieldChange = useCallback(
    (channelInfo, type) => {
      dispatch(
        setSeed({
          [type]: [...seed[type], channelInfo],
          errors: omit(errors, "email", "sms", "voice", "tty"),
          modified: true,
          [`${type}Modified`]: true,
        })
      );
    },
    [dispatch, errors, seed]
  );

  const onChipDeletion = useCallback(
    (channelType, index) => {
      const filteredSeeds = seed[channelType].filter((_, idx) => idx !== index);
      dispatch(
        setSeed({
          [channelType]: filteredSeeds,
          errors: omit(errors, [channelType, `${channelType}.${index}`]),
          modified: true,
          [`${channelType}Modified`]: filteredSeeds.length === 0 ? false : true,
        })
      );
    },
    [dispatch, errors, seed]
  );

  const onSave = useCallback(async () => {
    await dispatch(saveSeed());
    history.push("/seeds");
  }, [dispatch, history]);

  return (
    <BaseSeedPage
      seed={seed}
      onFieldChange={onFieldChange}
      onSave={onSave}
      onArrayFieldChange={onArrayFieldChange}
      onChipDeletion={onChipDeletion}
    />
  );
}

export default memo(CreateSeedPage);
