import React, { memo } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ListMasterRecipients from "./List";

function ListMasterRecipientsPage() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`}>
        <ListMasterRecipients />
      </Route>
    </Switch>
  );
}

export default memo(ListMasterRecipientsPage);
