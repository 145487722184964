import React, { memo } from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import { makeStyles } from "@material-ui/core/styles";
import { Page, Box } from "../../../components";
import Controls from "./Controls";
import Fields from "./Fields";
import NavigationDialog from "../../../components/NavigationDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    width: "100%",
    overflow: "auto",
  },
  fields: {
    marginBottom: theme.spacing(2),
  },
}));

function BaseSeedsPage({
  seed,
  onFieldChange,
  onSave,
  readOnly,
  onChipDeletion,
  onArrayFieldChange,
}) {
  const classes = useStyles();

  const { errors = {}, name, email, sms, voice, tty, permission, modified } =
    seed || {};
  const hasErrors = 0 < Object.keys(errors).length;

  return (
    <>
      <Page screenSized className={classes.root}>
        <Box p={2} className={classes.content}>
          <Fields
            errors={errors}
            name={name}
            email={email}
            sms={sms}
            voice={voice}
            tty={tty}
            permission={permission}
            onChange={onFieldChange}
            className={classes.fields}
            readOnly={readOnly}
            onChipDeletion={onChipDeletion}
            onArrayFieldChange={onArrayFieldChange}
          />
        </Box>
        {!readOnly && <Controls onSave={onSave} hasErrors={hasErrors} />}
      </Page>
      <NavigationPrompt when={Boolean(modified)}>
        {({ onConfirm, onCancel, isActive }) => (
          <NavigationDialog
            onConfirm={onConfirm}
            onCancel={onCancel}
            open={isActive}
          />
        )}
      </NavigationPrompt>
    </>
  );
}

export default memo(BaseSeedsPage);
