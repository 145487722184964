import React, { useCallback, useMemo, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
  ToggleButton,
  ToggleButtonGroup,
  FormControl,
  FormHelperText,
  FormLabel,
} from "../../../../components";
import { Close } from "../../../../icons";

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing(1),
  },
  toggleButton: ({ hasError }) => ({
    flex: 1,
    maxWidth: 40,
    borderColor: hasError ? theme.palette.error.main : null,
  }),
  digitButton: ({ hasError }) => ({
    color: hasError ? theme.palette.error.main : null,
  }),
  clearButton: {
    color: `${theme.palette.secondary.main} !important`,
  },
}));

function getDigits({ size, withSymbols }) {
  let digits = Array(size + 1)
    .fill(null)
    .map((_, idx) => String(idx));

  if (withSymbols) {
    digits = [...digits, "#", "*"];
  }
  return digits;
}

function DigitSelector({
  value,
  name,
  onChange,
  size = 9,
  error,
  label,
  withSymbols,
  optional,
  readOnly,
}) {
  const hasError = Boolean(error);
  const classes = useStyles({ hasError });

  const _onChange = useCallback(
    (_, newValue) => onChange({ target: { name, value: newValue } }),
    [onChange, name]
  );

  const digits = useMemo(() => getDigits({ size, withSymbols }), [
    size,
    withSymbols,
  ]);

  return (
    <FormControl error={hasError} fullWidth>
      <FormLabel component="legend" className={classes.label}>
        {label}
      </FormLabel>
      <ToggleButtonGroup
        size="small"
        value={String(value)}
        onChange={_onChange}
        exclusive
        fullWidth
      >
        {digits.map((digit) => (
          <ToggleButton
            key={digit}
            value={digit}
            data-test-id={`master-template-${label}-key-${digit}`}
            className={classNames(classes.toggleButton, classes.digitButton)}
            disabled={readOnly}
          >
            {digit}
          </ToggleButton>
        ))}
        {optional && (
          <ToggleButton
            value=""
            className={classNames(classes.toggleButton, classes.clearButton)}
            disabled={readOnly}
          >
            <Close fontSize="small" />
          </ToggleButton>
        )}
      </ToggleButtonGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

export default memo(DigitSelector);
