import React, { useEffect } from "react";
import { Page } from "../../../components";
import { useHistory, useParams } from "react-router-dom";
import { clearCampaign } from "../../../actions/campaign";
import { useDispatch, useSelector } from "react-redux";
import { ApproversPage } from "../../../core";
import { getCampaignDetail } from "../../../actions/pendingApprovalCampaigns";

function SingleCampaignPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { campaignId } = useParams();
  const campaign = useSelector((state) => state?.approvalData);

  useEffect(() => {
    dispatch(getCampaignDetail(campaignId));
    return () => dispatch(clearCampaign());
  }, [dispatch, campaignId]);

  return (
    <>
      <Page>
        {campaign && <ApproversPage campaignId={campaignId} readOnly />}
      </Page>
    </>
  );
}

export default SingleCampaignPage;
