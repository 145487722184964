import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Search, useMediaQuery } from "../../../components";

const useStyles = makeStyles((theme) => ({
  primaryContainer: {
    flexGrow: 1,
    maxWidth: 1000,
  },
  searchContainer: {
    flex: 2,
    minWidth: 140,
    maxWidth: 400,
  },
}));

function TemplatesFilters({ params, onSearchChange }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  return (
    <>
      <Grid container spacing={2} justify="space-between">
        <Grid item className={classes.primaryContainer}>
          <Grid container spacing={2}>
            <Grid item className={classes.searchContainer}>
              <Search
                variant="outlined"
                size="small"
                label={
                  isSmallScreen ? t("Search") : t("Search by template name")
                }
                onSearch={onSearchChange}
                defaultValue={params.campaignSearch}
                debounce={300}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default memo(TemplatesFilters);
