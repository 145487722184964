import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Paper, Alert, CircularProgress, Skeleton } from "../../../components";
import { Cloud, CloudDone, WarningRounded } from "../../../icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  progress: {
    margin: theme.spacing(2),
  },
  statusIcon: {
    fontSize: 170,
  },
  completedIcon: {
    color: theme.palette.success.main,
  },
  unsavedIcon: {
    color: theme.palette.warning.main,
  },
  skeletonIcon: {
    color: theme.palette.grey[200],
  },
  alertSkeleton: {
    width: "100%",
    height: 70,
    transform: "none",
  },
  alert: {
    width: "100%",
  },
}));

function RecipientListStatus({
  status,
  progress,
  loading,
  name,
  className,
  ...rest
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const unsaved = !status;
  const completed = status === "COMPLETED";
  const isLocked = status === "LOCKED";
  const uploading = progress !== undefined;
  // it's on a pendiding status otherwise
  // TODO: maybe add a status indicator for deleted lists too

  return (
    <Paper {...rest} className={classNames(className, classes.root)}>
      {loading ? (
        <Cloud
          className={classNames(classes.statusIcon, classes.skeletonIcon)}
        />
      ) : unsaved ? (
        <WarningRounded
          className={classNames(classes.statusIcon, classes.unsavedIcon)}
        />
      ) : completed || isLocked ? (
        <CloudDone
          className={classNames(classes.statusIcon, classes.completedIcon)}
        />
      ) : (
        <CircularProgress
          className={classes.progress}
          size={120}
          value={progress}
          withLabel={uploading}
          color="warning"
        />
      )}
      {loading ? (
        <Skeleton className={classes.alertSkeleton} />
      ) : (
        <Alert
          variant="filled"
          severity={completed ? "success" : "warning"}
          className={classes.alert}
        >
          {unsaved
            ? t(
                "Your list is not saved! Please save it before closing this tab."
              )
            : completed || isLocked
            ? uploading
              ? t(
                  "Your list uploaded successfully! You can safely close this tab."
                )
              : t(
                  "This list is fully uploaded and ready to be used in campaigns."
                )
            : uploading
            ? t(
                "Your list is being uploaded. Do not close the tab until the upload is completed!"
              )
            : t(
                "This list is still being processed, you can not use it for campaigns yet."
              )}
        </Alert>
      )}
    </Paper>
  );
}

export default memo(RecipientListStatus);
