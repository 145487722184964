import i18n from "i18next";
import * as api from "../api/recipientLists";
import { notify } from "./notification";

export const LIST_MASTER_RECIPIENTS = "LIST_MASTER_RECIPIENTS";

export function listMasterRecipients(params) {
  return async (dispatch, getState) => {
    const {
      storage,
      masterListRecipients: { recipientListId },
    } = getState();
    const { paid } = storage;
    const masterRecipients = await api.listMasterRecipients({
      ...params,
      paid,
      recipientListId,
    });
    dispatch({ type: LIST_MASTER_RECIPIENTS, masterRecipients });
  };
}

export function updateMasterRecipient(recipientId, recipient, params) {
  return async (dispatch, getState) => {
    await api.updateMasterRecipient(recipientId, recipient, params);
  };
}

export function deleteMasterRecipient(recipient) {
  return async (dispatch, getState) => {
    try {
      await api.deleteMasterRecipient(recipient._id);
      dispatch(
        notify({
          type: "success",
          message: i18n.t("Recipient successfully deleted"),
        })
      );
    } catch (err) {
      dispatch(
        notify({
          type: "error",
          message: i18n.t("Recipient data validation failed"),
        })
      );
    }
  };
}
