import { batch } from "react-redux";
import * as api from "../../api/recipientLists";
import { setStorageFields } from "../storage";

export const CREATE_NEW_SUPPRESSION_LIST = "CREATE_NEW_SUPPRESSION_LIST";
export const BUILD_NEW_SUPPRESSION_LIST = "BUILD_NEW_SUPPRESSION_LIST";
export const CLEAR_NEW_SUPPRESSION_LIST = "CLEAR_NEW_SUPPRESSION_LIST";

export function clearNewSuppressionList(keepParams) {
  return (dispatch) => {
    batch(() => {
      // clear the list id from localStorage, we don't have to warn the user about an aborted list upload
      // because the list is cleanly deleted
      dispatch(setStorageFields({ savingRecipientList: undefined }));
      if (keepParams?.keepRedirect) {
        return dispatch({
          type: CLEAR_NEW_SUPPRESSION_LIST,
          keepRedirect: keepParams.keepRedirect,
        });
      }
      return dispatch({ type: CLEAR_NEW_SUPPRESSION_LIST });
    });
  };
}

export function buildNewSuppressionList(newSuppressionListData) {
  return { type: BUILD_NEW_SUPPRESSION_LIST, newSuppressionListData };
}

export * from "./uploadNewSuppressionList";
