import React, { useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { omit } from "lodash";
import { buildNewRecipientList } from "../../../actions/newRecipientList";
import Edit from "../Edit/Edit";

function SaveRecipientList() {
  const dispatch = useDispatch();
  const newRecipientList = useSelector((state) => state.newRecipientList);
  const { errors } = newRecipientList || {};

  const onChange = useCallback(
    (ev) =>
      dispatch(
        buildNewRecipientList({
          [ev.target.name]: ev.target.value,
          errors: omit(errors, ev.target.name),
        })
      ),
    [dispatch, errors]
  );

  return (
    <Edit
      recipientList={newRecipientList}
      onSearchRecipient={() => {}}
      onChange={onChange}
      withProgress
    />
  );
}

export default memo(SaveRecipientList);
