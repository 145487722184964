import api from "./api";

const uploadBasePath = "/api/v1/upload/form";
export async function uploadImage(formData, params) {
  const { data } = await api.post(uploadBasePath, formData, params);

  return data;
}

// const campaignBasePath = "/api/v1/campaign";

// export async function stopCampaign(id, params) {
//   const { data } = await api.post(`${campaignBasePath}/${id}/stop`, null, {
//     params,
//   });
//   return data;
// }
