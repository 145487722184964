import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Stepper, Step, StepButton, Grid } from "@material-ui/core";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Save,
  DeleteForever,
  InsertDriveFile as Draft,
} from "../../icons";
import NavButton from "./NavButton";

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    flex: "1 0",
  },
  stepper: {
    backgroundColor: "inherit",
    padding: 0,
  },
  navButtons: {
    flex: "0 1",
  },
}));

function DesktopWizard({
  steps,
  children,
  className,
  disableNext,
  errors,
  onSave,
  onSaveAsDraft,
  onAbort,
  doneStepIdx,
  activeStepIdx,
  activeStep,
  nextStep,
  prevStep,
  pushHistory,
  ...rest
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      justify="space-between"
      alignItems="center"
      direction="row"
      wrap="nowrap"
    >
      <Grid item>
        <NavButton
          disabled={!prevStep || prevStep?.disable}
          to={prevStep?.disable ? prevStep?.prevPath : prevStep?.path}
          startIcon={<KeyboardArrowLeft />}
        >
          {t("Back")}
        </NavButton>
      </Grid>
      <Grid item className={classes.stepperContainer}>
        <Stepper
          {...rest}
          className={classes.stepper}
          activeStep={activeStepIdx}
        >
          {steps.map((step, idx) => {
            const stepProps = {};
            if (step.disable) {
              stepProps.completed = false;
            }
            return (
              <Step
                key={step.path}
                color="primary"
                disabled={doneStepIdx + 1 < idx}
                completed={step.disable ? false : idx <= doneStepIdx}
                {...stepProps}
              >
                <NavButton
                  component={StepButton}
                  to={step.path}
                  validate={activeStepIdx < idx && activeStep.validate}
                  pushHistory={pushHistory}
                  disabled={step.disable && idx === 1}
                >
                  {step.label}
                </NavButton>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <Grid item>
        <Grid container spacing={2} wrap="nowrap">
          {onAbort && (
            <Grid item>
              <NavButton
                onClick={onAbort}
                color="secondary"
                startIcon={<DeleteForever />}
              >
                {t("Exit")}
              </NavButton>
            </Grid>
          )}
          {onSaveAsDraft && (
            <Grid item>
              <NavButton
                color="default"
                onClick={onSaveAsDraft}
                startIcon={<Draft />}
              >
                {t("Save as draft")}
              </NavButton>
            </Grid>
          )}
          <Grid item>
            {nextStep ? (
              <NavButton
                variant="contained"
                disabled={disableNext || nextStep.disable}
                error={Object.keys(errors || {}).length}
                to={nextStep?.disable ? nextStep?.nextPath : nextStep?.path}
                endIcon={<KeyboardArrowRight />}
                validate={activeStep.validate}
                pushHistory={pushHistory}
                data-test-id="wizard-next-button"
              >
                {t("Next")}
              </NavButton>
            ) : (
              <NavButton
                data-test-id="wizard-save-button"
                variant="contained"
                disabled={disableNext}
                validate={activeStep.validate}
                onClick={onSave}
                startIcon={<Save />}
                error={Object.keys(errors || {}).length}
              >
                {t("Save")}
              </NavButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(DesktopWizard);
