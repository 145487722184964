import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from ".";

function DeleteDialog({ onClose, onDelete, open, name, resource, ...rest }) {
  const { t } = useTranslation();

  return (
    <Dialog {...rest} open={open} onClose={onClose}>
      <DialogTitle>{t("Delete {{resource}}", { resource })}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("Are you sure you would like to delete {{name}}?", {
            name: name ?? t("your {{resource}}", { resource }),
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} data-test-id="delete-dialog-close-button">
          {t("Cancel")}
        </Button>
        <Button
          onClick={onDelete}
          color="secondary"
          data-test-id="delete-dialog-delete-button"
        >
          {t("Delete {{resource}}", { resource })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(DeleteDialog);
