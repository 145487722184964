import React, { useEffect, useState, useCallback, useMemo, memo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, batch } from "react-redux";
import { keys, omit } from "lodash";
import * as recipientListAPI from "../../../api/recipientLists";
import {
  getRecipientList,
  buildRecipientList,
  saveRecipientList,
  getRecipients,
  clearRecipientList,
  deleteRecipientList,
} from "../../../actions/recipientList";
import useAuthorized, {
  RECIPIENTLIST_LIST_PERMISSION,
} from "../../../hooks/useAuthorized";
import { clearNewRecipientList } from "../../../actions/newRecipientList";
import { setParams } from "../../../actions/params";
import { Page, DeleteDialog, NavigationDialog } from "../../../components";
import Edit from "./Edit";
import Controls from "./Controls";
import { useTranslation } from "react-i18next";
import {
  addRecipient,
  deleteRecipient,
  updateRecipient,
} from "../../../actions/recipient";
import NavigationPrompt from "react-router-navigation-prompt";

const getParams = (
  { page, itemsPerPage, activeDivisionId, search, searchByFields },
  recipientListId
) => ({
  page: page || 0,
  itemsPerPage: itemsPerPage || 20,
  recipientListId,
  activeDivisionId,
  search,
  searchByFields,
});

const getResource = (t) => t("Recipient List");

function EditOrViewRecipientListPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const newRecipientList = useSelector((state) => state.newRecipientList);
  const savingRecipientList = useSelector(
    (state) => state.storage.savingRecipientList
  );
  let recipientList = useSelector((state) => state.recipientList);
  let hasViewPermission = useAuthorized(RECIPIENTLIST_LIST_PERMISSION);
  let isCompanyOrDivision =
    recipientList?.permission === "company" ||
    recipientList?.permission === "division";

  const resource = useMemo(() => getResource(t), [t]);

  // return the local new recipient list if the user requests that on during the upload
  // the local version has a granular progress indicator, that the API hosted version does not have
  let uploading = false;
  if (newRecipientList?._id === id || savingRecipientList?._id === id) {
    // There is a new recipient list being processed
    recipientList = newRecipientList ? newRecipientList : savingRecipientList;
    uploading = true;
  }

  const { errors = {} } = recipientList || {};
  const hasErrors = Object.keys(errors).length;

  const [showDeleteDialog, setShowDeleteDialog] = useState();

  const queryParams = useSelector((state) => state.params);
  const params = useMemo(() => getParams(queryParams, id), [queryParams, id]);
  const { activeDivisionId, paid } = useSelector((state) => state.storage);
  const activeCompanyId = useSelector((state) => state.user.companyId);

  // refetch the list when the id changes or when we switch over from the new recipientList
  useEffect(() => {
    const getRecipientListParams = {
      activeDivisionId,
      activeCompanyId,
    };
    let interval;
    if (!uploading) {
      dispatch(getRecipientList(id, getRecipientListParams));
    } else {
      interval = setInterval(async () => {
        const { status } = await recipientListAPI.getRecipientList(
          id,
          getRecipientListParams
        );
        if (status && status !== "PENDING") {
          dispatch(getRecipientList(id, getRecipientListParams));
          clearInterval(interval);
          if (newRecipientList?.redirect) {
            dispatch(clearNewRecipientList({ keepRedirect: true }));
          } else {
            dispatch(clearNewRecipientList());
          }
        }
      }, 5000);
    }

    return () =>
      batch(() => {
        clearInterval(interval);
        dispatch(clearRecipientList);
      });
  }, [dispatch, activeDivisionId, activeCompanyId, uploading, id]);

  // refetch the recipients whenever the params change
  useEffect(() => {
    if (!uploading) {
      dispatch(getRecipients(params, id));
    }
  }, [dispatch, uploading, params, id]);

  const onRecipientsPageChange = useCallback(
    (ev, page) => dispatch(setParams({ page })),
    [dispatch]
  );

  const onRecipientsItemsPerPageChange = useCallback(
    (ev) => dispatch(setParams({ itemsPerPage: ev.target.value })),
    [dispatch]
  );

  const onChange = useCallback(
    (ev) =>
      dispatch(
        buildRecipientList({
          [ev.target.name]: ev.target.value,
          errors: omit(errors, ev.target.name),
        })
      ),
    [dispatch, errors]
  );

  const onEditRecipient = useCallback(
    async (recipient) => {
      if (recipient._id === undefined) {
        dispatch(
          addRecipient(
            {
              recipientListId: recipientList._id,
              recipients: [recipient.fields],
            },
            { userId, paid }
          )
        ).then(() => {
          dispatch(getRecipients(params, id));
          dispatch(
            getRecipientList(id, {
              activeDivisionId,
              activeCompanyId,
            })
          );
        });
      } else {
        dispatch(
          updateRecipient(
            recipient._id,
            {
              recipientListId: recipientList._id,
              recipient: recipient.fields,
            },
            { userId }
          )
        ).then(() => {
          dispatch(getRecipients(params, id));
        });
      }
    },
    [dispatch, recipientList]
  );

  const onDeleteRecipient = useCallback(
    (recipient) => {
      dispatch(
        deleteRecipient(recipient._id, { userId, activeDivisionId })
      ).then(() => {
        dispatch(getRecipients(params, id));
        dispatch(
          getRecipientList(id, {
            activeDivisionId,
            activeCompanyId,
          })
        );
      });
    },
    [dispatch, recipientList]
  );

  const onSearchRecipient = useCallback(
    (search) =>
      dispatch(
        setParams(
          { search, searchByFields: keys(recipientList.recipientHeaders) },
          { updateUrl: false }
        )
      ),
    [dispatch, recipientList]
  );

  const onSave = useCallback(() => dispatch(saveRecipientList(params)), [
    dispatch,
    params,
  ]);

  const onDelete = useCallback(async () => {
    await dispatch(deleteRecipientList(recipientList));
    history.replace("/recipient-lists");
  }, [dispatch, recipientList, history]);

  const onDeleteToggle = useCallback(
    () => setShowDeleteDialog(!showDeleteDialog),
    [setShowDeleteDialog, showDeleteDialog]
  );

  const onContinueCampaign = () => {
    history.replace("/campaigns/create");
    dispatch(clearNewRecipientList());
  };

  if (!recipientList) {
    return null;
  }

  const { userId, status } = recipientList;
  const readOnly = user.id !== userId || status === "LOCKED";

  return (
    <Page screenSized>
      <NavigationPrompt
        when={(crntLocation, nextLocation, _action) =>
          !nextLocation || recipientList.status != "COMPLETED"
        }
      >
        {({ onConfirm, onCancel, isActive }) => (
          <NavigationDialog
            onConfirm={onConfirm}
            onCancel={onCancel}
            open={isActive}
          />
        )}
      </NavigationPrompt>
      <Edit
        recipientList={recipientList}
        onChange={onChange}
        onEditRecipient={onEditRecipient}
        onDeleteRecipient={onDeleteRecipient}
        onSearchRecipient={onSearchRecipient}
        recipientsPage={params.page}
        recipientsItemsPerPage={params.itemsPerPage}
        onRecipientsPageChange={onRecipientsPageChange}
        onRecipientsItemsPerPageChange={onRecipientsItemsPerPageChange}
        readOnly={
          readOnly || uploading || hasViewPermission || isCompanyOrDivision
        }
        isEditing={!(recipientList?.status === "LOCKED")}
      />
      {!readOnly && (
        <Controls
          onSave={onSave}
          onDelete={onDeleteToggle}
          onContinueCampaign={onContinueCampaign}
          hasErrors={hasErrors}
          disabled={uploading}
        />
      )}
      <DeleteDialog
        open={showDeleteDialog}
        onClose={onDeleteToggle}
        onDelete={onDelete}
        name={recipientList.name}
        resource={resource}
      />
    </Page>
  );
}

export default memo(EditOrViewRecipientListPage);
