import api from "./api";

const suppressionEndpoint = `/api/v1/suppressionList`;
const bulkSuppressionEndpoint = `/api/v1/suppressionList/bulk`;

export async function getSuppressionList(params) {
  const { data } = await api.get(suppressionEndpoint, { params });
  return data;
}

export async function deleteSuppressionById(suppressInfo) {
  const { _id, type } = suppressInfo;
  const data = { type };
  await api.delete(`${suppressionEndpoint}/${_id}`, { data });
}

// Add single Record (unused, but might be beneficial to revert)
export async function saveSuppressionNumber(body) {
  await api.post(suppressionEndpoint, body);
}

// Add multiple Records to Recipient List
export async function saveSuppressionList(body) {
  await api.post(bulkSuppressionEndpoint, body);
}

/**
 * {
    "id": 8,
    "projectAppId": 6,
    "vendorId": 3,
    "vendorConfig": {
        "sinchShortCodes": [
            "62667"
        ],
        "apiKey": "40bdfbb1294e46979c18df95212b2856",
        "serverLocation": "US",
        "sinchServicePlanId": "dev_RA_REST",
        "sinchMoCallbackUrl": "http://10.10.6.22:3014/appWebhook_event",
        "sinchCallbackUrl": "http://10.10.6.22:3014/appWebhook_event"
    },
    "messageType": "SMS"
}
 */
