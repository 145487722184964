export default async function retry(request, { count = 5, interval = 0 }) {
  let retriedRequest = Promise.reject();

  // add count retries in a sequantial way
  // if a retry fails the next catch block will try again
  // if one of the reties succeeds the other catch blocks will not be invoked
  for (let i = 0; i < count; i++) {
    retriedRequest = retriedRequest
      .catch(request)
      .catch(() => waitDuration(interval));
  }
  return retriedRequest;
}

async function waitDuration(durationMs) {
  // reject after the wait to jump into the next catch block, which fires a retry
  return new Promise((_, reject) => setTimeout(reject, durationMs));
}
