import React, { useCallback, useEffect, useMemo, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleFilterColumn,
  getMasterListHeaders,
} from "../../../../../actions/newRecipientList";
import { Box, Grid, Alert, Divider } from "../../../../../components";
import ColumnPickerSection from "./ColumnPickerSection";

const getSearchableHeaders = (recipientHeaders) =>
  Object.keys(recipientHeaders)
    .filter((header) => recipientHeaders[header].searchable)
    .sort();

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  sections: {
    flex: 1,
    margin: 0,
    width: "100%",
  },
  alert: {
    margin: theme.spacing(2),
    marginBottom: 0,
  },
}));

function MasterListColumnPickerPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const newRecipientList = useSelector((state) => state.newRecipientList);
  const {
    recipientHeaders,
    recipientFilters,
    errors,
    doneStepIdx,
  } = newRecipientList;
  const hasError = Boolean(errors.message);

  useEffect(() => {
    // do no do anything if the user is coming back to this screen from a latter step of the wizard
    // we already have the necessary info in this case and the below logic would just reset some user provided values
    if (doneStepIdx < 0) {
      dispatch(getMasterListHeaders());
    }
  }, [doneStepIdx, dispatch]);

  const searchableHeaders = useMemo(
    () => getSearchableHeaders(recipientHeaders),
    [recipientHeaders]
  );
  const selectedHeaders = searchableHeaders.filter(
    (header) => header in recipientFilters
  );
  const unselectedHeaders = searchableHeaders.filter(
    (header) => !(header in recipientFilters)
  );

  const onHeaderToggle = useCallback(
    (header) => dispatch(toggleFilterColumn(header)),
    [dispatch]
  );

  return (
    <Box className={classes.root}>
      <Alert
        variant="filled"
        severity={hasError ? "error" : "info"}
        className={classes.alert}
      >
        {t("Please select what columns you would like to filter for.")}
      </Alert>
      <Grid container spacing={4} wrap="nowrap" className={classes.sections}>
        <Grid item xs={6} xl={6}>
          <ColumnPickerSection
            title={t("Searchable headers")}
            headers={unselectedHeaders}
            selected={false}
            onToggle={onHeaderToggle}
          />
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={6} xl={6}>
          <ColumnPickerSection
            title={t("Selected headers")}
            headers={selectedHeaders}
            selected={true}
            error={errors.message}
            onToggle={onHeaderToggle}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(MasterListColumnPickerPage);
