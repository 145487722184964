import React, { useState, useCallback, useEffect, useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { debounce } from "lodash";
import {
  Paper,
  TextField,
  Button,
  Grid,
  PhoneInput,
  Chip,
  IconButton,
  Autocomplete,
} from "../../../../components";
import * as seedsApi from "../../../../api/seed";
import { Email, Sms, Hearing, DeleteForever } from "../../../../icons";

const useStyles = makeStyles((theme) => ({
  button: {
    height: "100%",
  },
  buttonContainer: {
    marginLeft: "auto",
  },
}));

const icons = {
  email: Email,
  sms: Sms,
  voice: Hearing,
  tty: Hearing,
};

const _typeInRegexValidation = /^[A-Za-z0-9\@\$\.\:\-\_\,\'\"\s\*\!\;\/\?\&]*$/;
const _pasteInRegexValidation = /[^A-Za-z0-9\@\$\.\:\-\_\,\'\"\s\*\!\;\/\?\&]*/g;

function seedToTest(seedList, testChips, type, recipientCount) {
  const values = seedList[type];
  if (!values || !values.length || !recipientCount) {
    return [];
  }
  return values
    .filter(
      (value) =>
        !testChips.some(
          (testChip) => testChip.type === type && testChip.value === value
        )
    )
    .map((value) => ({ type, value }));
}

function TestCampaign({
  onSendTest,
  activeDivisionId,
  counts,
  emailPlaceHolders,
  smsPlaceHolders,
  ...rest
}) {
  const commonPlaceHolders = {
    content:
      [...new Set([
        ...emailPlaceHolders.content,
        ...smsPlaceHolders.content
      ])]
  };
  const { t } = useTranslation();
  const classes = useStyles();

  const [seedListSearch, setSeedListSearch] = useState("");
  const [seedLists, setSeedLists] = useState([]);

  const [testFields, setTestFields] = useState({});
  const [testChips, setTestChips] = useState([]);

  const [subjectFields, setSubjectFields] = useState({});
  const [contentFields, setContentFields] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const [fieldError, setFieldError] = useState({});
  const freeFormValidation = (prefix, index, message) => {
    setFieldError((prev) => ({ ...prev, [`${prefix}_${index}`]: message }));
  };

  const handleEmailSubjectPlaceholderChange = useCallback(
    (event, key) => {
      const value = event.target.value;
      setSubjectFields({ ...subjectFields, [key]: value });
    },
    [setSubjectFields, subjectFields]
  );

  const handleEmailContentPlaceholderChange = useCallback(
    (event, key) => {
      const value = event.target.value;
      setContentFields({ ...contentFields, [key]: value });
    },
    [setContentFields, contentFields]
  );

  const onChange = useCallback(
    (ev) => setTestFields({ ...testFields, [ev.target.name]: ev.target.value }),
    [setTestFields, testFields]
  );

  const onDeleteTestChip = useCallback(
    (testChip) => setTestChips(testChips.filter((chip) => chip !== testChip)),
    [testChips, setTestChips]
  );

  const onAddTestChip = useCallback(
    (type, value) => {
      if (value && 3 <= value.length) {
        setTestFields({ ...testFields, [type]: "" });
        if (
          !testChips.some(
            (testChip) => testChip.value === value && testChip.type === type
          )
        ) {
          setTestChips([...testChips, { type, value }]);
        }
      }
    },
    [testFields, testChips, setTestChips, setTestFields]
  );

  const onClearTestChips = useCallback(() => setTestChips([]), [setTestChips]);

  const onPressEnter = useCallback(
    (ev) => {
      if (ev.key === "Enter") {
        const type = ev.target.name;
        const value = testFields[type];
        onAddTestChip(type, value);
      }
    },
    [testFields, onAddTestChip]
  );

  const onBlur = useCallback(
    (ev) => {
      const type = ev.target.name;
      const value = testFields[type];
      onAddTestChip(type, value);
    },
    [testFields, onAddTestChip]
  );

  const onSeedListChange = useCallback(
    (target) => setSeedListSearch(target.value),
    [setSeedListSearch]
  );

  const debouncedOnSeedListChange = useMemo(
    () => debounce(onSeedListChange, 300),
    [onSeedListChange]
  );

  const onSeedListAdd = useCallback(
    (ev, seedList) => {
      if (counts)
        setTestChips([
          ...testChips,
          ...seedToTest(seedList, testChips, "email", counts.emailCount),
          ...seedToTest(seedList, testChips, "sms", counts.smsCount),
          ...seedToTest(seedList, testChips, "voice", counts.voiceCount),
          ...seedToTest(seedList, testChips, "tty", counts.ttyCount),
          ...seedToTest(seedList, testChips, "custom", counts.customCount),
        ]);
    },
    [testChips, counts, setTestChips]
  );

  const onSendTestClick = useCallback(async () => {
    setTestChips(await onSendTest(testChips, subjectFields, contentFields));
  }, [onSendTest, testChips, setTestChips, subjectFields, contentFields]);

  useEffect(() => {
    if (counts)
      seedsApi
        .listSeeds({ activeDivisionId, search: seedListSearch || "" })
        .then(({ data }) => {
          const filteredData = data.filter((d) => {
            if (
              (counts.ttyCount && d.tty && d.tty.length) ||
              (counts.smsCount && d.sms && d.sms.length) ||
              (counts.emailCount && d.email && d.email.length) ||
              (counts.customCount && d.custom && d.custom.length) ||
              (counts.voiceCount && d.voice && d.voice.length)
            ) {
              return d;
            }
          });
          setSeedLists(filteredData);
        });
  }, [setSeedLists, activeDivisionId, counts, seedListSearch]);

  return (
    <Paper {...rest} p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md>
          <TextField
            type="email"
            name="email"
            value={testFields.email}
            onChange={onChange}
            label={t("Email")}
            variant="outlined"
            className={classes.input}
            size="small"
            onBlur={onBlur}
            onKeyUp={onPressEnter}
            fullWidth
            data-test-id="campaign-test-email"
            disabled={counts.emailCount ? false : true}
            inputProps={{
              style: {
                backgroundColor: counts.emailCount ? null : "lightgrey",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md>
          <PhoneInput
            inputProps={{
              name: "sms",
              onBlur,
              onKeyUp: onPressEnter,
            }}
            className={classes.input}
            value={testFields.sms || "+1"}
            onChange={(value) => onChange({ target: { name: "sms", value } })}
            label={t("SMS")}
            variant="outlined"
            country="us"
            countryCodeEditable={false}
            size="small"
            fullWidth
            data-test-id="campaign-test-sms"
            backgroundColor={counts.smsCount ? null : "lightgrey"}
            readOnly={counts.smsCount ? false : true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md>
          <PhoneInput
            inputProps={{
              name: "voice",
              onBlur,
              onKeyUp: onPressEnter,
            }}
            value={testFields.voice || "+1"}
            onChange={(value) => onChange({ target: { name: "voice", value } })}
            label={t("Voice")}
            variant="outlined"
            country="us"
            countryCodeEditable={false}
            size="small"
            fullWidth
            data-test-id="campaign-test-voice"
            backgroundColor={counts.voiceCount ? null : "lightgrey"}
            readOnly={counts.voiceCount ? false : true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md>
          <PhoneInput
            inputProps={{
              name: "tty",
              onBlur,
              onKeyUp: onPressEnter,
            }}
            value={testFields.tty || "+1"}
            onChange={(value) => onChange({ target: { name: "tty", value } })}
            label={t("TTY")}
            variant="outlined"
            country="us"
            countryCodeEditable={false}
            size="small"
            fullWidth
            data-test-id="campaign-test-tty"
            backgroundColor={counts.ttyCount ? null : "lightgrey"}
            readOnly={counts.ttyCount ? false : true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md>
          <Autocomplete
            value={null}
            onChange={onSeedListAdd}
            onInputChange={(ev) => debouncedOnSeedListChange(ev.target)}
            filterOptions={(options) => options}
            clearOnEscape
            clearOnBlur
            autoHighlight
            selectOnFocus
            options={seedLists}
            getOptionLabel={(seedList) => seedList.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Add Seed List")}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
            data-test-id="campaign-test-seed-list"
          />
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <Button
            variant="contained"
            className={classes.button}
            // onClick={onSendTestClick}
            onClick={() => {
              if (
                emailPlaceHolders.subject.length ||
                commonPlaceHolders.content.length
              )
                setOpenModal(true);
              else onSendTestClick();
            }}
            disabled={testChips.length === 0}
            data-test-id="campaign-test-button"
          >
            {t("Send Test")}
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems="center"
        data-test-id="campaign-test-chips"
      >
        {testChips.map((testChip) => {
          const Icon = icons[testChip.type];
          return (
            <Grid item key={testChip.value}>
              <Chip
                color={testChip.error ? "secondary" : "default"}
                icon={<Icon />}
                label={testChip.value}
                onDelete={() => onDeleteTestChip(testChip)}
              />
            </Grid>
          );
        })}
        {Boolean(testChips.length) && (
          <Grid item className={classes.buttonContainer}>
            <IconButton
              color="secondary"
              onClick={onClearTestChips}
              data-test-id="campaign-test-chips-clear-button"
            >
              <DeleteForever />
            </IconButton>
          </Grid>
        )}
      </Grid>

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle id="placeholder-form-dialog">
          {t("Fill SMS & Email Target Fields")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {t(
              // eslint-disable-next-line max-len
              "You may provide sample values for the Customer Data placeholders within the test message. If not provided they will be filled with values like variableName-testValue"
            )}
          </DialogContentText>
          <Grid container spacing={2}>
            {0 < emailPlaceHolders.subject.length && (
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Grid container direction="row" spacing={2}>
                  {emailPlaceHolders.subject.map((item, index) => (
                    <Grid key={item} item xs={12} sm={12} md={12} xl={12}>
                      <Grid container direction="row" spacing={1}>
                        <Grid item xs={5} sm={5} md={5} xl={5}>
                          <TextField
                            fullWidth
                            size="small"
                            data-test-id="email-subject-free-form-approval-page"
                            label={`${item} free form`}
                            variant="outlined"
                            onChange={(event) =>
                              handleEmailSubjectPlaceholderChange(event, item)
                            }
                            value={
                              subjectFields?.[item] ? subjectFields?.[item] : ""
                            }
                            inputProps={{ maxLength: 60 }}
                            onKeyDown={(event) => {
                              if (!_typeInRegexValidation.test(event.key)) {
                                freeFormValidation(
                                  "email_sub_placeholder",
                                  index,
                                  `Character "${event.key}" not allowed`
                                );
                                event.preventDefault();
                              } else if (fieldError) {
                                freeFormValidation(
                                  "email_sub_placeholder",
                                  index,
                                  ""
                                );
                              }
                            }}
                            onPaste={(event) => {
                              const content = event.clipboardData.getData(
                                "text"
                              );
                              const invalidCharacters = content
                                .match(_pasteInRegexValidation)
                                .join("");
                              if (invalidCharacters) {
                                freeFormValidation(
                                  "email_sub_placeholder",
                                  index,
                                  `Character(s) not supported: "${invalidCharacters}"`
                                );
                                event.preventDefault();
                              } else {
                                freeFormValidation(
                                  "email_sub_placeholder",
                                  index,
                                  ""
                                );
                              }
                            }}
                            error={Boolean(
                              fieldError[`email_sub_placeholder_${index}`]
                            )}
                            helperText={
                              fieldError[`email_sub_placeholder_${index}`]
                            }
                          ></TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}

            {0 < commonPlaceHolders.content.length && (
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Grid container direction="row" spacing={2}>
                  {commonPlaceHolders.content.map((item, index) => (
                    <Grid key={item} item xs={12} sm={12} md={12} xl={12}>
                      <Grid container direction="row" spacing={1}>
                        <Grid item xs={5} sm={5} md={5} xl={5}>
                          <TextField
                            fullWidth
                            size="small"
                            data-test-id="email-free-form"
                            label={`${item} free form`}
                            variant="outlined"
                            onChange={(event) =>
                              handleEmailContentPlaceholderChange(event, item)
                            }
                            value={
                              contentFields?.[item] ? contentFields?.[item] : ""
                            }
                            inputProps={{ maxLength: 60 }}
                            onKeyDown={(event) => {
                              if (!_typeInRegexValidation.test(event.key)) {
                                freeFormValidation(
                                  "email_content_placeholder",
                                  index,
                                  `Character "${event.key}" not allowed`
                                );
                                event.preventDefault();
                              } else if (fieldError) {
                                freeFormValidation(
                                  "email_content_placeholder",
                                  index,
                                  ""
                                );
                              }
                            }}
                            onPaste={(event) => {
                              const content = event.clipboardData.getData(
                                "text"
                              );
                              const invalidCharacters = content
                                .match(_pasteInRegexValidation)
                                .join("");
                              if (invalidCharacters) {
                                freeFormValidation(
                                  "email_content_placeholder",
                                  index,
                                  `Character(s) not supported: "${invalidCharacters}"`
                                );
                                event.preventDefault();
                              } else {
                                freeFormValidation(
                                  "email_content_placeholder",
                                  index,
                                  ""
                                );
                              }
                            }}
                            error={Boolean(
                              fieldError[`email_content_placeholder_${index}`]
                            )}
                            helperText={
                              fieldError[`email_content_placeholder_${index}`]
                            }
                          ></TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenModal(false)}
            color="primary"
            variant="contained"
            size="large"
          >
            {t("Cancel")}
          </Button>

          <Button
            onClick={() => {
              setOpenModal(false);
              onSendTestClick();
            }}
            color="secondary"
            variant="contained"
            size="large"
          >
            {t("Send Test")}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default memo(TestCampaign);
