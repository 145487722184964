import React, { useMemo, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  ToggleButtonGroup,
  ToggleButton,
  Badge,
} from "../../../../components";
import { LIVE_TYPE, MACHINE_TYPE } from "../constants";

const useStyles = makeStyles({
  toggleButton: {
    flex: 1,
  },
  buttonGroup: {
    width: "100%",
  },
  badge: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});

const hasErrorTypes = (errors, type) =>
  Object.keys(errors).some((key) => key.indexOf(type) !== -1);

const hasSimilarErrorTypes = (errors, type) =>
  Object.keys(errors).some((key) => RegExp(type).test(key));

function VoiceTypeToggle({
  answeringMachine,
  onToggleAnsweringMachine,
  onTabChange,
  activeTab,
  errors,
  readOnly,
  copyLiveToAnseringMachine,
  onToggleCopyLiveToAnsweringMachine,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const hasAnsweringMachine = Boolean(answeringMachine);
  const hasLiveErrors = useMemo(() => hasSimilarErrorTypes(errors, LIVE_TYPE), [
    errors,
  ]);
  const hasMachineErrors = useMemo(() => hasErrorTypes(errors, MACHINE_TYPE), [
    errors,
  ]);

  const copyLive = Boolean(copyLiveToAnseringMachine);
  const handleChange = (_, tab) => {
    if (tab !== null) {
      onToggleAnsweringMachine();
      // clicking on the already active tab, tab = null
      onTabChange(null, tab);
    }
  };

  return (
    <Grid container spacing={2}>
      {/* {!readOnly && (
        <Grid item xs={6} md={6} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                data-test-id="master-template-voice-answering-machine-toggle"
                checked={hasAnsweringMachine}
                onChange={onToggleAnsweringMachine}
                color="primary"
              />
            }
            label={t(
              "I have a different message for voicemails than live calls."
            )}
          />
        </Grid>
      )} */}
      {/* {hasAnsweringMachine && ( */}
      <Grid item xs={6} md={6} sm={6}>
        <ToggleButtonGroup
          data-test-id="master-template-voice-active-tab-toggle"
          size="small"
          color="primary"
          onChange={handleChange}
          value={activeTab}
          exclusive
          fullWidth
          className={classes.buttonGroup}
        >
          <ToggleButton
            color="primary"
            value={LIVE_TYPE}
            className={classes.toggleButton}
          >
            {hasLiveErrors && (
              <Badge badgeContent="!" color="error" className={classes.badge} />
            )}
            {t("Live")}
          </ToggleButton>
          <ToggleButton value={MACHINE_TYPE} className={classes.toggleButton}>
            {hasMachineErrors && (
              <Badge badgeContent="!" color="error" className={classes.badge} />
            )}
            {t("Answering Machine")}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {/* )} */}

      {activeTab === MACHINE_TYPE && (
        <Grid item xs={6} md={6} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                data-test-id="master-template-voice-copy-live-to-answering-machine"
                checked={copyLive}
                onChange={onToggleCopyLiveToAnsweringMachine}
                color="primary"
              />
            }
            label={t(
              "Use the same message for answering machines as for the live calls."
            )}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default memo(VoiceTypeToggle);
