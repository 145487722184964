import api from "./api";

export async function getEonsConfig(params) {
  const { data } = await api.get("/api/v1/project-vendors/eons", {
    params,
  });
  return data;
}

export async function getCalleridDetail(callerIds) {
  const { data } = await api.get("/api/v1/callerid", {
    params: { callerIds },
  });
  return data;
}
