import { INIT_MAP, BUILD_MAP, CLEAR_MAP } from "../actions/map";

export default function mapReducer(map = null, { type, data }) {
  switch (type) {
    case INIT_MAP:
      return {};
    case BUILD_MAP:
      return { ...map, ...data };
    case CLEAR_MAP:
      return null;
    default:
      return map;
  }
}
