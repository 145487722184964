export const INIT_MAP = "INIT_MAP";
export const BUILD_MAP = "BUILD_MAP";
export const CLEAR_MAP = "CLEAR_MAP";

export function initMap() {
  return { type: INIT_MAP };
}

export function buildMap(data) {
  return { type: BUILD_MAP, data };
}

export function clearMap() {
  return { type: CLEAR_MAP };
}
