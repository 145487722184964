import React, { memo } from "react";
import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  drawer: ({ width }) => ({
    width,
    flexShrink: 0,
    whiteSpace: "nowrap",
    overflowX: "hidden",
  }),
  paper: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

function SmallDrawer({
  children,
  width = 200,
  isOpen,
  onOpen,
  onClose,
  className,
  ...rest
}) {
  const classes = useStyles({ width, isOpen });

  return (
    <Drawer
      {...rest}
      anchor="left"
      open={isOpen}
      onClick={onClose}
      onClose={onClose}
      onOpen={onOpen}
      className={classes.drawer}
      classes={{
        paper: classNames(className, classes.paper, classes.drawer),
      }}
    >
      {children}
    </Drawer>
  );
}

export default memo(SmallDrawer);
