import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Grid, Paper, Typography, ButtonBase } from "../../../../../components";
import { DoubleArrow } from "../../../../../icons";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  fullHeight: {
    height: "100%",
  },
  columnPickerButton: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    paddingRight: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  buttonText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: theme.spacing(1),
  },
  reverseIcon: {
    transform: "rotate(180deg)",
  },
  hint: {
    position: "absolute",
    top: "30%",
    transform: "translateY(-50%)",
    width: "100%",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.hint,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

function MasterListColumnPickerSection({
  title,
  headers,
  error,
  onToggle,
  selected,
}) {
  const classes = useStyles();
  const hasError = Boolean(error);

  return (
    <>
      <Typography variant="button" paragraph>
        {title}
      </Typography>
      <Grid
        container
        spacing={2}
        className={classNames(classes.container, {
          [classes.fullHeight]: hasError,
        })}
      >
        {error ? (
          <Typography
            variant="button"
            className={classNames(classes.hint, classes.error)}
          >
            {error}
          </Typography>
        ) : (
          headers.map((header) => (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
              <Paper
                component={ButtonBase}
                className={classes.columnPickerButton}
                onClick={() => onToggle(header)}
              >
                <Typography variant="button" className={classes.buttonText}>
                  {header}
                </Typography>
                <DoubleArrow
                  color={selected ? "secondary" : "primary"}
                  className={classNames({ [classes.reverseIcon]: selected })}
                />
              </Paper>
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
}

export default memo(MasterListColumnPickerSection);
