import React, { memo, useState } from "react";
import {
  capitalize,
  Collapse,
  IconButton,
  makeStyles,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import FileActionList from "../FileAction/FileActionList";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export function FileRow({ timestamp, status, name, fileName, actions }) {
  const { t } = useTranslation();
  return (
    <TableRow key={fileName}>
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell align="left">{t(status)}</TableCell>
      <TableCell align="left">
        {timestamp ? format(new Date(timestamp), "MM/dd/yyyy HH:mm a") : "None"}
      </TableCell>
      <TableCell>
        <FileActionList>{actions}</FileActionList>
      </TableCell>
    </TableRow>
  );
}

export function FilesTable({ children }) {
  const { t } = useTranslation();
  return (
    <Table size="small" aria-label="purchase">
      <TableHead>
        <TableRow>
          <TableCell>{t("File")}</TableCell>
          <TableCell>{t("Status")}</TableCell>
          <TableCell>{t("Timestamp")}</TableCell>
          <TableCell>{t("Action")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  );
}

export function CollapsibleReportRow({ type, children }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="h6" gutterBottom component="div">
            {`${capitalize(type)} Report File(s)`}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto">
            {children}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function ReportListTable({ children }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead></TableHead>
        <TableBody>
          {/* {lists.map((reportType) => (
            <Row
              key={reportType}
              type={reportType}
              data={reportFiles[reportType]}
              actions={actions}
            />
          ))} */}
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(ReportListTable);
