import { combineReducers } from "redux";
import { RESET_STORE } from "../actions/user";
import user from "./user";
import loading from "./loading";
import notification from "./notification";
import recipientLists from "./recipientLists";
import recipientList from "./recipientList";
import report from "./report";
import newRecipientList from "./newRecipientList";
import params from "./params";
import storage from "./storage";
import eonsConfig from "./eonsConfig";
import masterTemplates from "./masterTemplates";
import masterTemplate from "./masterTemplate";
import approvers from "./approvers";
import approver from "./approver";
import seed from "./seed";
import seeds from "./seeds";
import campaign from "./campaign";
import campaigns from "./campaigns";
import approvalData from "./approvalData";
import map from "./map";
import downloadReport from "./downloadReport";
import suppression from "./suppression";
import newSuppressionList from "./newSuppressionList";
import masterListRecipients from "./masterListRecipients";
import keywords from "./keywords";
import dnd from "./dnd";
import dns from "./dns";
import timer from "./timer";
import foldersTemplates from "./foldersTemplates";
import folderTemplate from "./folderTemplate";
import pendingApprovalCampaigns from "./pendingApprovalCampaigns";

const storeReducer = combineReducers({
  user,
  loading,
  notification,
  params,
  storage,
  recipientLists,
  masterListRecipients,
  recipientList,
  report,
  newRecipientList,
  eonsConfig,
  masterTemplates,
  masterTemplate,
  approvers,
  approver,
  seeds,
  seed,
  campaign,
  campaigns,
  approvalData,
  map,
  downloadReport,
  suppression,
  newSuppressionList,
  keywords,
  dnd,
  dns,
  timer,
  foldersTemplates,
  folderTemplate,
  pendingApprovalCampaigns,
});

export default function rootReducer(state = {}, action) {
  if (action.type === RESET_STORE) {
    return {};
  }
  return storeReducer(state, action);
}
