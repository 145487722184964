import React, { useRef, memo } from "react";
import { Page, useMediaQuery } from "../../../components";
import MasterRecipientListsBase from "./Base";

function MasterRecipientsPage() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const listsRef = useRef();
  return (
    <Page p={2} screenSized={!isSmallScreen}>
      <MasterRecipientListsBase ref={listsRef} />
    </Page>
  );
}

export default memo(MasterRecipientsPage);
