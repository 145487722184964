import React, { useCallback, useMemo, useState, memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Button, Menu, MenuItem } from "../../../components";
import { ExpandMore } from "../../../icons";

const useStyles = makeStyles({
  button: {
    height: 40,
  },
});

const orderRecipientHeaders = (recipientHeaders) =>
  Object.keys(recipientHeaders).sort();

function MasterTemplateRecipientFieldSelector({
  onAddRecipientField,
  container,
  disabled,
  ...rest
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const recipientHeaders = useSelector(
    (state) => state.eonsConfig.vendorConfig.recipientHeaders
  );

  const orderedRecipientHeaders = useMemo(
    () => orderRecipientHeaders(recipientHeaders),
    [recipientHeaders]
  );

  const onMenuOpen = useCallback((ev) => setAnchorEl(ev.currentTarget), [
    setAnchorEl,
  ]);
  const onMenuClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  const _onAddRecipientField = useCallback(
    (header) => {
      onMenuClose();
      onAddRecipientField(header);
    },
    [onMenuClose, onAddRecipientField]
  );

  return (
    <>
      <Button
        {...rest}
        size="small"
        className={classNames("ql-params", classes.button)}
        onClick={onMenuOpen}
        endIcon={<ExpandMore />}
        disabled={disabled}
      >
        {t("Customer Data")}
      </Button>
      <Menu
        container={container}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={onMenuClose}
      >
        {orderedRecipientHeaders.map((header) => (
          <MenuItem key={header} onClick={() => _onAddRecipientField(header)}>
            {header}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default memo(MasterTemplateRecipientFieldSelector);
