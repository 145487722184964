import React, { memo } from "react";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textTransform: "uppercase",
    textAlign: "center",
    color: theme.palette.text.hint,
  },
}));

function NotFoundMessage({ message, columns }) {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell colSpan={columns.length}>
        <Typography variant="h6" className={classes.text}>
          {message}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default memo(NotFoundMessage);
