import React, { memo } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import CallerIdPage from "./List";
import CreateEditViewCampaign from "./Single";
import View from "./Single/View";

function InboundCampaignsPage() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:templateId/view`} component={View} />
      <Route path={`${match.path}/:callerId`}>
        <CreateEditViewCampaign />
      </Route>
      <Route path={`${match.path}`}>
        <CallerIdPage />
      </Route>
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}

export default memo(InboundCampaignsPage);
