import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { CloudDone } from "../../../../../../icons";

const useStyles = makeStyles((theme) => ({
  cloudIcon: {
    color: "inherit",
  },
}));

function UploadSuccessIcon({ className, ...rest }) {
  const classes = useStyles();

  return (
    <CloudDone {...rest} className={classNames(className, classes.cloudIcon)} />
  );
}

export default memo(UploadSuccessIcon);
