import React, { useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  ActionButton,
  Grid,
  Search,
  DateTimePicker,
  TextField,
  MenuItem,
  useMediaQuery,
  Checkbox,
  FormControl,
  ListItemText,
} from "../../../components";
import useAuthorized, {
  TEMPLATE_CREATE_PERMISSION,
} from "../../../hooks/useAuthorized";
import { useSelector } from "react-redux";

const getSearchTypeOptions = (t) => [
  {
    label: t("Contains"),
    value: "contains",
  },
  {
    label: t("Starts With"),
    value: "startsWith",
  },
  {
    label: t("Ends With"),
    value: "endsWith",
  },
];

const getPermissions = (t) => [
  {
    label: t("All"),
    value: "all",
  },
  {
    label: t("User"),
    value: "user",
  },
  {
    label: t("Division"),
    value: "division",
  },
  {
    label: t("Company"),
    value: "company",
  },
];

const useStyles = makeStyles((theme) => ({
  createButton: {
    height: "100%",
  },
  searchContainer: {
    display: "flex",
    minWidth: 140,
    maxWidth: 800,
  },
  searchContentContainer: {
    flex: 2,
    minWidth: 140,
    maxWidth: 800,
  },
  permissionContainer: {
    flex: 1,
    minWidth: 140,
    maxWidth: 220,
  },
  headerFieldContainer: {
    flex: 1,
    minWidth: 140,
    maxWidth: 170,
  },
  searchTypeContainer: {
    flex: 1,
    minWidth: 140,
    maxWidth: 160,
    marginRight: 15,
  },
  searchInput: {
    minWidth: 300,
  },
  dateInput: {
    maxWidth: 200,
  },
}));

function MasterTemplatesFilters({
  params,
  onSearchChange,
  onPermissionChange,
  onFromDateChange,
  onToDateChange,
  hideCreate,
  onHeaderFieldsChange,
  onSearchContentChange,
  onSearchTypeChange,
}) {
  const match = useRouteMatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const permissions = useMemo(() => getPermissions(t), [t]);
  const eonsHeader = useSelector(
    (state) => state.eonsConfig.vendorConfig.recipientHeaders
  );
  const canCreate = useAuthorized(TEMPLATE_CREATE_PERMISSION);
  const headerFields = useMemo(() => Object.keys(eonsHeader), [eonsHeader]);

  const handleHeaderFieldsChange = (event) => {
    onHeaderFieldsChange && onHeaderFieldsChange(event.target.value);
  };

  return (
    <>
      <Grid
        container
        xs={12}
        lg={12}
        spacing={2}
        justifyContent="space-between"
      >
        <Grid item className={classes.primaryContainer}>
          <Grid container spacing={2}>
            {!hideCreate && (
              <ActionButton
                classes={{ desktop: classes.createButton }}
                component={Link}
                to={`${match.path}/create`}
                text={t("New Template")}
                data-test-id="template-create-new"
                disabled={!canCreate}
              />
            )}
            <Grid item className={classes.searchContainer}>
              <FormControl size="small" className={classes.searchTypeContainer}>
                <TextField
                  select
                  onChange={onSearchTypeChange}
                  labelId="search-type-label"
                  variant="outlined"
                  label={t("Search Type")}
                  size="small"
                >
                  {getSearchTypeOptions(t).map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <Search
                variant="outlined"
                size="small"
                label={
                  isSmallScreen
                    ? t("Search")
                    : t("Search by name, creator or tag")
                }
                className={classes.searchInput}
                onSearch={onSearchChange}
                defaultValue={params.search}
                debounce={300}
                fullWidth
                testId="template"
              />
            </Grid>
            <Grid item className={classes.searchContentContainer}>
              <Search
                variant="outlined"
                size="small"
                label={
                  isSmallScreen ? t("Search") : t("Search template content")
                }
                onSearch={onSearchContentChange}
                defaultValue={params.searchContent}
                debounce={300}
                fullWidth
                testId="template-search"
              />
            </Grid>
            <Grid item className={classes.permissionContainer}>
              <TextField
                select
                value={params.permission || ""}
                onChange={onPermissionChange}
                label={t("Permission")}
                size="small"
                variant="outlined"
                fullWidth
                data-test-id={`template-permission-select`}
              >
                {permissions.map((permission) => (
                  <MenuItem
                    data-test-id={`template-permission-select-${permission.value}`}
                    key={permission.value}
                    value={permission.value}
                  >
                    {permission.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item className={classes.headerFieldContainer}>
              <TextField
                select
                fullWidth
                label={t("Header")}
                size="small"
                variant="outlined"
                data-test-id="template-header-fields-select"
                SelectProps={{
                  multiple: true,
                  value: params?.headerFields,
                  onChange: handleHeaderFieldsChange,
                  renderValue: (selected) => selected.join(", ").toUpperCase(),
                }}
              >
                {headerFields.map((field) => (
                  <MenuItem key={field} value={field}>
                    <Checkbox
                      checked={params?.headerFields?.indexOf(field) > -1}
                    />
                    <ListItemText primary={String(field).toUpperCase()} />
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} wrap="nowrap">
            <Grid item>
              <DateTimePicker
                label={t("Created after")}
                disableFuture
                date
                displayFormat={"MM/dd/yyyy"}
                defaultValue={params.fromDate}
                maxDate={params.toDate}
                onAccept={onFromDateChange}
                className={classes.dateInput}
                testId="template-after"
              />
            </Grid>
            <Grid item>
              <DateTimePicker
                label={t("Created before")}
                disableFuture
                date
                displayFormat={"MM/dd/yyyy"}
                defaultValue={params.toDate}
                minDate={params.fromDate}
                onAccept={onToDateChange}
                className={classes.dateInput}
                testId="template-before"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <OverwriteDialog
        open={showConfirmDialog}
        onClose={onCloseConfirmDialog}
        onOverwriteList={onOverwriteList}
        name={newRecipientList?.name}
      /> */}
    </>
  );
}

export default memo(MasterTemplatesFilters);
