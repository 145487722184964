import React, { memo, useMemo, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { SmartTable, ActionButton, Grid, Search } from "../../../components";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { getParams } from "../../../utils";

import { Link, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1190,
  },
  smallCell: {
    minWidth: 110,
    maxWidth: 150,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

const getColumns = ({ t, extraColumns = [], type }) => {
  return [
    {
      name: "shortcode",
      label: t("Shortcode"),
      sortable: true,
      align: "center",
      testId: "keyword-shortCode",
    },
    {
      name: "keyword",
      label: t("Keyword"),
      sortable: true,
      width: 2,
      testId: "keyword",
    },
    {
      name: "value",
      label: t("Response"),
      sortable: true,
      width: 2,
      testId: "keyword-response",
    },
    // {
    //   name: "timestamp",
    //   label: t("Created At"),
    //   getter: (list) =>
    //     list.timestamp
    //       ? format(new Date(list.timestamp), "MM/dd/yyyy HH:mm")
    //       : "-",
    //   sortable: true,
    //   align: "center",
    //   testId: "keyword-createAt",
    // },
    // {
    //   name: "createdBy",
    //   label: t("Created By"),
    //   sortable: true,
    //   getter: (list) => (list.createdBy ? list.createdBy : "-"),
    //   align: "center",
    //   testId: "keyword-createBy",
    // },
    ...extraColumns,
  ];
};

function KeywordListPageBase(
  {
    extraColumns,
    onlyStatus,
    disableTable,
    keywordsList,
    canCreate,
    onItemsPerPageChange,
    onSearchChange,
    onPageChange,
    keywordCount,
    type,
  },
  ref
) {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const queryParams = useSelector((state) => state.params);

  const columns = useMemo(() => getColumns({ t, extraColumns, type }), [
    t,
    extraColumns,
    type,
  ]);

  const params = useMemo(() => getParams(queryParams), [queryParams]);

  const totalCount = keywordCount;
  const keywordTxtMap = {
    addBtnTxt: "Add New Keyword",
    noResultTxt: "No keyword found for your filters",
  };

  return (
    <SmartTable
      filters={
        <Grid container spacing={2} justify="space-between">
          <Grid item>
            <Grid container spacing={2}>
              <ActionButton
                text={t(keywordTxtMap.addBtnTxt)}
                disabled={!canCreate}
                component={Link}
                to={`${match.path}/create`}
                data-test-id={"keyword-create-new"}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Search
                  onSearch={onSearchChange}
                  variant="outlined"
                  size="small"
                  label={t("Search")}
                  defaultValue={params.search}
                  debounce={300}
                  fullWidth
                  testId="searchKeyword"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      className={classes.table}
      columns={columns}
      data={keywordsList}
      totalCount={totalCount}
      notFoundMessage={t(keywordTxtMap.noResultTxt)}
      // orderBy={params.orderBy}
      // order={params.order}
      page={params.page}
      itemsPerPage={params.itemsPerPage}
      onSortChange={() => {}}
      onPageChange={onPageChange}
      onItemsPerPageChange={onItemsPerPageChange}
      testId="keywordsList"
      style={{ display: disableTable ? "none" : "table" }}
    />
  );
}

export default memo(forwardRef(KeywordListPageBase));
