import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "../../../../components";

function RecipientListStepResetDialog({
  onReset,
  onClose,
  title,
  text,
  resetText,
  ...rest
}) {
  const { t } = useTranslation();

  return (
    <Dialog {...rest} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-test-id="reset-dialog-button-cancel" onClick={onClose}>
          {t("Cancel")}
        </Button>
        <Button
          data-test-id="reset-dialog-button-reset"
          onClick={onReset}
          color="secondary"
        >
          {resetText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(RecipientListStepResetDialog);
