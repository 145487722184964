import { makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import React, { memo, useMemo, useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { notify } from "../../../actions/notification";
import { Save, DeleteForever } from "../../../icons";
import { updateKeywordById } from "../../../actions/keywords";
import {
  Page,
  Box,
  Grid,
  TextField,
  MenuItem,
  PhoneInput,
  Button,
  Typography,
} from "../../../components";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    overflow: "auto",
  },
  root: {
    padding: `0 ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.grey[200],
    marginTop: "auto",
    marginBottom: 0,
  },
}));

const getShortCodes = ({
  sinchShortCodes = [],
  xmlShortCodes = [],
  jasminShortCodes = [],
}) => [...sinchShortCodes, ...xmlShortCodes, ...jasminShortCodes];

function UpdateKeyword() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = useSelector((state) => state.params);
  // const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const [keyword, setKeyword] = useState({});
  useEffect(() => {
    setKeyword(location.state ? location.state.keyword : {});
  }, [location]);

  const { activeDivisionId, paid } = useSelector((state) => state.storage);

  const { smsConfig } = useSelector((state) => state.eonsConfig);

  const { errors = {} } = useSelector((state) =>
    state.keywords ? state.keywords : {}
  );

  const shortCodes = useMemo(() => getShortCodes(smsConfig), [smsConfig]);

  const onChange = (ev) => {
    const { name, value } = ev.target;
    setKeyword({
      ...keyword,
      [name]: value,
    });
  };

  const onSave = async () => {
    try {
      await dispatch(updateKeywordById(keyword));
      history.replace(`/keyword`);
    } catch (err) {
      if (err.message?.indexOf("400") === -1) {
        dispatch(
          notify({
            type: "error",
            message: err.message,
          })
        );
      }
    }
  };

  const onCancel = useCallback(() => history.replace("/keyword"), [history]);

  const addWordsToKeywordList = (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          component={Typography}
          color="primary"
        >
          {`UPDATE KEYWORD`}
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
          <TextField
            select
            label={t("Short Code")}
            variant="outlined"
            size="small"
            fullWidth
            name="shortCode"
            value={"" + keyword.shortcode}
            onChange={onChange}
            error={Boolean(errors["shortCode"])}
            helperText={
              Boolean(errors["shortCode"]) ? t("must be a valid shortcode") : ""
            }
          >
            {shortCodes.map((shortCode) => (
              <MenuItem key={shortCode} value={shortCode}>
                {shortCode}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
          <TextField
            data-test-id="keyword-create-keyword-field"
            label={t("Keyword")}
            variant="outlined"
            size="small"
            fullWidth
            name="keyword"
            value={keyword.keyword}
            onChange={onChange}
            error={Boolean(errors["keyword"])}
            helperText={
              Boolean(errors["keyword"]) ? t("must not be empty") : ""
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={8} xl={4}>
          <TextField
            data-test-id="keyword-create-response-field"
            multiline
            rows={4}
            rowsMax={8}
            label={t("Response")}
            variant="outlined"
            size="small"
            fullWidth
            name="value"
            value={keyword.value}
            onChange={onChange}
            error={Boolean(errors["value"])}
            helperText={Boolean(errors["value"]) ? t("must not be empty") : ""}
          />
        </Grid>
      </Grid>
    </>
  );

  return (
    <Page screenSized>
      <Box p={2} className={classes.content}>
        <Grid container spacing={2} wrap="nowrap">
          {addWordsToKeywordList}
        </Grid>
      </Box>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justify="flex-end"
        className={classes.root}
      >
        <Grid item>
          <Button
            data-test-id="keyword-create-cancel-button"
            variant="contained"
            color="secondary"
            startIcon={<DeleteForever />}
            onClick={onCancel}
            disabled={false}
          >
            {t("Cancel")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            data-test-id="keyword-create-save-button"
            variant="contained"
            color="primary"
            startIcon={<Save />}
            onClick={onSave}
            disabled={false}
            error={false}
          >
            {t("Update")}
          </Button>
        </Grid>
      </Grid>
    </Page>
  );
}

export default memo(UpdateKeyword);
