import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from ".";

function ConfirmationDialog({
  onClose,
  open,
  name,
  action,
  resource,
  message,
  ...rest
}) {
  const { t } = useTranslation();

  return (
    <Dialog {...rest} open={open} onClose={onClose}>
      <DialogTitle>{t("Confirmation")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t(message)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} data-test-id="dialog-close-button">
          {t("OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ConfirmationDialog);
