import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "../../../components";

function RecipientListOverwriteDialog({
  onClose,
  onOverwriteList,
  open,
  name,
  ...rest
}) {
  const { t } = useTranslation();

  return (
    <Dialog {...rest} open={open} onClose={onClose}>
      <DialogTitle>{t("Overwriting Recipient List")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            "Creating a new recipient list will delete {{name}}. Would you like to continue?",
            {
              name: name ?? t("your in progress list"),
            }
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-test-id="recipientList-overwrite-dialog-button-cancel"
          onClick={onClose}
        >
          {t("Cancel")}
        </Button>
        <Button
          data-test-id="recipientList-overwrite-dialog-button-open-existing"
          component={Link}
          to="/recipient-lists/create"
          color="primary"
        >
          {t("Open Existing List")}
        </Button>
        <Button
          data-test-id="recipientList-overwrite-dialog-button-new"
          onClick={onOverwriteList}
          color="secondary"
        >
          {t("Create New List")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(RecipientListOverwriteDialog);
