import React, { memo, useEffect } from "react";
import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  drawer: ({ isOpen, width }) => ({
    zIndex: 100,
    flexShrink: 0,
    whiteSpace: "nowrap",
    overflowX: "hidden",
    width: isOpen ? width : theme.spacing(7),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration:
        theme.transitions.duration[isOpen ? "leavingScreen" : "enteringScreen"],
    }),
  }),
  paper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  withTopbar: {
    paddingTop: theme.custom.topbarHeight,
  },
  content: {
    marginLeft: theme.spacing(7),
  },
}));

function MediumDrawer({
  children,
  width = 200,
  withTopbar = true,
  className,
  isOpen,
  onOpen,
  onClose,
  ...rest
}) {
  const classes = useStyles({ width, isOpen });

  useEffect(() => {
    window.addEventListener("click", onClose, true);
    return () => window.removeEventListener("click", onClose, true);
  }, [onClose]);

  return (
    <Drawer
      {...rest}
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classNames(className, classes.paper, classes.drawer, {
          [classes.withTopbar]: withTopbar,
        }),
      }}
    >
      {children}
    </Drawer>
  );
}

export default memo(MediumDrawer);
