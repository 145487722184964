import api from "./api";

const dndEndpoint = `/api/v1/dnd-voice`;

export async function listDnd(params) {
  const { data } = await api.get(`${dndEndpoint}`, { params });
  return data;
}

export async function saveDnd(body) {
  const { paid } = body;
  await api.post(`${dndEndpoint}?paid=${paid}`, body);
}

export async function deleteDnd(paid) {
  await api.delete(`${dndEndpoint}?paid=${paid}`);
}

export async function updateDnd(body) {
  const { paid } = body;
  await api.put(`${dndEndpoint}?paid=${paid}`, body);
}
