import { LIST_FOLDERS, CLEAR_FOLDERS } from "../actions/foldersTemplates";

export default function foldersReducer(
  folders = null,
  { type, folders: newFolders }
) {
  switch (type) {
    case LIST_FOLDERS:
      return newFolders;
    case CLEAR_FOLDERS:
      return null;
    default:
      return folders;
  }
}
