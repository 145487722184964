import React, { memo } from "react";
import { CircularProgress, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  colored: ({ color }) => ({
    color: theme.palette[color].main,
  }),
  label: ({ size }) => ({
    fontSize: size / 3.5,
  }),
}));

function CustomCircularProgress({
  withLabel,
  value,
  color = "primary",
  size = 30,
  className,
  ...rest
}) {
  const classes = useStyles({ size, color });

  if (withLabel) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress
          className={classNames(className, classes.colored)}
          variant="static"
          value={value}
          color={color}
          size={size}
          {...rest}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            className={classes.label}
            component="div"
            color="textSecondary"
          >{`${Math.ceil(value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <CircularProgress
      value={value}
      color={color}
      size={size}
      className={classNames(className, classes.colored)}
      {...rest}
    />
  );
}

export default memo(CustomCircularProgress);
