import React, { Fragment, useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textArea: ({}) => ({
    width: "100%",
    height: 120,
    border: "default",
  }),
  textAreaWithError: {
    width: "100%",
    height: 120,
    border: "1px solid red",
  },
  textRed: {
    color: "red",
  },
}));

const MESSAGE_LENGTH = (length) => (length > 160 ? 153 : 160);
const VARIABLE_REGEX = /<%=\s*(.+?)\s*(%>|$)/g;
const URL_SHORTENER_REGEX = /<\^URL_SHORTNER(.+?)(\^>|$)/g;

// eslint-disable-next-line no-control-regex, max-len
const UNICODE_REGEX = /[^\x00-\xFF]|[\x00-\x09]|[\x0B-\x0C]|[\x0E-\x0F]|[\x5B-\x5E]|[\x7B-\xA0]|[\xA2]|[\xA6]|[\xA8-\xBE]|[\xC0-\xC3]|[\xC8]|[\xCA-\xD0]|[\xD2-\xD5]|[\xD8-\xDB]|[\xDD-\xDE]|[\xE1-\xE3]|[\xE7]|[\xEA-\xEB]|[\xED-\xF0]|[\xF3-\xF5]|[\xF7]|[\xFA-\xFB]|[\xFD-\xFF]/g;

const TextBoxWithValidation = ({
  template = "",
  useCharacterCount = false,
  useUnicodeDetection = false,
  error = false,
  helperText = "",
  setRef,
  disabled,
  ...InputProps
}) => {
  // Parent Variables
  const character_count = template
    ? template
        .replace(URL_SHORTENER_REGEX, "https://mb1.io/sampleShortURL") // Approximate shortUrl length
        .replace(VARIABLE_REGEX, "$1").length // Use the variable name as the approximation
    : 0;
  const [numberOfMessages, setNumberOfMessages] = useState(
    Math.ceil(template ? template.length : 0 / MESSAGE_LENGTH(character_count))
  );
  const classes = useStyles({});
  const templateRef = useRef();

  // State Variables
  const [unicodeDetected, setUnicodeDetected] = useState(false);
  const [unicodeCharacters, setUnicodeCharacters] = useState([]);
  const [variableDetected, setVariableDetected] = useState(false);
  const [urlDetected, setUrlDetected] = useState(false);

  // Determine Unicode and number of Messages
  useEffect(() => {
    // Exclude carats (^) from unicode detection if they belong to variable
    const _template = template
      ? template.replace(URL_SHORTENER_REGEX, "$1")
      : "";

    let unicode_detected = UNICODE_REGEX.test(_template);
    setUnicodeDetected(unicode_detected);

    setVariableDetected(VARIABLE_REGEX.test(template));
    setUrlDetected(URL_SHORTENER_REGEX.test(template));

    let MAX_CHARS_PER_MESSAGE = 160; // Non-unicode, single message max
    if (unicode_detected) {
      MAX_CHARS_PER_MESSAGE = 70; // Unicode, single message max

      const unique_characters = new Set(_template.match(UNICODE_REGEX));
      setUnicodeCharacters(Array.from(unique_characters));
    }

    if (character_count > MAX_CHARS_PER_MESSAGE) {
      MAX_CHARS_PER_MESSAGE = 153; // Non-unicode, multiple message max

      if (unicode_detected) {
        MAX_CHARS_PER_MESSAGE = 66; // Unicode, multiple message max
      }
    }

    setNumberOfMessages(Math.ceil(character_count / MAX_CHARS_PER_MESSAGE));
  }, [template, character_count]);

  useEffect(() => {
    setRef(templateRef);
  }, [templateRef]);

  return (
    <Fragment>
      <textarea
        disabled={disabled}
        className={error ? classes.textAreaWithError : classes.textArea}
        tag="textarea"
        value={template}
        ref={templateRef}
        {...InputProps}
      ></textarea>
      <p
        // eslint-disable-next-line max-len
        className="MuiFormHelperText-root-549 MuiFormHelperText-contained-551 Mui-error MuiFormHelperText-marginDense-550"
      >
        {helperText}
      </p>
      {useCharacterCount ? (
        <div className="d-block text-right mt-2">
          {character_count} characters -{" "}
          {character_count ? numberOfMessages : 0} message
          {numberOfMessages === 1 ? "" : "s"}
        </div>
      ) : null}

      {InputProps.maxLength && (
        <small className="d-block text-right mt-2">
          {character_count >= InputProps.maxLength && (
            <span className="text-danger">MAX CHARACTERS MET - </span>
          )}
          {character_count} / {InputProps.maxLength} characters
        </small>
      )}

      {variableDetected ? (
        <small style={{ display: "block" }}>
          Note: Variable content may increase or decrease the actual message
          length. The current message length is displayed using the variable
          name as the placeholder count.
        </small>
      ) : null}

      {urlDetected ? (
        <small style={{ display: "block" }}>
          Note: The actual characters added by your Short URL will depend on
          your configured domain, we have provided an approximation for your
          convenience.
        </small>
      ) : null}

      {useUnicodeDetection && unicodeDetected ? (
        <small className={classes.textRed}>
          {unicodeCharacters.length} Unicode character
          {unicodeCharacters.length === 1 ? "" : "s"} detected:{" "}
          <span style={{ fontFamily: "Courier New, monospace" }}>
            "{unicodeCharacters.join('", "')}"
          </span>
          {useCharacterCount && (
            <span>
              <br />
              This will reduce the number of characters allowed per message
            </span>
          )}
        </small>
      ) : null}
    </Fragment>
  );
};

export default TextBoxWithValidation;
