import i18n from "i18next";
import { batch } from "react-redux";
import * as api from "../api/recipientLists";
import { clearNewRecipientList } from "./newRecipientList";
import { notify } from "./notification";

export const CLEAR_RECIPIENT_LIST = "CLEAR_RECIPIENT_LIST";
export const BUILD_RECIPIENT_LIST = "BUILD_RECIPIENT_LIST";

export function clearRecipientList() {
  return { type: CLEAR_RECIPIENT_LIST };
}

export function getRecipientList(id, params) {
  return async (dispatch) => {
    try {
      dispatch(
        buildRecipientList(
          {
            errors: {},
            recipients: {},
            name: "",
            permission: "",
            loading: true,
          },
          { overwrite: true }
        )
      );
      const recipientList = await api.getRecipientList(id, params);
      dispatch(buildRecipientList({ ...recipientList, loading: false }));
    } catch (err) {
      buildRecipientList({ loading: false, notFound: true });
    }
  };
}

export function getRecipients(params) {
  return async (dispatch) => {
    const recipients = await api.listRecipients(params);
    dispatch(buildRecipientList({ recipients }));
  };
}

export function buildRecipientList(recipientListData, { overwrite } = {}) {
  return { type: BUILD_RECIPIENT_LIST, recipientListData, overwrite };
}

export function saveRecipientList(params) {
  return async (dispatch, getState) => {
    const { recipientList, storage } = getState();
    const { _id, name, permission } = recipientList;
    const { activeDivisionId } = storage;
    // udpdate the recipient list metadata on the api
    try {
      const recipientList = await api.updateRecipientList(
        _id,
        {
          name,
          permission,
        },
        { activeDivisionId }
      );
      dispatch(
        notify({
          type: "success",
          message: i18n.t("Successfully saved {{listName}}", {
            listName: recipientList.name,
          }),
        })
      );
    } catch (err) {
      dispatch(buildRecipientList({ errors: err.response.data }));
    }
  };
}

export function deleteRecipientList({ _id, name }) {
  return async (dispatch, getState) => {
    const { newRecipientList } = getState();
    const { storage } = getState();
    const { activeDivisionId } = storage;

    // clear the recipient list at the API if it is already saved
    // local recipient lists do not yet have an _id
    if (_id) {
      await api.deleteRecipientList(_id, { activeDivisionId });
    }

    batch(() => {
      // if the deleted recipient list is the new one clear it locally
      // this also gets invoked when recipientList._id is still undefined (nothing is saved to the API)
      if (newRecipientList?._id === _id) {
        dispatch(clearNewRecipientList());
      }
      dispatch(
        notify({
          type: "success",
          message: i18n.t("{{name}} successfully deleted", {
            name: name ?? i18n.t("Recipient list"),
          }),
        })
      );
    });
  };
}

export function dipRecipientList(list, params = {}) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { paid, activeDivisionId } = storage;
    const { _id, name } = list;
    if (_id) {
      try {
        await api.dipRecipientList(_id, {
          ...params,
          paid,
          activeDivisionId,
        });
        dispatch(
          notify({
            type: "success",
            message: i18n.t("{{name}} successfully validated", {
              name: i18n.t("Recipient list"),
            }),
          })
        );
      } catch (err) {
        dispatch(
          notify({
            type: "error",
            message: i18n.t("{{listName}} data validation failed", {
              listName: name,
            }),
          })
        );
      }
    }
  };
}

export function generateRecipientListCSV({ _id }) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { activeDivisionId } = storage;
    if (_id) {
      try {
        await api.generateRecipientListCSV(_id, { activeDivisionId });
        dispatch(
          notify({
            type: "success",
            message: i18n.t(
              "Successfully queued receipient file for generation"
            ),
          })
        );
      } catch (err) {
        dispatch(
          notify({
            type: "error",
            message: i18n.t("Failed to generate receipient file"),
          })
        );
      }
    }
  };
}

export function cloneRecipientList({ _id }) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { activeDivisionId } = storage;
    if (_id) {
      try {
        await api.cloneRecipientList(_id, { activeDivisionId });
        dispatch(
          notify({
            type: "success",
            message: i18n.t("Successfully cloned recipient list"),
          })
        );
      } catch (err) {
        dispatch(
          notify({
            type: "error",
            message: i18n.t("Failed to generate clone recipient list"),
          })
        );
      }
    }
  };
}

export function downloadList({ _id }) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { activeDivisionId } = storage;
    if (_id) {
      await api.downloadList(_id, { activeDivisionId });
    }
  };
}

export function deDuplicateList({ _id, recipientHeaders }) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { activeDivisionId } = storage;
    if (_id) {
      await api.deDulicateRecipientList(_id, recipientHeaders, {
        activeDivisionId,
      });
    }
  };
}
