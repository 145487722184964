import React, {
  useCallback,
  useRef,
  useMemo,
  memo,
  useState,
  useEffect,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import {
  Grid,
  TextField,
  Tooltip,
  MenuItem,
  TextEditor,
} from "../../../components";
import RecipientFieldSelector from "./RecipientFieldSelector";
import { Info as InfoIcon } from "../../../icons";
import TemplateFields from "./CustomTemplate/TemplateFields";
import { isArray } from "lodash";

const useStyles = makeStyles({
  testButton: {
    height: 40,
  },
  flexDivider: {
    marginLeft: "auto",
  },
  testSms: {
    height: 40,
  },
});

function CustomTemplateSection({
  customTemplate = {},
  customConfig,
  onCustomFieldChange,
  errors = {},
  readOnly,
}) {
  const templateRef = useRef();
  const classes = useStyles();
  const { template = "", _id: templateId } = customTemplate;
  const readOnlyProps = useMemo(() => ({ readOnly }), [readOnly]);
  const [isCustomField, setIsCustomField] = useState(false);
  const [customFieldData, setCustomFieldData] = useState({});
  let fields = null;

  try {
    fields = customConfig.template ? JSON.parse(customConfig.template) : null;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("error parsing template string");
  }

  const onAddRecipientField = useCallback(
    (header) => {
      const { current: input } = templateRef;
      const { selectionEnd } = input;
      const textToInsert = `<%= ${header} %>`;

      onCustomFieldChange({
        target: {
          name: "template",
          value:
            template.slice(0, selectionEnd) +
            textToInsert +
            template.slice(selectionEnd),
        },
      });
      setTimeout(() => {
        input.focus();
        const newCursorIdx = selectionEnd + textToInsert.length;
        input.setSelectionRange(newCursorIdx, newCursorIdx);
      });
    },
    [template, onCustomFieldChange, templateRef]
  );

  const {
    vendorConfig: { createCustomerTemplateData },
  } = useSelector((state) => state.eonsConfig);

  const setCustomFieldInitialData = () => {
    const items = repopulateFieldsFromPreviousSaved();
    if (isArray(items) && items.length > 0) {
      items.map((item) => {
        customFieldData[item.key] = item.value;
        setCustomFieldData(customFieldData);
      });
    }

    // there is race condition between setCustomFieldData and setIsCustomField
    // due to that missing key value
    setTimeout(() => {
      if (fields && fields.length > 0) {
        setIsCustomField(true);
      }
    }, 600);
  };

  const repopulateFieldsFromPreviousSaved = () => {
    const result = [];
    if (template) {
      const data = JSON.parse(template);
      Object.keys(data).map((key) => {
        fields.map((item) => {
          if (item.key === key) {
            const newData = item;
            newData.value = data[key];
            result.push(newData);
          }
        });
      });
    }

    return result;
  };

  useEffect(() => {
    setCustomFieldInitialData();
  }, [template, onCustomFieldChange, templateRef]);

  const canUseCustomerData = createCustomerTemplateData;
  if (isCustomField) {
    return (
      <TemplateFields
        onAddRecipientField={onAddRecipientField}
        customFieldData={customFieldData}
        fields={fields}
        onCustomFieldChange={onCustomFieldChange}
      />
    );
  } else {
    return (
      <Grid
        container
        direction="column"
        spacing={2}
        data-test-id="master-template-custom-section"
      >
        {canUseCustomerData && (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <RecipientFieldSelector
              onAddRecipientField={onAddRecipientField}
              data-test-id="master-template-custom-variable-section"
              disabled={readOnly}
            />
          </Grid>
        )}
        <Grid item>
          <TextField
            data-test-id="master-template-custom-template-field"
            multiline
            rows={4}
            rowsMax={8}
            variant="outlined"
            fullWidth
            name="template"
            value={template}
            onChange={onCustomFieldChange}
            error={Boolean(errors["customTemplate.template"])}
            helperText={errors["customTemplate.template"]}
            InputProps={readOnlyProps}
            inputRef={templateRef}
            disabled={readOnly}
          />
        </Grid>
        {templateId && (
          <Grid item className={classes.flexDivider}>
            <Tooltip title={templateId} placement="right" interactive>
              <InfoIcon color="disabled" style={{ height: "auto" }} />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default memo(CustomTemplateSection);
