import * as reportsApi from "../api/report";

export const SET_EMAIL_REPORT = "SET_EMAIL_REPORT";
export const SET_SMS_REPORT = "SET_SMS_REPORT";
export const SET_VOICE_REPORT = "SET_VOICE_REPORT";
export const SET_TTY_REPORT = "SET_TTY_REPORT";
export const SET_CUSTOM_REPORTS = "SET_CUSTOM_REPORT";
export const SET_CAMPAIGN_RECIPIENTS = "SET_CAMPAIGN_RECIPIENTS";
export const CLEAR_CAMPAIGN_RECIPIENTS = "CLEAR_CAMPAIGN_RECIPIENTS";
export const DOWNLOAD_REPORT = "DOWNLOAD_REPORT";

export function getEmailReport(campaignId, params) {
  return async (dispatch) => {
    const report = await reportsApi.getCampaignStatusPercentages(campaignId, {
      ...params,
      contactType: "email",
    });
    dispatch({ type: SET_EMAIL_REPORT, report });
  };
}

export function getSmsReport(campaignId, params) {
  return async (dispatch) => {
    const report = await reportsApi.getCampaignStatusPercentages(campaignId, {
      ...params,
      contactType: "sms",
    });
    dispatch({ type: SET_SMS_REPORT, report });
  };
}
export function getVoiceReport(campaignId, params) {
  return async (dispatch) => {
    const report = await reportsApi.getCampaignStatusPercentages(campaignId, {
      ...params,
      contactType: "voice",
    });
    dispatch({ type: SET_VOICE_REPORT, report });
  };
}

export function getTTYReport(campaignId, params) {
  return async (dispatch) => {
    const report = await reportsApi.getCampaignStatusPercentages(campaignId, {
      ...params,
      contactType: "tty",
    });
    dispatch({ type: SET_TTY_REPORT, report });
  };
}

export function getCustomReport(campaignId, params) {
  return async (dispatch) => {
    const report = await reportsApi.getCampaignStatusPercentages(campaignId, {
      ...params,
      contactType: "custom_channel",
    });
    dispatch({ type: SET_CUSTOM_REPORTS, report });
  };
}

export function getReport(campaignId, params) {
  return async (dispatch) => {
    dispatch(getEmailReport(campaignId, params));
    dispatch(getSmsReport(campaignId, params));
    dispatch(getVoiceReport(campaignId, params));
    dispatch(getTTYReport(campaignId, params));
    dispatch(getCustomReport(campaignId, params));
  };
}

export function getCampaignRelatedRecipients(campaignId, params) {
  return async (dispatch) => {
    const recipients = await reportsApi.getCampaignRelatedRecipients(
      campaignId,
      params
    );
    dispatch({ type: SET_CAMPAIGN_RECIPIENTS, report: recipients });
  };
}

export function clearCampaignRelatedRecipients() {
  return async (dispatch) => {
    dispatch({ type: CLEAR_CAMPAIGN_RECIPIENTS });
  };
}
