import React, { useMemo, useCallback, useEffect, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Wizard, Page, Box } from "../../../components";
import { setCampaign } from "../../../actions/campaign";
import { setCallerIdForInboundTemplate } from "../../../actions/masterTemplates";

import SelectMasterTemplate from "./SelectMasterTemplate";
import FillData from "./FillData";

const useStyles = makeStyles({
  content: {
    flex: 1,
    height: 0,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  wizard: {
    marginTop: "auto",
  },
});

const getSteps = ({ t, path, saveTemplate }) => [
  {
    label: t("Choose Template"),
    path: `${path}/template`,
    component: SelectMasterTemplate,
    validate: saveTemplate,
  },
  {
    label: t("Preview"),
    path: `${path}/data`,
    component: FillData,
  },
];

function SingleCampaignPage() {
  const dispatch = useDispatch();
  const { callerId } = useParams();
  const history = useHistory();
  const match = useRouteMatch();
  const classes = useStyles();
  const campaign = useSelector((state) => state.campaign);
  const { t } = useTranslation();
  const { stepIdx = -1, templateId, inboundTemplateId } = campaign || {};

  const onStepChange = useCallback(
    (activeStepIdx) => {
      dispatch(setCampaign({ activeStepIdx, modified: false, errors: {} }));
    },
    [dispatch]
  );

  const onSave = useCallback(async () => {
    await dispatch(
      setCallerIdForInboundTemplate({ templateId, inboundTemplateId, callerId })
    );
    history.push(`/inbound`);
  }, [dispatch, templateId, inboundTemplateId, history]);

  const saveTemplate = useCallback(() => {
    if (campaign.templateId && campaign.inboundTemplateId) {
      dispatch(
        setCampaign({
          ...campaign,
          stepIdx: 1,
          modified: false,
          errors: {},
        })
      );
      return true;
    } else {
      return false;
    }
  }, [dispatch, campaign]);

  const steps = useMemo(
    () =>
      getSteps({
        t,
        path: match.url,
        saveTemplate,
      }),
    [t, match, saveTemplate]
  );

  const currStep = steps[Math.min(steps.length - 1, stepIdx + 1)];

  return (
    <Page p={2}>
      <Box p={2} className={classes.content}>
        <Switch>
          {steps.slice(0, stepIdx + 2).map((step) => (
            <Route
              key={step.path}
              path={step.path}
              component={step.component}
            />
          ))}
          <Redirect to={currStep.path} />
        </Switch>
      </Box>
      <Wizard
        steps={steps}
        className={classes.wizard}
        doneStepIdx={stepIdx}
        onStepChange={onStepChange}
        disableNext={false}
        onSave={onSave}
        pushHistory
      />
    </Page>
  );
}

export default memo(SingleCampaignPage);
