import React, { useMemo, useEffect, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { matchPath, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Box, useMediaQuery } from "@material-ui/core";
import DesktopWizard from "./Desktop";
import MobileWizard from "./Mobile";

const getActiveStepIdx = ({ steps, location, doneStepIdx }) =>
  Math.min(
    Math.max(
      steps
        .map((step) => matchPath(location.pathname, { path: step.path }))
        .findIndex(Boolean),
      // fall back the the first step as the default
      0
    ),
    // do not let the active step be after the last in progress step
    doneStepIdx + 1
  );

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
  },
}));

function Wizard({ className, onStepChange, steps, doneStepIdx, ...rest }) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const classes = useStyles();
  const location = useLocation();

  const activeStepIdx = useMemo(
    () => getActiveStepIdx({ steps, location, doneStepIdx }),
    [steps, location, doneStepIdx]
  );

  useEffect(() => {
    if (onStepChange) {
      onStepChange(activeStepIdx);
    }
  }, [onStepChange, activeStepIdx]);

  const activeStep = steps[activeStepIdx];
  const prevStep = steps[activeStepIdx - 1];
  const nextStep = steps[activeStepIdx + 1];

  const ResponsiveWizard = isSmallScreen ? MobileWizard : DesktopWizard;

  return (
    <Box px={2} py={1} className={classNames(className, classes.root)}>
      <ResponsiveWizard
        {...rest}
        steps={steps}
        activeStepIdx={activeStepIdx}
        doneStepIdx={doneStepIdx}
        activeStep={activeStep}
        nextStep={nextStep}
        prevStep={prevStep}
      />
    </Box>
  );
}

export default memo(Wizard);
