import {
  CLEAR_RECIPIENT_LIST,
  BUILD_RECIPIENT_LIST,
} from "../actions/recipientList";

export default function recipientListReducer(
  recipientList = null,
  { type, recipientListData, overwrite }
) {
  switch (type) {
    case BUILD_RECIPIENT_LIST: {
      if (overwrite) {
        return recipientListData;
      }
      return { ...recipientList, ...recipientListData };
    }
    case CLEAR_RECIPIENT_LIST:
      return null;
    default:
      return recipientList;
  }
}
