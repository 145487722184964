import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 400,
  },
  root: {
    marginTop: 5,
    width: "100%",
    backgroundColor: "#4c4c4c",
    color: "yellow",
    height: 300,
    overflow: "auto",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
}));

export default function MoveTemplateDialog({
  t,
  open,
  _onClose,
  _onMove,
  templateName,
  data = [],
}) {
  const classes = useStyles();
  const [folder, setFolder] = useState(null);

  const moveTemplate = () => {
    _onMove(folder);
  };

  return (
    <Dialog
      key={`dialog-move-${templateName}`}
      open={open}
      onClose={_onClose}
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <Box style={{ wordWrap: "breakWord", width: 400 }}>
        <DialogTitle>{`Move Template '${templateName.toUpperCase()}' to:`}</DialogTitle>
      </Box>
      <DialogContent className={classes.dialog}>
        <Autocomplete
          id="template-move-box"
          options={data}
          getOptionLabel={(option) => option.name}
          onChange={(event, item) => setFolder(item)}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Folder"
              variant="outlined"
              size="small"
            />
          )}
          ListboxProps={{
            style: {
              maxHeight: 400,
              fontSize: 14,
            },
          }}
          noOptionsText={t("No FoldersTemplates Found!")}
          renderOption={(option) => {
            return (
              <Typography style={{ marginLeft: 10 * option.depth }}>
                {option.name}
              </Typography>
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={_onClose} color="primary">
          {t("Cancel")}
        </Button>
        <Button
          color="primary"
          onClick={moveTemplate}
          disabled={!Boolean(folder)}
        >
          {t("Move")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
