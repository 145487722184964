import {
  SET_APPROVAL_DATA,
  REJECT_CAMPAIGN,
  APPROVE_CAMPAIGN,
} from "../actions/approvalData";

export default function userReducer(
  approvalData = null,
  { type, approvalData: newApprovalData }
) {
  switch (type) {
    case SET_APPROVAL_DATA:
      return newApprovalData;
    case REJECT_CAMPAIGN:
      return approvalData;
    case APPROVE_CAMPAIGN:
      return approvalData;
    default:
      return approvalData;
  }
}
