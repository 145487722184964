import { sub, endOfDay, startOfDay } from "date-fns";

export function getParams(queryParams, activeDivisionId) {
  // pick the usable params, do not include extra params that are not used by this page
  const {
    search,
    fromDate,
    toDate,
    page,
    itemsPerPage,
    orderBy,
    order,
    permission,
  } = queryParams;

  // add default values to the usable params
  // the end of the current day to include the whole day as the user would expect
  const defaultToDate = endOfDay(Date.now()).getTime();
  // the start of the day to include the whole day as the user would expect
  const defaultFromDate = startOfDay(
    sub(defaultToDate, { months: 12 })
  ).getTime();

  return {
    search: search ?? "",
    permission,
    fromDate: fromDate ?? defaultFromDate,
    toDate: toDate ?? defaultToDate,
    page: page ?? 0,
    itemsPerPage: itemsPerPage ?? 20,
    orderBy: orderBy ?? "createdAt",
    order: order ?? -1,
    activeDivisionId,
  };
}
