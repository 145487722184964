import React, { memo, useEffect, useState, useCallback, useMemo } from "react";
import { getMasterTemplate } from "../../../../api/masterTemplate";
import { getRecipientList } from "../../../../api/recipientLists";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Grid, MenuItem, TextEditor } from "../../../../components";
import { buildCampaign } from "../../../../actions/campaign";
import { omit, intersection } from "lodash";
import VoicePreview from "../VoicePreview";
import TTYPreview from "../TTYPreview";
import { Button } from "@material-ui/core";
import { IconButton } from "../../../../components";
import { DeleteForever } from "../../../../icons";
import { CKEditor } from "ckeditor4-react";

const useStyles = makeStyles((theme) => ({
  textCenter: { textAlign: "center" },
  greyColor: { color: "grey" },
}));

const _typeInRegexValidation = /^[A-Za-z0-9\@\$\.\:\-\_\,\'\"\s\*\!\;\/\?\&]*$/;
const _pasteInRegexValidation = /[^A-Za-z0-9\@\$\.\:\-\_\,\'\"\s\*\!\;\/\?\&]*/g;

export function getPlaceHolders(templateString = "") {
  const regex = /<%=(\s*\w+\s*)%>/g;
  const results = [];
  let result;

  // tslint:disable-next-line: no-conditional-assignment
  while ((result = regex.exec(templateString))) {
    if (result && Array.isArray(result)) {
      if (results.indexOf(result[1].trim()) == -1) {
        results.push(result[1].trim());
      }
    }
  }

  return results;
}
export function getEmailPlaceHolders(templateString = "") {
  templateString = templateString
    .replace(/<%/g, "&lt;%")
    .replace(/%>/g, "%&gt;");
  const regex = /&lt;%=?(\s*\w+\s*)%&gt;/g;
  const results = [];
  let result;

  // tslint:disable-next-line: no-conditional-assignment
  while ((result = regex.exec(templateString))) {
    if (result && Array.isArray(result)) {
      if (results.indexOf(result[1].trim()) == -1) {
        results.push(result[1].trim());
      }
    }
  }

  return results;
}

function getVoicePlaceHolders(voiceTemplate) {
  if (!voiceTemplate) {
    return [];
  }
  return voiceTemplate
    .filter((live) => live.stepType === "customerData")
    .filter((live) => !live.transcript)
    .map((live) => live.variableName);
}

function FillData() {
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.campaign);
  const [fieldError, setFieldError] = useState({});
  const freeFormValidation = (prefix, index, message) => {
    setFieldError((prev) => ({ ...prev, [`${prefix}_${index}`]: message }));
  };

  const {
    emailSubjectTargetFieldsSetup,
    emailTargetFieldsSetup,
    smsTargetFieldsSetup,
    customTargetFieldsSetup,
    liveVoiceTargetFieldsSetup,
    answeringVoiceTargetFieldsSetup,
    ttyTargetFieldsSetup,
    ejsTargetFieldsSetup,
    templateId: campaignMasterTemplateId,
    lists: campaignLists = [],
    errors = {},
  } = campaign || {};
  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );

  const paid = useSelector((state) => state.storage.paid);

  const classes = useStyles();

  const [emailTemplate, setEmailTemplate] = useState();
  const [emailSubject, setEmailSubject] = useState();
  const [smsTemplate, setSmsTemplate] = useState();
  const [customTemplate, setCustomTemplate] = useState();
  const [liveVoiceTemplate, setLiveVoiceTemplate] = useState();
  const [answeringVoiceTemplate, setAnsweringVoiceTemplate] = useState();
  const [advanceVoiceTemplate, setAdvanceVoiceTemplate] = useState();
  const [ttyTemplate, setTtyTemplate] = useState();
  const [ejsTemplateData, setEjsData] = useState([]);
  const [ejsInputData, setEjsInputData] = useState({});
  const [recipientHeaders, setRecipientHeaders] = useState([]);
  const activeCompanyId = useSelector((state) => state.user.companyId);

  useEffect(() => {
    let ejsArr = [];
    for (let k in ejsTargetFieldsSetup) {
      ejsArr.push({
        field: k,
        freeFormValue: ejsTargetFieldsSetup[k].freeForm
          ? ejsTargetFieldsSetup[k].value
          : "",
        value: ejsTargetFieldsSetup[k].freeForm
          ? ""
          : ejsTargetFieldsSetup[k].value,
      });
    }
    setEjsData(ejsArr);
  }, []);
  useEffect(() => {
    async function fetchCampaignData() {
      const getParams = {
        activeDivisionId,
        activeCompanyId,
        paid,
      };
      const masterTemplate = await getMasterTemplate(
        campaignMasterTemplateId,
        getParams
      );

      setEmailSubject(masterTemplate?.emailTemplate?.subjectEmail);
      setEmailTemplate(masterTemplate?.emailTemplate?.template);
      setSmsTemplate(masterTemplate?.smsTemplate?.template);
      setCustomTemplate(masterTemplate?.customTemplate?.template);
      setLiveVoiceTemplate(
        masterTemplate?.voiceTemplate?.freeswitch?.dialPlan?.person?.steps
      );
      setAnsweringVoiceTemplate(
        masterTemplate?.voiceTemplate?.freeswitch?.dialPlan?.machine?.steps
      );
      setAdvanceVoiceTemplate(masterTemplate?.voiceTemplate?.advanceVoice);
      setTtyTemplate(
        masterTemplate?.ttyTemplate?.freeswitch?.dialPlan?.person?.steps
      );

      const fetchingRecipientLists = campaignLists.map(async (list) =>
        getRecipientList(list, getParams)
      );

      const recipientLists = await Promise.all(fetchingRecipientLists);
      if (recipientLists.length === 0) {
        return;
      }

      const recipientHeaderCollections = recipientLists.map((recipientList) =>
        Object.keys(recipientList.recipientHeaders)
      );

      const uniqueRecipientHeaders = intersection(
        ...recipientHeaderCollections
      );

      setRecipientHeaders(uniqueRecipientHeaders);
    }

    fetchCampaignData();
  }, [
    setRecipientHeaders,
    campaignMasterTemplateId,
    campaignLists,
    activeDivisionId,
    activeCompanyId,
    paid,
  ]);

  const emailPlaceHolders = useMemo(() => getPlaceHolders(emailTemplate), [
    emailTemplate,
  ]);

  const emailSubjectPlaceHolders = useMemo(
    () => getPlaceHolders(emailSubject),
    [emailSubject]
  );
  const smsPlaceHolders = useMemo(() => getPlaceHolders(smsTemplate), [
    smsTemplate,
  ]);
  const customPlaceHolders = useMemo(() => getPlaceHolders(customTemplate), [
    customTemplate,
  ]);

  const liveVoicePlaceHolders = useMemo(
    () => getVoicePlaceHolders(liveVoiceTemplate),
    [liveVoiceTemplate]
  );

  const answeringVoicePlaceHolders = useMemo(
    () => getVoicePlaceHolders(answeringVoiceTemplate),
    [answeringVoiceTemplate]
  );

  const handleEmailChange = useCallback(
    (event, templateValue, freeForm) => {
      const recipientHeaderValue = event.target.value;
      dispatch(
        buildCampaign({
          emailTargetFieldsSetup: {
            ...emailTargetFieldsSetup,
            [templateValue]: {
              freeForm,
              value: recipientHeaderValue,
            },
          },
          errors: omit(
            errors,
            "emailTargetFieldsSetup",
            `emailTargetFieldsSetup.${templateValue}.value`
            // because of the OR connection between channels, we have to wipe the other channel related errors also
            // "smsTargetFieldsSetup",
            // "liveVoiceTargetFieldsSetup"
          ),
        })
      );
    },
    [dispatch, emailTargetFieldsSetup, errors]
  );

  const handleEjsChange = (event) => {
    setEjsInputData({
      ...ejsInputData,
      [event.target.name]: event.target.value,
    });
  };

  const addEjsField = () => {
    setEjsData([...ejsTemplateData, ejsInputData]);
    setEjsInputData({});
    dispatch(
      buildCampaign({
        ejsTargetFieldsSetup: {
          ...ejsTargetFieldsSetup,
          [ejsInputData.field]: {
            freeForm: ejsInputData.freeFormValue ? true : false,
            value: ejsInputData.value
              ? ejsInputData.value
              : ejsInputData.freeFormValue,
          },
        },
        errors: omit(
          errors,
          "ejsTargetFieldsSetup",
          `ejsTargetFieldsSetup.${ejsInputData.field}.value`
        ),
      })
    );
  };

  const onDelete = (field) => {
    let ejsFilterData = ejsTemplateData.filter((e) => e.field != field);
    setEjsData(ejsFilterData);
  };

  const handleEmailSubjectChange = useCallback(
    (event, templateValue, freeForm) => {
      const recipientHeaderValue = event.target.value;
      dispatch(
        buildCampaign({
          emailSubjectTargetFieldsSetup: {
            ...emailSubjectTargetFieldsSetup,
            [templateValue]: {
              freeForm,
              value: recipientHeaderValue,
            },
          },
          errors: omit(
            errors,
            "emailSubjectTargetFieldsSetup",
            `emailSubjectTargetFieldsSetup.${templateValue}.value`
          ),
        })
      );
    },
    [dispatch, emailSubjectTargetFieldsSetup, errors]
  );

  const handleSmsChange = useCallback(
    (event, templateValue, freeForm) => {
      const recipientHeaderValue = event.target.value;
      dispatch(
        buildCampaign({
          smsTargetFieldsSetup: {
            ...smsTargetFieldsSetup,
            [templateValue]: {
              freeForm,
              value: recipientHeaderValue,
            },
          },
          errors: omit(
            errors,
            "smsTargetFieldsSetup",
            `smsTargetFieldsSetup.${templateValue}.value`
            // because of the OR connection between channels, we have to wipe the other channel related errors also
            // "emailTargetFieldsSetup",
            // "liveVoiceTargetFieldsSetup"
          ),
        })
      );
    },
    [dispatch, smsTargetFieldsSetup, errors]
  );
  const handleCustomChange = useCallback(
    (event, templateValue, freeForm) => {
      const recipientHeaderValue = event.target.value;
      dispatch(
        buildCampaign({
          customTargetFieldsSetup: {
            ...customTargetFieldsSetup,
            [templateValue]: {
              freeForm,
              value: recipientHeaderValue,
            },
          },
          errors: omit(
            errors,
            "customTargetFieldsSetup",
            `customTargetFieldsSetup.${templateValue}.value`
          ),
        })
      );
    },
    [dispatch, customTargetFieldsSetup, errors]
  );

  const handleLiveVoiceChange = useCallback(
    (event, templateValue, freeForm) => {
      const recipientHeaderValue = event.target.value;
      dispatch(
        buildCampaign({
          liveVoiceTargetFieldsSetup: {
            ...liveVoiceTargetFieldsSetup,
            [templateValue]: {
              freeForm,
              value: recipientHeaderValue,
            },
          },
          errors: omit(
            errors,
            "liveVoiceTargetFieldsSetup",
            `liveVoiceTargetFieldsSetup.${templateValue}.value`
            // because of the OR connection between channels, we have to wipe the other channel related errors also
            // 'emailTargetFieldsSetup',
            // 'smsTargetFieldsSetup'
          ),
        })
      );
    },
    [dispatch, liveVoiceTargetFieldsSetup, errors]
  );

  const handleAnsweringVoiceChange = useCallback(
    (event, templateValue, freeForm) => {
      const recipientHeaderValue = event.target.value;
      dispatch(
        buildCampaign({
          answeringVoiceTargetFieldsSetup: {
            ...answeringVoiceTargetFieldsSetup,
            [templateValue]: {
              freeForm,
              value: recipientHeaderValue,
            },
          },
          errors: omit(
            errors,
            "answeringVoiceTargetFieldsSetup",
            `answeringVoiceTargetFieldsSetup.${templateValue}.value`
          ),
        })
      );
    },
    [dispatch, answeringVoiceTargetFieldsSetup, errors]
  );

  const recipientHeaderMenuItems = useMemo(() => {
    if (recipientHeaders.length) {
      return recipientHeaders.map((recipientHeader) => (
        <MenuItem
          key={recipientHeader}
          data-test-id={`select-option-${recipientHeader}`}
          value={recipientHeader}
        >
          {recipientHeader}
        </MenuItem>
      ));
    } else {
      return <MenuItem></MenuItem>;
    }
  }, [recipientHeaders]);

  const ejsHeaderMenuItems = useMemo(() => {
    if (recipientHeaders.length) {
      const ejsHeaders = recipientHeaders.filter(
        (r) =>
          ejsTemplateData.findIndex((et) => et.field === r) === -1 &&
          !emailPlaceHolders.includes(r)
      );
      return ejsHeaders.map((recipientHeader) => (
        <MenuItem
          key={recipientHeader}
          data-test-id={`select-option-${recipientHeader}`}
          value={recipientHeader}
        >
          {recipientHeader}
        </MenuItem>
      ));
    } else {
      return <MenuItem></MenuItem>;
    }
  }, [recipientHeaders, ejsTemplateData]);

  const handleOnInstanceReady = () => { };

  return (
    <>
      <Grid container direction="column" spacing={2}>
        {emailTemplate && (
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <h2>Email Preview</h2>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <h4 className={classes.greyColor}>Email Subject:</h4>
                <TextField
                  fullWidth
                  size="small"
                  data-test-id="fill-data-email-subject-preview"
                  value={emailSubject}
                  readOnly
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <h4 className={classes.greyColor}>Email Body:</h4>
                <CKEditor
                  readOnly={true}
                  debug={false}
                  config={{
                    versionCheck: false,
                    allowedContent: true,
                    extraAllowedContent: "*{*}",
                    toolbar: [],
                  }}
                  onInstanceReady={handleOnInstanceReady}
                  name="email-preview"
                  initData={emailTemplate}
                />
              </Grid>
              {0 < emailPlaceHolders.length && (
                <section>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <h2>Fill Email Target Fields</h2>
                    <h3 className={classes.greyColor}>
                      Fill the data of your campaign, either as free form or
                      select from your list
                    </h3>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Grid container direction="row" spacing={2}>
                      {emailPlaceHolders.map((templateValue, index) => (
                        <Grid
                          key={templateValue}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          xl={12}
                        >
                          <Grid container direction="row" spacing={1}>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                fullWidth
                                size="small"
                                data-test-id="email-free-form"
                                error={
                                  Boolean(fieldError[`email_${index}`]) ||
                                  Boolean(
                                    errors?.[
                                    `emailTargetFieldsSetup.${templateValue}.value`
                                    ]
                                  ) ||
                                  Boolean(errors.emailTargetFieldsSetup)
                                }
                                helperText={
                                  fieldError[`email_${index}`] ||
                                  errors?.[
                                  `emailTargetFieldsSetup.${templateValue}.value`
                                  ] ||
                                  errors.emailTargetFieldsSetup
                                }
                                label={`${templateValue} free form`}
                                variant="outlined"
                                onChange={(event) =>
                                  handleEmailChange(event, templateValue, true)
                                }
                                value={
                                  emailTargetFieldsSetup?.[templateValue]
                                    ?.freeForm
                                    ? emailTargetFieldsSetup?.[templateValue]
                                      ?.value
                                    : ""
                                }
                                inputProps={{ maxLength: 60 }}
                                onKeyDown={(event) => {
                                  // if (!/^[ A-Za-z0-9@$.:-]*$/.test(event.key)) {
                                  if (!_typeInRegexValidation.test(event.key)) {
                                    freeFormValidation(
                                      "email",
                                      index,
                                      `Character "${event.key}" not allowed`
                                    );
                                    event.preventDefault();
                                  } else if (fieldError) {
                                    freeFormValidation("email", index, "");
                                  }
                                }}
                                onPaste={(event) => {
                                  const content = event.clipboardData.getData(
                                    "text"
                                  );
                                  const invalidCharacters = content
                                    .match(_pasteInRegexValidation)
                                    .join("");
                                  if (invalidCharacters) {
                                    freeFormValidation(
                                      "email",
                                      index,
                                      `Chracter(s) not supported: "${invalidCharacters}"`
                                    );
                                    event.preventDefault();
                                  } else {
                                    freeFormValidation("email", index, "");
                                  }
                                }}
                              ></TextField>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} xl={2}>
                              <p className={classes.textCenter}>OR</p>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                fullWidth
                                size="small"
                                data-test-id="email-list-column"
                                label={`${templateValue} list column`}
                                variant="outlined"
                                select
                                error={
                                  Boolean(
                                    errors?.[
                                    `emailTargetFieldsSetup.${templateValue}.value`
                                    ]
                                  ) || Boolean(errors.emailTargetFieldsSetup)
                                }
                                helperText={
                                  errors?.[
                                  `emailTargetFieldsSetup.${templateValue}.value`
                                  ] || errors.emailTargetFieldsSetup
                                }
                                value={
                                  !emailTargetFieldsSetup?.[templateValue]
                                    ?.freeForm
                                    ? emailTargetFieldsSetup?.[templateValue]
                                      ?.value ?? ""
                                    : ""
                                }
                                onChange={(event) =>
                                  handleEmailChange(event, templateValue, false)
                                }
                              >
                                {recipientHeaderMenuItems}
                              </TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </section>
              )}
              {0 < emailSubjectPlaceHolders.length && (
                <section>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <h2>Fill Email Subject Target Fields</h2>
                    <h3 className={classes.greyColor}>
                      Fill the data of your campaign, either as free form or
                      select from your list
                    </h3>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Grid container direction="row" spacing={2}>
                      {emailSubjectPlaceHolders.map((templateValue) => (
                        <Grid
                          key={templateValue}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          xl={12}
                        >
                          <Grid container direction="row" spacing={1}>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                fullWidth
                                size="small"
                                data-test-id="email-subject-free-form"
                                error={
                                  Boolean(
                                    errors?.[
                                    `emailSubjectTargetFieldsSetup.${templateValue}.value`
                                    ]
                                  ) ||
                                  Boolean(errors.emailSubjectTargetFieldsSetup)
                                }
                                helperText={
                                  errors?.[
                                  `emailSubjectTargetFieldsSetup.${templateValue}.value`
                                  ] || errors.emailSubjectTargetFieldsSetup
                                }
                                label={`${templateValue} free form`}
                                variant="outlined"
                                onChange={(event) =>
                                  handleEmailSubjectChange(
                                    event,
                                    templateValue,
                                    true
                                  )
                                }
                                value={
                                  emailSubjectTargetFieldsSetup?.[templateValue]
                                    ?.freeForm
                                    ? emailSubjectTargetFieldsSetup?.[
                                      templateValue
                                    ]?.value
                                    : ""
                                }
                              >
                                {recipientHeaderMenuItems}
                              </TextField>
                            </Grid>
                            <Grid item xs={2} md={2} xl={2}>
                              <p className={classes.textCenter}>OR</p>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                fullWidth
                                size="small"
                                data-test-id="email-subject-list-column"
                                label={`${templateValue} list column`}
                                variant="outlined"
                                select
                                error={
                                  Boolean(
                                    errors?.[
                                    `emailSubjectTargetFieldsSetup.${templateValue}.value`
                                    ]
                                  ) ||
                                  Boolean(errors.emailSubjectTargetFieldsSetup)
                                }
                                helperText={
                                  errors?.[
                                  `emailSubjectTargetFieldsSetup.${templateValue}.value`
                                  ] || errors.emailSubjectTargetFieldsSetup
                                }
                                value={
                                  !emailSubjectTargetFieldsSetup?.[
                                    templateValue
                                  ]?.freeForm
                                    ? emailSubjectTargetFieldsSetup?.[
                                      templateValue
                                    ]?.value ?? ""
                                    : ""
                                }
                                onChange={(event) =>
                                  handleEmailSubjectChange(
                                    event,
                                    templateValue,
                                    false
                                  )
                                }
                              >
                                {recipientHeaderMenuItems}
                              </TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </section>
              )}
            </Grid>
          </Grid>
        )}
        {smsTemplate && (
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <h2>SMS Preview</h2>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <TextField
                  fullWidth
                  value={smsTemplate}
                  multiline
                  inputProps={{ readOnly: true }}
                  rows={4}
                  variant="outlined"
                ></TextField>
              </Grid>
              {0 < smsPlaceHolders.length && (
                <section>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <h2>Fill SMS Target Fields</h2>
                    <h3 className={classes.greyColor}>
                      Fill the data of your campaign, either as free form or
                      select from your list
                    </h3>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Grid container direction="row" spacing={2}>
                      {smsPlaceHolders.map((templateValue, index) => (
                        <Grid
                          key={templateValue}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          xl={12}
                        >
                          <Grid container direction="row" spacing={1}>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                fullWidth
                                size="small"
                                data-test-id="sms-free-form"
                                label={`${templateValue} free form`}
                                variant="outlined"
                                onChange={(event) =>
                                  handleSmsChange(event, templateValue, true)
                                }
                                error={
                                  Boolean(fieldError[`sms_${index}`]) ||
                                  Boolean(
                                    errors?.[
                                    `smsTargetFieldsSetup.${templateValue}.value`
                                    ]
                                  ) ||
                                  Boolean(errors.smsTargetFieldsSetup)
                                }
                                helperText={
                                  fieldError[`sms_${index}`] ||
                                  errors?.[
                                  `smsTargetFieldsSetup.${templateValue}.value`
                                  ] ||
                                  errors.smsTargetFieldsSetup
                                }
                                value={
                                  smsTargetFieldsSetup?.[templateValue]
                                    ?.freeForm
                                    ? smsTargetFieldsSetup?.[templateValue]
                                      ?.value
                                    : ""
                                }
                                inputProps={{ maxLength: 60 }}
                                onKeyDown={(event) => {
                                  if (!_typeInRegexValidation.test(event.key)) {
                                    freeFormValidation(
                                      "sms",
                                      index,
                                      `Character "${event.key}" not allowed`
                                    );
                                    event.preventDefault();
                                  } else if (fieldError) {
                                    freeFormValidation("sms", index, "");
                                  }
                                }}
                                onPaste={(event) => {
                                  const content = event.clipboardData.getData(
                                    "text"
                                  );
                                  const invalidCharacters = content
                                    .match(_pasteInRegexValidation)
                                    .join("");
                                  if (invalidCharacters) {
                                    freeFormValidation(
                                      "sms",
                                      index,
                                      `Chracter(s) not supported: "${invalidCharacters}"`
                                    );
                                    event.preventDefault();
                                  } else {
                                    freeFormValidation("sms", index, "");
                                  }
                                }}
                              ></TextField>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} xl={2}>
                              <p className={classes.textCenter}>OR</p>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                fullWidth
                                data-test-id="sms-list-column"
                                size="small"
                                label={`${templateValue} list column`}
                                variant="outlined"
                                select
                                error={
                                  Boolean(
                                    errors?.[
                                    `smsTargetFieldsSetup.${templateValue}.value`
                                    ]
                                  ) || Boolean(errors.smsTargetFieldsSetup)
                                }
                                helperText={
                                  errors?.[
                                  `smsTargetFieldsSetup.${templateValue}.value`
                                  ] || errors.smsTargetFieldsSetup
                                }
                                value={
                                  !smsTargetFieldsSetup?.[templateValue]
                                    ?.freeForm
                                    ? smsTargetFieldsSetup?.[templateValue]
                                      ?.value ?? ""
                                    : ""
                                }
                                onChange={(event) =>
                                  handleSmsChange(event, templateValue, false)
                                }
                              >
                                {recipientHeaderMenuItems}
                              </TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </section>
              )}
            </Grid>
          </Grid>
        )}
        {customTemplate && (
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <h2>Custom Preview</h2>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <TextField
                  fullWidth
                  value={customTemplate}
                  multiline
                  inputProps={{ readOnly: true }}
                  rows={4}
                  variant="outlined"
                ></TextField>
              </Grid>
              {0 < customPlaceHolders.length && (
                <section>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <h2>Fill Custom Target Fields</h2>
                    <h3 className={classes.greyColor}>
                      Fill the data of your campaign, either as free form or
                      select from your list
                    </h3>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Grid container direction="row" spacing={2}>
                      {customPlaceHolders.map((templateValue) => (
                        <Grid
                          key={templateValue}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          xl={12}
                        >
                          <Grid container direction="row" spacing={1}>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                fullWidth
                                size="small"
                                data-test-id="custom-free-form"
                                label={`${templateValue} free form`}
                                variant="outlined"
                                onChange={(event) =>
                                  handleCustomChange(event, templateValue, true)
                                }
                                error={
                                  Boolean(
                                    errors?.[
                                    `customTargetFieldsSetup.${templateValue}.value`
                                    ]
                                  ) || Boolean(errors.customTargetFieldsSetup)
                                }
                                helperText={
                                  errors?.[
                                  `customTargetFieldsSetup.${templateValue}.value`
                                  ] || errors.customTargetFieldsSetup
                                }
                                value={
                                  customTargetFieldsSetup?.[templateValue]
                                    ?.freeForm
                                    ? customTargetFieldsSetup?.[templateValue]
                                      ?.value
                                    : ""
                                }
                              ></TextField>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} xl={2}>
                              <p className={classes.textCenter}>OR</p>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                fullWidth
                                data-test-id="custom-list-column"
                                size="small"
                                label={`${templateValue} list column`}
                                variant="outlined"
                                select
                                error={
                                  Boolean(
                                    errors?.[
                                    `customTargetFieldsSetup.${templateValue}.value`
                                    ]
                                  ) || Boolean(errors.customTargetFieldsSetup)
                                }
                                helperText={
                                  errors?.[
                                  `customTargetFieldsSetup.${templateValue}.value`
                                  ] || errors.customTargetFieldsSetup
                                }
                                value={
                                  !customTargetFieldsSetup?.[templateValue]
                                    ?.freeForm
                                    ? customTargetFieldsSetup?.[templateValue]
                                      ?.value ?? ""
                                    : ""
                                }
                                onChange={(event) =>
                                  handleCustomChange(
                                    event,
                                    templateValue,
                                    false
                                  )
                                }
                              >
                                {recipientHeaderMenuItems}
                              </TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </section>
              )}
            </Grid>
          </Grid>
        )}
        {liveVoiceTemplate && (
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <h2>Live Voice Preview</h2>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <VoicePreview voiceArray={liveVoiceTemplate} />
              </Grid>
              {0 < liveVoicePlaceHolders.length && (
                <section>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <h2>Fill Live Voice Target Fields</h2>
                    <h3 className={classes.greyColor}>
                      Fill the data of your campaign, either as free form or
                      select from your list
                    </h3>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Grid container direction="row" spacing={2}>
                      {liveVoicePlaceHolders.map((templateValue) => (
                        <Grid
                          key={templateValue}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          xl={12}
                        >
                          <Grid container direction="row" spacing={1}>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                fullWidth
                                size="small"
                                data-test-id="liveVoice-free-form"
                                label={`${templateValue} free form`}
                                variant="outlined"
                                error={
                                  Boolean(
                                    errors?.[
                                    `liveVoiceTargetFieldsSetup.${templateValue}.value`
                                    ]
                                  ) ||
                                  Boolean(errors.liveVoiceTargetFieldsSetup)
                                }
                                helperText={
                                  errors?.[
                                  `liveVoiceTargetFieldsSetup.${templateValue}.value`
                                  ] || errors.liveVoiceTargetFieldsSetup
                                }
                                onChange={(event) =>
                                  handleLiveVoiceChange(
                                    event,
                                    templateValue,
                                    true
                                  )
                                }
                                value={
                                  liveVoiceTargetFieldsSetup?.[templateValue]
                                    ?.freeForm
                                    ? liveVoiceTargetFieldsSetup?.[
                                      templateValue
                                    ]?.value
                                    : ""
                                }
                              ></TextField>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} xl={2}>
                              <p className={classes.textCenter}>OR</p>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                fullWidth
                                size="small"
                                data-test-id="liveVoice-list-column"
                                label={`${templateValue} list column`}
                                variant="outlined"
                                select
                                error={
                                  Boolean(
                                    errors?.[
                                    `liveVoiceTargetFieldsSetup.${templateValue}.value`
                                    ]
                                  ) ||
                                  Boolean(errors.liveVoiceTargetFieldsSetup)
                                }
                                helperText={
                                  errors?.[
                                  `liveVoiceTargetFieldsSetup.${templateValue}.value`
                                  ] || errors.liveVoiceTargetFieldsSetup
                                }
                                value={
                                  !liveVoiceTargetFieldsSetup?.[templateValue]
                                    ?.freeForm
                                    ? liveVoiceTargetFieldsSetup?.[
                                      templateValue
                                    ]?.value ?? ""
                                    : ""
                                }
                                onChange={(event) =>
                                  handleLiveVoiceChange(
                                    event,
                                    templateValue,
                                    false
                                  )
                                }
                              >
                                {recipientHeaderMenuItems}
                              </TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </section>
              )}
            </Grid>
          </Grid>
        )}
        {answeringVoiceTemplate && (
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <h2>Answering Voice Preview</h2>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <VoicePreview voiceArray={answeringVoiceTemplate} />
              </Grid>
              {0 < answeringVoicePlaceHolders.length && (
                <section>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <h2>Fill Answering Voice Target Fields</h2>
                    <h3 className={classes.greyColor}>
                      Fill the data of your campaign, either as free form or
                      select from your list
                    </h3>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Grid container direction="row" spacing={2}>
                      {answeringVoicePlaceHolders.map((templateValue) => (
                        <Grid
                          key={templateValue}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          xl={12}
                        >
                          <Grid container direction="row" spacing={1}>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                fullWidth
                                data-test-id="answeringVoice-free-form"
                                size="small"
                                label={`${templateValue} free form`}
                                variant="outlined"
                                onChange={(event) =>
                                  handleAnsweringVoiceChange(
                                    event,
                                    templateValue,
                                    true
                                  )
                                }
                                error={
                                  Boolean(
                                    errors?.[
                                    `answeringVoiceTargetFieldsSetup.${templateValue}.value`
                                    ]
                                  ) ||
                                  Boolean(
                                    errors.answeringVoiceTargetFieldsSetup
                                  )
                                }
                                helperText={
                                  errors?.[
                                  `answeringVoiceTargetFieldsSetup.${templateValue}.value`
                                  ] || errors.answeringVoiceTargetFieldsSetup
                                }
                                value={
                                  answeringVoiceTargetFieldsSetup?.[
                                    templateValue
                                  ]?.freeForm
                                    ? answeringVoiceTargetFieldsSetup?.[
                                      templateValue
                                    ]?.value
                                    : ""
                                }
                              ></TextField>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} xl={2}>
                              <p className={classes.textCenter}>OR</p>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                data-test-id="answeringVoice-list-column"
                                fullWidth
                                size="small"
                                label={`${templateValue} list column`}
                                variant="outlined"
                                select
                                error={
                                  Boolean(
                                    errors?.[
                                    `answeringVoiceTargetFieldsSetup.${templateValue}.value`
                                    ]
                                  ) ||
                                  Boolean(
                                    errors.answeringVoiceTargetFieldsSetup
                                  )
                                }
                                helperText={
                                  errors?.[
                                  `answeringVoiceTargetFieldsSetup.${templateValue}.value`
                                  ] || errors.answeringVoiceTargetFieldsSetup
                                }
                                value={
                                  !answeringVoiceTargetFieldsSetup?.[
                                    templateValue
                                  ]?.freeForm
                                    ? answeringVoiceTargetFieldsSetup?.[
                                      templateValue
                                    ]?.value ?? ""
                                    : ""
                                }
                                onChange={(event) =>
                                  handleAnsweringVoiceChange(
                                    event,
                                    templateValue,
                                    false
                                  )
                                }
                              >
                                {recipientHeaderMenuItems}
                              </TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </section>
              )}
            </Grid>
          </Grid>
        )}
        {advanceVoiceTemplate && (
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12} md={12} xl={22}>
                <h2>Advance Voice Preview</h2>
              </Grid>
              <section>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <h3 className={classes.greyColor}>
                    Advance Voice does not have preview
                  </h3>
                </Grid>
              </section>
            </Grid>
          </Grid>
        )}
        {ttyTemplate && (
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <h2>TTY Preview</h2>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <TTYPreview tty={ttyTemplate[0].path} />
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* Below is not required as of now vide ECS-2720 */}
        {!1 && (
          <Grid item>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <h2>Advance Fields</h2>
              <h3 className={classes.greyColor}>
                Fill the data for ejs template
              </h3>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              {ejsTemplateData.map((ejs) => (
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={3} sm={3} md={3} xl={3}>
                    <TextField
                      fullWidth
                      size="small"
                      data-test-id="ejs-list-column"
                      label="Ejs template field name"
                      variant="outlined"
                      select
                      value={ejs.field}
                      disabled
                      name="field"
                    >
                      {recipientHeaderMenuItems}
                    </TextField>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} xl={1}></Grid>
                  <Grid item xs={3} sm={3} md={3} xl={3}>
                    <TextField
                      fullWidth
                      size="small"
                      data-test-id="ejs-free-form"
                      label="free form value"
                      variant="outlined"
                      value={ejs.freeFormValue}
                      disabled
                    ></TextField>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} xl={1}>
                    <p className={classes.textCenter}>OR</p>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} xl={3}>
                    <TextField
                      fullWidth
                      size="small"
                      data-test-id="ejs-list-column"
                      label="Mapped list column"
                      variant="outlined"
                      select
                      value={ejs.value}
                      name="value"
                      disabled
                    >
                      {recipientHeaderMenuItems}
                    </TextField>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} xl={1}>
                    <IconButton
                      size="small"
                      color="secondary"
                      onClick={() => onDelete(ejs.field)}
                    >
                      <DeleteForever fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Grid container direction="row" spacing={1}>
                <Grid item xs={3} sm={3} md={3} xl={3}>
                  <TextField
                    fullWidth
                    size="small"
                    data-test-id="ejs-list-column"
                    label="Ejs template field name"
                    variant="outlined"
                    select
                    value={ejsInputData.field}
                    onChange={(event) => handleEjsChange(event)}
                    name="field"
                  >
                    {ejsHeaderMenuItems}
                  </TextField>
                </Grid>
                <Grid item xs={1} sm={1} md={1} xl={1}></Grid>
                <Grid item xs={3} sm={3} md={3} xl={3}>
                  <TextField
                    fullWidth
                    size="small"
                    data-test-id="ejs-free-form"
                    label="free form value"
                    variant="outlined"
                    onChange={(event) => handleEjsChange(event)}
                    value={ejsInputData.freeFormValue}
                    name="freeFormValue"
                  ></TextField>
                </Grid>
                <Grid item xs={1} sm={1} md={1} xl={1}>
                  <p className={classes.textCenter}>OR</p>
                </Grid>
                <Grid item xs={3} sm={3} md={3} xl={3}>
                  <TextField
                    fullWidth
                    size="small"
                    data-test-id="ejs-list-column"
                    label="Mapped list column"
                    variant="outlined"
                    select
                    value={ejsInputData.value}
                    onChange={(event) => handleEjsChange(event)}
                    name="value"
                  >
                    {recipientHeaderMenuItems}
                  </TextField>
                </Grid>
                <Grid item xs={1} sm={1} md={1} xl={1}>
                  <Button
                    onClick={addEjsField}
                    disabled={!ejsHeaderMenuItems.length}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default memo(FillData);
