import React, { useCallback, memo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IconButton, Tooltip } from "../../../components";
import { Visibility, Edit, DeleteForever, FileCopy } from "../../../icons";
import { useTranslation } from "react-i18next";
import useAuthorized, {
  TEMPLATE_UPDATE_PERMISSION,
} from "../../../hooks/useAuthorized";

function MasterTemplatesActions({ masterTemplate, onDelete, onClone }) {
  const { _id, userId, status } = masterTemplate;
  const { t } = useTranslation();
  const hasEditPermission = useAuthorized(TEMPLATE_UPDATE_PERMISSION);

  const canEdit =
    useSelector((state) => state.user.id === userId) || hasEditPermission;
  const isTemplateLocked = status === "LOCKED";
  const EditIcon = canEdit && !isTemplateLocked ? Edit : Visibility;

  const _onDelete = useCallback(() => onDelete(masterTemplate), [
    onDelete,
    masterTemplate,
  ]);

  const _onClone = useCallback(() => onClone(masterTemplate), [
    onClone,
    masterTemplate,
  ]);

  return (
    <>
      <Tooltip
        title={
          canEdit && !isTemplateLocked ? t("Edit template") : t("View template")
        }
      >
        <IconButton
          data-test-id={`template-action-edit-${_id}`}
          component={Link}
          to={`/master-templates/${_id}`}
          size="small"
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Delete template")}>
        <IconButton
          data-test-id={`template-action-delete-${_id}`}
          size="small"
          color="secondary"
          onClick={_onDelete}
        >
          <DeleteForever fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Clone template")}>
        <IconButton
          data-test-id={`template-action-copy-${_id}`}
          size="small"
          onClick={_onClone}
        >
          <FileCopy fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default memo(MasterTemplatesActions);
