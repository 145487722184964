import React, { memo, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "..";
import { logout } from "../../actions/user";
import {
  addMoreTime,
  getCurrentTimerTime,
  resetTimer,
} from "../../actions/timer";
import { getMe } from "../../actions/user";

function Timer({}) {
  const [open, setOpen] = useState(false);
  const [openRenewSession, setOpenRenewSession] = useState(false);
  const timeoutTime = 1800000; // 30 minutes
  const lastTwoMinutes = 1680000; // 28 minutes
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onLogout = useCallback(() => dispatch(logout()), [dispatch]);

  const [intval, setIntval] = useState({});
  useEffect(() => {
    const intval = setInterval(async () => {
      let timerTime = await dispatch(getCurrentTimerTime());
      timerTime += 1000;
      if (timerTime >= timeoutTime) {
        setOpen(true);
        dispatch(resetTimer());
        setOpenRenewSession(false);
        timerTime = 0;
        clearInterval(intval);
      }

      if (timerTime === lastTwoMinutes) {
        setOpenRenewSession(true);
      }

      dispatch(addMoreTime(timerTime));
    }, 1000);

    setIntval(intval);

    //reset timer on dismiss component
    return () => {
      setOpen(false);
      setOpenRenewSession(false);
      dispatch(resetTimer());
      clearInterval(intval);
    };
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          onLogout();
          setOpen(false);
          clearInterval(intval);
        }}
        // ECS-2972 Boost zIndex to ensure it's displayed over email template editor
        style={{ zIndex: 10000 }}
      >
        <DialogTitle>{t("Session Expired")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("Your session has expired.")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onLogout();
              setOpen(false);
              clearInterval(intval);
            }}
            color="secondary"
            data-test-id="delete-dialog-delete-button"
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openRenewSession}
        onClose={() => setOpenRenewSession(false)}
        // ECS-2972 Boost zIndex to ensure it's displayed over email template editor
        style={{ zIndex: 10000 }}
      >
        <DialogTitle>{t("Session Expiring")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(
              "Your session is about to expire. To stay logged in, click continue."
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              await dispatch(getMe());
              dispatch(resetTimer());
              setOpenRenewSession(false);
            }}
            color="secondary"
            data-test-id="continue-dialog-continue-button"
          >
            {t("Continue")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default memo(Timer);
