import React, { useCallback, memo } from "react";
import { Route, Link, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ButtonGroup, Button, Grid } from "../../../../components";
import { buildCampaign } from "../../../../actions/campaign";
import ListTemplates from "./ListTemplates";
import CreateAdhocTemplate from "./CreateAdhocTemplate";

function SelectCampaignMasterTemplate() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.campaign);
  const { t } = useTranslation();

  const onTabChange = useCallback(
    ({ adhoc }) =>
      dispatch(
        buildCampaign({
          templateId: null,
          adhoc,
        })
      ),
    [dispatch]
  );

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={8} lg={6}>
        <ButtonGroup variant="contained" fullWidth>
          <Button
            onClick={() => onTabChange({ adhoc: false })}
            color={match.isExact ? "primary" : "default"}
            component={Link}
            to={match.path}
            data-test-id="campaign-template-create-new-button"
          >
            {t("Select Template")}
          </Button>
          <Button
            onClick={() => onTabChange({ adhoc: true })}
            color={match.isExact ? "default" : "primary"}
            component={Link}
            to={`${match.path}/create`}
            data-test-id="campaign-template-list-button"
          >
            {t("Ad Hoc Campaign")}
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <Switch>
          <Route path={`${match.path}/create`}>
            <CreateAdhocTemplate />
          </Route>
          {campaign.adhoc && <Redirect to={`${match.path}/create`} />}
          <Route path={match.path}>
            <ListTemplates />
          </Route>
          <Redirect to={match.path} />
        </Switch>
      </Grid>
    </Grid>
  );
}

export default memo(SelectCampaignMasterTemplate);
