import api from "./api";

const dndEndpoint = `/api/v1/dnd-sms`;

export async function listDns(params) {
  const { data } = await api.get(`${dndEndpoint}`, { params });
  return data;
}

export async function saveDns(body) {
  const { paid } = body;
  await api.post(`${dndEndpoint}?paid=${paid}`, body);
}

export async function deleteDns(paid) {
  await api.delete(`${dndEndpoint}?paid=${paid}`);
}

export async function updateDns(body) {
  const { paid } = body;
  await api.put(`${dndEndpoint}?paid=${paid}`, body);
}
