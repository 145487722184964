import React, { useState, memo, useEffect } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import useAuthorized, {
  SUPPRESSION_CREATE_PERMISSION,
  SUPPRESSION_LIST_PERMISSION,
} from "../../hooks/useAuthorized";
import ListSuppresionList from "./List";
import CreateSuppressionNumber from "./Create";
import SuppressionToggle from "./SuppressionToggle";
import { Grid } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearSuppressions } from "../../actions/suppression";

function SuppressionPage() {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParams = useSelector((state) => state.params);
  const canCreate = useAuthorized(SUPPRESSION_CREATE_PERMISSION);
  const canList = useAuthorized(SUPPRESSION_LIST_PERMISSION);
  const [suppressionTab, setSuppressionTab] = useState("sms");

  const onInputTabChange = (_, activeTab) => {
    if (activeTab) {
      history.push(`/suppression?type=${activeTab}`);
    }
  };

  useEffect(() => {
    let { type } = queryParams;
    dispatch(clearSuppressions());
    if (!type) {
      history.push(`/suppression?type=sms`);
    }
    setSuppressionTab(type);
  }, [queryParams]);
  return (
    <>
      <Switch>
        {canCreate && (
          <Route path={`${match.path}/create`}>
            <CreateSuppressionNumber type={suppressionTab} />
          </Route>
        )}
        {(canList || canCreate) && (
          <Route path={`${match.path}`}>
            <ListSuppresionList type={suppressionTab} />
          </Route>
        )}
        <Redirect to={`${match.path}`} />
      </Switch>
    </>
  );
}

export default memo(SuppressionPage);
