import React, { useState, useCallback, useMemo, useRef, memo } from "react";
import ReactQuill from "react-quill";
import { FormControl, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Fullscreen,
  FullscreenExit,
  Undo,
  Redo,
  Code,
} from "@material-ui/icons";
import classNames from "classnames";
import screenfull from "screenfull";
import "react-quill/dist/quill.snow.css";
import "./styles.css";
import { useSelector } from "react-redux";

// import useAuthorized, {
//   TEMPLATE_CREATE_CUSTOMER_DATA_PERMISSION,
// } from "../../hooks/useAuthorized";

import api from "../../api/api";
import { uploadImage } from "../../api/upload";

const getModules = ({ readOnly, handlers }) => ({
  history: {
    delay: 1000,
    userOnly: true,
  },
  toolbar: readOnly
    ? false
    : {
        container: "#quill-toolbar",
        handlers: {
          ...handlers,
        },
      },
});

export const FORMATS = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "align",
  "link",
  "image",
  "color",
  "background",
];

const useStyles = makeStyles((theme) => ({
  textEditor: {
    backgroundColor: theme.palette.background.paper,
  },
  toolbar: {
    display: "flex",
  },
  rightToolbarSection: {
    marginLeft: "auto",
  },
}));

function TextEditor({
  error,
  helperText,
  className,
  customControls,
  editorRef,
  readOnly,
  formats = [],
  ...rest
}) {
  const classes = useStyles();
  const [isFullscreen, setFullscreen] = useState(false);
  const [isRaw, setRaw] = useState(false);
  const containerRef = useRef();

  const onToggleFullScreen = useCallback(() => {
    setFullscreen(!isFullscreen);
    screenfull.toggle(containerRef.current);
    editorRef.current.focus();
  }, [containerRef, editorRef, isFullscreen, setFullscreen]);

  const onToggleRaw = useCallback(() => {
    const { editor } = editorRef.current;
    if (!isRaw) {
      const htmlContent = editor.root.innerHTML;
      editor.setText(htmlContent, "user");
    } else {
      const htmlContent = editor.getText();
      editor.setContents([], "user");
      editor.clipboard.dangerouslyPasteHTML(0, htmlContent, "user");
    }
    editor.history.clear();
    setRaw(!isRaw);
  }, [editorRef, isRaw]);

  const onUndo = useCallback(() => editorRef.current.editor.history.undo(), [
    editorRef,
  ]);

  const onRedo = useCallback(() => editorRef.current.editor.history.redo(), [
    editorRef,
  ]);

  /**
   * https://kaloraat.com/articles/react-quill-wysiwyg-rich-text-editor-image-upload-to-server
   */
  const imageHandler = async () => {
    // eslint-disable-next-line no-console
    console.log("Image Handler");

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();

      formData.append("image", file);

      const editor = editorRef.current.getEditor();

      // Save current cursor state
      const range = editor.getSelection(true);

      // Insert temporary loading placeholder image
      editor.insertEmbed(
        range.index,
        "image",
        `${window.location.origin}/images/loaders/placeholder.gif`
      );

      // Move cursor to right side of image (easier to continue typing)
      editor.setSelection(range.index + 1);

      const data = await uploadImage(formData, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary${formData._boundary}`,
        },
      });

      // Remove placeholder image
      editor.deleteText(range.index, 1);

      editor.insertEmbed(range.index, "image", data.saved_files[0].filePath);
    };
  };

  const modules = useMemo(
    () => getModules({ readOnly, handlers: { image: imageHandler } }),
    [readOnly]
  );

  // const canUseCustomerData = useAuthorized(
  //   TEMPLATE_CREATE_CUSTOMER_DATA_PERMISSION
  // );

  const config = useSelector((state) =>
    state && state.eonsConfig ? state.eonsConfig : {}
  );
  const canUseCustomerData =
    config &&
    config.vendorConfig &&
    config.vendorConfig.createCustomerTemplateData
      ? config.vendorConfig.createCustomerTemplateData
      : false;

  return (
    <FormControl
      fullWidth
      error={error}
      ref={containerRef}
      className={classNames(className, "text-editor", classes.textEditor, {
        error,
      })}
    >
      {!readOnly && (
        <div className={classes.toolbar} id="quill-toolbar">
          {formats.indexOf("header") > 0 && (
            <div className="ql-formats">
              <select disabled={isRaw} className="ql-header" />
            </div>
          )}

          <div className="ql-formats">
            {formats.indexOf("bold") > 0 && (
              <button disabled={isRaw} className="ql-bold"></button>
            )}
            {formats.indexOf("italic") > 0 && (
              <button disabled={isRaw} className="ql-italic"></button>
            )}
            {formats.indexOf("underline") > 0 && (
              <button disabled={isRaw} className="ql-underline"></button>
            )}
            {formats.indexOf("strike") > 0 && (
              <button disabled={isRaw} className="ql-strike"></button>
            )}
            {formats.indexOf("blockquote") > 0 && (
              <button disabled={isRaw} className="ql-blockquote"></button>
            )}
          </div>
          {formats.indexOf("list") > 0 && (
            <div className="ql-formats">
              <button
                disabled={isRaw}
                className="ql-list"
                value="ordered"
              ></button>
              <button
                disabled={isRaw}
                className="ql-list"
                value="bullet"
              ></button>
            </div>
          )}
          {formats.indexOf("indent") > 0 && (
            <div className="ql-formats">
              <select disabled={isRaw} className="ql-align" />
              <button
                disabled={isRaw}
                className="ql-indent"
                value="+1"
              ></button>
              <button
                disabled={isRaw}
                className="ql-indent"
                value="-1"
              ></button>
            </div>
          )}

          <div className="ql-formats">
            {formats.indexOf("color") > 0 && (
              <select disabled={isRaw} className="ql-color" />
            )}
            {formats.indexOf("background") > 0 && (
              <select disabled={isRaw} className="ql-background" />
            )}
          </div>
          <div className="ql-formats">
            {formats.indexOf("link") > 0 && (
              <button disabled={isRaw} className="ql-link"></button>
            )}
            {formats.indexOf("image") > 0 && (
              <button disabled={isRaw} className="ql-image"></button>
            )}
          </div>
          {/* <div className="ql-formats">
            <button disabled={isRaw} className="ql-clean"></button>
          </div> */}

          <div className="ql-formats">
            {/* <button
              className={classNames("ql-raw", { "ql-active": isRaw })}
              onClick={onToggleRaw}
            >
              <Code />
            </button> */}
            <button className="ql-undo" onClick={onUndo}>
              <Undo />
            </button>
            <button className="ql-redo" onClick={onRedo}>
              <Redo />
            </button>
          </div>

          <div
            className={classNames("ql-formats", classes.rightToolbarSection)}
          >
            {canUseCustomerData && customControls}
            {screenfull.isEnabled && (
              <button className={"ql-fullscreen"} onClick={onToggleFullScreen}>
                {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
              </button>
            )}
          </div>
        </div>
      )}
      <ReactQuill
        {...rest}
        readOnly={readOnly}
        theme="snow"
        ref={editorRef}
        modules={modules}
        formats={formats}
      />
      {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default memo(TextEditor);
