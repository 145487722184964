import { ADD_TIME, RESET_TIME } from "../actions/timer";

export default function timer(time = 0, { type, newTime }) {
  switch (type) {
    case ADD_TIME:
      time = newTime;
      return time;
    case RESET_TIME:
      time = 0;
      return time;
    default:
      return time;
  }
}
