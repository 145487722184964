import React, { memo, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDnd } from "../../actions/dnd";
import { getDns } from "../../actions/dns";
import ManageDnd from "./Dnd";
import ManageDns from "./Dns";
import ApproversPage from "../Approvers";
import {
  Grid,
  Card,
  Page,
  Typography,
  CardContent,
  useMediaQuery,
} from "../../components";

function AdminPage() {
  const dispatch = useDispatch();

  const { paid } = useSelector((state) => state.storage);
  const { dnd, dns, advancedApproverManagement = false } = useSelector(
    (state) => state.eonsConfig.vendorConfig
  );
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const hasDnd = Boolean(dnd);
  const hasDns = Boolean(dns);
  const hasEitherDndDns = Boolean(dnd) || Boolean(dns);
  useEffect(() => {
    dispatch(getDnd(paid));
    dispatch(getDns(paid));
  }, [dispatch, paid]);

  return (
    <Fragment>
      <Page p={4} screenSized={!isSmallScreen}>
        <Grid
          container
          spacing={5}
          columns={{ xs: 12, md: 12 }}
          style={{ overflowY: "auto" }}
        >
          {hasEitherDndDns && (
            <Grid item>
              <Card>
                <CardContent>
                  <Grid container spacing={5} columns={{ xs: 12, md: 12 }}>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        variant="h4"
                        style={{ color: "#3f51b5" }}
                        align="left"
                      >
                        DO NOT DISTURB
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        align="left"
                      >
                        Specify a time frame during which campaigns should not
                        be sent
                      </Typography>
                    </Grid>
                    {hasDns && (
                      <Grid item xs={8}>
                        <ManageDns />
                      </Grid>
                    )}
                    {hasDnd && (
                      <Grid item xs={8}>
                        <ManageDnd />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          {true === true ? (
            <Grid item>
              <Grid>
                <Grid item xs={12}>
                  <ApproversPage />
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Page>
    </Fragment>
  );
}

export default memo(AdminPage);
