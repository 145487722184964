import i18n from "i18next";
import { batch } from "react-redux";
import { pick, isObject } from "lodash";
import * as api from "../api/masterTemplate";
import { notify } from "./notification";
import { cleanUpHtmlStringForTemplate } from "../utils/formatEscape";

export const FILE_TYPE = "file";
export const CUSTOMER_DATA_TYPE = "customerData";

export const CLEAR_MASTER_TEMPLATE = "CLEAR_MASTER_TEMPLATE";
export const BUILD_MASTER_TEMPLATE = "BUILD_MASTER_TEMPLATE";

export function deleteMasterTemplate({ _id, name }) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { paid } = storage;
    if (_id) {
      await api.deleteMasterTemplate(_id, { paid });
    }

    batch(() => {
      dispatch(clearMasterTemplate());
      dispatch(
        notify({
          type: "success",
          message: i18n.t("{{name}} successfully deleted", {
            name: name ?? i18n.t("template"),
          }),
        })
      );
    });
  };
}

export function getMasterTemplate(id) {
  return async (dispatch, getState) => {
    const { storage, masterTemplate: currMasterTemplate } = getState();
    const { activeDivisionId, paid } = storage;

    // if we have a fresh master template don't do anythig
    // this might happen when the user is redirected from the create page to the update page

    if (currMasterTemplate && currMasterTemplate._id === id) {
      return;
    }

    if (!id) {
      return dispatch(
        buildMasterTemplate(
          {
            errors: {},
            name: "",
            permission: "",
            modified: false,
          },
          { overwrite: true }
        )
      );
    }

    try {
      dispatch(
        buildMasterTemplate(
          {
            errors: {},
            name: "",
            permission: "",
            loading: true,
            modified: false,
          },
          { overwrite: true }
        )
      );

      const masterTemplate = await api.getMasterTemplate(id, {
        activeDivisionId,
        paid,
      });

      dispatch(
        buildMasterTemplate({
          ...masterTemplate,
          emailTemplate: masterTemplate.emailTemplate
            ? {
                ...masterTemplate.emailTemplate,
                [masterTemplate.emailTemplate.advanceEmail
                  ? "advanceTemplate"
                  : "simpleTemplate"]: masterTemplate.emailTemplate
                  .reactQuilRawContent,
              }
            : undefined,
          loading: false,
          modified: false,
        })
      );
    } catch (err) {
      buildMasterTemplate({ loading: false, notFound: true, modified: false });
    }
  };
}

async function processAdhocVoice(
  template = [],
  voiceTemplate,
  voiceConfig,
  localFileName,
  companyId
) {
  // generate and distribute the voice files with tts
  await Promise.all(
    template.map((voiceItem) => {
      return new Promise(async (res) => {
        if (voiceItem.stepType === "customerData" && voiceItem.transcript) {
          const { path } = await api.createTTS({
            transcript: voiceItem.transcript,
            talent: voiceTemplate.ttsVoiceType,
            campaignId: localFileName,
            companyId,
          });
          voiceItem.path = path;
          return res({ ...voiceItem, path });
        }
        return res(voiceItem);
      });
    })
  );
  return template;
}

export function saveMasterTemplate(extraFields = {}) {
  return async (dispatch, getState) => {
    const { masterTemplate, eonsConfig, storage, user } = getState();
    const { voiceConfig } = eonsConfig;
    const { activeDivisionId, paid } = storage;
    const { companyId } = user;
    if (!masterTemplate) {
      return;
    }

    const {
      _id,
      name,
      permission,
      emailTemplate,
      emailTemplateId,
      smsTemplate,
      smsTemplateId,
      voiceTemplate,
      voiceTemplateId,
      inboundTemplate,
      inboundTemplateId,
      ttyTemplate,
      ttyTemplateId,
      customTemplate,
      customTemplateId,
      tags,
    } = masterTemplate;

    const isNewTemplate = !_id;
    const isDraft = extraFields.status === "DRAFT";

    try {
      const localFileName = Date.now().toString();
      if (
        extraFields.adhoc &&
        voiceTemplate?.freeswitch?.dialPlan?.person?.steps &&
        voiceTemplate?.freeswitch?.dialPlan?.person?.steps.length
      ) {
        voiceTemplate.freeswitch.dialPlan.person.steps = await processAdhocVoice(
          voiceTemplate.freeswitch.dialPlan.person.steps,
          voiceTemplate,
          voiceConfig,
          localFileName,
          companyId
        );
      }
      if (
        extraFields.adhoc &&
        voiceTemplate?.freeswitch?.dialPlan?.machine?.steps &&
        voiceTemplate?.freeswitch?.dialPlan?.machine?.steps.length
      ) {
        voiceTemplate.freeswitch.dialPlan.machine.steps = await processAdhocVoice(
          voiceTemplate.freeswitch.dialPlan.machine.steps,
          voiceTemplate,
          voiceConfig,
          localFileName,
          companyId
        );
      }

      const escapeFreeEmailTemplate = emailTemplate
        ? {
            ...pick(
              emailTemplate,
              "fromEmail",
              "subjectEmail",
              "fromName",
              "reactQuilContent",
              "reactQuilRawContent",
              "advanceEmail"
            ),
            template: emailTemplate.advanceEmail
              ? emailTemplate.reactQuilContent
              : cleanUpHtmlStringForTemplate(emailTemplate.template),
          }
        : undefined;

      const saveBodyInitial = {
        name,
        permission,
        companyId,
        status: "DRAFT",
      };

      const saveBody = {
        name,
        permission,
        companyId,
        tags,
        emailTemplateId,
        emailTemplate: escapeFreeEmailTemplate,
        smsTemplateId,
        smsTemplate: smsTemplate
          ? { ...pick(smsTemplate, "template", "shortCode"), companyId }
          : undefined,
        voiceTemplateId,
        voiceTemplate: voiceTemplate
          ? voiceTemplate.advanceVoice
            ? { advanceVoice: voiceTemplate.advanceVoice, companyId }
            : {
                ...pick(
                  voiceTemplate,
                  "ttsVoiceType",
                  "callerId",
                  // "retries", // deprecated
                  "optOutKey",
                  "freeswitch",
                  "voiceType"
                  // "liveAgentTransferKey", // deprecated
                  // "liveAgentTransferNumber" // deprecated
                ),
                companyId,
                // live: voiceTemplate.live  // deprecated
                //   ? voiceTemplate.live.map((voiceItem) =>
                //       omit(voiceItem, "_id")
                //     )
                //   : [],
                // answeringMachine: voiceTemplate.answeringMachine // deprecated
                //   ? voiceTemplate.answeringMachine.map((voiceItem) =>
                //       omit(voiceItem, "_id")
                //     )
                //   : null,
              }
          : undefined,
        inboundTemplateId,
        inboundTemplate: inboundTemplate
          ? inboundTemplate.advanceVoice
            ? { advanceVoice: inboundTemplate.advanceVoice, companyId }
            : {
                ...pick(
                  inboundTemplate,
                  "ttsVoiceType",
                  "callerId",
                  "optOutKey",
                  "freeswitch",
                  "voiceType"
                ),
                companyId,
              }
          : undefined,
        ttyTemplateId,
        ttyTemplate: ttyTemplate
          ? { ...pick(ttyTemplate, "freeswitch"), companyId }
          : undefined,
        customTemplateId,
        customTemplate: customTemplate
          ? { ...pick(customTemplate, "template"), companyId }
          : undefined,
        ...extraFields,
      };
      const saveParams = {
        activeDivisionId,
        paid,
      };

      const masterTemplateFromApi = await (isNewTemplate
        ? extraFields.initial
          ? api.createMasterTemplate(saveBodyInitial, saveParams)
          : api.createMasterTemplate(saveBody, saveParams)
        : api.updateMasterTemplate(_id, saveBody, saveParams));

      batch(() => {
        dispatch(
          buildMasterTemplate({
            ...masterTemplateFromApi,
            modified: false,
            emailTemplate: masterTemplate.emailTemplate
              ? {
                  ...masterTemplate.emailTemplate,
                  [masterTemplate.emailTemplate.advanceEmail
                    ? "advanceTemplate"
                    : "simpleTemplate"]: masterTemplate.emailTemplate
                    .reactQuilRawContent,
                }
              : undefined,
            voiceTemplateId: masterTemplate.voiceTemplateId,
            errors: {},
          })
        );

        let successMessage = isNewTemplate
          ? i18n.t("Successfully saved {{name}}", { name })
          : i18n.t("Successfully updated {{name}}", { name });
        if (isDraft) {
          successMessage += ` ${i18n.t("as draft")}`;
        }
        dispatch(
          notify({
            type: "success",
            message: successMessage,
          })
        );
      });

      return {
        masterTemplateId: masterTemplateFromApi._id,
        voiceTemplateId: masterTemplateFromApi.voiceTemplateId,
      };
    } catch (err) {
      batch(() => {
        const newErrors = err?.response?.data;
        if (isObject(newErrors)) {
          dispatch(
            buildMasterTemplate({
              errors: newErrors,
            })
          );
        }

        if (isDraft) {
          dispatch(
            notify({
              type: "error",
              message: isNewTemplate
                ? i18n.t("Failed to save {{name}} as draft", { name })
                : i18n.t("Failed to update {{name}} as draft", { name }),
            })
          );
        }
      });
      throw err;
    }
  };
}

export function buildMasterTemplate(masterTemplateData, { overwrite } = {}) {
  masterTemplateData = { modified: true, ...masterTemplateData };
  return { type: BUILD_MASTER_TEMPLATE, masterTemplateData, overwrite };
}

export function cloneMasterTemplate({ _id, name }, params) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { activeDivisionId, paid } = storage;

    const clone = await api.cloneMasterTemplate(_id, {
      activeDivisionId,
      paid,
    });

    dispatch(
      notify({
        type: "success",
        message: i18n.t("Successfully cloned {{name}}!", {
          name,
        }),
      })
    );

    return clone._id;
  };
}

export function clearMasterTemplate() {
  return { type: CLEAR_MASTER_TEMPLATE };
}

export function sendTestMessage(testMessage) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { activeDivisionId, paid } = storage;

    await api.sendTestMessage(testMessage, { activeDivisionId, paid });

    dispatch(
      notify({
        type: "success",
        message: i18n.t("Test message successfully sent!"),
      })
    );
  };
}

export function uploadWavFile(file) {
  return async (_, getState) => {
    const {
      user: { companyId },
      masterTemplate: { _id: templateId },
    } = getState();
    // this end point return file path
    const { path } = await api.uploadWavFile(file, { companyId, templateId }); // upload file to voice service

    // following pice is commented out due to api changes
    // await api.transferFiles(
    // { fileName: params.DSE, directory: params.DS }
    // ); // Voice Transfer this file to SFTP server
    // await api.transferFiles({
    //   fileName: file.name,
    //   companyId,
    //   templateId,
    // });

    // update master templat with file location here
    return path;
  };
}

export function getAudioForTTs({ transcript, voice }) {
  return async (_, getState) => {
    const data = await api.getAudioForTTsApi({ transcript, voice }); // upload file to voice service
    return data;
  };
}

function formatHTMLForReactQuil(htmlString = "") {
  return `<p>${htmlString
    .replace("<p>", "")
    .replace(new RegExp("</p>$"), "")
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;")}</p>`;
}
