import React, { memo, useState, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Menu, MenuItem, Badge } from "../../../../components";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import SendIcon from "@material-ui/icons/Send";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export function ErrorWrapper({ error, children, className, ...rest }) {
  if (error) {
    return (
      <Badge
        color="error"
        badgeContent={<b>!</b>}
        className={className}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ width: "100%" }}
      >
        {children}
      </Badge>
    );
  }
  return children;
}

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "& :focus": {
      backgroundColor: theme.palette.primary.main,
      " & .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function VoiceStepButtons({ steps, label, testId, readOnly }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnMenuClick = (onClick) => {
    return (ev) => {
      handleClose();
      onClick(ev);
    };
  };

  const errorCheck = steps.filter((step) => step.error);
  return (
    <>
      <Button
        disabled={readOnly}
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        error={errorCheck.length > 0}
        data-test-id={testId ? testId : "master-template-add-voice-items"}
      >
        {label}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {steps.map((step, idx) => {
          const { onClick, startIcon, label, permission, error, testId } = step;
          return (
            permission && (
              <StyledMenuItem
                onClick={handleOnMenuClick(onClick)}
                data-test-id={testId}
              >
                <ErrorWrapper error={error}>
                  <ListItemIcon>{startIcon}</ListItemIcon>
                </ErrorWrapper>
                <ListItemText primary={label} />
              </StyledMenuItem>
            )
          );
        })}
      </StyledMenu>
    </>
  );
}

export default memo(VoiceStepButtons);
