import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  ActionButton,
  Grid,
  Search,
  DateTimePicker,
  useMediaQuery,
} from "../../../components";
import {
  useAuthorized,
  APPROVER_CREATE_PERMISSION,
} from "../../../hooks/useAuthorized";

const useStyles = makeStyles((theme) => ({
  primaryContainer: {
    flexGrow: 1,
    maxWidth: 1000,
  },
  createButton: {
    height: "100%",
  },
  searchContainer: {
    flex: 2,
    minWidth: 140,
    maxWidth: 800,
  },
  permissionContainer: {
    flex: 1,
    minWidth: 140,
    maxWidth: 220,
  },
}));

function ApproversFilters({ params, onSearchChange }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const canCreate = useAuthorized(APPROVER_CREATE_PERMISSION);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const { advancedApproverManagement = false } = useSelector(
    (state) => state.eonsConfig.vendorConfig
  );
  const match = useRouteMatch();

  const path = advancedApproverManagement
    ? match.path + "approvers"
    : match.path;

  return (
    <>
      <Grid container spacing={2} justify="space-between">
        <Grid item className={classes.primaryContainer}>
          <Grid item lg={8} xs={12} container spacing={2}>
            {canCreate ? (
              <ActionButton
                classes={{ desktop: classes.createButton }}
                component={Link}
                to={`${path}/create`}
                text={t("New Approver")}
                data-test-id="approver-create-new"
              />
            ) : null}
            <Grid item className={classes.searchContainer}>
              <Search
                variant="outlined"
                size="small"
                label={
                  isSmallScreen
                    ? t("Search")
                    : t("Search by name/creator/sms/email")
                }
                onSearch={onSearchChange}
                defaultValue={params.search}
                debounce={300}
                fullWidth
                testId="approver"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default memo(ApproversFilters);
