import {
  LIST_MASTER_TEMPLATES,
  CLEAR_MASTER_TEMPLATES,
} from "../actions/masterTemplates";

export default function masterTemplatesReducer(
  masterTemplates = null,
  { type, masterTemplates: newMasterTemplates }
) {
  switch (type) {
    case LIST_MASTER_TEMPLATES:
      return newMasterTemplates;
    case CLEAR_MASTER_TEMPLATES:
      return null;
    default:
      return masterTemplates;
  }
}
