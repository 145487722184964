export const SET_STORAGE_FIELDS = "SET_STORAGE_FIELDS";
export const STORAGE_KEY = "ENTERPRISE_EONS_STORAGE";

export function setStorageFields(setFields) {
  return (dispatch, getState) => {
    const { storage, user } = getState();
    // scope the cross session storage per user
    const storageKey = `${STORAGE_KEY}:${user?.id}`;

    const newFields = { ...storage, ...setFields };
    localStorage.setItem(storageKey, JSON.stringify(newFields));
    dispatch({ type: SET_STORAGE_FIELDS, newFields });
  };
}
