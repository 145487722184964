import * as campaignApi from "../api/campaign";

export const LIST_CAMPAIGNS = "LIST_CAMPAIGNS";
export const CLEAR_CAMPAIGNS = "CLEAR_CAMPAIGNS";

export function clearCampaigns() {
  return { type: CLEAR_CAMPAIGNS };
}

export function listCampaigns(params) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { paid } = storage;

    const campaigns = await campaignApi.listCampaigns({ ...params, paid });
    dispatch({ type: LIST_CAMPAIGNS, campaigns });
  };
}
