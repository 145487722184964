import React, { useMemo, useState, useCallback, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Page, SmartTable, useMediaQuery } from "../../../components";
import { setParams } from "../../../actions/params";
import Actions from "./Actions";
import { getTemplateForCallerId } from "../../../actions/masterTemplates";
import moment from "moment";

import { getParams } from "../../../utils";

const getColumns = ({ t }) => [
  {
    name: "callerId",
    getter: (callerInfo) => callerInfo.callerId || "-",
    label: t("Name"),
    sortable: true,
    width: 2,
    testId: "callerId",
  },
  {
    name: "template",
    getter: (callerInfo) => (callerInfo?.name ? callerInfo.name : "-"),
    label: t("Template"),
    sortable: true,
    width: 2,
    testId: "callerId",
  },
  {
    name: "last used",
    getter: (callerInfo) =>
      callerInfo?.lastUsed
        ? moment(callerInfo.lastUsed).format("MM/DD/YYYY")
        : "-",
    label: t("Last Used"),
    sortable: true,
    width: 2,
    testId: "callerId",
  },
  {
    name: "updated date",
    getter: (callerInfo) =>
      callerInfo?.updatedDate
        ? moment(callerInfo.updatedDate).format("MM/DD/YYYY")
        : "-",
    label: t("Updated Date"),
    sortable: true,
    width: 2,
    testId: "callerId",
  },
  {
    name: "actions",
    getter: (callerInfo) => (
      <Actions testId="callerId" callerInfo={callerInfo} />
    ),
    label: t("Actions"),
    sortable: false,
    align: "center",
  },
];

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1000,
  },
  smallCell: {
    minWidth: 110,
    maxWidth: 150,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

function CallerIdPage() {
  const [callerIdInfo, setCallerIdInfo] = useState([]);
  const { vendorConfig, callerIdDetail } = useSelector(
    (state) => state.eonsConfig
  );
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const queryParams = useSelector((state) => state.params);
  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );

  // fill in missing query params with default values and the active division id
  // the default values are not persisted in the query string for a better user experience
  const params = useMemo(() => getParams(queryParams, activeDivisionId), [
    queryParams,
    activeDivisionId,
  ]);

  // memoize the column getter for a perf boost
  const columns = useMemo(() => getColumns({ t }), [t]);

  // keep master templates defining primitive params in state.params which is reflected in the URL query params
  const onPageChange = useCallback(
    (ev, page) => dispatch(setParams({ page })),
    [dispatch]
  );

  const onItemsPerPageChange = useCallback(
    (ev) => dispatch(setParams({ itemsPerPage: ev.target.value })),
    [dispatch]
  );

  const onSortChange = useCallback(
    (orderBy, order) => dispatch(setParams({ orderBy, order, page: 0 })),
    [dispatch]
  );

  useEffect(() => {
    getTemplateForCallerId(vendorConfig.callerId).then((data) => {
      let info = vendorConfig.callerId.map((c) => {
        let info = data.find((d) => d.callerId == c);
        let callerIdDetailLastUsed = callerIdDetail.find(
          (d) => d.callerId == c
        );

        if (info) {
          info.lastUsed = callerIdDetailLastUsed?.lastUsed;

          return info;
        }
        return { callerId: c };
      });
      setCallerIdInfo(info);
    });
  }, [vendorConfig]);

  return (
    <Page p={2} screenSized={!isSmallScreen}>
      <SmartTable
        className={classes.table}
        columns={columns}
        data={callerIdInfo}
        totalCount={callerIdInfo.length}
        notFoundMessage={t("No callerId found for your filters")}
        orderBy={params.orderBy}
        order={params.order}
        page={params.page}
        itemsPerPage={params.itemsPerPage}
        onSortChange={onSortChange}
        onPageChange={onPageChange}
        onItemsPerPageChange={onItemsPerPageChange}
        testId="inbound_campaign"
      />
    </Page>
  );
}

export default memo(CallerIdPage);
