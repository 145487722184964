import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Check, Lock, InsertDriveFile as Draft } from "../../icons";
import { useTranslation } from "react-i18next";
import { Tooltip } from "../../components";

const useStyles = makeStyles((theme) => ({
  done: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
}));

function MasterTemplateStatusIndicator({ status, testId, ...rest }) {
  const { t } = useTranslation();
  const classes = useStyles();

  if (status === "DRAFT") {
    return (
      <Tooltip title={t("Draft")}>
        <Draft
          data-test-id={`template-status-icon-draft-${testId}`}
          {...rest}
          className={classes.warning}
        />
      </Tooltip>
    );
  }
  if (status === "LOCKED") {
    return (
      <Tooltip title={t("Locked")}>
        <Lock
          data-test-id={`template-status-icon-locked-${testId}`}
          {...rest}
          className={classes.warning}
        />
      </Tooltip>
    );
  }

  if (status === "COMPLETED") {
    return (
      <Tooltip title={t("Completed")}>
        <Check
          data-test-id={`template-status-icon-check-${testId}`}
          {...rest}
          className={classes.done}
        />
      </Tooltip>
    );
  }

  return "";
}

export default memo(MasterTemplateStatusIndicator);
