import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CloudUpload from "@material-ui/icons/CloudUpload";
import EditLocationIcon from "@material-ui/icons/EditLocation";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import * as api from "../../api/recipientLists";
import { Tooltip } from "../../components";

import { AddCircle } from "../../icons";
import Paper from "../Paper";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createNewRecipientList } from "../../actions/newRecipientList";

const StyledMenu = withStyles({
  root: {},
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    color: "#424FBF",
    textDecoration: "none",
  },
  disabledLink: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.38) !important",
    cursor: "not-allowed",
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

export default function CustomizedMenus({ newListConfig, isCampaign }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const divisionId = useSelector((state) => state.storage.activeDivisionId);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [enableFilterFromMaster, setEnableFilterFromMaster] = React.useState(
    false
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getRecipientCount = async () => {
    const result = await api.getMasterRecipientsCount(divisionId);
    if (result.totalRecipientCount) {
      setEnableFilterFromMaster(true);
    } else {
      setEnableFilterFromMaster(false);
    }
  };

  useEffect(() => {
    if (newListConfig?.masterList) {
      getRecipientCount();
      const interval = setInterval(() => getRecipientCount(), 30000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [newListConfig?.masterList]);

  const desktopIcon = <AddCircle />;
  const classes = useStyles();

  const onCreateCampaign = () => {
    dispatch(createNewRecipientList({ redirect: isCampaign }));
  };
  const handleNavClick = (e) => {
    if (!enableFilterFromMaster) e.preventDefault();
  };
  return (
    <div style={{ marginTop: "8px" }}>
      <Button
        startIcon={desktopIcon}
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        New List
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {newListConfig?.upload && (
          <StyledMenuItem onClick={onCreateCampaign}>
            <Paper
              elevation={0}
              className={classes.link}
              component={NavLink}
              to={"/recipient-lists/create/file/upload"}
            >
              <ListItemIcon>
                <CloudUpload fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Upload List" />
            </Paper>
          </StyledMenuItem>
        )}
        {newListConfig?.map && (
          <StyledMenuItem onClick={onCreateCampaign}>
            <Paper
              elevation={0}
              className={classes.link}
              component={NavLink}
              to={"/recipient-lists/create/map/filter"}
            >
              <ListItemIcon>
                <EditLocationIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Select from Map" />
            </Paper>
          </StyledMenuItem>
        )}
        {newListConfig?.masterList && (
          <Tooltip
            title={
              enableFilterFromMaster ? "" : t("No Recipients in Master List")
            }
          >
            <StyledMenuItem onClick={onCreateCampaign}>
              <Paper
                elevation={0}
                className={
                  enableFilterFromMaster ? classes.link : classes.disabledLink
                }
                component={NavLink}
                onClick={handleNavClick}
                to={"/recipient-lists/create/filter/columns"}
              >
                <ListItemIcon>
                  <FindInPageIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Filter from Master List" />
              </Paper>
            </StyledMenuItem>
          </Tooltip>
        )}
      </StyledMenu>
    </div>
  );
}
