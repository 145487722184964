import * as reportApi from "../api/report";
import { notify } from "./notification";
import i18n from "i18next";

export const SET_DOWNLOAD_LIST = "SET_DOWNLOAD_LIST";
export function getDownloadFileList(campaignId) {
  return async (dispatch, getState) => {
    try {
      const { storage, downloadReport } = getState();
      const { activeDivisionId } = storage;
      const fileList = await reportApi.getCampaignReportFileList(campaignId, {
        activeDivisionId,
      });

      if (JSON.stringify(downloadReport.list) !== JSON.stringify(fileList)) {
        // There is an update
        dispatch(setDownloadList({ ...fileList }));
      }
    } catch (err) {
      dispatch(
        notify({
          type: "error",
          message: i18n.t("Failed to get Download File List"),
        })
      );
    }
  };
}

export function generateCampaignReportFile(campaignId, identifier) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { activeDivisionId } = storage;
    try {
      await reportApi.generateCampaignReportFile(campaignId, {
        activeDivisionId,
        identifier,
      });
      dispatch(
        notify({
          type: "success",
          message: i18n.t("Successfully queued report file for generation"),
        })
      );
    } catch (err) {
      dispatch(
        notify({
          type: "error",
          message: i18n.t("Failed to generate report file"),
        })
      );
    }
  };
}

export function updateCampaignReportFile(campaignId, identifier) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { activeDivisionId } = storage;
    try {
      await reportApi.patchCampaignReportFile(campaignId, {
        activeDivisionId,
        identifier,
      });
      dispatch(
        notify({
          type: "success",
          message: i18n.t("Successfully queued report file for update"),
        })
      );
    } catch (err) {
      dispatch(
        notify({
          type: "error",
          message: i18n.t("Failed to update report file"),
        })
      );
    }
  };
}

export function downloadCampaignReportFile(campaignId, identifier) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { activeDivisionId } = storage;
    try {
      await reportApi.downloadCampaignReportFile(campaignId, {
        activeDivisionId,
        identifier,
      });
    } catch (err) {
      dispatch(
        notify({
          type: "error",
          message: i18n.t("Failed to download report file"),
        })
      );
    }
  };
}
export function clearDownloadFileList() {
  return async (dispatch, getState) => {
    dispatch(setDownloadList({}));
  };
}

export function setDownloadList(list) {
  return { type: SET_DOWNLOAD_LIST, list };
}
