import React, { useCallback, memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import NavigationPrompt from "react-router-navigation-prompt";
import { omit, omitBy, random } from "lodash";
import moment from "moment";
import { NavigationDialog, Alert } from "../../../components";
import {
  buildMasterTemplate,
  cloneMasterTemplate,
} from "../../../actions/masterTemplate";
import Fields from "./Fields";
import TemplateSection from "./TemplateSection";
import EmailTemplateSection from "./EmailTemplate";
import SmsTemplateSection from "./SmsTemplate";
import VoiceTemplateSection from "./VoiceTemplate";
import InboundTemplateSection from "./InboundTemplate";
import TTYTemplateSection from "./TTYTemplate";
import CustomTemplate from "./CustomTemplate";
import useAuthorized, {
  INBOUND_CREATE_PERMISSION,
} from "../../../hooks/useAuthorized";

const rootKeys = [
  "",
  "emailTemplate",
  "smsTemplate",
  "voiceTemplate",
  "ttyTemplate",
  "inboundTemplate",
  "customTemplate",
];

const hasSectionErrors = (section, errorKeys) =>
  errorKeys.some((name) => name.indexOf(section) !== -1);

const useStyles = makeStyles((theme) => ({
  bottomPadded: {
    marginBottom: theme.spacing(2),
  },
}));

function BaseMasterTemplatePage({
  masterTemplate,
  readOnly,
  adhoc,
  isEditingView,
  onSaveAsDraftInitial,
}) {
  const canCreateInbound = useAuthorized(INBOUND_CREATE_PERMISSION);
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    smsConfig,
    emailConfig,
    voiceConfig,
    inboundConfig,
    vendorConfig: eonsConfig,
    customConfig,
    callerIdDetail,
  } = useSelector((state) => state.eonsConfig);
  const { id: userId } = useSelector((state) => state.user);

  const {
    errors = {},
    name,
    permission,
    status,
    emailTemplate,
    smsTemplate,
    voiceTemplate,
    ttyTemplate,
    inboundTemplate,
    customTemplate,
    modified,
    tags,
  } = masterTemplate || {};
  const errorKeys = Object.keys(errors);
  const hasEmailErrors = hasSectionErrors("emailTemplate", errorKeys);
  const hasSmsErrors = hasSectionErrors("smsTemplate", errorKeys);
  const hasVoiceErrors = hasSectionErrors("voiceTemplate", errorKeys);
  const hasTTYErrors = hasSectionErrors("ttyTemplate", errorKeys);
  const hasInboundErrors = hasSectionErrors("inboundTemplate", errorKeys);
  const hasCustomErrors = hasSectionErrors("customTemplate", errorKeys);

  const hasRootErrors = Boolean(
    errors[""] ||
      errors.emailTemplate ||
      errors.smsTemplate ||
      errors.voiceTemplate ||
      errors.ttyTemplate ||
      errors.inboundTemplate ||
      errors.customTemplate
  );

  const isEmailEmpty = !Object.keys(emailTemplate || {}).length;
  const isSmsEmpty = !Object.keys(smsTemplate || {}).length;
  const isVoiceEmpty = !Object.keys(voiceTemplate || {}).length;
  const isTTYEmpty = !Object.keys(ttyTemplate || {}).length;
  const isInboundEmpty = !Object.keys(inboundTemplate || {}).length;
  const isCustomEmpty = !Object.keys(customConfig || {}).length;

  const showSmsSection = smsConfig && !(readOnly && isSmsEmpty);
  const showEmailSection = emailConfig && !(readOnly && isEmailEmpty);
  const showVoiceSection = voiceConfig && !(readOnly && isVoiceEmpty);
  const showTTYSection =
    voiceConfig && eonsConfig.tty && !(readOnly && isTTYEmpty);
  const showInboundSection =
    canCreateInbound && !(readOnly && isInboundEmpty) && !adhoc;
  const showCustomSection =
    customConfig && !(readOnly && isCustomEmpty) && customConfig.enabled;
  const defaultPermission = adhoc ? "company" : null;
  const defaultTemplateName = adhoc
    ? `ADHOC-${userId}-${moment().format("YYYY-MM-DD")}-${random(1000, 9999)}`
    : null;

  useEffect(() => {
    //Need to update template name, visibility
    if (adhoc && !name && !permission) {
      dispatch(
        buildMasterTemplate({
          permission: defaultPermission,
          name: defaultTemplateName,
          errors: omit(errors, "name"),
        })
      );
    }
  }, [dispatch, masterTemplate]);

  const onFieldChange = useCallback(
    (ev) =>
      dispatch(
        buildMasterTemplate({
          [ev.target.name]: ev.target.value,
          errors: omit(errors, ev.target.name),
        })
      ),
    [dispatch, errors]
  );

  const onSectionFieldChange = useCallback(
    (ev, section, wipeErrors = []) =>
      dispatch(
        buildMasterTemplate({
          [section]: {
            ...masterTemplate[section],
            [ev.target.name]: ev.target.value,
          },
          errors: omit(
            errors,
            `${section}.${ev.target.name}`,
            ...[wipeErrors.map((error) => `${section}.${error}`)]
          ),
        })
      ),
    [dispatch, masterTemplate, errors]
  );

  const onEmailFieldChange = useCallback(
    (ev, c) => {
      if (ev && ev.name === "instanceReady") {
        if (ev.editor.getData().trim() === "") {
          return;
        }
        ev = {
          target: {
            name: ev.editor.name || "template",
            value: ev.editor.getData().trim(),
          },
        };
      }
      onSectionFieldChange(ev, "emailTemplate");
    },
    [onSectionFieldChange]
  );
  const onSmsFieldChange = useCallback(
    (ev) => onSectionFieldChange(ev, "smsTemplate"),
    [onSectionFieldChange]
  );
  const onVoiceFieldChange = useCallback(
    (ev, { wipeErrors } = {}) =>
      onSectionFieldChange(ev, "voiceTemplate", wipeErrors),
    [onSectionFieldChange]
  );
  const onInboundFieldChange = useCallback(
    (ev, { wipeErrors } = {}) =>
      onSectionFieldChange(ev, "inboundTemplate", wipeErrors),
    [onSectionFieldChange]
  );
  const onCustomFieldChange = useCallback(
    (ev) => onSectionFieldChange(ev, "customTemplate"),
    [onSectionFieldChange]
  );

  const onToggleSection = useCallback(
    (section) =>
      dispatch(
        buildMasterTemplate({
          [section]: masterTemplate[section] ? undefined : {},
          errors: omitBy(
            errors,
            (_, key) =>
              key.indexOf(section) !== -1 || rootKeys.indexOf(key) !== -1
          ),
        })
      ),
    [dispatch, masterTemplate, errors]
  );

  const onToggleVoice = useCallback(() => onToggleSection("voiceTemplate"), [
    onToggleSection,
  ]);
  const onToggleSms = useCallback(() => onToggleSection("smsTemplate"), [
    onToggleSection,
  ]);
  const onToggleEmail = useCallback(() => onToggleSection("emailTemplate"), [
    onToggleSection,
  ]);
  const onToggleTTY = useCallback(() => onToggleSection("ttyTemplate"), [
    onToggleSection,
  ]);
  const onToggleInbound = useCallback(
    () => onToggleSection("inboundTemplate"),
    [onToggleSection]
  );
  const onToggleCustom = useCallback(() => onToggleSection("customTemplate"), [
    onToggleSection,
  ]);

  const _onClone = useCallback(async () => {
    // console.log(voiceTemplate)
    // console.log(Boolean(voiceTemplate))
    // console.log(masterTemplate)
    const cloneId = await dispatch(cloneMasterTemplate(masterTemplate));
    history.push(`/master-templates/${cloneId}`);
  }, [dispatch, history, masterTemplate]);

  return (
    <>
      {!adhoc && (
        <Fields
          errors={errors}
          tagss={tags}
          name={name || defaultTemplateName}
          permission={permission || defaultPermission}
          status={status}
          onChange={(event) => {
            onFieldChange(event);
          }}
          className={classes.bottomPadded}
          readOnly={readOnly}
          onClone={_onClone}
          isEditingView={isEditingView}
        />
      )}
      {hasRootErrors && (
        <Alert
          data-test-id="master-template-no-channel-alert"
          severity="error"
          variant="filled"
          className={classes.bottomPadded}
        >
          {t("You must select at least one channel")}
        </Alert>
      )}
      <div>Choose a template type *</div>
      <div style={{ overflow: "scroll" }}>
        {showSmsSection && (
          <TemplateSection
            title={t("SMS")}
            enabled={Boolean(smsTemplate)}
            onToggle={onToggleSms}
            readOnly={readOnly}
            error={hasSmsErrors}
            isEmpty={isSmsEmpty}
          >
            <SmsTemplateSection
              smsTemplate={smsTemplate}
              smsConfig={smsConfig}
              errors={errors}
              onSmsFieldChange={onSmsFieldChange}
              readOnly={readOnly}
            />
          </TemplateSection>
        )}
        {showEmailSection && (
          <TemplateSection
            title={t("Email")}
            enabled={Boolean(emailTemplate)}
            onToggle={onToggleEmail}
            readOnly={readOnly}
            error={hasEmailErrors}
            isEmpty={isEmailEmpty}
          >
            <EmailTemplateSection
              emailTemplate={emailTemplate}
              errors={errors}
              onEmailFieldChange={onEmailFieldChange}
              readOnly={readOnly}
              isEmailChecked={Boolean(emailTemplate)}
            />
          </TemplateSection>
        )}
        {showVoiceSection && (
          <TemplateSection
            title={t("Voice")}
            readOnly={readOnly}
            enabled={Boolean(voiceTemplate)}
            onToggle={onToggleVoice}
            error={hasVoiceErrors}
            isEmpty={isVoiceEmpty}
          >
            <VoiceTemplateSection
              voiceTemplate={voiceTemplate}
              onSaveAsDraftInitial={onSaveAsDraftInitial}
              onVoiceFieldChange={onVoiceFieldChange}
              errors={errors}
              readOnly={readOnly}
              voiceConfig={voiceConfig}
              eonsConfig={eonsConfig}
              callerIdDetail={callerIdDetail}
              isEditingView={isEditingView}
              adhoc={adhoc}
            />
          </TemplateSection>
        )}
        {showTTYSection && (
          <TemplateSection
            title={t("TTY")}
            readOnly={readOnly}
            enabled={Boolean(ttyTemplate)}
            onSaveAsDraftInitial={onSaveAsDraftInitial}
            onToggle={onToggleTTY}
            error={hasTTYErrors}
            isEmpty={isTTYEmpty}
          >
            <TTYTemplateSection
              ttyTemplate={ttyTemplate}
              errors={errors}
              hasTTYErrors={hasTTYErrors}
              onSaveAsDraftInitial={onSaveAsDraftInitial}
              readOnly={readOnly}
              voiceConfig={voiceConfig}
            />
          </TemplateSection>
        )}
        {showInboundSection && !adhoc && (
          <TemplateSection
            title={t("Inbound")}
            readOnly={readOnly}
            enabled={Boolean(inboundTemplate)}
            onToggle={onToggleInbound}
            error={hasInboundErrors}
            isEmpty={isInboundEmpty}
          >
            <InboundTemplateSection
              inboundTemplate={inboundTemplate}
              onSaveAsDraftInitial={onSaveAsDraftInitial}
              onInboundFieldChange={onInboundFieldChange}
              errors={errors}
              readOnly={readOnly}
              voiceConfig={inboundConfig}
              eonsConfig={eonsConfig}
              callerIdDetail={callerIdDetail}
              adhoc={adhoc}
              isEditingView={isEditingView}
            />
          </TemplateSection>
        )}

        {showCustomSection && (
          <TemplateSection
            title={t("Custom")}
            enabled={Boolean(customTemplate)}
            onToggle={onToggleCustom}
            readOnly={readOnly}
            error={hasCustomErrors}
            isEmpty={isCustomEmpty}
          >
            <CustomTemplate
              customTemplate={customTemplate}
              customConfig={customConfig}
              errors={errors}
              onCustomFieldChange={onCustomFieldChange}
              readOnly={readOnly}
            />
          </TemplateSection>
        )}
      </div>
      <NavigationPrompt when={Boolean(masterTemplate && modified && !adhoc)}>
        {({ onConfirm, onCancel, isActive }) => (
          <NavigationDialog
            data-test-id="navigation-master-template-prompt"
            onConfirm={onConfirm}
            onCancel={onCancel}
            open={isActive}
          />
        )}
      </NavigationPrompt>
    </>
  );
}

export default memo(BaseMasterTemplatePage);
