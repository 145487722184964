import React, { memo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Login } from "../pages";

function PublicPages() {
  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Redirect to="/login" />
    </Switch>
  );
}

export default memo(PublicPages);
