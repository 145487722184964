import React, { memo } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import ListRecipientLists from "./List";
import EditRecipientList from "./Edit";
import CreateRecipientList from "./Create";
import useAuthorized, {
  RECIPIENTLIST_CREATE_PERMISSION,
  RECIPIENTLIST_UPDATE_PERMISSION,
  RECIPIENTLIST_LIST_PERMISSION,
} from "../../hooks/useAuthorized";

function RecipientsPage() {
  const match = useRouteMatch();
  const canEdit = useAuthorized(RECIPIENTLIST_UPDATE_PERMISSION);
  const canCreate = useAuthorized(RECIPIENTLIST_CREATE_PERMISSION);
  const canList = useAuthorized(RECIPIENTLIST_LIST_PERMISSION);

  return (
    <Switch>
      {canCreate && (
        <Route path={`${match.path}/create`}>
          <CreateRecipientList />
        </Route>
      )}
      {canEdit && (
        <Route path={`${match.path}/:id`}>
          <EditRecipientList />
        </Route>
      )}
      {(canList || canCreate) && (
        <Route path={`${match.path}`}>
          <ListRecipientLists />
        </Route>
      )}
      <Redirect to={`${match.path}`} />
    </Switch>
  );
}

export default memo(RecipientsPage);
