import {
  CLEAR_MASTER_TEMPLATE,
  BUILD_MASTER_TEMPLATE,
} from "../actions/masterTemplate";

export default function masterTemplateReducer(
  masterTemplate = null,
  { type, masterTemplateData, overwrite }
) {
  switch (type) {
    case BUILD_MASTER_TEMPLATE: {
      if (overwrite) {
        return masterTemplateData;
      }
      return masterTemplate
        ? { ...masterTemplate, ...masterTemplateData }
        : null;
    }
    case CLEAR_MASTER_TEMPLATE:
      return null;
    default:
      return masterTemplate;
  }
}
