import React, { useCallback, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { IconButton } from "../../../components";
import {
  Visibility,
  Edit,
  DeleteForever,
  FileCopy,
  Stop,
  PlayArrow,
} from "../../../icons";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";

function CampaignsActions({
  campaign,
  onDelete,
  onClone,
  onStop,
  testId,
  onResume,
  cloneAndDelete,
}) {
  const { t } = useTranslation();
  const [stopDialog, setStopDialog] = useState(false);
  const [resumeDialog, setResumeDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const { _id, createdById, status, schedule } = campaign;
  const history = useHistory();

  const scheduleDate = new Date(schedule);
  const presentDate = new Date();
  const dateIsDue = scheduleDate > presentDate;

  const userId = useSelector((state) => state.user.id);
  const canEdit =
    (userId === createdById &&
      status !== "RUNNING" &&
      status !== "WAITING_FOR_APPROVAL" &&
      status !== "STOPPED" &&
      status !== "COMPLETED") ||
    dateIsDue;

  const stoppable = status === "RUNNING";
  const resumable = status === "STOPPED";
  const deletable = !(status === "COMPLETED" || status === "LAUNCHED");
  const EditIcon = canEdit ? Edit : Visibility;
  const _onDelete = useCallback(() => onDelete(campaign), [onDelete, campaign]);
  const _onClone = useCallback(() => onClone(campaign), [onClone, campaign]);
  // const _onCloneAndDelete = useCallback(() => cloneAndDelete(campaign), [cloneAndDelete, campaign]);

  const _onStop = useCallback(async () => {
    await onStop(campaign);
    setStopDialog(false);
  }, [onStop, campaign, setStopDialog]);

  const _onResume = useCallback(async () => {
    await onResume(campaign);
    setResumeDialog(false);
  }, [onResume, campaign, setResumeDialog]);

  const redirectCondition = () => {
    if (canEdit && status === "RUNNING") setEditDialog(true);
    else history.push(`/campaigns/${_id}/view`);
  };

  return (
    <>
      <Tooltip title={canEdit ? t("Edit campaign") : t("View campaign")}>
        <IconButton
          // data-test-id={`${testId}-action-edit`}
          // component={Link}
          to={canEdit ? `/campaigns/${_id}` : `/campaigns/${_id}/view`}
          size="small"
          onClick={redirectCondition}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      {deletable && (
        <Tooltip title={t("Delete campaign")}>
          <IconButton
            data-test-id={`${testId}-action-delete`}
            size="small"
            color="secondary"
            onClick={_onDelete}
          >
            <DeleteForever fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={t("Clone Campaign")}>
        <IconButton
          data-test-id={`${testId}-action-clone`}
          size="small"
          onClick={_onClone}
        >
          <FileCopy fontSize="small" />
        </IconButton>
      </Tooltip>
      {stoppable && (
        <Tooltip title={t("Stop campaign")}>
          <IconButton
            data-test-id={`${testId}-action-stop`}
            size="small"
            onClick={() => setStopDialog(true)}
          >
            <Stop fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {resumable && (
        <Tooltip title={t("Resume campaign")}>
          <IconButton
            data-test-id={`${testId}-action-resume`}
            size="small"
            onClick={() => setResumeDialog(true)}
          >
            <PlayArrow fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        open={stopDialog}
        onClose={() => setStopDialog(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">{t("Stop Campaign")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {t("Are you sure you want to stop this campaign?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={_onStop}
            color="secondary"
            variant="contained"
            size="large"
          >
            {t("Yes")}
          </Button>
          <Button
            onClick={() => setStopDialog(false)}
            color="primary"
            variant="contained"
            size="large"
          >
            {t("No")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={resumeDialog}
        onClose={() => setResumeDialog(false)}
        aria-labelledby="resume-dialog-title"
        aria-describedby="resume-dialog-description"
      >
        <DialogTitle id="resume-dialog-title">
          {t("Resume Campaign")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {t("Are you sure you want to resume this campaign?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={_onResume}
            color="secondary"
            variant="contained"
            size="large"
          >
            {t("Yes")}
          </Button>
          <Button
            onClick={() => setResumeDialog(false)}
            color="primary"
            variant="contained"
            size="large"
          >
            {t("No")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editDialog}
        onClose={() => setEditDialog(false)}
        aria-labelledby="edit-dialog-title"
        aria-describedby="edit-dialog-description"
      >
        <DialogTitle id="edit-dialog-title">{t("Edit Campaign")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="edit-dialog-description">
            {t("Are you sure you want to edit this campaign?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            component={Link}
            to={`/campaigns/${_id}`}
            color="secondary"
            variant="contained"
            size="large"
            // onClick={ _onCloneAndDelete}
          >
            {t("Yes")}
          </Button>
          <Button
            onClick={() => setEditDialog(false)}
            color="primary"
            variant="contained"
            size="large"
          >
            {t("No")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default memo(CampaignsActions);
