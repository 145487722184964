import api from "./api";

const campaignBasePath = "/api/v1/campaign";

export async function getCampaignDetail(id, params) {
  const headers = {};

  const token = params?.token;
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const { data } = await api.get(`${campaignBasePath}/${id}/detail`, {
    params,
    headers,
  });
  return data;
}

export async function listPendingApprovalCampaigns(params, authToken) {
  const headers = {};

  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
  }

  const { data } = await api.get(`${campaignBasePath}/pending-approvals`, {
    params,
    headers,
  });
  return data;
}
