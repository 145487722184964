import React, { useMemo, useCallback, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Wizard } from "../../../../components";
import {
  buildNewRecipientList,
  validateMapPolygon,
  saveNewRecipientList,
  geoFilterMasterRecipients,
  getMasterListHeaders,
} from "../../../../actions/newRecipientList";
import RecipientMap from "./RecipientMap";
import Save from "../Save";

const useStyles = makeStyles((theme) => ({
  wizard: {
    // marginTop: "auto",
  },
}));

const getSteps = ({ t, validatePolygon }) => [
  {
    label: t("Filter Map"),
    path: "/recipient-lists/create/map/filter",
    validate: validatePolygon,
    component: RecipientMap,
  },
  {
    label: t("Save"),
    path: "/recipient-lists/create/map/save",
    component: Save,
  },
];

function FilterRecipientListFromMap({ onAbort, afterSave, children }) {
  const dispatch = useDispatch();
  const newRecipientList = useSelector((state) => state.newRecipientList);
  const { doneStepIdx } = newRecipientList;

  const classes = useStyles();
  const { t } = useTranslation();

  const validatePolygon = useCallback(() => dispatch(validateMapPolygon()), [
    dispatch,
  ]);

  const steps = useMemo(() => getSteps({ t, validatePolygon }), [
    t,
    validatePolygon,
  ]);

  const onStepChange = useCallback(
    (activeStepIdx) =>
      dispatch(buildNewRecipientList({ activeStepIdx, errors: {} })),
    [dispatch]
  );

  const onSave = useCallback(async () => {
    await dispatch(getMasterListHeaders());
    const recipientListId = await dispatch(saveNewRecipientList());
    await dispatch(geoFilterMasterRecipients());
    if (afterSave) {
      afterSave(recipientListId);
    }
  }, [dispatch, afterSave]);

  return (
    <>
      <Wizard
        steps={steps}
        className={classes.wizard}
        doneStepIdx={doneStepIdx}
        onStepChange={onStepChange}
        disableNext={
          Object.keys(newRecipientList.errors).length ||
          newRecipientList.loading
        }
        errors={newRecipientList.errors}
        onSave={onSave}
        onAbort={onAbort}
      />
      {children}
      <Switch>
        {steps.slice(0, doneStepIdx + 2).map((step) => (
          <Route key={step.path} path={step.path} component={step.component} />
        ))}
        <Redirect to={steps[newRecipientList.doneStepIdx + 1].path} />
      </Switch>
    </>
  );
}

export default memo(FilterRecipientListFromMap);
