import { omit } from "lodash";
import {
  CREATE_NEW_SUPPRESSION_LIST,
  BUILD_NEW_SUPPRESSION_LIST,
  CLEAR_NEW_SUPPRESSION_LIST,
} from "../actions/newSuppressionList";

export default function (
  newSuppressionList = [],
  { type, newSuppressionListData, forceCreate, keepRedirect }
) {
  switch (type) {
    case CREATE_NEW_SUPPRESSION_LIST: {
      if (forceCreate) {
        return {
          ...newSuppressionListData,
          redirect:
            newSuppressionList?.redirect || newSuppressionListData?.redirect,
        };
      }
      return {
        ...newSuppressionList,
        ...newSuppressionListData,
        redirect:
          newSuppressionList?.redirect || newSuppressionListData?.redirect,
      };
    }
    case BUILD_NEW_SUPPRESSION_LIST: {
      return { ...newSuppressionList, ...newSuppressionListData };
    }
    case CLEAR_NEW_SUPPRESSION_LIST: {
      if (keepRedirect) {
        return { redirect: true };
      }
      return null;
    }
    default:
      return newSuppressionList;
  }
}
