import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Skeleton } from "../../../components";
import { exportToCsv } from "../../../utils/exportToCSV";

const useStyles = makeStyles((theme) => ({
  value: ({ color }) => ({
    color: theme.palette[color].main,
  }),
}));

function RecipientListCount({
  color = "text",
  label,
  value,
  loading,
  testId,
  failedRecipients = [],
  ...rest
}) {
  const classes = useStyles({ color });

  const renderTextOrLink = () => {
    if (failedRecipients.length === 0) {
      return (
        <Typography
          data-test-id={`recipientList-${testId}-count`}
          variant="button"
          className={classes.value}
        >
          {value}
        </Typography>
      );
    } else {
      return (
        <a
          href="#"
          onClick={(event) => {
            exportToCsv("failed_recipients.csv", failedRecipients);
            event.preventDefault();
          }}
        >
          <Typography
            data-test-id={`recipientList-${testId}-count`}
            variant="button"
            className={classes.value}
          >
            {value}
          </Typography>
        </a>
      );
    }
  };

  return (
    <Grid
      {...rest}
      container
      spacing={2}
      alignItems="center"
      justify="space-between"
      wrap="nowrap"
      className={classes.root}
    >
      <Grid item className={classes.label}>
        <Typography variant="button">{label}:</Typography>
      </Grid>
      <Grid item>{loading ? <Skeleton width={60} /> : renderTextOrLink()}</Grid>
    </Grid>
  );
}

export default memo(RecipientListCount);
