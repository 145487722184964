import * as api from "../api/suppression";

export const LIST_SUPPRESSIONS = "LIST_SUPPRESIONS";
export const CLEAR_SUPPRESSIONS = "CLEAR_SUPPRESSIONS";

export function clearSuppressions() {
  return { type: CLEAR_SUPPRESSIONS };
}

export function saveSuppressionList(type, body) {
  return async (dispatch, getState) => {
    const { user, storage, eonsConfig } = getState();
    const { emailConfig } = eonsConfig;
    const { email } = user;
    let isError = false;

    body.map((item) => {
      item.timestamp = new Date().toISOString();
      item.createdBy = email;

      if (type === "email") {
        //from field is from file upload
        item.domainAddress = item.from ? item.from : item.domainAddress;
        item.paid = String(storage.paid);
        if (
          !emailConfig.domainAddress
            ?.toLowerCase()
            ?.includes(item?.domainAddress?.toLowerCase())
        ) {
          isError = true;
        }

        if (item["from"]) delete item["from"];
      }
    });

    if (!isError) {
      try {
        await api.saveSuppressionList({
          data: body,
          type,
        });
      } catch (err) {
        dispatch({
          type: LIST_SUPPRESSIONS,
          suppressionList: {
            errors: { shortCode: err.message, sms: err.message },
          },
        });
        throw err;
      }
      dispatch(clearSuppressions());
    } else {
      const err = new Error(
        "One or more domain address in the list does not match From Email list"
      );

      dispatch({
        type: LIST_SUPPRESSIONS,
        suppressionList: {
          errors: { shortCode: err.message, sms: err.message },
        },
      });
      throw err;
    }
  };
}

export function listSuppressions({
  shortCodes = [],
  params,
  domainAddresses = [],
  callerIds = [],
}) {
  return async (dispatch, _) => {
    let query = {
      shortcodes: shortCodes,
    };
    if (params.type === "email") {
      query = { domainAddresses: domainAddresses };
    } else if (params.type === "voice") {
      query = { callerIds };
    }
    const suppressionList = await api.getSuppressionList({
      ...query,
      ...params,
    });
    const count = await api.getSuppressionList({
      ...query,
      ...params,
      getCount: true,
    });

    dispatch({
      type: LIST_SUPPRESSIONS,
      suppressionList: { list: suppressionList, count },
    });
  };
}

export function deleteSuppression(suppressInfo) {
  return async (dispatch, getState) => {
    await api.deleteSuppressionById(suppressInfo);
  };
}
