import React, { memo } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import useAuthorized, {
  TEMPLATE_LIST_PERMISSION,
} from "../../hooks/useAuthorized";
import FolderListPage from "./List";

function FoldersPage() {
  const match = useRouteMatch();
  const hasRights = useAuthorized(TEMPLATE_LIST_PERMISSION);

  return (
    <>
      <Switch>
        {hasRights && (
          <Route path={`${match.path}/:folderId`}>
            <FolderListPage subFolderView={true} />
          </Route>
        )}

        {hasRights && (
          <Route path={`${match.path}`}>
            <FolderListPage />
          </Route>
        )}
        <Redirect to={`${match.path}`} />
      </Switch>
    </>
  );
}

export default memo(FoldersPage);
