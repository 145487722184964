import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "../../../components";
import { makeStyles } from "@material-ui/core/styles";
import { Folder, MoreVert } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  displayIcon: {
    width: 50,
    height: 50,
    color: "#fccc77",
  },
  iconBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    border: "solid 1px #ccc",
    position: "relative",
    borderRadius: "8px",
    padding: "2px",
    width: "18ch",
    minHeight: "13ch",
    margin: "2ch",
  },
  moreAction: {
    position: "absolute",
    right: "2px",
    top: "2px",
  },
  folderPanel: {
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
  },
}));

export default function DisplayFolders({ folders = [], onRename, onDelete }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMoreActionClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleMoreActionClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!folders.length && (
        <Paper
          style={{
            minHeight: "10ch",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>{t(`No Sub-folders found!`)}</Typography>
        </Paper>
      )}
      {folders && (
        <Paper>
          <Grid container className={classes.folderPanel}>
            {folders.map((folder, index) => (
              <Box className={classes.iconBox} key={`folder-box-${folder._id}`}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={(e) => handleMoreActionClick(index, e)}
                  data-test-id={`more-button-${folder._id}`}
                  className={classes.moreAction}
                >
                  <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                  key={`menu-button-${folder._id}`}
                  anchorEl={anchorEl && anchorEl[index]}
                  keepMounted
                  open={Boolean(anchorEl && anchorEl[index])}
                  onClose={handleMoreActionClose}
                  PaperProps={{
                    style: {
                      width: "15ch",
                    },
                  }}
                >
                  <MenuItem
                    key={`edit-${folder._id}`}
                    onClick={() => {
                      onRename(folder);
                      handleMoreActionClose();
                    }}
                    data-test-id={`rename-menu-${folder._id}`}
                  >
                    {t("Rename")}
                  </MenuItem>
                  <MenuItem
                    key={`delete-${folder._id}`}
                    onClick={() => {
                      onDelete(folder);
                      handleMoreActionClose();
                    }}
                    data-test-id={`delete-menu-${folder._id}`}
                  >
                    {t("Delete")}
                  </MenuItem>
                </Menu>

                <Link
                  key={folder._id}
                  color="inherit"
                  to={"/foldersTemplates/" + folder._id}
                >
                  <Folder className={classes.displayIcon} />
                </Link>
                {folder.name.length > 10 ? (
                  <Tooltip title={folder.name.toUpperCase()}>
                    <div
                      style={{
                        width: "15ch",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography noWrap={true} style={{ fontSize: 14 }}>
                        {folder.name.toUpperCase()}
                      </Typography>
                    </div>
                  </Tooltip>
                ) : (
                  <Typography style={{ fontSize: 14 }}>
                    {folder.name.toUpperCase()}
                  </Typography>
                )}
              </Box>
            ))}
          </Grid>
        </Paper>
      )}
    </>
  );
}
