import React, { useMemo, useCallback, memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
  TextField,
  Chip,
  IconButton,
  Tooltip,
  Button,
  Box,
} from "../../../components";
import MenuItem from "@material-ui/core/MenuItem";
import StatusIndicator from "../StatusIndicator";
import { compact, difference } from "lodash";
import { FileCopy } from "@material-ui/icons";

const useStyles = makeStyles({
  statusIndicator: {
    alignSelf: "center",
  },
  cloneButtonBox: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
});

const chipStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  phoneInput: {
    width: "100% !important",
  },
}));

const tagNames = [
  "Unplanned Outage",
  "Planned Outage",
  "Emergency Notification",
  "Planned Notification",
  "Energy Efficiency",
  "Billing & Payments",
  "Other",
];
function MasterTemplateFields({
  name = "",
  permission = "",
  tagss = "",
  status = "",
  errors = {},
  readOnly,
  onChange,
  onClone,
  isEditingView,
  ...rest
}) {
  const { t } = useTranslation();
  const readOnlyProps = useMemo(() => ({ readOnly }), [readOnly]);
  const classes = useStyles();
  const chipClasses = chipStyles();
  const [tags, setTags] = useState([]);
  const [tagValue, setTagValue] = useState("");
  const [hasOtherTag, setHasOtherTag] = useState(false);
  const [otherTagName, setOtherTagName] = useState("");
  const [otherFieldError, setOtherFieldError] = useState(false);

  const {
    vendorConfig: { disableUserPermissions = [] },
  } = useSelector((state) => state.eonsConfig);

  const shouldDisplayOnlyMe = !disableUserPermissions?.includes("template");
  useEffect(() => {
    setTags(tagss ? tagss.trim().split(",") : []);
  }, [tagss]);

  useEffect(() => {
    //Finding the tag name in case of OTHER option
    //as it is input by user in that case
    const diff = difference(tags, tagNames); //(tags,tagName) Order is important
    if (tags.includes("Other")) {
      setOtherTagName(diff[0]); //diff[0] has the name for OTHER
      setHasOtherTag(true);
    }
    return () => {};
  }, [tags]);

  const onAddChip = useCallback(
    (event) => {
      const tag = event.target.value;
      let newTags = Object.assign([], tags);
      const OtherIndex = newTags.indexOf("Other");

      if (tag) {
        if (newTags && newTags.indexOf(tag) == -1) {
          let deletedTags = [];
          if (OtherIndex > -1) {
            deletedTags = newTags.splice(
              OtherIndex,
              newTags.length - OtherIndex
            );
          }

          newTags.push(tag.trim());
          newTags = newTags.concat(deletedTags);
          setTags(newTags);
          setTagValue("");
          onChange({ target: { name: "tags", value: newTags?.toString() } });
        }
      }
    },
    [tags]
  );

  const deleteTag = (indx) => {
    const newTags = Object.assign([], tags);
    const tagToRemove = newTags[indx];
    newTags.splice(indx, 1);
    if (tagToRemove === "Other") {
      setOtherTagName("");
      setHasOtherTag(false);
      setOtherFieldError(false);
      const diff = difference(tags, tagNames);
      let temp = newTags.filter((item) => item !== diff[0]); //Also removing the user input value for other tag
      setTags(temp);
      onChange({ target: { name: "tags", value: temp?.toString() } });
    } else {
      setTags(newTags);
      onChange({ target: { name: "tags", value: newTags?.toString() } });
    }
  };

  function createChips(tags, classes) {
    const readOnly = false;
    return (
      <ul className={classes.root}>
        {tags.map((tag, idx) => {
          if (tagNames.includes(tag)) {
            //Listing only the dropdown values and not the user input value
            return (
              <li key={idx}>
                <Chip
                  label={tag}
                  onDelete={!readOnly && (() => deleteTag(idx))}
                  className={classes.chip}
                  disabled={readOnly}
                />
              </li>
            );
          }
        })}
      </ul>
    );
  }
  const allTags = useMemo(() => createChips(tags, chipClasses), [
    tags,
    classes,
  ]);

  const checkOtherField = () => {
    if (!otherTagName || otherTagName === "") {
      setOtherFieldError(true);
    } else {
      setOtherFieldError(false);
      let newTags = Object.assign([], tags);
      if (newTags && newTags.indexOf(otherTagName) == -1) {
        const otherIndex = newTags.indexOf("Other");
        if (newTags.length > otherIndex) {
          const i = otherIndex + 1;
          newTags.splice(i, 1);
        }
        newTags.push(otherTagName.trim());
        setTags(newTags);
        onChange({ target: { name: "tags", value: newTags?.toString() } });
      }
    }
  };

  return (
    <Paper {...rest} p={2}>
      <Grid
        container
        spacing={4}
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={12} md={6}>
          {/* TextFields sometimes have an issue with overlapping label and value, which happens here.
        Setting `InputLabelProps={{ shrink: Boolean(value) }}` is the official fix for the issue, see more here:
        https://material-ui.com/components/text-fields/#limitations */}
          <TextField
            name="name"
            value={name}
            onChange={onChange}
            label={t("Template Name *")}
            variant="outlined"
            fullWidth
            autoComplete="off"
            error={Boolean(errors.name)}
            helperText={errors.name}
            InputLabelProps={{ shrink: Boolean(name) }}
            InputProps={readOnlyProps}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={10} sm={10} md={5}>
          <FormControl
            component="fieldset"
            error={Boolean(errors.permission)}
            disabled={readOnly}
          >
            <FormLabel component="legend">{t("Visibility Level *")}</FormLabel>
            <RadioGroup
              row
              name="permission"
              onChange={!readOnly && onChange}
              value={permission}
            >
              {shouldDisplayOnlyMe ? (
                <FormControlLabel
                  value="user"
                  control={<Radio color="primary" />}
                  label={t("Only Me")}
                />
              ) : (
                ""
              )}
              <FormControlLabel
                value="division"
                control={<Radio color="primary" />}
                label={t("My Division")}
              />
              <FormControlLabel
                value="company"
                control={<Radio color="primary" />}
                label={t("My Company")}
              />
            </RadioGroup>
            {errors.permission && (
              <FormHelperText>{errors.permission}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs className={classes.statusIndicator}>
          <StatusIndicator status={status} fontSize="large" />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            //type="text"
            select
            name="tags"
            value={tagValue}
            label={t("Tags")}
            variant="outlined"
            fullWidth
            onChange={(event) => {
              setTagValue(event.target.value);
              onAddChip(event);
            }}
            // onKeyPress={(event) => {
            //   if (event.key === "Enter") {
            //     setTagValue(event.target.value);
            //     onAddChip(event);
            //   }
            // }}
            onBlur={onAddChip}
            data-test-id="campaign-tags"
            disabled={readOnly}
          >
            {tagNames.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
          {allTags}
          {hasOtherTag && (
            <TextField
              name="otherTagName"
              value={otherTagName}
              onChange={(event) => {
                setOtherTagName(event.target.value);
                setOtherFieldError(false);
              }}
              label={t("Specify other")}
              variant="outlined"
              fullWidth
              onBlur={checkOtherField}
              error={Boolean(otherFieldError)}
              helperText={
                otherFieldError &&
                `This is required as you have chosen 'Other' as one of the tags!`
              }
              // InputLabelProps={{ shrink: Boolean(name) }}
              // InputProps={readOnlyProps}
              size="small"
              disabled={readOnly}
            />
          )}
        </Grid>
        {isEditingView && (
          <Grid item xs={12} sm={12} md={12}>
            <Box className={classes.cloneButtonBox}>
              <Button
                data-test-id="master-template-url-shortner-button"
                variant="contained"
                color="primary"
                onClick={onClone}
                size="small"
                disabled={readOnly}
              >
                {t("Clone Template")}
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

export default memo(MasterTemplateFields);
