export function setParams(params) {
  const query = paramsToQuery(params);
  window.history.replaceState(null, "", `?${query}`);
}

export function getParams(search = window.location.search) {
  return queryToParams(search.slice(1));
}

export function paramsToQuery(params = {}) {
  return Object.getOwnPropertyNames(params)
    .map((name) => {
      name = encodeURIComponent(name);
      const rawValue = params[name];
      const value = encodeURIComponent(JSON.stringify(rawValue));
      return rawValue ? `${name}=${value}` : "";
    })
    .filter(Boolean)
    .join("&");
}

export function queryToParams(query = "") {
  const tokens = query.split("&");
  return tokens.reduce((params, token) => {
    const [name, value] = token.split("=");
    // ignore malformed query params
    if (!name) {
      return params;
    }
    const decodedName = decodeURIComponent(name);
    const decodedValue = decodeURIComponent(value);

    // try to parse the value to extract type information
    try {
      const parsedValue = JSON.parse(decodedValue);
      return { ...params, [decodedName]: parsedValue };
    } catch (err) {
      console.error(err);
    }

    // return the raw string value if the parsing fails
    return { ...params, [decodedName]: decodedValue };
  }, {});
}
