import * as seedApi from "../api/seed";

export const LIST_SEEDS = "LIST_SEEDS";
export const CLEAR_SEEDS = "CLEAR_SEEDS";

export function clearSeeds() {
  return { type: CLEAR_SEEDS };
}

export function listSeeds(params) {
  return async (dispatch) => {
    const seeds = await seedApi.listSeeds(params);
    dispatch({ type: LIST_SEEDS, seeds });
  };
}
