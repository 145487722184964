import React, { useState, useCallback, memo } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  Homepage,
  Campaigns,
  RecipientLists,
  MasterRecipients,
  MasterTemplates,
  Reports,
  Map,
  Calendar,
  Approvers,
  Seeds,
  Suppression,
  InboundCampaigns,
  Keywords,
  AdminPage,
  Folders,
} from "../pages";
import RecipientListAbortConfirmDialog from "../pages/RecipientLists/AbortConfirmDialog";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import Timer from "../components/LogoutTimer";
import useAuthorized, {
  APPROVER_LIST_PERMISSION,
  CALENDAR_LIST_PERMISSION,
  REPORT_LIST_PERMISSION,
  SEED_LIST_PERMISSION,
  TEMPLATE_LIST_PERMISSION,
  RECIPIENTLIST_LIST_PERMISSION,
  MAP_LIST_PERMISSION,
  RECIPIENTLIST_CREATE_PERMISSION,
  MAP_CREATE_PERMISSION,
  TEMPLATE_CREATE_PERMISSION,
  CAMPAIGN_CREATE_PERMISSION,
  CAMPAIGN_LIST_PERMISSION,
  SUPPRESSION_LIST_PERMISSION,
  SUPPRESSION_CREATE_PERMISSION,
  INBOUND_LIST_PERMISSION,
  INBOUND_CREATE_PERMISSION,
  KEYWORD_LIST_PERMISSION,
  KEYWORD_CREATE_PERMISSION,
  ADMIN_GET_PERMISSION,
  ADMIN_CREATE_PERMISSION,
} from "../hooks/useAuthorized";

function PrivatePages() {
  const [isSideNavOpen, setSideNavOpen] = useState(false);

  const onSideNavOpen = useCallback(() => setSideNavOpen(true), [
    setSideNavOpen,
  ]);
  const onSideNavClose = useCallback(() => setSideNavOpen(false), [
    setSideNavOpen,
  ]);
  const onSideNavToggle = useCallback(() => setSideNavOpen(!isSideNavOpen), [
    setSideNavOpen,
    isSideNavOpen,
  ]);

  const canListRecipientLists = useAuthorized(RECIPIENTLIST_LIST_PERMISSION);
  const canCreateRecipientLists = useAuthorized(
    RECIPIENTLIST_CREATE_PERMISSION
  );
  const hasAccessToRecipientListFeature =
    canListRecipientLists || canCreateRecipientLists;

  const canListReports = useAuthorized(REPORT_LIST_PERMISSION);
  const canListCalendar = useAuthorized(CALENDAR_LIST_PERMISSION);

  const canListTemplates = useAuthorized(TEMPLATE_LIST_PERMISSION);
  const canCreateTemplates = useAuthorized(TEMPLATE_CREATE_PERMISSION);
  const hasAccessToTemplateFeature = canListTemplates || canCreateTemplates;

  const canListApprovers = useAuthorized(APPROVER_LIST_PERMISSION);
  const canListSeeds = useAuthorized(SEED_LIST_PERMISSION);

  const canListCampaign = useAuthorized(CAMPAIGN_LIST_PERMISSION);
  const canCreateCampaign = useAuthorized(CAMPAIGN_CREATE_PERMISSION);
  const hasAccessToCampaignFeature = canListCampaign || canCreateCampaign;

  const canListInbound = useAuthorized(INBOUND_LIST_PERMISSION);
  const canCreateInbound = useAuthorized(INBOUND_CREATE_PERMISSION);
  const hasAccessToInboundFeature = canListInbound || canCreateInbound;

  const canListSuppression = useAuthorized(SUPPRESSION_LIST_PERMISSION);
  const canCreateSuppression = useAuthorized(SUPPRESSION_CREATE_PERMISSION);
  const {
    vendorConfig: {
      createRecipientListMasterDate,
      createRecipientListMap = false,
    },
  } = useSelector((state) => state.eonsConfig);

  const canListMaps = useAuthorized(MAP_LIST_PERMISSION);
  const canCreateMaps = useAuthorized(MAP_CREATE_PERMISSION);
  const hasAccessToMapFeature =
    (canListMaps || canCreateMaps) && createRecipientListMap;

  const canUseMasterData = createRecipientListMasterDate;

  const hasAccessToSuppressionFeature =
    canListSuppression || canCreateSuppression;

  const hasKeywordListPermission = useAuthorized(KEYWORD_LIST_PERMISSION);
  const hasKeywordCreatePermission = useAuthorized(KEYWORD_CREATE_PERMISSION);
  const hasKeywordManagementRight =
    hasKeywordListPermission || hasKeywordCreatePermission;

  const hasAdminListPermission = useAuthorized(ADMIN_GET_PERMISSION);
  const hasAdminCreatePermission = useAuthorized(ADMIN_CREATE_PERMISSION);
  const isEonsAdmin = hasAdminListPermission || hasAdminCreatePermission;
  // const isEonsAdmin = useSelector(
  //   (state) => state.eonsConfig?.vendorConfig?.eonsAdmin
  // );

  const { advancedApproverManagement = false } = useSelector(
    (state) => state.eonsConfig.vendorConfig
  );

  return (
    <>
      <TopNav onSideNavToggle={onSideNavToggle} isSideNavOpen={isSideNavOpen}>
        <Timer />
        <SideNav
          isOpen={isSideNavOpen}
          onOpen={onSideNavOpen}
          onClose={onSideNavClose}
        >
          <Switch>
            <Route path="/homepage">
              <Homepage />
            </Route>
            {hasAccessToCampaignFeature && (
              <Route path="/campaigns">
                <Campaigns />
              </Route>
            )}
            {hasAccessToInboundFeature && (
              <Route path="/inbound">
                <InboundCampaigns />
              </Route>
            )}
            {hasAccessToRecipientListFeature && (
              <Route path="/recipient-lists">
                <RecipientLists />
              </Route>
            )}
            {canUseMasterData && (
              <Route path="/master-recipients">
                <MasterRecipients />
              </Route>
            )}
            {hasAccessToTemplateFeature && (
              <Route path="/master-templates">
                <MasterTemplates />
              </Route>
            )}
            {canListReports && (
              <Route path="/reports">
                <Reports />
              </Route>
            )}
            {hasAccessToMapFeature && (
              <Route path="/map">
                <Map />
              </Route>
            )}
            {canListCalendar && (
              <Route path="/calendar">
                <Calendar />
              </Route>
            )}

            {!advancedApproverManagement ? (
              canListApprovers && (
                <Route path="/approvers">
                  <Approvers />
                </Route>
              )
            ) : isEonsAdmin ? (
              <Route path="/admin/approvers">
                <Approvers />
              </Route>
            ) : null}

            {canListSeeds && (
              <Route path="/seeds">
                <Seeds />
              </Route>
            )}

            {hasAccessToSuppressionFeature && (
              <Route path="/suppression">
                <Suppression />
              </Route>
            )}

            {hasKeywordManagementRight && (
              <Route path="/keyword">
                <Keywords />
              </Route>
            )}
            {isEonsAdmin && (
              <Route path="/admin/">
                <AdminPage />
              </Route>
            )}
            <Route path="/foldersTemplates">
              <Folders />
            </Route>

            <Redirect to="/homepage" />
          </Switch>
        </SideNav>
      </TopNav>
      <RecipientListAbortConfirmDialog />
    </>
  );
}

export default memo(PrivatePages);
