import React, { useCallback, useState, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { omit, omitBy } from "lodash";
import { Grid, Alert, Tooltip } from "../../../../components";
import {
  Add,
  AttachFile,
  Info as InfoIcon,
  Directions,
  Dialpad,
} from "../../../../icons";
import { buildMasterTemplate } from "../../../../actions/masterTemplate";
import { uploadWavFile } from "../../../../actions/masterTemplate";
import {
  FILE_TYPE,
  CUSTOMER_DATA_TYPE,
  TTS_DATA_TYPE,
  LIVE_TYPE,
  MACHINE_TYPE,
  SIMPLE_VOICE_INPUT,
  ADVANCE_VOICE_INPUT,
  DTMF_DATA_TYPE,
  DIRECTION_DATA_TYPE,
} from "../constants";
import VoiceItems from "../VoiceTemplate/VoiceItems";
import VoiceTTS from "../VoiceTemplate/VoiceTTS";
import InboundFields from "../InboundTemplate/InboundFields";
import VoiceAdvanceField from "../VoiceTemplate/VoiceAdvanceFields";
import _ from "lodash";
import VoiceStepButtons from "../VoiceTemplate/VoiceStepButtons";

const useStyles = makeStyles((theme) => ({
  fileSelector: {
    width: "100%",
    height: 120,
  },
  flexDivider: {
    marginLeft: "auto",
  },
  padded: {
    marginRight: "38px !important",
    marginLeft: "38px !important",
  },
  dragIndicator: {
    cursor: "grab",
  },
}));

function InboundTemplateSection({
  inboundTemplate = {
    voiceType: "freeswitch",
    freeswitch: {
      dialPlan: {
        person: {
          steps: [],
        },
      },
    },
  },
  voiceConfig,
  eonsConfig,
  onInboundFieldChange,
  errors = {},
  readOnly,
  adhoc,
  isEditingView,
  onSaveAsDraftInitial,
  callerIdDetail,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { advanceVoice, _id: templateId } = inboundTemplate;
  const [activeTab, setActiveTab] = useState(LIVE_TYPE);
  const [advanceTab, setAdvanceTab] = useState(SIMPLE_VOICE_INPUT);

  if (!inboundTemplate.freeswitch) {
    inboundTemplate["freeswitch"] = {
      dialPlan: {
        machine: null,
        person: {
          steps: [],
        },
      },
    };
    inboundTemplate["voiceType"] = "freeswitch";
  }
  const {
    freeswitch: { dialPlan },
  } = inboundTemplate;

  const { steps: voiceItems } = dialPlan[activeTab]
    ? dialPlan[activeTab]
    : { steps: [] };
  const onToggleAnsweringMachine = useCallback(() => {
    dispatch(
      buildMasterTemplate({
        inboundTemplate: {
          ...inboundTemplate,
          freeswitch: {
            ...inboundTemplate.freeswitch,
            dialPlan: {
              ...inboundTemplate.freeswitch.dialPlan,
              [MACHINE_TYPE]: inboundTemplate.freeswitch.dialPlan[MACHINE_TYPE]
                ? null
                : { steps: [] },
            },
          },
        },
      })
    );
    setActiveTab(LIVE_TYPE);
  }, [dispatch, inboundTemplate, setActiveTab]);

  useEffect(() => {
    if (advanceVoice) setAdvanceTab(ADVANCE_VOICE_INPUT);
  }, [advanceVoice]);

  const onSetVoiceItems = useCallback(
    (items, errors) =>
      dispatch(
        buildMasterTemplate({
          inboundTemplate: _.omit(
            {
              ...inboundTemplate,
              freeswitch: {
                ...inboundTemplate.freeswitch,
                dialPlan: {
                  ...inboundTemplate.freeswitch.dialPlan,
                  [activeTab]: { steps: items },
                },
              },
            },
            "advanceVoice"
          ),
          errors,
        })
      ),
    [inboundTemplate, activeTab, dispatch]
  );

  const onAddVoiceItem = useCallback(
    (stepType) => {
      let action = "None";
      if (stepType === "file") {
        action = "playback";
      } else if (stepType === "customerData") {
        action = "playback";
      } else if (stepType === "ttsData") {
        action = "playback";
      } else if (stepType === "dtmf") {
        action = "dtmf";
      } else if (stepType === "goto") {
        action = "goto";
      }
      let steps = [
        ...(inboundTemplate.freeswitch.dialPlan[activeTab].steps || []),
      ];
      steps = [
        ...steps,
        { action, stepType, _id: String(Date.now()), name: `${steps.length}` },
      ];

      dispatch(
        buildMasterTemplate({
          inboundTemplate: {
            ...inboundTemplate,
            freeswitch: {
              ...inboundTemplate.freeswitch,
              dialPlan: {
                ...inboundTemplate.freeswitch.dialPlan,
                [activeTab]: {
                  steps,
                },
              },
            },
          },
          errors: omit(
            errors,
            `inboundTemplate.freeswitch.dialPlan.${activeTab}.steps`
          ),
        })
      );
    },
    [inboundTemplate, activeTab, errors, dispatch]
  );

  const onDeleteVoiceItem = useCallback(
    (idx) => {
      dispatch(
        buildMasterTemplate({
          inboundTemplate: {
            ...inboundTemplate,
            freeswitch: {
              ...inboundTemplate.freeswitch,
              dialPlan: {
                ...inboundTemplate.freeswitch.dialPlan,
                [activeTab]: {
                  steps: inboundTemplate.freeswitch.dialPlan[
                    activeTab
                  ].steps.filter((_, i) => i !== idx),
                },
              },
            },
          },
          // This will clear inbound item erros
          // - to remove only specific item, it will get complex and very algorithmicy
          errors: omitBy(
            errors,
            (_, key) =>
              key.startsWith(
                `inboundTemplate.freeswitch.dialPlan.${activeTab}.`
              ) !== -1
          ),
        })
      );
    },
    [inboundTemplate, activeTab, errors, dispatch]
  );

  const onAddCustomerData = useCallback(
    () => onAddVoiceItem(CUSTOMER_DATA_TYPE),
    [onAddVoiceItem]
  );

  const onAddTTSData = useCallback(() => onAddVoiceItem(TTS_DATA_TYPE), [
    onAddVoiceItem,
  ]);

  const onAddDirectionData = useCallback(
    () => onAddVoiceItem(DIRECTION_DATA_TYPE),
    [onAddVoiceItem]
  );

  const onAddDTMFData = useCallback(() => onAddVoiceItem(DTMF_DATA_TYPE), [
    onAddVoiceItem,
  ]);

  const onAddVoiceFile = useCallback(() => onAddVoiceItem(FILE_TYPE), [
    onAddVoiceItem,
  ]);

  const onSetCustomerData = useCallback(
    (idx, transcript) => {
      const newItems = [
        ...inboundTemplate.freeswitch.dialPlan[activeTab].steps,
      ];
      newItems[idx] = {
        ...newItems[idx],
        variableName: transcript,
      };
      dispatch(
        buildMasterTemplate({
          inboundTemplate: {
            ...inboundTemplate,
            freeswitch: {
              ...inboundTemplate.freeswitch,
              dialPlan: {
                ...inboundTemplate.freeswitch.dialPlan,
                [activeTab]: { steps: newItems },
              },
            },
          },
          errors: omit(
            errors,
            `inboundTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.variableName`,
            `inboundTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.transcript`
          ),
        })
      );
    },
    [inboundTemplate, activeTab, errors, dispatch]
  );
  const onSetTTSData = useCallback(
    (idx, transcript) => {
      const newItems = [
        ...inboundTemplate.freeswitch.dialPlan[activeTab].steps,
      ];
      newItems[idx] = {
        ...newItems[idx],
        transcript,
      };
      dispatch(
        buildMasterTemplate({
          inboundTemplate: {
            ...inboundTemplate,
            freeswitch: {
              ...inboundTemplate.freeswitch,
              dialPlan: {
                ...inboundTemplate.freeswitch.dialPlan,
                [activeTab]: { steps: newItems },
              },
            },
          },
          errors: omit(
            errors,
            `inboundTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.variableName`,
            `inboundTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.transcript`
          ),
        })
      );
    },
    [inboundTemplate, activeTab, errors, dispatch]
  );

  const onSetDirectionData = useCallback(
    (idx, step) => {
      const newItems = [
        ...inboundTemplate.freeswitch.dialPlan[activeTab].steps,
      ];
      newItems[idx] = {
        ...newItems[idx],
        step: step.toString(),
      };
      dispatch(
        buildMasterTemplate({
          inboundTemplate: {
            ...inboundTemplate,
            freeswitch: {
              ...inboundTemplate.freeswitch,
              dialPlan: {
                ...inboundTemplate.freeswitch.dialPlan,
                [activeTab]: { steps: newItems },
              },
            },
          },
          errors: omit(
            errors,
            `inboundTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.goto`
          ),
        })
      );
    },
    [inboundTemplate, activeTab, errors, dispatch]
  );

  const onSetDTMFData = useCallback(
    (idx, keys) => {
      const newItems = [
        ...inboundTemplate.freeswitch.dialPlan[activeTab].steps,
      ];
      newItems[idx] = { ...newItems[idx], keys };

      // dtmf errors: Filter out general error belong to dtmf in this index
      const errorKeys = Object.keys(errors).filter(
        (e) =>
          !e.startsWith(
            `inboundTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}`
          )
      );

      const updatedErrors = {};
      errorKeys.forEach((k) => {
        updatedErrors[k] = errors[k];
      });

      dispatch(
        buildMasterTemplate({
          inboundTemplate: {
            ...inboundTemplate,
            freeswitch: {
              ...inboundTemplate.freeswitch,
              dialPlan: {
                ...inboundTemplate.freeswitch.dialPlan,
                [activeTab]: { steps: newItems },
              },
            },
          },
          errors: updatedErrors,
        })
      );
    },
    [inboundTemplate, activeTab, dispatch, errors]
  );

  const onDTMFFilePathChange = useCallback(
    (idx, filePath) => {
      const items = [...inboundTemplate.freeswitch.dialPlan[activeTab].steps];
      items[idx].path = filePath;

      dispatch(
        buildMasterTemplate({
          inboundTemplate: {
            ...inboundTemplate,
            freeswitch: {
              ...inboundTemplate.freeswitch,
              dialPlan: {
                ...inboundTemplate.freeswitch.dialPlan,
                [activeTab]: { steps: items },
              },
            },
          },
          errors: errors,
        })
      );
    },
    [inboundTemplate, dispatch, errors]
  );

  const onSetVoiceRetries = useCallback(
    (ev) => {
      dispatch(
        buildMasterTemplate({
          inboundTemplate: {
            ...inboundTemplate,
            freeswitch: {
              ...inboundTemplate.freeswitch,
              retries: ev.target.value,
            },
          },
          errors: omit(errors, `inboundTemplate.freeswitch.retries`),
        })
      );
    },
    [dispatch, inboundTemplate, errors]
  );

  const onSetVoiceAdvance = useCallback(
    (text, errors) => {
      if (!text || text.length < 1) {
        text = null;
      }
      dispatch(
        buildMasterTemplate({
          inboundTemplate: { ...inboundTemplate, advanceVoice: text },
          errors: omit(errors, `inboundTemplate.advanceVoice`),
        })
      );
    },
    [inboundTemplate, dispatch]
  );

  const onRejectVoiceFile = useCallback(
    (idx) =>
      dispatch(
        buildMasterTemplate({
          errors: {
            ...errors,
            [`inboundTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.path`]: t(
              "Upload a valid mp3, wav, m4a or ogg file"
            ),
          },
        })
      ),
    [dispatch, activeTab, errors, t]
  );

  const onUploadVoiceFile = useCallback(
    async ([file], idx) => {
      try {
        const newItems = [
          ...inboundTemplate.freeswitch.dialPlan[activeTab].steps,
        ];

        await onSaveAsDraftInitial();
        const path = await dispatch(uploadWavFile(file));
        newItems[idx] = {
          ...newItems[idx],
          path,
        };

        dispatch(
          buildMasterTemplate({
            inboundTemplate: {
              ...inboundTemplate,
              freeswitch: {
                ...inboundTemplate.freeswitch,
                dialPlan: {
                  ...inboundTemplate.freeswitch.dialPlan,
                  [activeTab]: { steps: newItems },
                },
              },
            },
            errors: omit(
              errors,
              `inboundTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.path`
            ),
          })
        );
      } catch (err) {
        console.error(err);
        onRejectVoiceFile(idx);
      }
    },
    [dispatch, inboundTemplate, errors, activeTab, onRejectVoiceFile]
  );

  const onTabChange = useCallback((_, activeTab) => setActiveTab(activeTab), [
    setActiveTab,
  ]);

  const onInputTabChange = useCallback(
    (_, activeTab) => setAdvanceTab(activeTab),
    [setAdvanceTab]
  );

  const {
    vendorConfig: {
      createCustomerTemplateData,
      canAddVoiceFile = false,
      canAddDirection = false,
      canAddDTMF = false,
    },
  } = useSelector((state) => state.eonsConfig);

  const canUseCustomerData = createCustomerTemplateData;

  return (
    <Grid container spacing={2} data-test-id="master-template-inbound-section">
      <Grid item xs={12} sm={12} md={12}>
        <Grid spacing={4}>
          {advanceTab === SIMPLE_VOICE_INPUT ? (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <InboundFields
                  inboundTemplate={inboundTemplate}
                  errors={errors}
                  onInboundFieldChange={onInboundFieldChange}
                  onVoiceRetryChange={onSetVoiceRetries}
                  readOnly={readOnly}
                  eonsConfig={eonsConfig}
                  callerIdDetail={callerIdDetail}
                  isEditingView={isEditingView}
                  adhoc={adhoc}
                  voiceActionStep={
                    !adhoc && (
                      <VoiceStepButtons
                        readOnly={readOnly}
                        testId={"master-template-add-inbound-voice-items"}
                        label={t("Add Voice Action Items")}
                        steps={[
                          {
                            testId: `master-template-add-tts-button-person-${activeTab}`,
                            startIcon: <Add />,
                            onClick: onAddTTSData,
                            label: t("Add TTS"),
                            permission: true,
                          },
                          {
                            testId: `master-template-add-inbound-file-button-${activeTab}`,
                            startIcon: <AttachFile />,
                            onClick: onAddVoiceFile,
                            error: errors[`inboundTemplate.${activeTab}`],
                            label: t("Add Voice File"),
                            permission: true && canAddVoiceFile,
                          },
                          {
                            testId: `master-template-add-inbound-goto-button-${activeTab}`,
                            startIcon: <Directions />,
                            onClick: onAddDirectionData,
                            label: t("Add Direction"),
                            permission:
                              activeTab === LIVE_TYPE && canAddDirection, // disabled GOTO from Machine Tab
                          },
                          {
                            testId: `master-template-add-inbound-dtmf-button-${activeTab}`,
                            startIcon: <Dialpad />,
                            onClick: onAddDTMFData,
                            label: t("Add DTMF"),
                            permission: activeTab === LIVE_TYPE && canAddDTMF, // disabled DTMF from Machine Tab
                          },
                        ]}
                      />
                    )
                  }
                  voiceActionItems={
                    <Grid item xs={12} sm={12} md={12}>
                      {adhoc ? (
                        <VoiceTTS
                          inboundTemplate={voiceItems}
                          onSetVoiceItems={onSetVoiceItems}
                          errors={errors}
                          readOnly={readOnly}
                        />
                      ) : (
                        <Grid container direction="column" spacing={2}>
                          {Boolean(voiceItems?.length) && (
                            <Grid item>
                              <VoiceItems
                                voiceItems={voiceItems}
                                errors={errors}
                                readOnly={readOnly}
                                onDeleteVoiceItem={onDeleteVoiceItem}
                                onUploadVoiceFile={onUploadVoiceFile}
                                onRejectVoiceFile={onRejectVoiceFile}
                                onSetCustomerData={onSetCustomerData}
                                onSetTTSData={onSetTTSData}
                                onSetVoiceItems={onSetVoiceItems}
                                onSetDTMFData={onSetDTMFData}
                                onDTMFFilePathChange={onDTMFFilePathChange}
                                onSetDirectionData={onSetDirectionData}
                                onSaveAsDraftInitial={onSaveAsDraftInitial}
                                activeTab={activeTab}
                                testSection="inbound-section"
                              />
                            </Grid>
                          )}
                          {errors[
                            `inboundTemplate.freeswitch.dialPlan.person.steps`
                          ] && (
                            <Alert severity="error">
                              {
                                errors[
                                  `inboundTemplate.freeswitch.dialPlan.person.steps`
                                ]
                              }
                            </Alert>
                          )}

                          {errors[`inboundTemplate.${activeTab}`] && (
                            <Grid item className={classes.padded}>
                              <Alert severity="error" variant="filled">
                                {t("At least one inbound file is required")}
                              </Alert>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  }
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={12} md={12}>
              <VoiceAdvanceField
                advanceVoice={advanceVoice}
                errors={errors}
                readOnly={readOnly}
                onSetVoiceAdvance={onSetVoiceAdvance}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {templateId && (
        <Grid item className={classes.flexDivider}>
          <Tooltip title={templateId} placement="right" interactive>
            <InfoIcon color="disabled" style={{ height: "auto" }} />
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
}

export default memo(InboundTemplateSection);
