import { CLEAR_SEED, SET_SEED } from "../actions/seed";

export default function seedReducer(seed = null, { type, newSeed }) {
  switch (type) {
    case SET_SEED: {
      return { ...seed, ...newSeed };
    }
    case CLEAR_SEED:
      return null;
    default:
      return seed;
  }
}
