import api from "./api";

export async function approveOrRejectCampaign(
  campaignId,
  token,
  approve,
  comment
) {
  const { data } = await api.patch(
    `/api/v1/campaign/${campaignId}/approve`,
    { approve, comment },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return data;
}

export async function sendTestMessage(testMessage, token) {
  const { data } = await api.post(
    `/api/v1/campaign/approver/test`,
    testMessage,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return data;
}
