import i18n from "i18next";
import { batch } from "react-redux";
import * as seedApi from "../api/seed";
import { notify } from "./notification";

export const CLEAR_SEED = "CLEAR_SEED";
export const SET_SEED = "SET_SEED";

export function deleteSeed({ _id, name }, params) {
  return async (dispatch) => {
    if (_id) {
      await seedApi.deleteSeed(_id, params);
      dispatch(
        notify({
          type: "success",
          message: i18n.t("{{name}} successfully deleted", {
            name: name ?? i18n.t("seed"),
          }),
        })
      );
    }
  };
}

export function getSeed(id) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { activeDivisionId } = storage;

    try {
      const seed = await seedApi.getSeed(id, {
        activeDivisionId,
      });
      dispatch(setSeed(seed));
    } catch (err) {
      dispatch(setSeed({ notFound: true }));
    }
  };
}

export function updateSeed() {
  return async (dispatch, getState) => {
    const { seed, storage } = getState();
    const { _id, name } = seed;

    const seedUpdates = {
      name: seed.name,
      permission: seed.permission,
    };

    if (seed.emailModified) {
      seedUpdates.email = seed.email;
    }
    if (seed.smsModified) {
      seedUpdates.sms = seed.sms;
    }
    if (seed.voiceModified) {
      seedUpdates.voice = seed.voice;
    }
    if (seed.ttyModified) {
      seedUpdates.tty = seed.tty;
    }

    try {
      await seedApi.updateSeed(_id, seedUpdates, {
        activeDivisionId: storage.activeDivisionId,
      });
      batch(() => {
        dispatch(clearSeed(seed));
        dispatch(
          notify({
            type: "success",
            message: i18n.t("Successfully updated {{name}}", { name }),
          })
        );
      });
    } catch (err) {
      if (err?.message?.includes("403")) {
        batch(() => {
          dispatch(getSeed(_id));
          dispatch(
            notify({
              type: "error",
              message: i18n.t(
                "Emptying out a Seed is not allowed in case of update!"
              ),
            })
          );
        });
      } else {
        batch(() => {
          dispatch(
            setSeed({
              errors: err.response.data,
            })
          );
          dispatch(
            notify({
              type: "error",
              message: i18n.t("Please correct your errors before updating"),
            })
          );
        });
      }
      throw err;
    }
  };
}

export function saveSeed() {
  return async (dispatch, getState) => {
    const { seed, storage } = getState();
    const {
      name,
      email,
      sms,
      voice,
      tty,
      permission,
      emailModified,
      smsModified,
      voiceModified,
      ttyModified,
    } = seed;

    const seedToSave = {
      name,
      permission,
    };

    if (emailModified) {
      seedToSave.email = email;
    }
    if (smsModified) {
      seedToSave.sms = sms;
    }
    if (voiceModified) {
      seedToSave.voice = voice;
    }
    if (ttyModified) {
      seedToSave.tty = tty;
    }

    try {
      await seedApi.createSeed(seedToSave, {
        activeDivisionId: storage.activeDivisionId,
      });
      batch(() => {
        dispatch(clearSeed(seed));
        dispatch(
          notify({
            type: "success",
            message: i18n.t("Successfully created {{name}}", { name }),
          })
        );
      });
    } catch (err) {
      batch(() => {
        dispatch(
          setSeed({
            errors: err.response.data,
          })
        );
        dispatch(
          notify({
            type: "error",
            message: i18n.t("Please correct your errors before updating"),
          })
        );
      });
      throw err;
    }
  };
}

export function resetSeed(seed) {
  const { name, email, sms, voice, tty, permission } = seed;
  const resetedSeed = { email, sms, name, voice, tty, permission };
  return { type: SET_SEED, resetedSeed };
}

export function setSeed(newSeed) {
  return { type: SET_SEED, newSeed };
}

export function clearSeed() {
  return { type: CLEAR_SEED };
}
