import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "../../../components";
import { DeleteForever, Edit } from "../../../icons";
import { useHistory } from "react-router-dom";
// todo: disable delete on permission

function KeywordListActions({ onDelete, keyword, canDelete }) {
  const { t } = useTranslation();
  const history = useHistory();
  const _onDelete = useCallback(() => onDelete(keyword), [onDelete, keyword]);

  const linkToUpdate = () => {
    history.push({
      pathname: "/keyword/update",
      state: { keyword: keyword },
    });
  };

  return (
    <>
      <Tooltip title={t("Edit keyword")}>
        <IconButton
          size="small"
          color="primary"
          onClick={linkToUpdate}
          data-test-id={`edit-button-${keyword.shortcode}-${keyword.keyword}-${keyword.value}`}
        >
          <Edit fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Delete keyword")}>
        <IconButton
          size="small"
          color="secondary"
          onClick={_onDelete}
          disabled={!canDelete}
          data-test-id={`delete-button-${keyword.shortcode}-${keyword.keyword}-${keyword.value}`}
        >
          <DeleteForever fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default memo(KeywordListActions);
