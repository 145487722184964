import React, { useCallback, memo } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../Button";

function WizardNavButton({
  component = Button,
  to,
  disabled,
  onClick,
  validate,
  color = "primary",
  pushHistory,
  children,
  ...rest
}) {
  const ResponsiveButton = component;
  const history = useHistory();

  const _onClick = useCallback(
    async (ev) => {
      ev.preventDefault();

      // allow both sync and async validation
      let isValid = !validate || validate();
      if (isValid instanceof Promise) {
        isValid = await isValid;
      }

      if (isValid) {
        if (to) {
          if (pushHistory) {
            history.push(to);
          } else {
            history.replace(to);
          }
        }
        if (onClick) {
          onClick();
        }
      }
    },
    [validate, onClick, history, to, pushHistory]
  );

  if (disabled) {
    return (
      <ResponsiveButton {...rest} disabled size="small" color={color}>
        {children}
      </ResponsiveButton>
    );
  }

  if (to) {
    return (
      <ResponsiveButton
        {...rest}
        size="small"
        component={Link}
        to={to}
        onClick={_onClick}
        color={color}
      >
        {children}
      </ResponsiveButton>
    );
  }

  return (
    <ResponsiveButton {...rest} size="small" onClick={_onClick} color={color}>
      {children}
    </ResponsiveButton>
  );
}

export default memo(WizardNavButton);
