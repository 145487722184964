import { CLEAR_APPROVER, SET_APPROVER } from "../actions/approver";

export default function approverReducer(
  approver = null,
  { type, newApprover }
) {
  switch (type) {
    case SET_APPROVER: {
      return { ...approver, ...newApprover };
    }
    case CLEAR_APPROVER:
      return null;
    default:
      return approver;
  }
}
