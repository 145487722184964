import React, { memo, useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import ListCampaignApprover from "./List";
import ReviewCampaign from "./Single";
import { useSelector } from "react-redux";

function CampaignApprover() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const newSearchString = searchParams.toString();

  return (
    <Switch>
      <Route path={`/campaign/approver`}>
        <ListCampaignApprover />
      </Route>
      <Route path={`/campaign/:campaignId/review`}>
        <ReviewCampaign />
      </Route>
      <Redirect to={`/campaign/approver${newSearchString}`} />
    </Switch>
  );
}

export default memo(CampaignApprover);
