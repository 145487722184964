import { GET_DNS, CLEAR_DNS } from "../actions/dns";

export default function dnsReducer(
  dnsData = null,
  { type, dnsData: newDnsData }
) {
  switch (type) {
    case GET_DNS:
      return newDnsData;
    case CLEAR_DNS:
      return null;
    default:
      return dnsData;
  }
}
