import React, { memo, useEffect, useState, useCallback, useMemo } from "react";
import { getMasterTemplate } from "../../../../api/masterTemplate";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Grid } from "../../../../components";
import { buildCampaign } from "../../../../actions/campaign";
import { omit } from "lodash";
import InboundPreview from "../Preview";

const useStyles = makeStyles((theme) => ({
  textCenter: { textAlign: "center" },
  greyColor: { color: "grey" },
}));

function getInboundPlaceHolders(InboundTemplate) {
  if (!InboundTemplate) {
    return [];
  }
  return InboundTemplate.filter((live) => live.stepType === "customerData")
    .filter((live) => !live.transcript)
    .map((live) => live.variableName);
}

function FillData({ templateId }) {
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.campaign);

  let {
    liveInboundTargetFieldsSetup,
    answeringInboundTargetFieldsSetup,
    templateId: campaignMasterTemplateId,
    errors = {},
  } = campaign || {};

  if (templateId) {
    campaignMasterTemplateId = templateId;
  }
  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );

  const paid = useSelector((state) => state.storage.paid);

  const classes = useStyles();

  const [liveInboundTemplate, setLiveInboundTemplate] = useState();
  const [answeringInboundTemplate, setAnsweringInboundTemplate] = useState();
  const [advanceInboundTemplate, setAdvanceInboundTemplate] = useState();
  const activeCompanyId = useSelector((state) => state.user.companyId);
  useEffect(() => {
    async function fetchCampaignData() {
      const getParams = {
        activeDivisionId,
        activeCompanyId,
        paid,
      };
      const masterTemplate = await getMasterTemplate(
        campaignMasterTemplateId,
        getParams
      );
      setLiveInboundTemplate(
        masterTemplate?.inboundTemplate?.freeswitch?.dialPlan?.person?.steps
      );
      setAnsweringInboundTemplate(
        masterTemplate?.inboundTemplate?.freeswitch?.dialPlan?.machine?.steps
      );
      setAdvanceInboundTemplate(
        masterTemplate?.inboundTemplate?.advanceInbound
      );
    }

    fetchCampaignData();
  }, [campaignMasterTemplateId, activeDivisionId, activeCompanyId, paid]);

  const liveInboundPlaceHolders = useMemo(
    () => getInboundPlaceHolders(liveInboundTemplate),
    [liveInboundTemplate]
  );

  const answeringInboundPlaceHolders = useMemo(
    () => getInboundPlaceHolders(answeringInboundTemplate),
    [answeringInboundTemplate]
  );

  const handleLiveInboundChange = useCallback(
    (event, templateValue, freeForm) => {
      const recipientHeaderValue = event.target.value;
      dispatch(
        buildCampaign({
          liveInboundTargetFieldsSetup: {
            ...liveInboundTargetFieldsSetup,
            [templateValue]: {
              freeForm,
              value: recipientHeaderValue,
            },
          },
          errors: omit(
            errors,
            "liveInboundTargetFieldsSetup",
            `liveInboundTargetFieldsSetup.${templateValue}.value`
          ),
        })
      );
    },
    [dispatch, liveInboundTargetFieldsSetup, errors]
  );

  const handleAnsweringInboundChange = useCallback(
    (event, templateValue, freeForm) => {
      const recipientHeaderValue = event.target.value;
      dispatch(
        buildCampaign({
          answeringInboundTargetFieldsSetup: {
            ...answeringInboundTargetFieldsSetup,
            [templateValue]: {
              freeForm,
              value: recipientHeaderValue,
            },
          },
          errors: omit(
            errors,
            "answeringInboundTargetFieldsSetup",
            `answeringInboundTargetFieldsSetup.${templateValue}.value`
          ),
        })
      );
    },
    [dispatch, answeringInboundTargetFieldsSetup, errors]
  );

  return (
    <>
      <Grid container direction="column" spacing={2}>
        {liveInboundTemplate && (
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <h2>Live Inbound Preview</h2>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <InboundPreview InboundArray={liveInboundTemplate} />
              </Grid>
              {0 < liveInboundPlaceHolders.length && (
                <section>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <h2>Fill Live Inbound Target Fields</h2>
                    <h3 className={classes.greyColor}>
                      Fill the data of your campaign, either as free form or
                      select from your list
                    </h3>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Grid container direction="row" spacing={2}>
                      {liveInboundPlaceHolders.map((templateValue) => (
                        <Grid
                          key={templateValue}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          xl={12}
                        >
                          <Grid container direction="row" spacing={1}>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                fullWidth
                                size="small"
                                data-test-id="liveInbound-free-form"
                                label={`${templateValue} free form`}
                                variant="outlined"
                                error={
                                  Boolean(
                                    errors?.[
                                      `liveInboundTargetFieldsSetup.${templateValue}.value`
                                    ]
                                  ) ||
                                  Boolean(errors.liveInboundTargetFieldsSetup)
                                }
                                helperText={
                                  errors?.[
                                    `liveInboundTargetFieldsSetup.${templateValue}.value`
                                  ] || errors.liveInboundTargetFieldsSetup
                                }
                                onChange={(event) =>
                                  handleLiveInboundChange(
                                    event,
                                    templateValue,
                                    true
                                  )
                                }
                                value={
                                  liveInboundTargetFieldsSetup?.[templateValue]
                                    ?.freeForm
                                    ? liveInboundTargetFieldsSetup?.[
                                        templateValue
                                      ]?.value
                                    : ""
                                }
                              ></TextField>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} xl={2}>
                              <p className={classes.textCenter}>OR</p>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                fullWidth
                                size="small"
                                data-test-id="liveInbound-list-column"
                                label={`${templateValue} list column`}
                                variant="outlined"
                                select
                                error={
                                  Boolean(
                                    errors?.[
                                      `liveInboundTargetFieldsSetup.${templateValue}.value`
                                    ]
                                  ) ||
                                  Boolean(errors.liveInboundTargetFieldsSetup)
                                }
                                helperText={
                                  errors?.[
                                    `liveInboundTargetFieldsSetup.${templateValue}.value`
                                  ] || errors.liveInboundTargetFieldsSetup
                                }
                                value={
                                  !liveInboundTargetFieldsSetup?.[templateValue]
                                    ?.freeForm
                                    ? liveInboundTargetFieldsSetup?.[
                                        templateValue
                                      ]?.value ?? ""
                                    : ""
                                }
                                onChange={(event) =>
                                  handleLiveInboundChange(
                                    event,
                                    templateValue,
                                    false
                                  )
                                }
                              ></TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </section>
              )}
            </Grid>
          </Grid>
        )}
        {answeringInboundTemplate && (
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <h2>Answering Inbound Preview</h2>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <InboundPreview InboundArray={answeringInboundTemplate} />
              </Grid>
              {0 < answeringInboundPlaceHolders.length && (
                <section>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <h2>Fill Answering Inbound Target Fields</h2>
                    <h3 className={classes.greyColor}>
                      Fill the data of your campaign, either as free form or
                      select from your list
                    </h3>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Grid container direction="row" spacing={2}>
                      {answeringInboundPlaceHolders.map((templateValue) => (
                        <Grid
                          key={templateValue}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          xl={12}
                        >
                          <Grid container direction="row" spacing={1}>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                fullWidth
                                data-test-id="answeringInbound-free-form"
                                size="small"
                                label={`${templateValue} free form`}
                                variant="outlined"
                                onChange={(event) =>
                                  handleAnsweringInboundChange(
                                    event,
                                    templateValue,
                                    true
                                  )
                                }
                                error={
                                  Boolean(
                                    errors?.[
                                      `answeringInboundTargetFieldsSetup.${templateValue}.value`
                                    ]
                                  ) ||
                                  Boolean(
                                    errors.answeringInboundTargetFieldsSetup
                                  )
                                }
                                helperText={
                                  errors?.[
                                    `answeringInboundTargetFieldsSetup.${templateValue}.value`
                                  ] || errors.answeringInboundTargetFieldsSetup
                                }
                                value={
                                  answeringInboundTargetFieldsSetup?.[
                                    templateValue
                                  ]?.freeForm
                                    ? answeringInboundTargetFieldsSetup?.[
                                        templateValue
                                      ]?.value
                                    : ""
                                }
                              ></TextField>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} xl={2}>
                              <p className={classes.textCenter}>OR</p>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                data-test-id="answeringInbound-list-column"
                                fullWidth
                                size="small"
                                label={`${templateValue} list column`}
                                variant="outlined"
                                select
                                error={
                                  Boolean(
                                    errors?.[
                                      `answeringInboundTargetFieldsSetup.${templateValue}.value`
                                    ]
                                  ) ||
                                  Boolean(
                                    errors.answeringInboundTargetFieldsSetup
                                  )
                                }
                                helperText={
                                  errors?.[
                                    `answeringInboundTargetFieldsSetup.${templateValue}.value`
                                  ] || errors.answeringInboundTargetFieldsSetup
                                }
                                value={
                                  !answeringInboundTargetFieldsSetup?.[
                                    templateValue
                                  ]?.freeForm
                                    ? answeringInboundTargetFieldsSetup?.[
                                        templateValue
                                      ]?.value ?? ""
                                    : ""
                                }
                                onChange={(event) =>
                                  handleAnsweringInboundChange(
                                    event,
                                    templateValue,
                                    false
                                  )
                                }
                              ></TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </section>
              )}
            </Grid>
          </Grid>
        )}
        {advanceInboundTemplate && (
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12} md={12} xl={22}>
                <h2>Advance Inbound Preview</h2>
              </Grid>
              <section>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <h3 className={classes.greyColor}>
                    Advance Inbound does not have preview
                  </h3>
                </Grid>
              </section>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default memo(FillData);
