import React, { useCallback, useState, useEffect, useRef, memo } from "react";
import { InputAdornment, IconButton, TextField } from "@material-ui/core";
import { Search as SearchIcon, Close as CloseIcon } from "../icons";

function Search({
  onSearch,
  defaultValue = "",
  debounce = 0,
  InputProps,
  testId,
  ...rest
}) {
  const [value, setValue] = useState(defaultValue);
  const timeoutId = useRef();

  // clear the field instead of search on icon click when the user is in a debounced mode with a value
  // the debounce will search in X ms in this case anyways
  // so adding another search options would trigger unnecessary searches
  const clearOnIconClick = Boolean(debounce && value);

  const onChange = useCallback(
    (ev) => {
      const { value } = ev.target;
      setValue(value);
      // clear the previous debounced search from the previous change event
      clearTimeout(timeoutId.current);
      // trigger a debounced search on changes in debounce mode
      if (debounce) {
        timeoutId.current = setTimeout(() => onSearch(value), debounce);
      }
    },
    [setValue, onSearch, debounce]
  );

  const onClear = useCallback(() => {
    if (value) {
      setValue("");
      onSearch("");
    }
    clearTimeout(timeoutId.current);
  }, [value, setValue, onSearch]);

  const onKeyDown = useCallback(
    (ev) => {
      // only trigger search on enter keys in none debounce mode
      // debounce mode triggers a search after X ms anyways
      // and we would like to avoid duplicate searches
      if (ev.key === "Enter" && !debounce) {
        onSearch(value);
      }
      if (ev.key === "Escape") {
        onClear();
      }
    },
    [onSearch, onClear, value, debounce]
  );

  const onIconClick = useCallback(() => {
    if (clearOnIconClick) {
      onClear();
    } else {
      onSearch(value);
    }
  }, [onSearch, onClear, value, clearOnIconClick]);

  useEffect(
    () => () => {
      // clear the final debounced search on component unmount
      clearTimeout(timeoutId.current);
    },
    []
  );

  return (
    <TextField
      {...rest}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      data-test-id={`${testId}-search-input-field`}
      id={`${testId}-search-input-field`}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" onClick={onIconClick}>
            <IconButton
              size="small"
              color="primary"
              data-test-id={`${testId}-search-bar-button`}
            >
              {clearOnIconClick ? <CloseIcon /> : <SearchIcon />}
            </IconButton>
          </InputAdornment>
        ),
        ...InputProps,
      }}
    />
  );
}

export default memo(Search);
