import React, { memo } from "react";
import { Grid } from "../../../components";
import { baseURL } from "../../../api/api";

const voiceBasePath = `${baseURL}/api/v1/voice_service`;

const createFilePath = (filePath) => {
  const finalPath = `${voiceBasePath}/assets?path=${encodeURIComponent(
    filePath
  )}`;
  return finalPath;
};

function TTYPreview({ tty }) {
  return (
    <Grid container spacing={2} alignItems="center">
      {tty ? (
        <audio
          controls
          className="my-2"
          style={{ height: "30px", width: "100%", marginTop: "5px" }}
        >
          <source src={createFilePath(tty)} type="audio/mpeg" />
          Your browser does not support the HTML audio
        </audio>
      ) : null}
    </Grid>
  );
}

export default memo(TTYPreview);
