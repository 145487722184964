import i18n from "i18next";
import { batch } from "react-redux";
import * as userApi from "../api/user";
import { notify } from "./notification";
import { setEonsConfig } from "./eons";

export const SET_USER = "SET_USER";
export const RESET_STORE = "RESET_STORE";

export function getMe() {
  return async (dispatch, getState) => {
    let user;
    try {
      user = await userApi.getMe();
    } catch (err) {
      // log out the user if the /me call fails
      // the call probably fails because the user is already logged out
      // but the user may have a stuck session instead which should be cleaned up by the API
      // otherwise the user will have no way to login until the stuck session's ttl expires
      await dispatch(logout());
      dispatch(
        notify({
          type: "error",
          message: i18n.t("Your login attempt failed, please try again later."),
        })
      );
      console.error(err);
      return;
    }

    // immediately log out the user if they have ECS divisions but no EONS enabled divisions
    if (!user.divisions.length) {
      await dispatch(logout());
      dispatch(
        notify({
          type: "error",
          message: i18n.t("You are not part of an EONS enabled divisions"),
        })
      );
      return;
    }

    // set the user before doing anything with the storage
    // this is necessary because the storage is scoped per user
    dispatch(setUser(user));

    // set the active division id to the first eons enabled division if the stored id is invalid
    // this can happen if a new user logs into the same machine or if the user is removed from a division
    const { storage } = getState();
    const activeDivision =
      user.divisions.find(
        (division) => division.id === storage.activeDivisionId
      ) || user.divisions[0];

    // this sets the active division id and fetches the relevant eons config
    await dispatch(
      setEonsConfig(activeDivision.id, activeDivision.companyId, {
        resetParams: false,
      })
    );
  };
}

export function login(loginData) {
  return async (dispatch) => {
    try {
      await userApi.login(loginData);
    } catch (err) {
      dispatch(
        notify({
          type: "error",
          message: "Invalid username or password!",
        })
      );
      console.error(err);
      return;
    }

    // try to get the user data if the login succeeds and creates a session
    await dispatch(getMe());
  };
}

export function logout() {
  return async (dispatch) => {
    await userApi.logout();
    // completely reset the in memory store on logout to do not leak other user's data
    // upon user change on the same computer
    batch(() => {
      dispatch(resetStore());
      dispatch(notify({ type: "info", message: i18n.t("You logged out") }));
    });
  };
}

function setUser(newUser) {
  return { type: SET_USER, newUser };
}

export function resetStore() {
  return { type: RESET_STORE };
}

export function loginSSO(loginData) {
  return async (dispatch) => {
    try {
      await userApi.loginSSO(loginData);
    } catch (err) {
      dispatch(
        notify({
          type: "error",
          message: "Invalid username or password!",
        })
      );
      console.error(err);
      return;
    }
    await dispatch(getMe());
  };
}
