import { Typography, Box, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { memo } from "react";
import * as _ from "lodash";
import { PieChart, Pie, ResponsiveContainer, Cell, Legend } from "recharts";

function Chart({ data = [], onCellClick, contactType }) {
  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#FF2864",
    "#BB9237",
    "#ABF618",
    "#179560",
  ];

  const renameProcessedTextToSent = (value) => {
    if (value?.toLowerCase() === "processed") {
      return "SENT";
    }
    return value;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          isAnimationActive={false}
          outerRadius={110}
          fill="#8884d8"
          dataKey="value"
          label={({
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            value,
            name,
          }) => {
            const RADIAN = Math.PI / 180;
            const radius = 25 + innerRadius + (outerRadius - innerRadius);
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
            return (
              <text
                x={x}
                y={y}
                fill="black"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
              >
                {`${_.capitalize(renameProcessedTextToSent(name))}: ${value}%`}
              </text>
            );
          }}
        >
          {data.map((entry, index) => (
            <Cell
              onClick={() => onCellClick(entry, contactType.toLowerCase())} // list based on status name
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
        <Legend
          verticalAlign="top"
          height={36}
          formatter={(value, entry, index) => {
            const {
              payload: { raw },
            } = entry;
            return `${renameProcessedTextToSent(value)}: ${raw}`;
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}

function ReportChart({ contactType, report, chartData, onCellClick }) {
  const { t } = useTranslation();
  return (
    <Box mb={2}>
      <Paper>
        <Box p={2}>
          <Typography variant="h4">{t(contactType)}</Typography>
        </Box>
        {_.isEmpty(report) ? (
          <Typography style={{ padding: 8 }}>
            {t(`No ${contactType.toLowerCase()} report data received yet.`)}
          </Typography>
        ) : (
          <Chart
            data={chartData}
            onCellClick={onCellClick}
            contactType={contactType}
          />
        )}
      </Paper>
    </Box>
  );
}

export default memo(ReportChart);
