import { LIST_SUPPRESSIONS, CLEAR_SUPPRESSIONS } from "../actions/suppression";

export default function suppressionReducer(
  suppressionList = null,
  { type, suppressionList: newSuppressionList }
) {
  switch (type) {
    case LIST_SUPPRESSIONS:
      return newSuppressionList;
    case CLEAR_SUPPRESSIONS:
      return null;
    default:
      return suppressionList;
  }
}
