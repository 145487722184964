import * as pendingApprovalCampaignsApi from "../api/pendingApprovalCampaigns";
import { SET_APPROVAL_DATA } from "./approvalData";
import { startLoading, stopLoading } from "./loading";
import { setParams } from "./params";

export const LIST_PENDING_APPROVAL_CAMPAIGNS =
  "LIST_PENDING_APPROVAL_CAMPAIGNS";
export const CLEAR_PENDING_APPROVAL_CAMPAIGNS =
  "CLEAR_PENDING_APPROVAL_CAMPAIGNS";

export function clearPendingApprovalCampaigns() {
  return { type: CLEAR_PENDING_APPROVAL_CAMPAIGNS };
}

export function listPendingApprovalCampaigns(queryParams = {}) {
  return async (dispatch, getState) => {
    startLoading();
    const { params } = getState();
    const { token } = params;
    if (token) {
      const campaigns = await pendingApprovalCampaignsApi.listPendingApprovalCampaigns(
        queryParams,
        token
      );
      dispatch({
        type: LIST_PENDING_APPROVAL_CAMPAIGNS,
        campaigns,
      });

      // Get back to previous page if there is no records in current page
      if (
        campaigns?.data?.length === 0 &&
        queryParams &&
        queryParams?.page > 0
      ) {
        dispatch(
          setParams({
            page: queryParams?.page - 1,
          })
        );
      }

      stopLoading();
    }
  };
}

export function getCampaignDetail(id) {
  return async (dispatch, getState) => {
    const { campaign: existingCampaign } = getState();
    if (!id) {
      dispatch({
        type: SET_APPROVAL_DATA,
        approvalData: {},
      });
    } else if (existingCampaign?._id !== id) {
      const { params } = getState();
      const token = params?.token;

      try {
        const campaign = await pendingApprovalCampaignsApi.getCampaignDetail(
          id,
          {
            token,
          }
        );
        dispatch({ type: SET_APPROVAL_DATA, approvalData: campaign });
      } catch (err) {}
    }
  };
}
