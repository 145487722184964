import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, ToggleButtonGroup, ToggleButton } from "../../components";

const useStyles = makeStyles({
  toggleButton: {
    flex: 1,
  },
  buttonGroup: {
    width: "100%",
  },
  badge: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});

function SuppressionToggle({ readOnly, onTabChange, activeTab }) {
  const { t } = useTranslation();
  const classes = useStyles();

  if (readOnly) return "";

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <ToggleButtonGroup
          data-test-id="master-template-voice-active-tab-toggle"
          size="small"
          color="primary"
          onChange={onTabChange}
          value={activeTab}
          exclusive
          fullwidth="true"
          className={classes.buttonGroup}
        >
          <ToggleButton
            color="primary"
            value={"sms"}
            className={classes.toggleButton}
          >
            {t("SMS")}
          </ToggleButton>
          <ToggleButton value={"email"} className={classes.toggleButton}>
            {t("Email")}
          </ToggleButton>
          <ToggleButton value={"voice"} className={classes.toggleButton}>
            {t("Voice")}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
}

export default memo(SuppressionToggle);
