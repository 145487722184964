import React, { memo } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import ListSeeds from "./List";
import CreateSeed from "./Single/Create";
import EditSeed from "./Single/Edit";
import useAuthorized, {
  SEED_UPDATE_PERMISSION,
  SEED_CREATE_PERMISSION,
  SEED_GET_PERMISSION,
  SEED_LIST_PERMISSION,
} from "../../hooks/useAuthorized";

function SeedsPage() {
  const canEdit = useAuthorized(SEED_UPDATE_PERMISSION);
  const canView = useAuthorized(SEED_GET_PERMISSION);
  const canCreate = useAuthorized(SEED_CREATE_PERMISSION);
  const canList = useAuthorized(SEED_LIST_PERMISSION);

  const match = useRouteMatch();
  return (
    <Switch>
      {canCreate && (
        <Route path={`${match.path}/create`}>
          <CreateSeed />
        </Route>
      )}

      {(canEdit || canView) && (
        <Route path={`${match.path}/:seedId`}>
          <EditSeed />
        </Route>
      )}

      {canList && (
        <>
          <Route path={`${match.path}`}>
            <ListSeeds />
          </Route>
          <Redirect to={`${match.path}`} />
        </>
      )}
    </Switch>
  );
}

export default memo(SeedsPage);
