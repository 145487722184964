export const ADD_TIME = "ADD_TIME";
export const RESET_TIME = "RESET_TIME";

export function addTime(time) {
  return { type: ADD_TIME, newTime: time };
}

export function resetTime() {
  return { type: RESET_TIME };
}

export function resetTimer() {
  return async (dispatch) => {
    dispatch(resetTime());
  };
}

export function addMoreTime(time) {
  return async (dispatch) => {
    dispatch(addTime(time));
  };
}

export function getCurrentTimerTime() {
  return async (dispatch, getState) => {
    return getState().timer;
  };
}
