import React, { useCallback, useMemo, memo, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, matchPath, Link as RouterLink } from "react-router-dom";
import classNames from "classnames";
import {
  Box,
  Typography,
  TextField,
  AppBar,
  Grid,
  IconButton,
  Breadcrumbs,
  Link,
  useMediaQuery,
} from "../components";
import { Menu, MenuOpen } from "../icons";
import { setEonsConfig } from "../actions/eons";
import PendingTasks from "./PendingTasks";
import { getDivision } from "../api/division";

const useStyles = makeStyles((theme) => ({
  appbar: {
    height: theme.custom.topbarHeight,
    backgroundColor: theme.palette.grey[200],
    zIndex: 500,
    padding: "0 4px",
  },
  largeScreen: {
    // paddingLeft: theme.custom.sidebarWidth + theme.spacing(2),
  },
  fullIcon: {
    width: theme.custom.sidebarWidth + theme.spacing(2),
    marginLeft: "1rem",
  },
  smallIcon: {
    height: theme.custom.topbarHeight,
    padding: "5px",
  },
  toolbar: {
    flex: 1,
  },
  title: {
    textTransform: "uppercase",
  },
  breadcrumbs: {
    flexWrap: "nowrap",
    whiteSpace: "pre",
  },
  leftControls: {
    maxWidth: "80%",
    overflow: "hidden",
  },
  content: {
    marginTop: theme.custom.topbarHeight,
  },
  divisionSelector: {
    minWidth: 250,
    marginLeft: "auto",
    marginRight: theme.spacing(2),
  },
  companyLogo: {
    maxWidth: 40,
    maxHeight: 40,
    marginLeft: "1rem",
  },
  companyName: {
    marginLeft: "1rem",
  },
}));

const getNavMap = (t, recipientListForCampaign) => [
  {
    path: "/recipient-lists",
    title: t("Recipient Lists"),
    testId: "recipientList",
    idx: 0,
  },
  {
    path: "/master-recipients",
    title: t("Master List"),
    testId: "masterRecipients",
    idx: 0,
  },
  {
    path: "/recipient-lists/:id",
    title: recipientListForCampaign
      ? t("Edit List for Campaign")
      : t("Edit List"),

    divisionSelectorDisabled: true,
    idx: 1,
  },
  {
    path: "/recipient-lists/create",
    title: recipientListForCampaign
      ? t("New List for Campaign")
      : t("New List"),
    divisionSelectorDisabled: true,
    idx: 1,
  },
  {
    path: "/master-templates",
    title: t("Templates"),
    testId: "templates",
    idx: 0,
  },
  {
    path: "/master-templates/:masterTemplateId",
    title: t("Edit Template"),
    divisionSelectorDisabled: true,
    idx: 1,
  },
  {
    path: "/master-templates/create",
    title: t("New Template"),
    divisionSelectorDisabled: true,
    idx: 1,
  },
  {
    path: "/approvers",
    title: t("Approvers"),
    testId: "approvers",
    idx: 0,
  },
  {
    path: "/approvers/:approverId",
    title: t("Edit Approver"),
    idx: 1,
    divisionSelectorDisabled: true,
  },
  {
    path: "/approvers/create",
    title: t("Create Approver"),
    idx: 1,
    divisionSelectorDisabled: true,
  },
  {
    path: "/seeds",
    title: t("Seeds"),
    testId: "seeds",
    idx: 0,
  },
  {
    path: "/seeds/:seedId",
    title: t("Edit Seed"),
    idx: 1,
    divisionSelectorDisabled: true,
  },
  {
    path: "/seeds/create",
    title: t("Create Seed"),
    idx: 1,
    divisionSelectorDisabled: true,
  },
  {
    path: "/campaigns",
    title: t("Campaigns"),
    testId: "campaigns",
    idx: 0,
  },
  {
    path: "/inbound",
    title: t("Inbound"),
    testId: "inbound_campaigns",
    idx: 0,
  },
  {
    path: "/inbound/:templateId/view",
    title: t("View Inbound"),
    testId: "inbound_campaigns_view",
    idx: 1,
  },
  {
    path: "/campaigns/:campaignId",
    title: t("Edit Campaign"),
    idx: 1,
    divisionSelectorDisabled: true,
  },
  {
    path: "/campaigns/:campaignId/view",
    title: t("View Campaign"),
    idx: 1,
    divisionSelectorDisabled: true,
  },
  {
    path: "/campaigns/create",
    title: t("Create Campaign"),
    idx: 1,
    divisionSelectorDisabled: true,
  },
  {
    path: "/map",
    title: t("Map"),
    idx: 0,
  },
  {
    path: "/reports",
    title: t("Reports"),
    divisionSelectorDisabled: false,
    idx: 0,
  },
  {
    path: "/suppression",
    title: t("Suppression"),
    testId: "suppression",
    divisionSelectorDisabled: true,
    idx: 0,
  },
  {
    path: "/suppression/create",
    title: t("Create"),
    testId: "suppression-create",
    divisionSelectorDisabled: true,
    idx: 1,
  },
  {
    path: "/homepage",
    title: t("Eons homepage"),
    divisionSelectorDisabled: false,
    idx: 0,
  },
  {
    path: "/keyword",
    title: t("Keywords"),
    divisionSelectorDisabled: true,
    idx: 0,
  },
  {
    path: "/keyword/create",
    title: t("Create Keyword"),
    divisionSelectorDisabled: true,
    idx: 1,
  },
  {
    path: "/keyword/update",
    title: t("Update Keyword"),
    divisionSelectorDisabled: true,
    idx: 1,
  },
  {
    path: "/admin",
    title: t("Admin Functions"),
    divisionSelectorDisabled: true,
    idx: 0,
  },
  {
    path: "/admin/dnd",
    title: t("DND"),
    divisionSelectorDisabled: true,
    idx: 1,
  },
  {
    path: "/admin/dns",
    title: t("DNS"),
    divisionSelectorDisabled: true,
    idx: 1,
  },
  {
    path: "/foldersTemplates",
    title: t("Folders"),
    testId: "foldersTemplates",
    idx: 0,
  },
];

function getNavCrumbs(location, breadcrumbMap) {
  let divisionSelectorDisabled = false;

  const breadcrumbs = [];
  breadcrumbMap.forEach((crumb) => {
    const match = matchPath(location.pathname, { path: crumb.path });
    if (match) {
      divisionSelectorDisabled =
        divisionSelectorDisabled || crumb.divisionSelectorDisabled;

      breadcrumbs[crumb.idx] = { ...crumb, path: match.url };
    }
  });

  return { breadcrumbs, divisionSelectorDisabled };
}

function TopNav({
  children,
  className,
  isSideNavOpen,
  onSideNavToggle,
  ...rest
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const recipientListForCampaign = useSelector(
    (state) => state.newRecipientList?.redirect
  );

  let [filterOption, setFilterOption] = useState(
    useSelector((state) => state.user.divisions)
  );

  const breadcrumbMap = useMemo(() => getNavMap(t, recipientListForCampaign), [
    t,
    recipientListForCampaign,
  ]);

  const { breadcrumbs, divisionSelectorDisabled } = useMemo(
    () => getNavCrumbs(location, breadcrumbMap),
    [location, breadcrumbMap]
  );

  const dispatch = useDispatch();
  const divisions = useSelector((state) => state.user.divisions);
  const activeDivision = useSelector((state) =>
    divisions.find((division) => division.id === state.storage.activeDivisionId)
  );
  const companyLogo = useSelector((state) => state.eonsConfig?.logoImage);
  const companyName = activeDivision?.companyName;
  const {
    vendorConfig: { whiteLabel },
  } = useSelector((state) => state.eonsConfig);
  const shouldDisplayCompanyLogo = whiteLabel;

  const onActiveDivisionChange = useCallback(
    (ev, newDivision) => {
      const selectedDivision = newDivision || activeDivision;
      if (selectedDivision) {
        dispatch(setEonsConfig(selectedDivision.id, newDivision?.companyId));
      }
    },
    [dispatch, activeDivision]
  );

  const handleSearchKeyPress = async (ev, val, reason) => {
    if (reason === "input") {
      const result = await getDivision(null, val);
      setFilterOption(() => [...result.data]);
    }
  };

  return (
    <>
      <AppBar
        {...rest}
        position="fixed"
        color="inherit"
        className={classNames(className, classes.appbar, {
          [classes.largeScreen]: isLargeScreen,
        })}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          wrap="nowrap"
          justify="space-between"
          className={classes.toolbar}
        >
          <Grid item className={classes.leftControls}>
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
              {(!isLargeScreen && (
                <Grid item>
                  <IconButton
                    onClick={onSideNavToggle}
                    // Inside toggle does not trigger the click event.
                    data-test-id={"sideNavToggle"}
                  >
                    {isSideNavOpen ? <MenuOpen /> : <Menu />}
                  </IconButton>
                </Grid>
              )) ||
                (!shouldDisplayCompanyLogo ? (
                  <img
                    className={classes.fullIcon}
                    alt="logo"
                    src="/assets/logo.png"
                  />
                ) : companyLogo ? (
                  <img className={classes.companyLogo} src={companyLogo} />
                ) : (
                  <Typography className={classes.companyName} variant="h6">
                    {companyName}
                  </Typography>
                ))}
              <Grid item>
                <Breadcrumbs
                  classes={{ root: classes.title, ol: classes.breadcrumbs }}
                >
                  {!isLargeScreen && (
                    <img
                      className={classes.smallIcon}
                      alt="logo"
                      src="/assets/logo_small.png"
                    />
                  )}
                  {breadcrumbs.map(({ path, title, testId }, idx) =>
                    idx === breadcrumbs.length - 1 ? (
                      <Typography variant="h6" key={path}>
                        {title}
                      </Typography>
                    ) : (
                      <Link
                        component={RouterLink}
                        variant="h6"
                        to={path}
                        key={path}
                        data-test-id={`top-nav-${testId}`}
                      >
                        {title}
                      </Link>
                    )
                  )}
                </Breadcrumbs>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
              <Grid item>
                <PendingTasks />
              </Grid>
              {1 < divisions.length && (
                <Grid item>
                  <Autocomplete
                    value={activeDivision}
                    onInputChange={handleSearchKeyPress}
                    onChange={onActiveDivisionChange}
                    disabled={divisionSelectorDisabled}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    options={filterOption}
                    getOptionLabel={(division) =>
                      `${division.name} - ${division.companyName}`
                    }
                    className={classes.divisionSelector}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Active division")}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
      <Box {...rest} className={classes.content}>
        {children}
      </Box>
    </>
  );
}

export default memo(TopNav);
