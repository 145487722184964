import React, { Fragment, memo } from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Collapse,
  List,
} from "../../components";

const useStyle = makeStyles((theme) => ({
  root: {
    opacity: 0.6,
    transition: "opacity 0.2s",
    "&:hover": {
      opacity: 1,
    },
  },
  icon: {
    color: theme.palette.primary.contrastText,
  },
  nested: {
    opacity: 0.6,
    transition: "opacity 0.2s",
    "&:hover": {
      opacity: 1,
    },
    paddingLeft: theme.spacing(5),
  },
  typography: {
    fontSize: 12,
  },
}));

function SideNavItem({
  testId,
  icon = "null",
  text,
  link,
  onClick,
  className,
  bold,
  submenu,
  subMenuStyle,
  ...rest
}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyle();
  const Icon = icon;

  const handleClick = () => {
    setOpen(!open);
  };

  if (bold) {
    text = <Box fontWeight="fontWeightBold">{text}</Box>;
  }

  const children = (
    <>
      <ListItemIcon
        data-test-id={`side-nav-icon-${testId}`}
        className={classes.icon}
      >
        <Icon />
      </ListItemIcon>
      <ListItemText data-test-id={`side-nav-text-${testId}`} primary={text} />
    </>
  );

  if (link) {
    const style = subMenuStyle ? classes.nested : classes.root;
    return (
      <ListItem
        {...rest}
        className={classNames(className, style)}
        button
        to={link}
        component={NavLink}
        activeClassName="Mui-selected"
        activeStyle={{ opacity: 1 }}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {children}
      </ListItem>
    );
  }

  if (onClick) {
    return (
      <ListItem
        {...rest}
        className={classNames(className, classes.root)}
        button
        onClick={onClick}
      >
        {children}
      </ListItem>
    );
  }

  if (submenu && submenu.length > 0) {
    return (
      <Fragment>
        <ListItem
          {...rest}
          className={classNames(className, classes.root)}
          button
          onClick={handleClick}
        >
          {children}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {submenu}
          </List>
        </Collapse>
      </Fragment>
    );
  }

  return (
    <ListItem {...rest} className={classNames(className, classes.root)}>
      {children}
    </ListItem>
  );
}

export default memo(SideNavItem);
