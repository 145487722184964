import React, { memo } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, FormHelperText } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: ({ label, error }) => ({
    "& .form-control": {
      width: "100%",
      color: error ? theme.palette.error.main : theme.palette.text.primary,
      borderColor: error ? theme.palette.error.main : "rgba(0, 0, 0, 0.2)",
      "& + div:before": {
        content: `"${label}"`,
        color: error ? theme.palette.error.main : theme.palette.text.secondary,
        cursor: "text",
        fontSize: 12,
      },
      "&:focus": {
        borderColor: error
          ? theme.palette.error.main
          : theme.palette.primary.main,
        boxShadow: `0 0 0 1px ${
          error ? theme.palette.error.main : theme.palette.primary.main
        }`,
        "& + div:before": {
          color: error ? theme.palette.error.main : theme.palette.primary.main,
        },
      },
    },
  }),
  fullWidth: {
    width: "100%",
  },
}));

function CustomPhoneInput({
  fullWidth,
  label,
  helperText,
  error,
  readOnly,
  inputProps,
  disableDropdown,
  size = "medium",
  backgroundColor = "white",
  ...rest
}) {
  const classes = useStyles({ label, error });

  return (
    <FormControl
      error={error}
      data-test-id={"master-template-sms-number-form-control-" + label}
      className={classNames({ [classes.fullWidth]: fullWidth })}
    >
      <PhoneInput
        {...rest}
        containerClass={classes.root}
        inputProps={{
          ...inputProps,
          readOnly,
          style: {
            height: size === "small" ? 40 : null,
            backgroundColor: backgroundColor,
          },
        }}
        disableDropdown={readOnly || disableDropdown}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default memo(CustomPhoneInput);
