import React, { useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Notification,
  LinearProgress,
  Fade,
  VersioningTag,
} from "./components";
import { PublicPages, PrivatePages, ApproversPage } from "./core";
import { url } from "./utils";
import { setParams } from "./actions/params";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";
import { CampaignApproverPage } from "./pages";

const TRACKING_ID = "UA-64682204-4";
const hjid = Number(process.env.REACT_APP_HOTJAR_ID);
const hjsv = Number(process.env.REACT_APP_HOTJAR_SNIPPET_VERSION);
hotjar.initialize(hjid, hjsv);
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

const useStyles = makeStyles((theme) => ({
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 1000,
  },
}));

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const user = useSelector((state) => state.user);
  const eonsConfig = useSelector((state) => state.eonsConfig);
  const isLoading = useSelector((state) => state.loading);
  const notification = useSelector((state) => state.notification);

  // change the store params to reflect the new react router params
  //  whenever react router changes the query params
  useEffect(() => {
    history.listen((location) =>
      dispatch(
        setParams(url.getParams(location.search), {
          overwrite: true,
          setUrl: false,
        })
      )
    );
  }, [history, dispatch]);

  return (
    <>
      <Fade in={isLoading}>
        <LinearProgress className={classes.loader} />
      </Fade>

      {token ? (
        <CampaignApproverPage />
      ) : user && eonsConfig ? (
        <PrivatePages />
      ) : (
        <PublicPages />
      )}
      {notification && (
        <Notification key={notification.message} notification={notification} />
      )}
      <VersioningTag />
    </>
  );
}

export default memo(App);
