import React, { memo } from "react";
import { Paper, Box } from "@material-ui/core";
import { DateTimePicker } from "../../components";
import { useTranslation } from "react-i18next";
import Search from "../../components/Search";
import moment from "moment";

function SearchBar({ datePicker, search }) {
  const { toDate, fromDate, onAccept } = datePicker;
  const { campaignSearch, onSearch } = search;
  const { t } = useTranslation();

  const defaultFromDateOneYearFromNow =
    fromDate || moment().subtract(1, "year").toDate().getTime();
  onAccept.createdAfter(new Date(defaultFromDateOneYearFromNow));

  return (
    <Paper>
      <Box mb={2}>
        <DateTimePicker
          label={t("Created after")}
          maxDate={toDate}
          defaultValue={defaultFromDateOneYearFromNow}
          date
          time
          onAccept={onAccept.createdAfter}
          disableFuture
          fullWidth
        />
      </Box>
      <Box mb={2}>
        <DateTimePicker
          label={t("Created before")}
          minDate={defaultFromDateOneYearFromNow}
          defaultValue={toDate}
          date
          time
          onAccept={onAccept.createBefore}
          disableFuture
          fullWidth
        />
      </Box>
      <Search
        onSearch={onSearch}
        variant="outlined"
        defaultValue={campaignSearch}
        label={t("Search campaigns...")}
        fullWidth
      />
    </Paper>
  );
}

export default memo(SearchBar);
