import {
  LIST_RECIPIENT_LISTS,
  CLEAR_RECIPIENT_LISTS,
  LIST_RECIPIENT_DIPPING,
} from "../actions/recipientLists";

export default function recipientListsReducer(
  recipientLists = null,
  { type, recipientLists: newRecipientLists, dippingList }
) {
  switch (type) {
    case LIST_RECIPIENT_LISTS:
      return newRecipientLists;
    case CLEAR_RECIPIENT_LISTS:
      return null;
    case LIST_RECIPIENT_DIPPING:
      if (recipientLists && dippingList) {
        // Need refactor for performance cost, bulk instead of individual
        return {
          ...recipientLists,
          dipStatus: {
            ...recipientLists.dipStatus,
            [dippingList.recipientListId]: dippingList,
          },
        };
      }
      return recipientLists;
    default:
      return recipientLists;
  }
}
