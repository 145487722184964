import * as api from "../api/recipientLists";

export const LIST_RECIPIENT_LISTS = "LIST_RECIPIENT_LISTS";
export const LIST_RECIPIENT_DIPPING = "LIST_RECIPIENT_DIPPING";
export const CLEAR_RECIPIENT_LISTS = "CLEAR_RECIPIENT_LISTS";

export function clearRecipientLists() {
  return { type: CLEAR_RECIPIENT_LISTS };
}

export function listRecipientLists(params) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { paid } = storage;
    const recipientLists = await api.listRecipientLists({ ...params, paid });
    dispatch({ type: LIST_RECIPIENT_LISTS, recipientLists });
  };
}

// THIS SHOULD BE factored into a non-individual request for each list
export function getDippingStatus(id, params = {}) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { paid, activeDivisionId } = storage;
    const dippingList = await api.getDippingStatus(id, {
      ...params,
      paid,
      activeDivisionId,
    });
    dispatch({
      type: LIST_RECIPIENT_DIPPING,
      dippingList: { ...dippingList, recipientListId: id },
    });
  };
}
