import i18n from "i18next";
import { omit } from "lodash";
import { batch } from "react-redux";
import unkinkPolygon from "@turf/unkink-polygon";
import shapeToGeoJson from "../../pages/Map/shapeToGeoJson";
import * as api from "../../api/recipientLists";
import { notify } from "../../actions/notification";
import {
  buildNewRecipientList,
  clearNewRecipientList,
  getMasterListHeadersAction,
} from "./index";

export function getMasterListHeaders() {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { activeDivisionId } = storage;

    const masterLists = await api.listMasterLists({ activeDivisionId });
    const { _id = null, recipientHeaders = {} } = masterLists.data[0]
      ? masterLists.data[0]
      : {};

    dispatch(
      buildNewRecipientList({
        // these are required by the wizard and also the API for filtered list creation
        recipientHeaders,
        masterListId: _id,
        // this will contain the api fetched and user selected values for specific columns
        recipientFilters: {},
      })
    );
  };
}
export function getMasterListRecipientHeaders() {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { activeDivisionId } = storage;

    const masterLists = await api.listMasterLists({ activeDivisionId });
    const { _id = null, recipientHeaders = {} } = masterLists.data[0]
      ? masterLists.data[0]
      : {};

    dispatch(
      getMasterListHeadersAction({ recipientHeaders, recipientListId: _id })
    );
  };
}

export function toggleFilterColumn(header, { allowEmpty = true } = {}) {
  return (dispatch, getState) => {
    const { recipientFilters, errors } = getState().newRecipientList;

    if (recipientFilters[header]) {
      // redirect the user to the first step and display an error message
      // if this was the last turned on header and allowEmpty is false
      if (!allowEmpty && Object.keys(recipientFilters).length <= 1) {
        dispatch(
          buildNewRecipientList({
            doneStepIdx: -1,
            recipientFilters: {},
            errors: {},
          })
        );
        dispatch(
          notify({
            type: "error",
            message: i18n.t(
              "You must select at least one column to filter on."
            ),
          })
        );
      } else {
        dispatch(
          buildNewRecipientList({
            recipientFilters: omit(recipientFilters, header),
            errors: omit(errors, header),
          })
        );
      }
    } else {
      dispatch(
        buildNewRecipientList({
          recipientFilters: { ...recipientFilters, [header]: {} },
          // only one header is required, so adding any header should remove the error message
          errors: omit(errors, "message", header),
        })
      );
    }
  };
}

export function validateFilterColumns() {
  return (dispatch, getState) => {
    const { recipientFilters } = getState().newRecipientList;

    if (!Object.keys(recipientFilters).length) {
      dispatch(
        buildNewRecipientList({
          doneStepIdx: -1,
          errors: {
            message: i18n.t(
              "You must select at least one column to filter on."
            ),
          },
        })
      );
      return false;
    }

    dispatch(
      buildNewRecipientList({
        doneStepIdx: 0,
        errors: {},
      })
    );
    return true;
  };
}

export function getDistinctMasterListColumnValues({ header, search }) {
  return async (dispatch, getState) => {
    const { masterListId } = getState().newRecipientList;

    const { data: values } = await api.listMasterRecipients({
      recipientListId: masterListId,
      distinct: true,
      searchBy: header,
      search,
      page: 0,
      itemsPerPage: 50,
    });

    // this has to be retrieved here again because of the async for part of the function
    const { recipientFilters } = getState().newRecipientList;
    dispatch(
      buildNewRecipientList({
        recipientFilters: {
          ...recipientFilters,
          [header]: {
            values,
            selectedValues: recipientFilters[header].selectedValues ?? [],
          },
        },
      })
    );
  };
}

export function getMasterListHeaderFields({ search, header }) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { paid } = storage;

    if (process.env.USE_DEPRECIATED_MASTER_LIST_HEADER) {
      return dispatch(getDistinctMasterListColumnValues({ header, search }));
    }

    const { masterListId, recipientFilters } = getState().newRecipientList;

    let newRecipientFilters = recipientFilters;

    if (search) {
      const item = newRecipientFilters[header];
      const filteredValues = item?.allValues?.filter((item) =>
        item.toLowerCase().includes(search.toLowerCase())
      );
      newRecipientFilters[header].values = filteredValues;
    } else {
      const values = await api.listMasterListHeaderFields({
        masterListId,
        header,
        paid
      });

      // this has to be retrieved here again because of the async for part of the function
      const { recipientFilters } = getState().newRecipientList;

      newRecipientFilters = {
        ...recipientFilters,
        [header]: {
          values,
          selectedValues: newRecipientFilters[header].selectedValues ?? [],
          allValues: values,
        },
      };
    }

    dispatch(
      buildNewRecipientList({
        recipientFilters: newRecipientFilters,
      })
    );
  };
}

export function toggleFilterValue(header, value) {
  return (dispatch, getState) => {
    const { recipientFilters, errors } = getState().newRecipientList;
    const { selectedValues } = recipientFilters[header];

    const valueIdx = selectedValues.indexOf(value);
    let newSelectedValues;

    if (valueIdx === -1) {
      newSelectedValues = [...selectedValues, value];
    } else {
      newSelectedValues = selectedValues.filter((v) => v !== value);
    }

    dispatch(
      buildNewRecipientList({
        errors: omit(errors, header),
        recipientFilters: {
          ...recipientFilters,
          [header]: {
            ...recipientFilters[header],
            selectedValues: newSelectedValues,
          },
        },
      })
    );
  };
}

export function validateFilterValues() {
  return (dispatch, getState) => {
    const { recipientFilters } = getState().newRecipientList;

    const errors = {};
    for (const header in recipientFilters) {
      const { selectedValues } = recipientFilters[header];
      if (!selectedValues.length) {
        errors[header] = i18n.t(
          "Select at least one value or remove this column.",
          {
            header,
          }
        );
      }
    }

    if (Object.keys(errors).length) {
      dispatch(buildNewRecipientList({ errors, doneStepIdx: 0 }));
      return false;
    }

    dispatch(buildNewRecipientList({ errors: {}, doneStepIdx: 1 }));
    return true;
  };
}

export function validateMapPolygon() {
  return (dispatch, getState) => {
    const { shapes = [], enabledLayers = {} } = getState().map;
    const enabledLayerKeys = Object.keys(enabledLayers);

    if (!shapes.length && !enabledLayerKeys.length) {
      dispatch(
        buildNewRecipientList({
          errors: { message: i18n.t("You must select or draw some polygons") },
          doneStepIdx: -1,
        })
      );
      return false;
    }

    const drawnFeatures = shapes.map((shape) => shapeToGeoJson(shape));
    const layerFeatures = enabledLayerKeys.reduce((features, layerKey) => {
      const { layer } = enabledLayers[layerKey];
      return [...features, ...(layer?.data?.features ?? [])];
    }, []);

    // mongodb does not understand self intersecting polygons
    // this function splits these polygons into 'normal' ones
    // https://turfjs.org/docs/#unkinkPolygon
    const withinCollection = unkinkPolygon({
      type: "FeatureCollection",
      features: [...drawnFeatures, ...layerFeatures],
    });

    const withinPolygon = {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          ...withinCollection.features.map(
            (feature) => feature.geometry.coordinates
          ),
        ],
      },
    };

    dispatch(
      buildNewRecipientList({ errors: {}, withinPolygon, doneStepIdx: 0 })
    );
    return true;
  };
}

export function filterMasterRecipients() {
  return async (dispatch, getState) => {
    const { newRecipientList } = getState();
    const { _id, masterListId, recipientFilters, name } = newRecipientList;

    // transform the filters into a { prop: [value1, value2, ...] } format
    const filters = Object.keys(recipientFilters).reduce(
      (filters, prop) => ({
        ...filters,
        [prop]: recipientFilters[prop].selectedValues,
      }),
      {}
    );

    await api.filterMasterRecipients({
      recipientListId: _id,
      masterListId,
      filters,
    });

    dispatch(
      notify({
        type: "success",
        message: i18n.t("Successfully uploaded {{name}}!", { name }),
      })
    );
  };
}

export function geoFilterMasterRecipients() {
  return async (dispatch, getState) => {
    const { newRecipientList } = getState();
    const { _id, masterListId, withinPolygon, name } = newRecipientList;

    await api.filterMasterRecipients({
      recipientListId: _id,
      masterListId,
      withinPolygon,
    });

    batch(() => {
      dispatch(
        notify({
          type: "success",
          message: i18n.t("Successfully uploaded {{name}}!", { name }),
        })
      );
    });
  };
}
