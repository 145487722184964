import React, { useCallback, memo } from "react";
import { Checkbox, IconButton, Tooltip } from "../../../../components";
import { Visibility } from "../../../../icons";
import { useTranslation } from "react-i18next";

function SelectCampaignMasterTemplateActions({
  masterTemplate,
  selectedTemplateId,
  onSelectTemplate,
  onOpenViewTemplate,
}) {
  const { t } = useTranslation();
  const isTemplateSelected = masterTemplate._id === selectedTemplateId;

  const onChange = useCallback(
    () => onSelectTemplate(isTemplateSelected ? null : masterTemplate),
    [onSelectTemplate, masterTemplate, isTemplateSelected]
  );

  const onViewTemplate = useCallback(
    () => onOpenViewTemplate(masterTemplate._id),
    [onOpenViewTemplate, masterTemplate]
  );

  return (
    <>
      <Tooltip title={t("View template")}>
        <IconButton
          onClick={onViewTemplate}
          size="small"
          data-test-id="campaign-template-view-button"
        >
          <Visibility fontSize="small" />
        </IconButton>
      </Tooltip>
      <Checkbox
        data-test-id="campaign-template-checkbox"
        color="primary"
        checked={isTemplateSelected}
        onChange={onChange}
      />
    </>
  );
}

export default memo(SelectCampaignMasterTemplateActions);
