import React, { useMemo, useCallback, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Grid,
  TextField,
  MenuItem,
  PhoneInput,
  Button,
  Box,
  Typography,
} from "../../../../components";
import { notify } from "../../../../actions/notification";
import { sendTestMessage } from "../../../../actions/masterTemplate";
import { format } from "date-fns";

function InboundFields({
  inboundTemplate = {},
  errors = {},
  readOnly,
  onInboundFieldChange,
  onVoiceRetryChange,
  eonsConfig,
  isEditingView,
  voiceActionStep,
  adhoc,
  voiceActionItems,
  callerIdDetail = [],
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    ttsVoiceType = "",
    callerId = "",
    // retries = "", // deprecated
    // repeatKey = "", // deprecated
    // liveAgentTransferKey = "", // deprecated
    // liveAgentTransferNumber = "", // deprecated
    optOutKey = "",
  } = inboundTemplate;

  const { retries } = inboundTemplate.freeswitch;

  const { voiceTalents: ttsVoiceTypes = [] } = eonsConfig;

  let { callerId: eonsCallerId = [] } = eonsConfig;
  let callerIdData = [];
  if (eonsCallerId) {
    const sortedCallerId = callerIdDetail.sort(function (a, b) {
      return new Date(b.lastUsed) - new Date(a.lastUsed);
    });
    eonsCallerId.forEach((caller) => {
      let index = callerIdDetail.findIndex((c) => c.callerId == caller);
      if (index == -1) {
        callerIdData.push({ value: caller.toString(), lastUsed: "Not Used" });
      }
    });
    sortedCallerId.forEach((scaller) => {
      callerIdData.push({
        value: scaller.callerId.toString(),
        lastUsed: format(new Date(scaller.lastUsed), "MM/dd/yyyy HH:mm"),
      });
    });
  }
  const readOnlyProps = useMemo(() => ({ readOnly }), [readOnly]);
  const [testVoice, setTestVoice] = useState("");
  const onTestVoiceChange = useCallback(
    (testVoice) => setTestVoice(testVoice),
    [setTestVoice]
  );

  const onSendTestVoice = useCallback(async () => {
    try {
      await dispatch(
        sendTestMessage({
          channelType: "inbound",
          data: {
            to: testVoice,
            // from: inboundTemplate.callerId,
            testMessage: inboundTemplate.freeswitch.dialPlan.person.steps,
            templateId: inboundTemplate._id,
          },
        })
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log({ err });
      dispatch(
        notify({
          type: "error",
          message: t("You must provide a valid test phone number."),
        })
      );
      throw err;
    }
  }, [dispatch, testVoice, inboundTemplate, t]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={6} md={6}>
        <TextField
          disabled={readOnly}
          data-test-id="master-template-inbound-voice-talent-field"
          select
          InputLabelProps={{ shrink: Boolean(ttsVoiceType) }}
          label={t("Voice Talent")}
          variant="outlined"
          size="small"
          fullWidth
          name="ttsVoiceType"
          value={ttsVoiceType}
          onChange={onInboundFieldChange}
          error={Boolean(errors["inboundTemplate.ttsVoiceType"])}
          helperText={errors["inboundTemplate.ttsVoiceType"]}
          InputProps={readOnlyProps}
        >
          {ttsVoiceTypes.map((voiceType) => (
            <MenuItem key={voiceType} value={voiceType}>
              {voiceType}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        {voiceActionStep}
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        {voiceActionItems}
      </Grid>
      {!adhoc ? (
        <Grid item xs={12} sm={12} md={12}>
          <Box mt={2}>
            <Typography>
              {t(
                // eslint-disable-next-line max-len
                "You can only test voice calls that were saved as a template in advance. To test the latest inbound template please save it first."
              )}
            </Typography>
          </Box>
          <Box mt={1}>
            <PhoneInput
              data-test-id="master-template-test-voice-number-field"
              label={t("Test Live Voice Sending")}
              country={"us"}
              value={testVoice}
              onChange={onTestVoiceChange}
              variant="outlined"
              size="small"
              disabled={!isEditingView}
              fullWidth
            />
          </Box>
          <Box mt={1}>
            <Button
              data-test-id="master-template-send-test-voice-button"
              variant="contained"
              color="primary"
              fullWidth
              onClick={onSendTestVoice}
              disabled={!isEditingView}
            >
              {t("Send Test")}
            </Button>
          </Box>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
}

export default memo(InboundFields);
