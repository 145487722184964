import React, { useState, useCallback, useMemo, useRef, memo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Page, DeleteDialog, useMediaQuery } from "../../../components";
import {
  deleteMasterTemplate,
  cloneMasterTemplate,
} from "../../../actions/masterTemplate";
import StatusIndicator from "../StatusIndicator";
import MasterTemplateListBase from "./Base";
import Actions from "./Actions";
import useAuthorized, {
  TEMPLATE_CREATE_PERMISSION,
  TEMPLATE_DELETE_PERMISSION,
  TEMPLATE_GET_PERMISSION,
  TEMPLATE_LIST_PERMISSION,
  TEMPLATE_UPDATE_PERMISSION,
} from "../../../hooks/useAuthorized";

const getExtraColumns = ({ t, onOpenDeleteDialog, onClone }) => [
  {
    name: "status",
    getter: (masterTemplate) => (
      <StatusIndicator
        testId={masterTemplate._id}
        status={masterTemplate.status}
      />
    ),
    label: t("Status"),
    sortable: true,
    width: 0.7,
    testId: "template-status",
    align: "center",
  },
  {
    name: "actions",
    getter: (masterTemplate) => (
      <Actions
        masterTemplate={masterTemplate}
        onDelete={onOpenDeleteDialog}
        onClone={onClone}
      />
    ),
    label: t("Actions"),
    sortable: false,
    align: "center",
  },
];

function MasterTemplatesListPage() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const templatesRef = useRef();

  const [masterTemplateToDelete, setMasterTemplateToDelete] = useState();

  const onOpenDeleteDialog = useCallback(
    (masterTemplate) => setMasterTemplateToDelete(masterTemplate),
    [setMasterTemplateToDelete]
  );

  const onClone = useCallback(
    async (masterTemplate) => {
      const cloneId = await dispatch(cloneMasterTemplate(masterTemplate));
      history.push(`/master-templates/${cloneId}`);
    },
    [dispatch, history]
  );

  const onCloseDeleteDialog = useCallback(
    () => setMasterTemplateToDelete(null),
    [setMasterTemplateToDelete]
  );

  const onDelete = useCallback(async () => {
    await dispatch(deleteMasterTemplate(masterTemplateToDelete));
    await templatesRef.current.listMasterTemplates();
    onCloseDeleteDialog();
  }, [dispatch, onCloseDeleteDialog, masterTemplateToDelete, templatesRef]);

  const extraColumns = useMemo(
    () => getExtraColumns({ t, onOpenDeleteDialog, onClone }),
    [t, onOpenDeleteDialog, onClone]
  );

  const canCreate = useAuthorized(TEMPLATE_CREATE_PERMISSION);
  const canView = useAuthorized(TEMPLATE_GET_PERMISSION);
  const canEdit = useAuthorized(TEMPLATE_UPDATE_PERMISSION);
  const canDelete = useAuthorized(TEMPLATE_DELETE_PERMISSION);
  const canList = useAuthorized(TEMPLATE_LIST_PERMISSION);

  const canOnlyCreate =
    canCreate && !canView && !canEdit && !canDelete && !canList;

  return (
    <Page p={2} screenSized={!isSmallScreen}>
      <MasterTemplateListBase
        extraColumns={extraColumns}
        ref={templatesRef}
        disableTable={canOnlyCreate}
      />
      <DeleteDialog
        open={Boolean(masterTemplateToDelete)}
        onClose={onCloseDeleteDialog}
        onDelete={onDelete}
        name={masterTemplateToDelete?.name}
        resource={t("Template")}
      />
    </Page>
  );
}

export default memo(MasterTemplatesListPage);
