import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "../../components";
import * as icons from "../../icons";

const useStyles = makeStyles((theme) => ({
  popover: {
    padding: theme.spacing(1),
    position: "absolute",
    top: -theme.spacing(4),
    left: 0,
    transform: "translate(-50%, -100%)",
    zIndex: 100,
  },
  icon: ({ color }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    transform: "translate(-50%, -100%)",
    color,
  }),
  label: {
    maxWidth: 180,
    width: "max-content",
    textAlign: "center",
  },
}));

function MapMarker({ $hover, color, icon = "PersonPinCircle", label }) {
  const classes = useStyles({ color });
  const Icon = icons[icon];

  return (
    <>
      <Icon color="primary" className={classes.icon} />
      {$hover && label && (
        <Paper className={classes.popover}>
          <Typography variant="body2" className={classes.label}>
            {label}
          </Typography>
        </Paper>
      )}
    </>
  );
}

export default memo(MapMarker);
