import * as approvalApi from "../api/approvalData";
import { notify } from "./notification";
import i18n from "i18next";
import * as _ from "lodash";
import formatEscape from "../utils/formatEscape";
import { batch } from "react-redux";
import { getCampaignDetail } from "./pendingApprovalCampaigns";

export const SET_APPROVAL_DATA = "SET_APPROVAL_DATA";
export const APPROVE_CAMPAIGN = "APPROVE_CAMPAIGN";
export const REJECT_CAMPAIGN = "REJECT_CAMPAIGN";

export function approveCampaign() {
  return async (dispatch, getState) => {
    const { params, approvalData } = getState();
    const { token } = params;
    const { _id: campaignId } = approvalData;

    const { message } = await approvalApi.approveOrRejectCampaign(
      campaignId,
      token,
      true
    );
    batch(() => {
      dispatch({ type: APPROVE_CAMPAIGN });
      // Show default message unless there is a message from backend
      dispatch(
        notify({
          type: "success",
          message: message
            ? i18n.t(message)
            : i18n.t("Successfully approved campaign"),
        })
      );
    });
  };
}

export function rejectCampaign(comment) {
  return async (dispatch, getState) => {
    const { params, approvalData } = getState();
    const { token } = params;
    const { _id: campaignId } = approvalData;
    const { message } = await approvalApi.approveOrRejectCampaign(
      campaignId,
      token,
      false,
      comment
    );
    batch(() => {
      dispatch(getCampaignDetail(campaignId));
      dispatch({ type: REJECT_CAMPAIGN });
      // Show default message unless there is a message from backend
      dispatch(
        notify({
          type: "success",
          message: message
            ? i18n.t(message)
            : i18n.t("Successfully rejected campaign"),
        })
      );
    });
  };
}

export function sendApprovalTest(
  addresses,
  { emailTemplate, smsTemplate, voiceTemplate } = {}
) {
  return async (dispatch, getState) => {
    const { params } = getState();
    const { token } = params;

    const validatedAddresses = await Promise.all(
      addresses.map(async ({ type, value }) => {
        const testMessage =
          type === "email"
            ? {
                from: emailTemplate.fromEmail,
                to: value,
                testMessage: formatEscape(emailTemplate.template),
                subject: emailTemplate.subjectEmail,
              }
            : type === "sms"
            ? {
                from: String(smsTemplate.shortCode),
                to: value,
                testMessage: smsTemplate.template,
              }
            : type === "voice" || type === "tty"
            ? {
                to: value,
                from: voiceTemplate.callerId,
                templateId: voiceTemplate._id,
                testMessage:
                  type === "voice"
                    ? ["Test Voice was sent out"]
                    : ["Test TTY was sent out"],
              }
            : {};

        try {
          await approvalApi.sendTestMessage(
            { channelType: type, data: testMessage },
            token
          );
          return { type, value };
        } catch (err) {
          return { type, value, error: true };
        }
      })
    );

    if (validatedAddresses.every((address) => address.error)) {
      dispatch(
        notify({
          type: "error",
          message: i18n.t("Failed to send test messages"),
        })
      );
    } else if (validatedAddresses.some((address) => address.error)) {
      dispatch(
        notify({
          type: "warning",
          message: i18n.t("Failed to send some test messages"),
        })
      );
    } else {
      dispatch(
        notify({
          type: "success",
          message: i18n.t("Successfully sent all test messages"),
        })
      );
    }

    return validatedAddresses;
  };
}
