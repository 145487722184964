import React, { useCallback, useEffect, memo } from "react";
import { useHistory } from "react-router-dom";
import { omit } from "lodash";
import { useDispatch, useSelector, batch } from "react-redux";
import BaseApproverPage from "./Base";
import {
  setApprover,
  clearApprover,
  saveApprover,
} from "../../../actions/approver";

function CreateApproverPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const approver = useSelector((state) => state.approver);
  const { errors = {} } = approver || {};

  const { advancedApproverManagement = false } = useSelector(
    (state) => state.eonsConfig.vendorConfig
  );

  const historyPush = advancedApproverManagement ? "/admin" : "/approvers";

  useEffect(() => {
    batch(() => {
      dispatch(setApprover({ name: "", email: "", sms: "" }));
    });
    return () => dispatch(clearApprover());
  }, [dispatch]);

  const onFieldChange = useCallback(
    (ev) =>
      dispatch(
        setApprover({
          [ev.target.name]: ev.target.value,
          errors: omit(errors, ev.target.name),
          modified: true,
        })
      ),
    [dispatch, errors]
  );

  const onSave = useCallback(async () => {
    await dispatch(saveApprover());
    history.push(historyPush);
  }, [dispatch, history]);

  return (
    <BaseApproverPage
      approver={approver}
      onFieldChange={onFieldChange}
      onSave={onSave}
    />
  );
}

export default memo(CreateApproverPage);
