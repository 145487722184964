import React, { useCallback, memo, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getMasterListHeaderFields,
  toggleFilterColumn,
  toggleFilterValue,
} from "../../../../../actions/newRecipientList";
import { Box, Grid } from "../../../../../components";
import ValuePicker from "./ValuePicker";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
  },
}));

function MasterListValuesFilterPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { recipientFilters, errors } = useSelector(
    (state) => state.newRecipientList
  );

  const onSearch = useCallback(
    (header, search) => dispatch(getMasterListHeaderFields({ header, search })),
    [dispatch]
  );

  const onHeaderToggle = useCallback(
    (header) => dispatch(toggleFilterColumn(header, { allowEmpty: false })),
    [dispatch]
  );

  const onValueToggle = useCallback(
    (header, value) => dispatch(toggleFilterValue(header, value)),
    [dispatch]
  );

  return (
    <Box p={2} className={classes.root}>
      <Grid container spacing={2}>
        {Object.keys(recipientFilters)
          .sort()
          .map((header) => (
            <Grid item key={header} xs={12} sm={12} md={6} lg={4} xl={4}>
              <ValuePicker
                header={header}
                // options={recipientFilters[header]}
                error={errors[header]}
                onSearch={onSearch}
                onToggle={onHeaderToggle}
                onValueToggle={onValueToggle}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

export default memo(MasterListValuesFilterPage);
