import Papa from "papaparse";
import { batch } from "react-redux";
import i18n from "i18next";
import { retry } from "../../utils";
import * as api from "../../api/recipientLists";
import { notify } from "../notification";
import { deleteRecipientList } from "../recipientList";
import { clearNewRecipientList, buildNewRecipientList } from "./index";

export const MAP_RECIPIENT_HEADER = "MAP_NEW_RECIPIENT_HEADER";

export function parseRecipientsPreview({ file, errorMessage }) {
  return (dispatch) => {
    // use the first 6 recipients od the file as a preview for the mapping and the save screen
    const previewSize = 6;

    dispatch(
      buildNewRecipientList({
        errors: {},
        loading: true,
        file,
      })
    );

    Papa.parse(file, {
      header: true,
      preview: previewSize,
      error() {
        dispatch(
          buildNewRecipientList({
            errors: {
              file: errorMessage,
            },
            loading: false,
            file: null,
          })
        );
      },
      complete({ meta, data }) {
        dispatch(
          buildNewRecipientList({
            headers: meta.fields,
            preview: data,
            loading: false,
          })
        );
      },
    });
  };
}

export function validateRecipientsFile() {
  return (dispatch, getState) => {
    const { newRecipientList } = getState();
    const { doneStepIdx } = newRecipientList;

    if (!newRecipientList.file) {
      dispatch(
        buildNewRecipientList({
          errors: {
            file: i18n.t("You must upload a valid csv or txt file"),
          },
          doneStepIdx: -1,
        })
      );
      return false;
    } else {
      dispatch(
        buildNewRecipientList({
          errors: {},
          doneStepIdx: Math.max(doneStepIdx, 0),
        })
      );
      return true;
    }
  };
}

export function validateRecipientsHeaderMapping() {
  return (dispatch, getState) => {
    const { newRecipientList, eonsConfig } = getState();
    const {
      headerMapping = {},
      headers = [],
      preview,
      doneStepIdx,
    } = newRecipientList;
    const eonsHeaders = eonsConfig.vendorConfig.recipientHeaders;

    const errors = {};
    const reverseMapping = {};

    headers.forEach((originalHeader) => {
      const mappedHeader = headerMapping[originalHeader];
      if (!mappedHeader && mappedHeader !== false) {
        errors[originalHeader] = i18n.t(
          "must be mapped to a header or excluded",
          {
            headerName: originalHeader,
          }
        );
      } else if (reverseMapping[mappedHeader]) {
        errors[originalHeader] = i18n.t(
          "{{mappedHeader}} is already mapped to {{originalHeader}}",
          {
            mappedHeader: mappedHeader,
            originalHeader: reverseMapping[mappedHeader],
          }
        );
      } else if (mappedHeader) {
        reverseMapping[mappedHeader] = originalHeader;
      }
    });

    for (const eonsHeader in eonsHeaders) {
      const config = eonsHeaders[eonsHeader];
      if (config.required && !reverseMapping[eonsHeader]) {
        errors[eonsHeader] = i18n.t("must be mapped to something", {
          eonsHeader,
        });
      }
    }

    if (Object.keys(errors).length) {
      dispatch(
        buildNewRecipientList({
          errors,
          doneStepIdx: 0,
        })
      );
      return false;
    } else {
      const recipientHeaders = Object.keys(reverseMapping).reduce(
        (headers, header) => ({ ...headers, [header]: eonsHeaders[header] }),
        {}
      );

      const recipients = {
        totalCount: preview.length,
        data: preview.map((recipient) => ({
          fields: Object.keys(recipient).reduce(
            (mappedRecipient, key) => ({
              ...mappedRecipient,
              [headerMapping[key]]: recipient[key],
            }),
            {}
          ),
        })),
      };

      dispatch(
        buildNewRecipientList({
          errors: {},
          recipientHeaders,
          recipients,
          doneStepIdx: Math.max(doneStepIdx, 1),
        })
      );
      return true;
    }
  };
}

export function mapRecipientHeader(originalHeader, mappedHeader) {
  return {
    type: MAP_RECIPIENT_HEADER,
    originalHeader,
    mappedHeader,
  };
}

export function uploadNewRecipientChunks(onComplete, redirect) {
  return async (dispatch, getState) => {
    const { newRecipientList, storage } = getState();
    const { _id, name, file, headerMapping } = newRecipientList;
    const { activeDivisionId, paid } = storage;
    // try to upload the file in 100 chunks with a minimum 10kb and maximum 1 mb chunk size
    // dynamic chunk size is useful to give a nice live feedback for both large and small files
    // as the progress feeback unit is a single chunk (which should ideally match with 1% progress)
    const chunkSize = Math.max(
      10 * 1000,
      Math.min(file.size / 100, 1 * 1000 * 1000)
    );
    const numOfChunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;

    // set the progress to 0 before starting the upload
    // not setting it here would delay feedback until the first chunk is uploaded and potentially make the user panic
    dispatch(buildNewRecipientList({ progress: 0 }));

    Papa.parse(file, {
      header: true,
      chunkSize,
      skipEmptyLines: true, // https://github.com/mholt/PapaParse/issues/447
      async chunk({ data }, parser) {
        // apply the header mapping to each recipient
        const recipients = data.map((recipient) => {
          const mappedRecipient = {};
          for (const header in recipient) {
            const value = recipient[header];
            const mappedHeader = headerMapping[header];
            if (mappedHeader) {
              mappedRecipient[mappedHeader] = value;
            }
          }
          return mappedRecipient;
        });

        // the chunks are processed in a sequential async flow
        // the parser pauses the file parsing until the current chunk is fully processed and uploaded to the server
        // this may take a bit longer bit it is more stable and less power consuming
        parser.pause();

        // TODO: add fallback logic if all retries fail
        // retry the chunk upload 3 times with a 10 sec interval before accepting a network failure
        const counts = await retry(
          () =>
            api.addRecipientsChunk(
              {
                recipientListId: _id,
                recipients,
              },
              { paid }
            ),
          { count: 3, interval: 20 * 1000 }
        );

        // abort the parsing if the current newRecipientList id does not match the original id from before the parsing
        // this can happen if the user deleted the list in the meantime or overwritten the list with a new one
        // do this after the last pending retry chunk returned
        // doing it before that could cause the last pending chunk
        // to update the deleted or new list in the above section
        const { newRecipientList } = getState();
        if (newRecipientList?._id !== _id) {
          parser.abort();
          return;
        }

        // get the counts from the latest version of the recipient list
        // which is freshly fetched from the store at the top of this chunk's handler
        const {
          totalCount,
          failedCount,
          smsCount,
          voiceCount,
          ttyCount,
          emailCount,
          customCount,
        } = newRecipientList;

        dispatch(
          buildNewRecipientList({
            progress: (currentChunk / numOfChunks) * 100,
            totalCount: totalCount + counts.insertedCount,
            failedCount: failedCount + counts.failedCount,
            smsCount: smsCount + counts.smsCount,
            emailCount: emailCount + counts.emailCount,
            customCount: customCount + counts.customCount,
            voiceCount: voiceCount + counts.voiceCount,
            ttyCount: ttyCount + counts.ttyCount,
          })
        );

        // resume the parsing and process the next chunk when the current one finished uploading
        currentChunk++;
        parser.resume();
      },
      async error() {
        await dispatch(deleteRecipientList(newRecipientList));
        dispatch(
          notify({
            type: "error",
            message: i18n.t("Failed to upload {{name}}", { name }),
          })
        );
      },
      async complete() {
        // do not do anything if the current newList id does not match
        // with the initial one (from the start of the chunk upload)
        // this can happen if the user deleted the recipientList or started a new one in the meantime
        const { newRecipientList } = getState();
        if (newRecipientList?._id !== _id) {
          return;
        }

        const { name } = await api.updateRecipientList(
          _id,
          {
            status: "COMPLETED",
          },
          { activeDivisionId }
        );
        batch(() => {
          if (redirect) {
            dispatch(clearNewRecipientList({ keepRedirect: true }));
          } else {
            dispatch(clearNewRecipientList());
          }
          dispatch(
            notify({
              type: "success",
              message: i18n.t("Successfully uploaded {{name}}!", { name }),
            })
          );
        });
        onComplete();
      },
    });

    return _id;
  };
}
