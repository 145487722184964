import React, { useCallback, useState, useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, batch } from "react-redux";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { DropZone, Box } from "../../../../../components";
import {
  buildNewRecipientList,
  parseRecipientsPreview,
  createNewRecipientList,
} from "../../../../../actions/newRecipientList";
import ResetDialog from "../../ResetDialog";
import UploadIcon from "./UploadIcon";
import UploadLoaderIcon from "./UploadLoaderIcon";
import UploadErrorIcon from "./UploadErrorIcon";
import UploadSuccessIcon from "./UploadSuccessIcon";

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  dropZone: ({ color }) => ({
    height: "100%",
    width: "100%",
    color: color ? theme.palette[color].main : null,
  }),
}));

function getStatus(newRecipientList, t) {
  const defaultStatus = {
    label: t("Upload a csv or txt file by drag-and-drop or clicking"),
    icon: UploadIcon,
    color: "primary",
  };
  if (!newRecipientList) {
    return defaultStatus;
  }

  const { loading, errors, file, doneStepIdx } = newRecipientList;

  if (Object.keys(errors).length) {
    return {
      label: errors.file,
      icon: UploadErrorIcon,
      color: "error",
    };
  }
  if (loading) {
    return {
      label: t("Parsing {{fileName}}...", { fileName: file.name }),
      icon: UploadLoaderIcon,
    };
  }
  if (file) {
    if (doneStepIdx < 0) {
      return {
        label: t("Sucessfully parsed {{fileName}}", { fileName: file.name }),
        icon: UploadSuccessIcon,
        color: "success",
      };
    }

    return {
      label: t("Changed your mind about {{fileName}}? Upload another file.", {
        fileName: file.name,
      }),
      icon: UploadIcon,
      color: "primary",
    };
  }
  return defaultStatus;
}

function UploadRecipientListFile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [newFile, setNewFile] = useState();

  const newRecipientList = useSelector((state) => state.newRecipientList);
  const isStepDone = 0 <= newRecipientList.doneStepIdx;

  const { icon, label, color } = useMemo(() => getStatus(newRecipientList, t), [
    newRecipientList,
    t,
  ]);

  const classes = useStyles({ color });
  const config = useSelector((state) =>
    state && state.eonsConfig ? state.eonsConfig : {}
  );
  const parseFile = useCallback(
    (file) =>
      dispatch(
        parseRecipientsPreview({
          file,
          errorMessage: t("Failed to parse {{fileName}}", {
            fileName: file.name,
          }),
        })
      ),
    [dispatch, t]
  );

  const onNewFile = useCallback(() => {
    batch(() => {
      dispatch(createNewRecipientList({ force: true }));
      parseFile(newFile);
      setNewFile(null);
    });
  }, [dispatch, parseFile, newFile, setNewFile]);

  const onDropAccepted = useCallback(
    ([file]) => {
      const fileLimit = parseInt(
        config.vendorConfig?.recipientListFileSizeLimit
      );
      if (fileLimit && file.size > fileLimit) {
        dispatch(
          buildNewRecipientList({
            errors: {
              file: "Your upload has exceeded the maximum allowed limit.",
            },
            doneStepIdx: -1,
            loading: false,
            file: null,
          })
        );
      } else {
        if (isStepDone) {
          setNewFile(file);
        } else {
          parseFile(file);
        }
      }
    },
    [isStepDone, setNewFile, parseFile, buildNewRecipientList, dispatch]
  );

  const onCloseResetDialog = useCallback(() => setNewFile(null), [setNewFile]);

  const onDropRejected = useCallback(
    ([{ file }]) => {
      dispatch(
        buildNewRecipientList({
          errors: {
            file: t(
              'The file type must be csv or txt. You uploaded "{{type}}".',
              {
                type: file.type,
              }
            ),
          },
        })
      );
    },
    [dispatch, t]
  );

  return (
    <>
      <Box p={2} className={classes.fullHeight}>
        <DropZone
          className={classNames(classes.dropZone, classes.fullHeight)}
          accept=".csv,.txt"
          multiple={false}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          Icon={icon}
          label={label}
          disabled={newRecipientList?.loading}
        />
      </Box>
      <ResetDialog
        open={Boolean(newFile)}
        onClose={onCloseResetDialog}
        onReset={onNewFile}
        title={t("You May Loose Progress")}
        text={t(
          "Uploading {{fileName}} will reset your progress. Are you sure about this?",
          { fileName: newFile?.name }
        )}
        resetText={t("Yes, upload it")}
      />
    </>
  );
}

export default memo(UploadRecipientListFile);
