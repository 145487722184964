import bboxToPolygon from "@turf/bbox-polygon";
import { polygon as toPolygon } from "@turf/helpers";
import circleToPolygon from "@turf/circle";

export default function shapeToGeoJson({ overlay, type }) {
  switch (type) {
    case "rectangle":
      return rectToGeoJson(overlay);
    case "polygon":
      return polygonToGeoJson(overlay);
    case "circle":
      return circleToGeoJson(overlay);
    default:
      throw new Error("Invalid shape, it can't be converted to GeoJSON");
  }
}

function rectToGeoJson(rect) {
  const bounds = rect.getBounds();
  const min = bounds.getSouthWest();
  const max = bounds.getNorthEast();

  const { type, geometry, properties } = bboxToPolygon([
    min.lng(),
    min.lat(),
    max.lng(),
    max.lat(),
  ]);
  return { type, geometry, properties };
}

function circleToGeoJson(circle) {
  const c = circle.getCenter();
  const center = [c.lng(), c.lat()];
  const radius = circle.getRadius();

  // google maps uses meters for radius while turf uses km, we have to convert here
  return circleToPolygon(center, radius / 1000, {
    steps: 50,
    properties: { circle: true, center, radius },
  });
}

function polygonToGeoJson(polygon) {
  const paths = polygon
    .getPaths()
    .getArray()
    .map((path) => {
      const coords = path
        .getArray()
        .map((coords) => [coords.lng(), coords.lat()]);
      coords.push(coords[0]);
      return coords;
    });

  return toPolygon(paths);
}
