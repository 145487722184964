import React, { useCallback, useMemo, useRef, memo } from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Typography } from "../../../../components";
import useAuthorized, {
  TEMPLATE_CREATE_CUSTOMER_DATA_PERMISSION,
} from "../../../../hooks/useAuthorized";
import RecipientFieldSelector from "../RecipientFieldSelector";

// rendering what is being display in textbox
function itemsToTemplate(voiceTemplate) {
  return voiceTemplate && voiceTemplate.length
    ? voiceTemplate.reduce((template, value) => {
        return (
          template +
          (value.stepType === "customerData" && value.variableName
            ? `<%= ${value.variableName} %>`
            : value.transcript)
        );
      }, "")
    : "";
}

function textToItems(text) {
  const items = [];
  let buffer = "";

  for (let i = 0; i < text.length; i++) {
    const character = text[i];
    buffer += character;

    if (
      buffer[0] !== "<" &&
      text[i + 1] !== "<" &&
      text[i + 2] !== "%" &&
      text[i + 3] !== "=" &&
      text[i + 4] !== " "
    ) {
      continue;
    } else if (
      text[i + 1] === "<" &&
      text[i + 2] === "%" &&
      text[i + 3] === "=" &&
      text[i + 4] === " "
    ) {
      items.push({
        stepType: "customerData",
        transcript: buffer,
        action: "playback",
        name: `${items.length}`,
      });
      buffer = "";
      continue;
    }

    if (buffer === "<%= ") {
      continue;
    }

    if (
      buffer[0] === "<" &&
      buffer[1] === "%" &&
      buffer[2] === "=" &&
      buffer[3] === " "
    ) {
      if (text[i] === " " && text[i + 1] === "%" && text[i + 2] === ">") {
        continue;
      } else if (
        text[i - 1] === " " &&
        text[i] === "%" &&
        text[i + 1] === ">"
      ) {
        continue;
      } else if (
        text[i - 2] === " " &&
        text[i - 1] === "%" &&
        text[i] === ">"
      ) {
        items.push({
          stepType: "customerData",
          action: "playback",
          variableName: buffer.replace("<%= ", "").replace(" %>", ""),
          _id: String(Date.now()),
          name: `${items.length}`,
        });
        buffer = "";
        continue;
      }
    }
  }

  if (items.length && items[items.length - 1].transcript && buffer) {
    items[items.length - 1].transcript += buffer;
  } else if (buffer) {
    items.push({
      stepType: "customerData",
      transcript: buffer,
      action: "playback",
      name: `${items.length}`,
    });
  }
  return items;
}

function VoiceTemplateTTS({
  errors,
  readOnly,
  onSetVoiceItems,
  voiceTemplate = [],
}) {
  const templateRef = useRef();
  const { t } = useTranslation();

  const readOnlyProps = useMemo(() => ({ readOnly }), [readOnly]);

  const onVoiceTemplateChange = useCallback(
    (ev) => onSetVoiceItems(textToItems(ev.target.value)), // need to change to accomadate existing voice item.
    [onSetVoiceItems]
  );

  const onAddRecipientField = useCallback(
    (header) => {
      const { current: input } = templateRef;
      const { selectionEnd } = input;
      const textToInsert = `<%= ${header} %>`;
      const text = itemsToTemplate(voiceTemplate);
      onSetVoiceItems(
        // need to change to accomadate existing voice item
        textToItems(
          text.slice(0, selectionEnd) + textToInsert + text.slice(selectionEnd)
        )
      );
      setTimeout(() => {
        input.focus();
        const newCursorIdx = selectionEnd + textToInsert.length;
        input.setSelectionRange(newCursorIdx, newCursorIdx);
      });
    },
    [voiceTemplate, onSetVoiceItems, templateRef]
  );

  const canUseCustomerData = useAuthorized(
    TEMPLATE_CREATE_CUSTOMER_DATA_PERMISSION
  );

  return (
    <Grid container spacing={2} justify="space-between" alignItems="center">
      <Grid item>
        <Typography xs>
          {t(
            "We use text to speech technology to deliver voice messages to your audience."
          )}
        </Typography>
      </Grid>
      {canUseCustomerData && (
        <Grid item xs>
          <RecipientFieldSelector
            onAddRecipientField={onAddRecipientField}
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          data-test-id="master-template-adhoc-voice-field"
          multiline
          rows={4}
          rowsMax={8}
          variant="outlined"
          fullWidth
          value={itemsToTemplate(voiceTemplate)}
          onChange={onVoiceTemplateChange}
          error={Boolean(
            errors["voiceTemplate.freeswitch.dialPlan.person.steps"]
          )}
          helperText={errors["voiceTemplate.freeswitch.dialPlan.person.steps"]}
          InputProps={readOnlyProps}
          inputRef={templateRef}
        />
      </Grid>
    </Grid>
  );
}

export default memo(VoiceTemplateTTS);
