import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField } from "../../../../components";

function VoiceAdvanceField({
  advanceVoice,
  onSetVoiceAdvance,
  readOnly,
  errors,
}) {
  const { t } = useTranslation();
  const readOnlyProps = useMemo(() => ({ readOnly }), [readOnly]);
  return (
    <Grid container spacing={1} wrap="nowrap" alignItems="flex-start">
      <Grid item xs>
        <TextField
          data-test-id="master-template-voice-data-advance-field"
          label={t("Add Advance Voice")}
          variant="outlined"
          multiline
          fullWidth
          value={advanceVoice ? advanceVoice : null}
          onChange={(ev) => onSetVoiceAdvance(ev.target.value)}
          inputProps={readOnlyProps}
          error={Boolean(errors[`voiceTemplate.advanceVoice`])}
          helperText={errors[`voiceTemplate.advanceVoice`]}
          disabled={readOnly}
        ></TextField>
      </Grid>
    </Grid>
  );
}

export default memo(VoiceAdvanceField);
