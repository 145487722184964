import React, { useCallback, memo } from "react";
import { Link } from "react-router-dom";
import { IconButton, Tooltip } from "../../../components";
import { Visibility, Edit, DeleteForever } from "../../../icons";
import {
  SEED_GET_PERMISSION,
  SEED_DELETE_PERMISSION,
  SEED_UPDATE_PERMISSION,
  useAuthorized,
} from "../../../hooks/useAuthorized";
import { useTranslation } from "react-i18next";

function SeedActions({ testId, seed, onDelete }) {
  const { _id } = seed;
  const { t } = useTranslation();

  const canEdit = useAuthorized(SEED_UPDATE_PERMISSION);
  const canView = useAuthorized(SEED_GET_PERMISSION);
  const canDelete = useAuthorized(SEED_DELETE_PERMISSION);

  const EditIcon = canEdit ? Edit : Visibility;
  const editTooltipText = canEdit ? t("Edit seed") : t("View seed");

  const _onDelete = useCallback(() => onDelete(seed), [onDelete, seed]);

  return (
    <>
      {(canEdit || canView) && (
        <Tooltip title={editTooltipText}>
          <IconButton
            component={Link}
            to={`/seeds/${_id}`}
            size="small"
            data-test-id={`${testId}-action-edit-${_id}`}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {canDelete && (
        <Tooltip title={t("Delete seed")}>
          <IconButton
            size="small"
            color="secondary"
            onClick={_onDelete}
            data-test-id={`${testId}-action-delete-${_id}`}
          >
            <DeleteForever fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

export default memo(SeedActions);
