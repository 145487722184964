import api from "./api";

const foldersBasePath = "/api/v1/foldersTemplates";

export async function getFolderById(id, params) {
  const { data } = await api.get(`${foldersBasePath}/${id}`, { params });
  return data;
}

export async function getFolders(params) {
  const { data } = await api.get(foldersBasePath, {
    params,
  });
  return data;
}

export async function createFolder(folder, params) {
  const { data } = await api.post(foldersBasePath, folder, {
    params,
  });
  return data;
}

export async function updateFolder({ folderId, name, paid }, params) {
  const { data } = await api.patch(
    `${foldersBasePath}/${folderId}`,
    { name, paid },
    { params }
  );
  return data;
}

export async function deleteFolders(id, params) {
  const { data } = await api.delete(`${foldersBasePath}/${id}`, {
    params,
  });
  return data;
}

export async function moveTemplate({ folderId, templateId }, params) {
  const { data } = await api.patch(
    `${foldersBasePath}/${folderId}/master-template`,
    { templateId },
    { params }
  );
  return data;
}
