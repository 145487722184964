import {
  CLEAR_CAMPAIGN,
  SET_CAMPAIGN,
  BUILD_CAMPAIGN,
} from "../actions/campaign";

export default function campaignReducer(
  campaign = null,
  { type, newCampaign, campaignData, overwrite }
) {
  switch (type) {
    case BUILD_CAMPAIGN: {
      if (overwrite) {
        return campaignData;
      }
      return campaign ? { ...campaign, ...campaignData } : null;
    }
    case SET_CAMPAIGN: {
      return { ...campaign, ...newCampaign };
    }
    case CLEAR_CAMPAIGN:
      return null;
    default:
      return campaign;
  }
}
