import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Check,
  Stop,
  Done,
  ArrowForward,
  InsertDriveFile as Draft,
  Pause,
  EmojiPeople,
  Close,
} from "../../../icons";
import { Tooltip } from "../../../components";
import { Typography } from "@material-ui/core";

export const CAMPAIGN_STATUS = {
  PENDING: "PENDING",
  RUNNING: "RUNNING",
  CANCELLED: "CANCELLED",
  STOPPED: "STOPPED",
  COMPLETED: "COMPLETED",
  REJECTED: "REJECTED",
  WAITING_FOR_APPROVAL: "WAITING_FOR_APPROVAL",
};

const useStyles = makeStyles((theme) => ({
  done: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  draft: {
    position: "relative",
  },
  draftText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: theme.palette.common.white,
    fontSize: 10,
    fontWeight: "bold",
  },
}));

function CampaignStatusIndicator({ testId, status, doneStepIdx, comments }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const pendingTitle = [
    t("Pending on: Choose List"),
    t("Pending on: Choose Template"),
    t("Pending on: Fill Data"),
    t("Pending on: Settings"),
    t("Pending on: Submit For Approval"),
  ];

  switch (status) {
    case CAMPAIGN_STATUS.PENDING:
      return (
        <Tooltip title={pendingTitle[doneStepIdx]}>
          <div className={classes.draft}>
            <Draft
              data-test-id={`${testId}-icon-draft`}
              className={classes.warning}
            />
            <Typography
              data-test-id={`${testId}-icon-draft-value`}
              variant="caption"
              className={classes.draftText}
            >
              {doneStepIdx + 1}
            </Typography>
          </div>
        </Tooltip>
      );
    case CAMPAIGN_STATUS.CANCELLED:
      return (
        <Tooltip title={t("Cancelled")}>
          <Stop
            data-test-id={`${testId}-icon-cancelled`}
            className={classes.error}
          />
        </Tooltip>
      );
    case CAMPAIGN_STATUS.COMPLETED:
      return (
        <Tooltip title={t("Completed")}>
          <Done
            data-test-id={`${testId}-icon-completed`}
            className={classes.done}
          />
        </Tooltip>
      );
    case CAMPAIGN_STATUS.RUNNING:
      return (
        <Tooltip title={t("Running")}>
          <ArrowForward
            data-test-id={`${testId}-icon-running`}
            className={classes.done}
          />
        </Tooltip>
      );
    case CAMPAIGN_STATUS.STOPPED:
      return (
        <Tooltip title={t("Stopped")}>
          <Pause
            data-test-id={`${testId}-icon-stopped`}
            className={classes.warning}
          />
        </Tooltip>
      );
    case CAMPAIGN_STATUS.WAITING_FOR_APPROVAL:
      return (
        <Tooltip title={t("Waiting for approval")}>
          <EmojiPeople
            data-test-id={`${testId}-icon-approval`}
            className={classes.warning}
          />
        </Tooltip>
      );
    case CAMPAIGN_STATUS.REJECTED:
      return (
        <Tooltip title={t(comments?.length ? comments[0].text : "Rejected ")}>
          <Close
            data-test-id={`${testId}-icon-rejected`}
            className={classes.error}
          />
        </Tooltip>
      );
    default:
      return (
        <Check data-test-id={`${testId}-icon-check`} className={classes.done} />
      );
  }
}

export default memo(CampaignStatusIndicator);
