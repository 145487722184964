import i18n from "i18next";
import { batch } from "react-redux";
import * as approverApi from "../api/approver";
import { notify } from "./notification";

export const CLEAR_APPROVER = "CLEAR_APPROVER";
export const SET_APPROVER = "SET_APPROVER";

export function deleteApprover({ _id, name }, params) {
  return async (dispatch) => {
    if (_id) {
      await approverApi.deleteApprover(_id, params);
      dispatch(
        notify({
          type: "success",
          message: i18n.t("{{name}} successfully deleted", {
            name: name ?? i18n.t("approver"),
          }),
        })
      );
    }
  };
}

export function getApprover(id) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { activeDivisionId } = storage;

    try {
      const approver = await approverApi.getApprover(id, {
        activeDivisionId,
      });
      dispatch(setApprover(approver));
    } catch (err) {
      dispatch(setApprover({ notFound: true }));
    }
  };
}

export function updateApprover() {
  return async (dispatch, getState) => {
    const { approver, storage } = getState();
    const { _id, name } = approver;
    let approverUpdates = {
      name: approver.name,
      sms: approver.sms.replace(/[^0-9a-z]/gi, ""),
      email: approver.email,
      approverActive: approver.approverActive,
    };
    try {
      await approverApi.updateApprover(_id, approverUpdates, {
        activeDivisionId: storage.activeDivisionId,
      });
      batch(() => {
        dispatch(clearApprover(approver));
        dispatch(
          notify({
            type: "success",
            message: i18n.t("Successfully updated {{name}}", { name }),
          })
        );
      });
    } catch (err) {
      batch(() => {
        dispatch(
          setApprover({
            errors: err.response.data,
          })
        );
        dispatch(
          notify({
            type: "error",
            message: i18n.t("Please correct your errors before updating"),
          })
        );
      });
      throw err;
    }
  };
}

export function saveApprover() {
  return async (dispatch, getState) => {
    const { approver, storage } = getState();
    const { name, email, sms } = approver;

    const approverToSave = {
      name,
      email,
      sms: sms.replace(/[^0-9a-z]/gi, ""),
    };

    try {
      await approverApi.createApprover(approverToSave, {
        activeDivisionId: storage.activeDivisionId,
      });
      batch(() => {
        dispatch(clearApprover(approver));
        dispatch(
          notify({
            type: "success",
            message: i18n.t("Successfully created {{name}}", { name }),
          })
        );
      });
    } catch (err) {
      batch(() => {
        dispatch(
          setApprover({
            errors: err.response.data,
          })
        );
        dispatch(
          notify({
            type: "error",
            message: i18n.t("Please correct your errors before updating"),
          })
        );
      });
      throw err;
    }
  };
}

export function resetApprover(approver) {
  const { name, email, sms } = approver;
  const resetedApprover = { email, sms, name };
  return { type: SET_APPROVER, resetedApprover };
}

export function setApprover(newApprover) {
  return { type: SET_APPROVER, newApprover };
}

export function clearApprover() {
  return { type: CLEAR_APPROVER };
}
