import React, { memo } from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, NavigationDialog } from "../../../components";
import Controls from "./Controls";
import Fields from "./Fields";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    width: "100%",
    overflow: "auto",
  },
  fields: {
    marginBottom: theme.spacing(2),
  },
}));

function BaseApproversPage({ approver, onFieldChange, onSave, readOnly }) {
  const classes = useStyles();

  const { errors = {}, name, email, sms, modified } = approver || {};
  const hasErrors = 0 < Object.keys(errors).length;

  return (
    <>
      <Card>
        <CardContent>
          <Fields
            errors={errors}
            name={name}
            email={email}
            sms={sms}
            onChange={onFieldChange}
            className={classes.fields}
            readOnly={readOnly}
          />
          {!readOnly && <Controls onSave={onSave} hasErrors={hasErrors} />}
        </CardContent>
      </Card>
      <NavigationPrompt when={Boolean(modified)}>
        {({ onConfirm, onCancel, isActive }) => (
          <NavigationDialog
            onConfirm={onConfirm}
            onCancel={onCancel}
            open={isActive}
          />
        )}
      </NavigationPrompt>
    </>
  );
}

export default memo(BaseApproversPage);
