import React, { memo } from "react";
import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  drawer: ({ width }) => ({
    zIndex: 100,
    width,
    flexShrink: 0,
    whiteSpace: "nowrap",
    overflowX: "hidden",
  }),
  paper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  withTopbar: {
    paddingTop: theme.custom.topbarHeight,
  },
  content: ({ width }) => ({
    marginLeft: width,
  }),
}));

function LargeDrawer({ children, width = 200, className, withTopbar = true }) {
  const classes = useStyles({ width });

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classNames(className, classes.paper, classes.drawer, {
          [classes.withTopbar]: withTopbar,
        }),
      }}
    >
      {children}
    </Drawer>
  );
}

export default memo(LargeDrawer);
