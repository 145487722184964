import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  FormControl,
  FormHelperText,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from "..";
import { ExpandMore, CancelRounded, CheckBox } from "../../icons";
import { LinearProgress, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  approversLabel: {
    marginBottom: theme.spacing(2),
  },
  noMargin: {
    minHeight: "0px !important",
    margin: "0px !important",
  },
  noPadding: {
    padding: "0px !important",
  },
}));

function Approval({
  approvers = [],
  acceptedApprovers = [],
  rejectedApprovers = [],
  neededPercent,
  errors = {},
  comments = [],
  loading,
  ...rest
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const approvedPercent = Math.floor(
    (acceptedApprovers.length / approvers.length) * 100
  );

  const ApprovalStatus = ({ isRejected, isAccepted, approver }) => {
    if (isRejected) {
      const rejectedComment = comments?.find(
        (comment) => comment?.createdById === approver?._id
      );
      return (
        <Tooltip title={rejectedComment?.text || ""}>
          <Checkbox
            checkedIcon={<CancelRounded />}
            className={classes.checkbox}
            color={"secondary"}
            checked={isRejected || isAccepted}
            name={approver.name}
          />
        </Tooltip>
      );
    } else {
      return (
        <Checkbox
          checkedIcon={<CheckBox />}
          className={classes.checkbox}
          color={"primary"}
          checked={isRejected || isAccepted}
          name={approver.name}
        />
      );
    }
  };

  return (
    <Paper {...rest}>
      <Box p={2}>
        <Grid container spacing={2} direction="column">
          <Grid item xs>
            <LinearProgress
              style={{ marginTop: 10, marginBottom: 10 }}
              variant="buffer"
              value={approvedPercent}
              valueBuffer={neededPercent}
              data-test-id="approverspage-status"
            />
            <Grid container justify="space-around">
              <Grid item component={Typography} color="primary">
                {`${approvedPercent}% ${t("approved")}`}
              </Grid>
              <Grid item component={Typography} color="secondary">
                {`${t("Approval needed for launch")}: ${neededPercent}%`}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10} md={11} style={{ alignSelf: "center" }}>
            <FormControl error={Boolean(errors.approvers)} fullWidth>
              <FormLabel component="legend" className={classes.approversLabel}>
                {t("Approvers")}
              </FormLabel>
              {loading ? (
                <Skeleton height={40} />
              ) : (
                <FormGroup>
                  <Grid
                    container
                    spacing={2}
                    data-test-id="approverspage-approvers-list"
                  >
                    {approvers.map((approver) => {
                      const isRejected = rejectedApprovers.includes(
                        approver._id
                      );
                      const isAccepted = acceptedApprovers.includes(
                        approver._id
                      );
                      return (
                        <Grid item key={approver._id}>
                          <FormControlLabel
                            control={ApprovalStatus({
                              isAccepted,
                              isRejected,
                              approver,
                            })}
                            label={approver.name}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  {errors.approvers && (
                    <FormHelperText>{errors.approvers}</FormHelperText>
                  )}
                </FormGroup>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Accordion
        elevation={0}
        classes={{ expanded: classes.noMargin }}
        data-test-id="approverspage-comments"
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          classes={{ expanded: classes.noMargin }}
        >
          <Typography>{t("Comments")}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.noPadding}>
          <List
            dense
            className={classes.noPadding}
            data-test-id="approverspage-comments-list"
          >
            {comments.map((comment) => (
              <ListItem key={comment._id}>
                <ListItemText
                  primary={comment.text}
                  secondary={`${
                    approvers.find(
                      (approver) => approver._id === comment.createdById
                    )?.name || "Campaign Creator"
                  } ${t("at")} 
                ${format(new Date(comment.createdAt), "MMMM do hh:mm aa")}`}
                />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}

export default memo(Approval);
