import React, { useCallback, useState, useMemo, memo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Badge,
  CircularProgress,
} from "../components";
import { Notifications } from "../icons";

function getPendingTasks({ newRecipientList, t }) {
  const pendingTasks = [];

  if (newRecipientList) {
    const { progress } = newRecipientList;
    const uploading = progress !== undefined;

    pendingTasks.push({
      path: "/recipient-lists/create",
      primaryLabel: t("In-progress recipient list"),
      secondaryLabel: newRecipientList.name,
      testId: "recipient-upload",
      icon: (
        <CircularProgress
          color="warning"
          size={uploading ? 40 : 25}
          withLabel={progress !== undefined}
          value={progress}
        />
      ),
    });
  }

  return pendingTasks;
}

function PendingTasks() {
  const { t } = useTranslation();
  const [anchor, setAnchor] = useState(null);
  const newRecipientList = useSelector((state) => state.newRecipientList);

  const pendingTasks = useMemo(() => getPendingTasks({ newRecipientList, t }), [
    newRecipientList,
    t,
  ]);
  const disabled = pendingTasks.length <= 0;

  const onOpen = useCallback((ev) => setAnchor(ev.currentTarget), [setAnchor]);
  const onClose = useCallback(() => setAnchor(null), [setAnchor]);

  return (
    <>
      <IconButton
        aria-controls="pending-tasks"
        aria-haspopup="true"
        onClick={onOpen}
        disabled={disabled}
        data-test-id={"pending-task-dropdown"}
      >
        <Badge badgeContent={pendingTasks.length} color="secondary">
          <Notifications />
        </Badge>
      </IconButton>
      <Menu
        id="pending-tasks"
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={onClose}
        onClick={onClose}
      >
        {pendingTasks.map((task) => (
          <MenuItem component={Link} to={task.path} key={task.path}>
            {task.icon && (
              <ListItemIcon data-test-id={`pending-task-icon-${task.testId}`}>
                {task.icon}
              </ListItemIcon>
            )}
            <ListItemText
              data-test-id={`pending-task-text-${task.testId}`}
              primaryTypographyProps={{ variant: "button" }}
              primary={task.primaryLabel}
              secondary={task.secondaryLabel}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default memo(PendingTasks);
