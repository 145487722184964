import api from "./api";
import qs from "qs";

const recipientListEndpoint = `/api/v1/eons/recipient-list`;
const masterListHeaderFieldEndpoint = `/api/v1/master-list-header-fields`;

export async function getRecipientList(id, params) {
  const { data } = await api.get(`${recipientListEndpoint}/${id}`, { params });
  return data;
}

export async function getDippingStatus(id, params) {
  const { data } = await api.get(`${recipientListEndpoint}/${id}/dip`, {
    params,
  });
  return data;
}

export async function dipRecipientList(id, params) {
  const { data } = await api.post(
    `${recipientListEndpoint}/${id}/dip`,
    {},
    {
      params,
    }
  );

  return data;
}

export async function listRecipientLists(params) {
  const { data } = await api.get(`${recipientListEndpoint}`, {
    params,
  });
  return data;
}

export async function createRecipientList(recipientList, params) {
  const { data } = await api.post(`${recipientListEndpoint}`, recipientList, {
    params,
  });
  return data;
}

export async function updateRecipientList(
  recipientListId,
  update,
  params = {}
) {
  const { data } = await api.patch(
    `${recipientListEndpoint}/${recipientListId}`,
    update,
    {
      params,
    }
  );
  return data;
}

export async function addRecipientsChunk(recipientsChunk, params) {
  // do not show the standard top loader for recipient chunks
  // it would constantly show a loading user as it requires several large requests
  // recipient chunks have their own, more sophisticated progress indicator
  const { data } = await api.post(
    "/api/v1/recipient/chunk-upload",
    recipientsChunk,
    { hideLoader: true, params }
  );
  return data;
}

export async function editRecipientList(id, recipientList) {
  const { data } = await api.patch(
    `${recipientListEndpoint}/${id}`,
    recipientList
  );
  return data;
}

export async function listRecipients(params) {
  const { data } = await api.get(`/api/v1/recipient`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return data;
}

export async function cloneRecipientList(id, params) {
  const { data } = await api.post(
    `${recipientListEndpoint}/${id}/clone`,
    {},
    { params }
  );
  return data;
}

export async function deleteRecipientList(id, params) {
  const { data } = await api.delete(`${recipientListEndpoint}/${id}`, {
    params,
  });
  return data;
}

export async function listMasterLists(params) {
  const { data } = await api.get("/api/v1/master-list", {
    params,
  });
  return data;
}

export async function listMasterRecipients(params) {
  const { data } = await api.get(`/api/v1/master-recipient`, { params });
  return data;
}

export async function updateMasterRecipient(recipientId, body, params) {
  const { data } = await api.patch(
    `/api/v1/master-recipient/${recipientId}`,
    body,
    { params }
  );
  return data;
}

export async function filterMasterRecipients(recipientsFilter) {
  const { data } = await api.post(
    `/api/v1/recipient/master-filter`,
    recipientsFilter,
    // list creation has its own dedicated loader on the creation page
    { hideLoader: true }
  );
  return data;
}

export async function generateRecipientListCSV(id, params) {
  const { data } = await api.post(
    `${recipientListEndpoint}/${id}/generate`,
    {},
    { params }
  );
  return data;
}

export async function downloadList(id, params) {
  const response = await api.get(`${recipientListEndpoint}/${id}/download`, {
    params,
    responseType: "blob",
  });

  const blob = new Blob([response.data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${id}.csv`);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
}
export async function deDulicateRecipientList(
  recipientListId,
  recipientHeaders,
  params
) {
  const { data } = await api.post(
    `${recipientListEndpoint}/deduping`,
    {
      recipientListId,
      recipientHeaders,
    },
    { params }
  );
  return data;
}

export async function deleteMasterRecipient(recipientId) {
  const { data } = await api.delete(`/api/v1/master-recipient/${recipientId}`);
  return data;
}

export async function getMasterRecipientsCount(divisionId) {
  const { data } = await api.get(
    `/api/v1/master-recipient/count/${divisionId}`
  );
  return data;
}

export async function listMasterListHeaderFields(params) {
  const { data } = await api.get(masterListHeaderFieldEndpoint, { params });
  return data;
}
