import React, { useCallback, useState, useEffect, memo, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { omit, sortBy } from "lodash";
import {
  launchCampaign,
  buildCampaign,
  sendCampaignTest,
} from "../../../../actions/campaign";
import * as approversApi from "../../../../api/approver";
import * as listsApi from "../../../../api/recipientLists";
import * as templatesApi from "../../../../api/masterTemplate";
import Approvers from "./Approvers";
import Info from "./Info";
import Test from "./Test";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Approval from "../../../../components/ApproversPage/Approval";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import useAuthorized, {
  ADMIN_CREATE_PERMISSION,
} from "../../../../hooks/useAuthorized";
import { getPlaceHolders } from "../FillData";
import _ from "lodash";

const getCounts = (
  recipientLists,
  { emailTemplate, smsTemplate, voiceTemplate, ttyTemplate, customTemplate }
) =>
  recipientLists.reduce(
    (
      {
        emailCount = 0,
        smsCount = 0,
        voiceCount = 0,
        ttyCount = 0,
        customCount = 0,
      },
      {
        emailCount: listEmailCount = 0,
        smsCount: listSmsCount = 0,
        voiceCount: listVoiceCount = 0,
        ttyCount: listTTYCount = 0,
        customCount: listCustomCount = 0,
      }
    ) => ({
      emailCount: emailCount + (emailTemplate ? listEmailCount : 0),
      smsCount: smsCount + (smsTemplate ? listSmsCount : 0),
      voiceCount: voiceCount + (voiceTemplate ? listVoiceCount : 0),
      ttyCount: ttyCount + (ttyTemplate ? listTTYCount : 0),
      customCount: customCount + (customTemplate ? listCustomCount : 0),
    }),
    {}
  );

function ApproveCampaign({ readOnly }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.campaign);
  const [commentDialog, setCommentDialog] = useState(false);
  const [comment, setComment] = useState();

  const autoLaunchPercentage = useSelector(
    (state) => state.eonsConfig?.vendorConfig?.autoLaunchPercentage
  );

  const userId = useSelector((state) => state.user.id);
  const activeCompanyId = useSelector((state) => state.user.companyId);

  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );

  const hasAdminCreatePermission = useAuthorized(ADMIN_CREATE_PERMISSION);
  const isEonsAdmin = hasAdminCreatePermission;

  const paid = useSelector((state) => state.storage.paid);
  const canOverride = useSelector(
    (state) => isEonsAdmin && state.eonsConfig?.vendorConfig?.overrideLaunch
  );

  const {
    advancedApproverManagement = false,
    campaignSelectAll = false,
    defaultFromName = undefined,
  } = useSelector((state) => state.eonsConfig.vendorConfig);

  const {
    errors,
    lists,
    templateId,
    approvers: selectedApproverIds = [],
    name,
    schedule,
    permission,
    _id: campaignId,
    acceptedApprovers = [],
    rejectedApprovers = [],
    status,
    comments = [],
    createdById,
    bypassDndDns,
    bypassSuppression,
    isHighPriority,
    sentSms = 0,
    sentEmail = 0,
    sentVoice = 0,
    sentTty = 0,
    sentCustom = 0,
  } = campaign;

  const isWaitingForApproval = useMemo(
    () => status === "WAITING_FOR_APPROVAL",
    [status]
  );

  const isFinalized = useMemo(
    () => status !== "PENDING" && status !== "REJECTED",
    [status]
  );

  const isCreator = useMemo(() => createdById === userId, [
    createdById,
    userId,
  ]);

  const [approvers, setApprovers] = useState();
  const [selectedApprovers, setSelectedApprovers] = useState();
  const [recipientLists, setRecipientLists] = useState([]);
  const [masterTemplate, setMasterTemplate] = useState({});

  const [emailPlaceHolders, setEmailPlaceHolders] = useState({
    subject: [],
    content: [],
  });
  const [smsPlaceHolders, setSmsPlaceHolders] = useState({
    content: [],
  });

  const loading = !approvers || !recipientLists || !masterTemplate;

  useEffect(() => {
    approversApi.listApprovers({ activeDivisionId }).then(({ data }) => {
      if (advancedApproverManagement) {
        data = data.filter((approver) => approver.approverActive === true);
      }

      if (campaignSelectAll) {
        let predefindedApprovers = [];
        data.map((approver) => {
          predefindedApprovers.push(approver._id);
        });
        dispatch(
          buildCampaign({
            approvers: predefindedApprovers,
            errors: omit(errors, "approvers"),
          })
        );
      }

      setApprovers(sortBy(data, (approver) => approver.name));
    });
  }, [setApprovers, activeDivisionId]);

  useEffect(() => {
    if (selectedApproverIds.length) {
      approversApi
        .listApprovers({
          activeDivisionId,
          approverIds: selectedApproverIds,
        })
        .then(({ data }) => {
          setSelectedApprovers(sortBy(data, (approver) => approver.name));
        });
    }
  }, [setSelectedApprovers, activeDivisionId, selectedApproverIds]);

  useEffect(() => {
    Promise.all(
      lists.map((recipientListId) =>
        listsApi.getRecipientList(recipientListId, {
          activeDivisionId,
          activeCompanyId,
        })
      )
    ).then((recipientLists) => {
      if (recipientLists[0] !== null) setRecipientLists(recipientLists);
      else setRecipientLists([]);
    });
  }, [setRecipientLists, activeDivisionId, lists]);

  useEffect(() => {
    templatesApi
      .getMasterTemplate(templateId, { activeDivisionId, paid })
      .then((masterTemplate) => setMasterTemplate(masterTemplate));
  }, [setMasterTemplate, activeDivisionId, templateId]);

  const onApproverToggle = useCallback(
    (approverId) =>
      dispatch(
        buildCampaign({
          approvers:
            selectedApproverIds.indexOf(approverId) === -1
              ? [...selectedApproverIds, approverId]
              : selectedApproverIds.filter((id) => id !== approverId),
          errors: omit(errors, "approvers"),
        })
      ),
    [dispatch, selectedApproverIds, errors]
  );

  useEffect(() => {
    if (masterTemplate.emailTemplate) {
      const { subjectEmail = "", template = "" } = masterTemplate.emailTemplate;
      const emailSubjectPlaceHolders = getPlaceHolders(subjectEmail);
      const emailPlaceHolders = getPlaceHolders(template);
      setEmailPlaceHolders({
        subject: emailSubjectPlaceHolders,
        content: emailPlaceHolders,
      });
    }
    if (masterTemplate.smsTemplate) {
      const { template = "" } = masterTemplate.smsTemplate;
      const smsPlaceHolders = getPlaceHolders(template);
      setSmsPlaceHolders({
        content: smsPlaceHolders,
      });
    }
  }, [masterTemplate, activeDivisionId]);

  const onSendTest = useCallback(
    async (testChips, subjectFields = {}, contentFields = {}) => {
      const { emailTemplate = {}, smsTemplate = {} } = masterTemplate;
      const { subjectEmail = "", template: templateEmail = "" } = emailTemplate;
      const { template: templateSMS = "" } = smsTemplate;
      let tempSubject = subjectEmail;
      let tempContentEmail = templateEmail;
      let tempContentSMS = templateSMS;
      if (!_.isEmpty(subjectFields)) {
        for (let key in subjectFields) {
          if (subjectFields.hasOwnProperty(key)) {
            tempSubject = tempSubject.replaceAll(
              new RegExp(`<%=\\s*${key}\\s*%>`, "g"),
              subjectFields[key]
            );
          }
        }
      }

      if (!_.isEmpty(contentFields)) {
        for (let key in contentFields) {
          if (contentFields.hasOwnProperty(key)) {
            tempContentEmail = tempContentEmail.replaceAll(
              new RegExp(`<%=\\s*${key}\\s*%>`, "g"),
              contentFields[key]
            );
            tempContentSMS = tempContentSMS.replaceAll(
              new RegExp(`<%=\\s*${key}\\s*%>`, "g"),
              contentFields[key]
            );
          }
        }
      }
      let modifiedMasterTemplate = {
        ...masterTemplate,
        emailTemplate: {
          ...masterTemplate.emailTemplate,
          template: tempContentEmail,
          subjectEmail: tempSubject,
          friendlyName: emailTemplate?.fromName ?? defaultFromName,
        },
        smsTemplate: {
          ...masterTemplate.smsTemplate,
          template: tempContentSMS,
        }
      };
      return dispatch(
        await sendCampaignTest(testChips, modifiedMasterTemplate)
      );
    },
    [dispatch, emailPlaceHolders, smsPlaceHolders, masterTemplate]
  );

  const onLaunch = useCallback(
    async (comment) => {
      setCommentDialog(false);
      dispatch(launchCampaign(campaignId, comment));
    },
    [dispatch, campaignId]
  );

  const counts = useMemo(() => getCounts(recipientLists, masterTemplate), [
    recipientLists,
    masterTemplate,
  ]);

  return (
    <>
      <Info
        mb={2}
        name={name}
        schedule={schedule}
        permission={permission}
        recipientLists={recipientLists}
        masterTemplate={masterTemplate}
        loading={loading}
        counts={
          Object.keys(counts).length
            ? counts
            : {
                emailCount: sentEmail,
                smsCount: sentSms,
                voiceCount: sentVoice,
                ttyCount: sentTty,
                customCount: sentCustom,
              }
        }
        data-test-id="campaign-approve-info"
        bypassDndDns={bypassDndDns}
        bypassSuppression={bypassSuppression}
        isHighPriority={isHighPriority}
      />
      {!isFinalized && (
        <Test
          mb={2}
          onSendTest={onSendTest}
          emailPlaceHolders={emailPlaceHolders}
          smsPlaceHolders={smsPlaceHolders}
          activeDivisionId={activeDivisionId}
          counts={counts}
          data-test-id="campaign-approve-test"
        />
      )}
      {isFinalized ? (
        <Approval
          approvers={selectedApprovers}
          comments={comments}
          acceptedApprovers={acceptedApprovers}
          rejectedApprovers={rejectedApprovers}
          neededPercent={autoLaunchPercentage}
          data-test-id="campaign-approve-approval"
        />
      ) : (
        <Approvers
          mb={2}
          errors={errors}
          approvers={approvers}
          selectedApprovers={selectedApproverIds}
          onApproverToggle={onApproverToggle}
          readOnly={readOnly}
          loading={loading}
          launchPercentage={autoLaunchPercentage}
          data-test-id="campaign-approve-approvers"
        />
      )}
      {isWaitingForApproval && isCreator && canOverride && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => setCommentDialog(true)}
        >
          {t("Override Launch")}
        </Button>
      )}

      <Dialog
        open={commentDialog}
        onClose={() => setCommentDialog(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">{t("Override Campaign")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {t("Please write a comment in order to override this campaign:")}
          </DialogContentText>
          <TextField
            variant="outlined"
            label="Comment"
            autoFocus
            margin="dense"
            multiline={true}
            rows={3}
            rowsMax={5}
            fullWidth
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onLaunch(comment)}
            color="secondary"
            variant="contained"
            size="large"
          >
            {t("Override")}
          </Button>
          <Button
            onClick={() => setCommentDialog(false)}
            color="primary"
            variant="contained"
            size="large"
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default memo(ApproveCampaign);
