import React, { memo, Fragment } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import ListApprovers from "./List";
import CreateApprover from "./Single/Create";
import EditApprover from "./Single/Edit";
import useAuthorized, {
  APPROVER_UPDATE_PERMISSION,
  APPROVER_CREATE_PERMISSION,
  APPROVER_GET_PERMISSION,
  APPROVER_LIST_PERMISSION,
} from "../../hooks/useAuthorized";
import {
  Grid,
  Card,
  Typography,
  CardContent,
  Page,
  useMediaQuery,
} from "../../components";

function ApproversPage() {
  const canEdit = useAuthorized(APPROVER_UPDATE_PERMISSION);
  const canView = useAuthorized(APPROVER_GET_PERMISSION);
  const canCreate = useAuthorized(APPROVER_CREATE_PERMISSION);
  const canList = useAuthorized(APPROVER_LIST_PERMISSION);

  const { advancedApproverManagement = false } = useSelector(
    (state) => state.eonsConfig.vendorConfig
  );

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const match = useRouteMatch();

  const pageRender = (
    <Card>
      <CardContent>
        <Grid container spacing={5} columns={{ xs: 12, md: 12 }}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              variant="h4"
              style={{ color: "#3f51b5" }}
              align="left"
            >
              Approver List
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              align="left"
            >
              {advancedApproverManagement === true
                ? "Add, Edit, and Enable Campaign Approvers"
                : " Add and Edit Campaign Appovers"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Switch>
              {canCreate ? (
                <Route path={`${match.path}/create`}>
                  <CreateApprover />
                </Route>
              ) : null}

              {canEdit || canView ? (
                <Route path={`${match.path}/:approverId`}>
                  <EditApprover />
                </Route>
              ) : null}

              {canList ? (
                <>
                  <Route path={`${match.path}`}>
                    <ListApprovers />
                  </Route>
                  <Redirect to={`${match.path}`} />
                </>
              ) : null}
            </Switch>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
  return (
    <Fragment>
      {advancedApproverManagement === true ? (
        pageRender
      ) : (
        <Page p={4} screenSized={!isSmallScreen}>
          {pageRender}
        </Page>
      )}
    </Fragment>
  );
}

export default memo(ApproversPage);
