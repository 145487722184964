import React, { memo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

function ReportListDialog({ open, close, title, children }) {
  const { message: closeMessage, onClose } = close;
  return (
    <Dialog open={open} maxWidth="md" onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="secondary">
          {closeMessage}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ReportListDialog);
