import api from "./api";

const seedBasePath = "/api/v1/seed";

export async function getSeed(id, params) {
  const { data } = await api.get(`${seedBasePath}/${id}`, { params });
  return data;
}

export async function listSeeds(params) {
  const { data } = await api.get(seedBasePath, {
    params,
  });
  return data;
}

export async function createSeed(seed, params) {
  const { data } = await api.post(seedBasePath, seed, {
    params,
  });
  return data;
}

export async function updateSeed(seedId, update, params) {
  const { data } = await api.patch(`${seedBasePath}/${seedId}`, update, {
    params,
  });
  return data;
}

export async function deleteSeed(id, params) {
  const { data } = await api.delete(`${seedBasePath}/${id}`, {
    params,
  });
  return data;
}
