import { START_LOADING, STOP_LOADING } from "../actions/loading";

export default function loadingReducer(loading = false, { type }) {
  switch (type) {
    case START_LOADING:
      return true;
    case STOP_LOADING:
      return false;
    default:
      return loading;
  }
}
