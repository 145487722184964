import { isArray, set } from "lodash";
import React, {
  useMemo,
  useCallback,
  useRef,
  memo,
  useState,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Grid, TextField, TextEditor, MenuItem } from "../../../../components";
import RecipientFieldSelector from "../RecipientFieldSelector";

function TemplateFields({
  customFieldData,
  fields = [],
  onCustomFieldChange,
  readOnly,
}) {
  const { t } = useTranslation();
  const [data, setData] = useState(customFieldData);
  const [lastElement, setLastElement] = useState(null);

  const onAddRecipientField = useCallback(
    (header) => {
      if (lastElement) {
        const fieldName = lastElement.name;

        const textToInsert = `<%= ${header} %>`;
        let value = data[fieldName] ? data[fieldName] : "";
        value = value + " " + textToInsert;
        data[fieldName] = value;
        lastElement.value = data[fieldName];
        lastElement.setAttribute("value", data[fieldName]);

        setData(data);
        onCustomFieldChange({
          target: {
            name: "template",
            value: JSON.stringify(data),
          },
        });
      }
    },
    [data, lastElement]
  );

  const onFieldChange = (event) => {
    customFieldData[event.target.name] = event.target.value;
    setData(data);
    onCustomFieldChange({
      target: {
        name: "template",
        value: JSON.stringify(data),
      },
    });
  };

  const renderFields = () => {
    return fields.map((field, key) => {
      if (field.type === "input") {
        return (
          <Grid item xs={12}>
            <TextField
              data-test-id="master-template-custom-select-field"
              rows={4}
              key={key}
              rowsMax={8}
              variant="outlined"
              fullWidth
              id={field.key}
              value={data[field.key]}
              name={field.key}
              onChange={onFieldChange}
              label={t(field.label)}
              onBlur={(event) => setLastElement(event.target)}
              disabled={readOnly}
            ></TextField>
          </Grid>
        );
      } else if (field.type === "select") {
        return (
          <Grid item xs={12}>
            <TextField
              id={field.key}
              data-test-id="master-template-custom-input-field"
              select
              key={key}
              variant="outlined"
              name={field.key}
              fullWidth
              onChange={onFieldChange}
              label={t(field.label)}
              value={data[field.key]}
              onBlur={(event) => setLastElement(event.target)}
              disabled={readOnly}
            >
              {field.options.map((opt) => (
                <MenuItem key={opt} value={opt}>
                  {opt}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        );
      } else if (field.type === "textarea") {
        return (
          <Grid item xs={12}>
            <TextField
              id="outlined-multiline-flexible"
              label={t(field.label)}
              multiline
              fullWidth
              name={field.key}
              rows={4}
              value={data[field.key]}
              onChange={onFieldChange}
              onBlur={(event) => setLastElement(event.target)}
              disabled={readOnly}
            />
          </Grid>
        );
      }
    });
  };

  return (
    customFieldData && (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <RecipientFieldSelector
            onAddRecipientField={onAddRecipientField}
            data-test-id="master-template-custom-variable-section"
          />
        </Grid>
        {customFieldData && renderFields()}
      </Grid>
    )
  );
}

export default memo(TemplateFields);
