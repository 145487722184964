import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from ".";

function NavigationDialog({ onConfirm, onCancel, open, ...rest }) {
  const { t } = useTranslation();

  return (
    <Dialog {...rest} open={open} onClose={onCancel}>
      <DialogTitle>{t("You have unsaved changes")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            "Are you sure you would like to navigate away? You may lose your unsaved changes."
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-test-id="navigation-dialog-button-stay" onClick={onCancel}>
          {t("Stay")}
        </Button>
        <Button
          data-test-id="navigation-dialog-button-navigate"
          onClick={onConfirm}
          color="secondary"
        >
          {t("Navigate Away")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(NavigationDialog);
