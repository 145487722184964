import React, { useMemo, useCallback, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Wizard } from "../../../../components";
import {
  buildNewRecipientList,
  validateFilterColumns,
  validateFilterValues,
  saveNewRecipientList,
  filterMasterRecipients,
} from "../../../../actions/newRecipientList";
import ColumnPicker from "./ColumnPicker";
import ValuesFilter from "./ValuesFilter";
import Save from "../Save";

const useStyles = makeStyles((theme) => ({
  wizard: {
    // marginTop: "auto",
  },
}));

const getSteps = ({ t, validateColumns, validateValues }) => [
  {
    label: t("Select Columns"),
    path: "/recipient-lists/create/filter/columns",
    component: ColumnPicker,
    validate: validateColumns,
  },
  {
    label: t("Filter Data"),
    path: "/recipient-lists/create/filter/values",
    component: ValuesFilter,
    validate: validateValues,
  },
  {
    label: t("Save"),
    path: "/recipient-lists/create/filter/save",
    component: Save,
  },
];

function FilterRecipientListFromMaster({ onAbort, afterSave, children }) {
  const dispatch = useDispatch();
  const newRecipientList = useSelector((state) => state.newRecipientList);
  const { doneStepIdx } = newRecipientList;

  const classes = useStyles();
  const { t } = useTranslation();

  const validateColumns = useCallback(() => dispatch(validateFilterColumns()), [
    dispatch,
  ]);

  const validateValues = useCallback(() => dispatch(validateFilterValues()), [
    dispatch,
  ]);

  const steps = useMemo(
    () => getSteps({ t, validateColumns, validateValues }),
    [t, validateColumns, validateValues]
  );

  const onStepChange = useCallback(
    (activeStepIdx) =>
      dispatch(buildNewRecipientList({ activeStepIdx, errors: {} })),
    [dispatch]
  );

  const onSave = useCallback(async () => {
    const recipientListId = await dispatch(saveNewRecipientList());
    await dispatch(filterMasterRecipients());
    if (afterSave) {
      afterSave(recipientListId);
    }
  }, [dispatch, afterSave]);

  return (
    <>
      <Wizard
        steps={steps}
        className={classes.wizard}
        doneStepIdx={doneStepIdx}
        onStepChange={onStepChange}
        disableNext={
          Object.keys(newRecipientList.errors).length ||
          newRecipientList.loading
        }
        errors={newRecipientList.errors}
        onSave={onSave}
        onAbort={onAbort}
      />
      {children}
      <Switch>
        {steps.slice(0, doneStepIdx + 2).map((step) => (
          <Route key={step.path} path={step.path} component={step.component} />
        ))}
        <Redirect to={steps[newRecipientList.doneStepIdx + 1].path} />
      </Switch>
    </>
  );
}

export default memo(FilterRecipientListFromMaster);
