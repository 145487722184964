import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  Grid,
} from "../../../components";
import TransferList from "../../../components/TransferList";
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
  },
}));

function RecipientListDedupingDialog({
  onClose,
  onDeduplicateList,
  open,
  listToDeDupe,
  ...rest
}) {
  const { t } = useTranslation();
  const [headers, setHeaders] = React.useState();
  const classes = useStyles();
  const [selectedHeaders, setSelectedHeaders] = React.useState([]);

  useEffect(() => {
    const headersList = listToDeDupe?.recipientHeaders
      ? Object.keys(listToDeDupe?.recipientHeaders)
      : [];
    setHeaders(headersList);
    setSelectedHeaders([]);
  }, [listToDeDupe?.recipientHeaders]);

  if (!headers) {
    return t("No headers found");
  }

  return (
    <Dialog {...rest} open={open} onClose={onClose}>
      <DialogTitle>{t("Deduplicating Recipient List")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("Select headers to deduplicate a recipient list")}
          <Box p={2} className={classes.root}>
            <Grid container spacing={2}>
              <TransferList
                left={headers}
                right={selectedHeaders}
                setLeft={setHeaders}
                setRight={setSelectedHeaders}
              />
            </Grid>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-test-id="recipientList-deduplicate-dialog-button-cancel"
          onClick={onClose}
        >
          {t("Cancel")}
        </Button>
        <Button
          data-test-id="recipientList-deduplicate-dialog-button-open-existing"
          onClick={() =>
            onDeduplicateList({
              _id: listToDeDupe._id,
              recipientHeaders: selectedHeaders,
            })
          }
          color="secondary"
        >
          {t("Deduplicate List")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(RecipientListDedupingDialog);
