import { LIST_CAMPAIGNS, CLEAR_CAMPAIGNS } from "../actions/campaigns";

export default function campaignsReducer(
  campaigns = null,
  { type, campaigns: newCampaigns }
) {
  switch (type) {
    case LIST_CAMPAIGNS:
      return newCampaigns;
    case CLEAR_CAMPAIGNS:
      return null;
    default:
      return campaigns;
  }
}
