import React, { useCallback, useState, memo } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Container, Grid, Button, TextField } from "../components";
import { login, loginSSO } from "../actions/user";
import { resetTimer } from "../actions/timer";
import { MicrosoftSSO } from "@mb/react-components";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 100,
  },
  logoContainer: {
    justifyContent: "center",
    display: "flex",
    width: "100%",
    "& img": {
      width: "300px",
      marginTop: "2rem",
    },
  },
  loginSSOBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
  },
}));

function Login() {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const onLogin = useCallback(
    (e) => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      dispatch(resetTimer());
      dispatch(login(loginData));
    },
    [loginData, dispatch]
  );

  const onInputChange = useCallback(
    (ev) => {
      const { name, value } = ev.target;
      setLoginData({
        ...loginData,
        [name]: value,
      });
    },
    [loginData, setLoginData]
  );

  const validateToken = (info) => {
    const { accessToken, idTokenClaims } = info;
    dispatch(loginSSO({ accessToken, groups: idTokenClaims.groups }));
  };

  return (
    <Container maxWidth="xs" className={classes.root}>
      <form onSubmit={onLogin}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="email"
              type="email"
              label={t("Email")}
              value={loginData.email}
              onChange={onInputChange}
              fullWidth
              data-test-id="login-email-input-field"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="password"
              type="password"
              label={t("Password")}
              value={loginData.password}
              onChange={onInputChange}
              fullWidth
              data-test-id="login-password-input-field"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              onClick={onLogin}
              variant="contained"
              color="primary"
              fullWidth
              data-test-id="login-button"
            >
              {t("Log In")}
            </Button>
            <div className={classes.loginSSOBtn}>
              <MicrosoftSSO
                clientId={process.env.REACT_APP_MICROSOFT_SSO_CLIENT_KEY}
                onAuthenticate={(session) => validateToken(session)}
                redirectUri={window.location.origin + "/login"}
              />
            </div>
            <div className={classes.logoContainer}>
              <img alt="logo" src="/assets/logo.png" />
            </div>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
export default memo(Login);
