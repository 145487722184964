import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, Alert } from "../../../../components";
import RecipientMap from "../../../Map/Map";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  error: {
    marginTop: theme.spacing(2),
  },
}));

function FilterRecipientListRecipientMap() {
  const { t } = useTranslation();
  const classes = useStyles();

  const { errors } = useSelector((state) => state.newRecipientList);

  return (
    <Box p={2} className={classes.container}>
      <RecipientMap showFilterButton={false} />
      {errors.message && (
        <Alert variant="filled" severity="error" className={classes.error}>
          {t("Select or draw some polygon layers before filtering")}
        </Alert>
      )}
    </Box>
  );
}

export default memo(FilterRecipientListRecipientMap);
