import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IconButton, Tooltip } from "../../../components";
import { Edit, DeleteForever } from "../../../icons";

function EditRecipientActions({ onEdit, onDelete, recipient }) {
  const { t } = useTranslation();
  const { _id } = recipient;

  return (
    <>
      <Tooltip title={t("Edit Recipient")}>
        <IconButton
          component={Link}
          size="small"
          onClick={onEdit}
          data-test-id={`recipient-action-edit-${_id}`}
        >
          <Edit fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Delete Recipient")}>
        <IconButton
          size="small"
          color="secondary"
          onClick={onDelete}
          data-test-id={`recipient-action-delete-${_id}`}
        >
          <DeleteForever fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default memo(EditRecipientActions);
