import React, { memo, useState, useEffect } from "react";
import { IconButton, Tooltip, Popover } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import OfflinePinIcon from "@material-ui/icons/OfflinePin";
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded";
import { useSelector } from "react-redux";

/**
 * File Action (Generate File) Button
 */
export function generateFileActionButton({ onClick, key }) {
  return (
    <ActionButton
      key={key}
      actionButton={<AddCircleOutlineIcon color="action" />}
      postActionButton={<CheckCircleIcon style={{ color: green[500] }} />}
      onClick={onClick}
      tooltip={"Generate Report"}
    />
  );
}

/**
 * File Action (Disabled Generate File) Button
 */
export function generateFileDisabledButton({ key }) {
  return (
    <ActionButton
      key={key}
      actionButton={<AddCircleOutlineIcon color="disabled" />}
      disabled={true}
    />
  );
}

/**
 * File Action (Download File) Button
 */
export function downloadFileActionButton({ onClick, key }) {
  return (
    <ActionButton
      key={key}
      actionButton={<GetAppRoundedIcon color="primary" />}
      onClick={onClick}
      tooltip={"Download"}
    />
  );
}

/**
 * File Action (Update File) Button
 */
export function updateFileActionButton({ onClick, key }) {
  return (
    <ActionButton
      key={key}
      actionButton={<UpdateRoundedIcon color="action" />}
      postActionButton={<CheckCircleIcon style={{ color: green[500] }} />}
      onClick={onClick}
      tooltip={"Update"}
    />
  );
}

/**
 * Action button
 * @param {*} param0
 * @returns
 */
function ActionButton({
  disabled = false,
  actionButton,
  onClick = () => {},
  postActionButton = undefined,
  tooltip = undefined,
}) {
  const { t } = useTranslation();
  const [clicked, setClick] = useState(false);
  const handleClick = () => {
    if (postActionButton) {
      setClick(true);
    }
    onClick();
  };
  const button = (
    <IconButton disabled={disabled} onClick={handleClick}>
      {actionButton}
    </IconButton>
  );
  const postButton = <IconButton>{postActionButton}</IconButton>;
  return tooltip ? (
    <Tooltip title={t(tooltip)} key={tooltip}>
      {clicked ? postButton : button}
    </Tooltip>
  ) : (
    <>{clicked ? postButton : button} </>
  );
}

/**
 * Action List per file.
 * - onClick show action list as popover
 * @param {*} param0
 * @returns
 */
function FileActionList({ children }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const downloadReport = useSelector((state) => state.downloadReport);
  const handleClick = (event) => {
    if (event.currentTarget !== event.currentTarget) handleClose();
    else setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Close Action list when there is a change to report file list
  useEffect(() => {
    handleClose();
  }, [downloadReport]);

  return (
    <>
      <IconButton variant="contained" onClick={handleClick}>
        <MoreVertRoundedIcon color="action" />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        {children}
      </Popover>
    </>
  );
}

export default memo(FileActionList);
