import React, { memo } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import useAuthorized, {
  TEMPLATE_CREATE_PERMISSION,
  TEMPLATE_LIST_PERMISSION,
  TEMPLATE_UPDATE_PERMISSION,
  TEMPLATE_GET_PERMISSION,
} from "../../hooks/useAuthorized";
import ListMasterTemplates from "./List";
import CreateEditViewMasterTemplate from "./Single";

function MasterTemplatesPage() {
  const match = useRouteMatch();

  const canList = useAuthorized(TEMPLATE_LIST_PERMISSION);
  const canEdit = useAuthorized(TEMPLATE_UPDATE_PERMISSION);
  const canView = useAuthorized(TEMPLATE_GET_PERMISSION);
  const canCreate = useAuthorized(TEMPLATE_CREATE_PERMISSION);

  return (
    <Switch>
      {canCreate ? (
        <Route path={`${match.path}/create`}>
          <CreateEditViewMasterTemplate />
        </Route>
      ) : null}
      {canEdit || canView ? (
        <Route path={`${match.path}/:masterTemplateId`}>
          <CreateEditViewMasterTemplate isEditingView={true} />
        </Route>
      ) : null}
      {canList || canCreate ? (
        <Route path={`${match.path}`}>
          <ListMasterTemplates />
        </Route>
      ) : null}
      <Redirect to={`${match.path}`} />
    </Switch>
  );
}

export default memo(MasterTemplatesPage);
