import React, { memo } from "react";
import { useParams } from "react-router-dom";
import FillData from "../FillData";
import { Page } from "../../../../components";

function ViewInbound() {
  const { templateId } = useParams();
  return (
    <Page p={2}>
      <FillData templateId={templateId}></FillData>
    </Page>
  );
}

export default memo(ViewInbound);
