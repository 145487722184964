import { batch } from "react-redux";
import * as api from "../../api/recipientLists";
import { setStorageFields } from "../storage";

export const CREATE_NEW_RECIPIENT_LIST = "CREATE_NEW_RECIPIENT_LIST";
export const BUILD_NEW_RECIPIENT_LIST = "BUILD_NEW_RECIPIENT_LIST";
export const CLEAR_NEW_RECIPIENT_LIST = "CLEAR_NEW_RECIPIENT_LIST";
export const GET_MASTER_LIST_HEADERS = "GET_MASTER_LIST_HEADERS";

export function createNewRecipientList({ force, redirect } = {}) {
  return (dispatch, getState) => {
    const {
      user,
      eonsConfig: { vendorConfig },
    } = getState();
    const { disableUserPermissions = [] } = vendorConfig;
    const defaultRecipientListPermission = disableUserPermissions.includes(
      "recipientList"
    )
      ? "division"
      : "user";

    batch(() => {
      dispatch(setStorageFields({ savingRecipientList: {} }));
      dispatch({
        type: CREATE_NEW_RECIPIENT_LIST,
        forceCreate: force,
        newRecipientListData: {
          permission: defaultRecipientListPermission,
          doneStepIdx: -1,
          activeTabIdx: 0,
          activeStepIdx: 0,
          errors: {},
          recipientHeaders: {},
          recipients: {
            data: [],
            totalCount: 0,
          },
          totalCount: 0,
          failedCount: 0,
          emailCount: 0,
          customCount: 0,
          smsCount: 0,
          voiceCount: 0,
          ttyCount: 0,
          failRecipients: [],
          userId: user.id,
          redirect,
        },
      });
    });
  };
}

export function saveNewRecipientList() {
  return async (dispatch, getState) => {
    const { newRecipientList, storage } = getState();
    const { activeDivisionId, paid } = storage;
    const { name, permission, recipientHeaders } = newRecipientList;

    // save the recipient list metadaata
    try {
      const recipientList = await api.createRecipientList(
        { name, permission, recipientHeaders, paid },
        {
          activeDivisionId,
        }
      );
      batch(() => {
        // add the _id, creation date and permission details, and status to the new recipient list
        dispatch(buildNewRecipientList(recipientList));
        // save the saving list id to the user scoped localStorage
        // this way we can warn the user about the aborted save if they come back after a crash
        dispatch(
          setStorageFields({
            savingRecipientList: {
              _id: recipientList._id,
              name: recipientList.name,
              status: recipientList.status,
              ...newRecipientList,
            },
          })
        );
      });
      return recipientList._id;
    } catch (err) {
      // return here without starting to upload the recipient chunks if the API validation failed
      // the user has to correct any errors and fill required fields before uploading chunks
      dispatch(
        buildNewRecipientList({
          errors: err?.response?.data,
        })
      );
      throw err;
    }
  };
}

export function clearNewRecipientList(keepParams) {
  return (dispatch) => {
    batch(() => {
      // clear the list id from localStorage, we don't have to warn the user about an aborted list upload
      // because the list is cleanly deleted
      dispatch(setStorageFields({ savingRecipientList: undefined }));
      if (keepParams?.keepRedirect) {
        return dispatch({
          type: CLEAR_NEW_RECIPIENT_LIST,
          keepRedirect: keepParams.keepRedirect,
        });
      }
      return dispatch({ type: CLEAR_NEW_RECIPIENT_LIST });
    });
  };
}

export function buildNewRecipientList(newRecipientListData) {
  return { type: BUILD_NEW_RECIPIENT_LIST, newRecipientListData };
}

export function getMasterListHeadersAction(recipientHeaders) {
  return { type: GET_MASTER_LIST_HEADERS, recipientHeaders };
}

export * from "./uploadNewRecipientList";
export * from "./filterNewRecipientList";
