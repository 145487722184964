import React, { memo } from "react";
import ReactAudioPlayer from "react-audio-player";
import { Grid, Chip } from "../../../components";
import { baseURL } from "../../../api/api";

const voiceBasePath = `${baseURL}/api/v1/voice_service`;

export const createFilePath = (path) => {
  const finalPath = `${voiceBasePath}/assets?path=${encodeURIComponent(path)}`;
  return finalPath;
};

const renderData = ({
  stepType,
  variableName,
  path,
  _id,
  step,
  transcript,
}) => {
  if (stepType === "customerData" && variableName) {
    return <Chip label={variableName} />;
  } else if (stepType === "dtmf") {
    return <Chip label="dtmf" />;
  } else if (stepType === "ttsData") {
    return <Chip label={transcript} />;
  } else if (stepType === "goto") {
    return <Chip label={`Direction (${step})`} />;
  } else {
    return <ReactAudioPlayer src={createFilePath(path)} controls />;
  }
};

function VoicePreview({ voiceArray = [] }) {
  return (
    <Grid container spacing={2} alignItems="center">
      {voiceArray.map((data) => (
        <Grid item key={data._id}>
          {renderData(data)}
        </Grid>
      ))}
    </Grid>
  );
}

export default memo(VoicePreview);
