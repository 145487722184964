import { SET_EONS_CONFIG } from "../actions/eons";

export default function eonsConfig(config = null, { type, newConfig }) {
  switch (type) {
    case SET_EONS_CONFIG:
      return newConfig;
    default:
      return config;
  }
}
