import React, { useMemo, useState, useCallback, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sub, endOfDay, startOfDay, format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { Page, SmartTable, useMediaQuery } from "../../../components";
import { listSeeds, clearSeeds } from "../../../actions/seeds";
import { setParams } from "../../../actions/params";
import Filters from "./Filters";
import Actions from "./Actions";
import DeleteDialog from "../../../components/DeleteDialog";
import { deleteSeed } from "../../../actions/seed";
import useAuthorized, {
  SEED_UPDATE_PERMISSION,
  SEED_DELETE_PERMISSION,
  SEED_GET_PERMISSION,
} from "../../../hooks/useAuthorized";

const getColumns = ({ t, onOpenDeleteDialog, authorizedToDoAnyAction }) => {
  const seedColums = [
    {
      name: "name",
      label: t("Name"),
      sortable: true,
      width: 2,
      testId: "seed-name",
    },
    {
      name: "createdByName",
      label: t("Created By"),
      sortable: true,
      testId: "seed-createdBy",
    },
    {
      name: "createdAt",
      getter: (seed) => format(new Date(seed.createdAt), "MM/dd/yyyy"),
      label: t("Created At"),
      sortable: true,
      testId: "seed-createdAt",
    },
    {
      name: "modifiedAt",
      getter: (seed) => format(new Date(seed.modifiedAt), "MM/dd/yyyy"),
      label: t("Modified At"),
      sortable: true,
      testId: "seed-modifiedAt",
    },
  ];

  if (authorizedToDoAnyAction) {
    return seedColums.concat([
      {
        name: "actions",
        getter: (seed) => (
          <Actions testId="seed" seed={seed} onDelete={onOpenDeleteDialog} />
        ),
        label: t("Actions"),
        sortable: false,
        align: "center",
      },
    ]);
  } else {
    return seedColums;
  }
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 800,
  },
  smallCell: {
    minWidth: 110,
    maxWidth: 150,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

function getParams(queryParams, activeDivisionId) {
  // pick the usable params, do not include extra params that are not used by this page
  const {
    search,
    fromDate,
    toDate,
    page,
    itemsPerPage,
    orderBy,
    order,
    permission,
  } = queryParams;

  // add default values to the usable params
  // the end of the current day to include the whole day as the user would expect
  const defaultToDate = endOfDay(Date.now()).getTime();
  // the start of the day to include the whole day as the user would expect
  const defaultFromDate = startOfDay(
    sub(defaultToDate, { months: 2 })
  ).getTime();

  return {
    search: search ?? "",
    permission,
    fromDate: fromDate ?? defaultFromDate,
    toDate: toDate ?? defaultToDate,
    page: page ?? 0,
    itemsPerPage: itemsPerPage ?? 20,
    orderBy: orderBy ?? "createdDate",
    order: order ?? -1,
    activeDivisionId,
  };
}

const getResource = (t) => t("Seeds");

function SeedsPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [seedToDelete, setSeedToDelete] = useState();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const resource = useMemo(() => getResource(t), [t]);

  const seeds = useSelector((state) => state.seeds);
  const queryParams = useSelector((state) => state.params);
  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );

  // fill in missing query params with default values and the active division id
  // the default values are not persisted in the query string for a better user experience
  const params = useMemo(() => getParams(queryParams, activeDivisionId), [
    queryParams,
    activeDivisionId,
  ]);

  // clean up global data and params when the user leaves the page
  useEffect(
    // return a function which runs at component unmount
    () => () => dispatch(clearSeeds()),
    [dispatch]
  );

  // deleting related handlers
  const onOpenDeleteDialog = useCallback((seed) => setSeedToDelete(seed), [
    setSeedToDelete,
  ]);

  const onCloseDeleteDialog = useCallback(() => setSeedToDelete(null), [
    setSeedToDelete,
  ]);

  const onDelete = useCallback(async () => {
    await dispatch(deleteSeed(seedToDelete, { activeDivisionId }));
    await dispatch(listSeeds(params));
    onCloseDeleteDialog();
  }, [dispatch, onCloseDeleteDialog, seedToDelete, params, activeDivisionId]);

  const canEdit = useAuthorized(SEED_UPDATE_PERMISSION);
  const canDelete = useAuthorized(SEED_DELETE_PERMISSION);
  const canView = useAuthorized(SEED_GET_PERMISSION);

  const authorizedToDoAnyAction = canEdit || canDelete || canView;

  // memoize the column getter for a perf boost
  const columns = useMemo(
    () => getColumns({ t, onOpenDeleteDialog, authorizedToDoAnyAction }),
    [t, onOpenDeleteDialog, authorizedToDoAnyAction]
  );

  // refetch the master templates whenever the params change
  useEffect(() => {
    dispatch(listSeeds(params));
  }, [dispatch, params]);

  // keep master templates defining primitive params in state.params which is reflected in the URL query params
  const onPageChange = useCallback(
    (ev, page) => dispatch(setParams({ page })),
    [dispatch]
  );

  const onItemsPerPageChange = useCallback(
    (ev) => dispatch(setParams({ itemsPerPage: ev.target.value })),
    [dispatch]
  );

  // reset params.page to 0 whenever a none page related param is modified
  // this is the usual expected behavior by users
  const onSearchChange = useCallback(
    (search) => dispatch(setParams({ search, page: 0 })),
    [dispatch]
  );

  const onFromDateChange = useCallback(
    (fromDate) =>
      dispatch(setParams({ fromDate: fromDate.getTime(), page: 0 })),
    [dispatch]
  );

  const onToDateChange = useCallback(
    (toDate) => dispatch(setParams({ toDate: toDate.getTime(), page: 0 })),
    [dispatch]
  );

  const onSortChange = useCallback(
    (orderBy, order) => dispatch(setParams({ orderBy, order, page: 0 })),
    [dispatch]
  );

  return (
    <Page p={2} screenSized={!isSmallScreen}>
      <SmartTable
        filters={
          <Filters
            params={params}
            onSearchChange={onSearchChange}
            onFromDateChange={onFromDateChange}
            onToDateChange={onToDateChange}
            testId="seed"
          />
        }
        className={classes.table}
        columns={columns}
        data={seeds?.data}
        totalCount={seeds?.totalCount}
        notFoundMessage={t("No seeds found for your filters")}
        orderBy={params.orderBy}
        order={params.order}
        page={params.page}
        itemsPerPage={params.itemsPerPage}
        onSortChange={onSortChange}
        onPageChange={onPageChange}
        onItemsPerPageChange={onItemsPerPageChange}
        testId="seed"
      />
      <DeleteDialog
        open={Boolean(seedToDelete)}
        onClose={onCloseDeleteDialog}
        onDelete={onDelete}
        name={seedToDelete?.name}
        resource={resource}
      />
    </Page>
  );
}

export default memo(SeedsPage);
