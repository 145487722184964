import React, { useState, useCallback, memo } from "react";
import { Alert } from "@material-ui/lab";
import { Snackbar, Slide } from "@material-ui/core";

const SlideTransition = (props) => <Slide {...props} direction="up" />;

function Notification({ notification, ...rest }) {
  const [isOpen, setOpen] = useState(true);
  const onClose = useCallback(() => setOpen(false), [setOpen]);

  return (
    <Snackbar
      TransitionComponent={SlideTransition}
      key={notification.message}
      open={isOpen}
      autoHideDuration={notification.timeout}
    >
      <Alert
        {...rest}
        elevation={6}
        variant="filled"
        severity={notification.type}
        onClose={onClose}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
}

export default memo(Notification);
