import { useSelector } from "react-redux";

function hasPermission(requiredPermission, userPermissionsInGroup) {
  if (userPermissionsInGroup && userPermissionsInGroup.length) {
    return !!userPermissionsInGroup.find(
      (userPermission) => userPermission === requiredPermission
    );
  }
  return false;
}

const EVERY_DIVISION_PERMISSION_SHORTKEY = "null";
export function useAuthorized(requiredPermission) {
  const permissions = useSelector((state) =>
    state.user ? state.user.permissions : null
  );
  const activeDivisionId = useSelector((state) =>
    state.storage ? state.storage.activeDivisionId : null
  );

  let isAuthorized = false;

  if (permissions === null || activeDivisionId === null) {
    return isAuthorized; // Default state is false
  }

  const everyDivisionApplicablePermissions =
    permissions[EVERY_DIVISION_PERMISSION_SHORTKEY];
  if (everyDivisionApplicablePermissions) {
    isAuthorized = hasPermission(
      requiredPermission,
      everyDivisionApplicablePermissions
    );
  }

  const actualDivisionApplicablePermissions = permissions[activeDivisionId];
  if (!isAuthorized && actualDivisionApplicablePermissions) {
    isAuthorized = hasPermission(
      requiredPermission,
      actualDivisionApplicablePermissions
    );
  }

  return isAuthorized;
}

// Not implemented
// export const CAMPAIGN_UPDATE_PERMISSION = "eons.campaign.update";
// export const CAMPAIGN_GET_PERMISSION = "eons.campaign.get";
// export const CAMPAIGN_DELETE_PERMISSION = "eons.campaign.delete";
// export const CAMPAIGN_CREATE_PERMISSION = "eons.campaign.create";
// export const CAMPAIGN_LIST_PERMISSION = "eons.campaign.list";
// export const CAMPAIGN_STOP_PERMISSION = "eons.campaign.stop";

export const RECIPIENTLIST_UPDATE_PERMISSION = "eons.recipientlist.update";
export const RECIPIENTLIST_GET_PERMISSION = "eons.recipientlist.get";
export const RECIPIENTLIST_DELETE_PERMISSION = "eons.recipientlist.delete";
export const RECIPIENTLIST_CREATE_PERMISSION = "eons.recipientlist.create";
export const RECIPIENTLIST_LIST_PERMISSION = "eons.recipientlist.list";
export const RECIPIENTLIST_MAP_PERMISSION = "eons.recipientlist.create.map";
export const RECIPIENTLIST_MASTERDATA_PERMISSION =
  "eons.recipientlist.create.masterdata";
export const RECIPIENTLIST_DOWNLOAD_PERMISSION = "eons.recipientlist.download";

export const TEMPLATE_UPDATE_PERMISSION = "eons.template.update";
export const TEMPLATE_GET_PERMISSION = "eons.template.get";
export const TEMPLATE_DELETE_PERMISSION = "eons.template.delete";
export const TEMPLATE_CREATE_PERMISSION = "eons.template.create";
export const TEMPLATE_CREATE_CUSTOMER_DATA_PERMISSION =
  "eons.template.create.customerdata";
export const TEMPLATE_LIST_PERMISSION = "eons.template.list";

export const REPORT_LIST_PERMISSION = "eons.report.list";
export const REPORT_GET_PERMISSION = "eons.report.get";
export const REPORT_DOWNLOAD_PERMISSION = "eons.report.download";

export const MAP_LIST_PERMISSION = "eons.map.list";
export const MAP_CREATE_PERMISSION = "eons.map.create";
export const MAP_DELETE_PERMISSION = "eons.map.delete";

export const CALENDAR_LIST_PERMISSION = "eons.calendar.list";

export const APPROVER_UPDATE_PERMISSION = "eons.approver.update";
export const APPROVER_GET_PERMISSION = "eons.approver.get";
export const APPROVER_DELETE_PERMISSION = "eons.approver.delete";
export const APPROVER_CREATE_PERMISSION = "eons.approver.create";
export const APPROVER_LIST_PERMISSION = "eons.approver.list";

export const SEED_UPDATE_PERMISSION = "eons.seed.update";
export const SEED_GET_PERMISSION = "eons.seed.get";
export const SEED_DELETE_PERMISSION = "eons.seed.delete";
export const SEED_CREATE_PERMISSION = "eons.seed.create";
export const SEED_LIST_PERMISSION = "eons.seed.list";

export const CAMPAIGN_UPDATE_PERMISSION = "eons.campaign.update";
export const CAMPAIGN_GET_PERMISSION = "eons.campaign.get";
export const CAMPAIGN_DELETE_PERMISSION = "eons.campaign.delete";
export const CAMPAIGN_CREATE_PERMISSION = "eons.campaign.create";
export const CAMPAIGN_LIST_PERMISSION = "eons.campaign.list";

export const SUPPRESSION_CREATE_PERMISSION = "eons.suppression.create";
export const SUPPRESSION_DELETE_PERMISSION = "eons.suppression.delete";
export const SUPPRESSION_LIST_PERMISSION = "eons.suppression.list";

export const INBOUND_LIST_PERMISSION = "eons.inbound.list";
export const INBOUND_CREATE_PERMISSION = "eons.inbound.create";

export const KEYWORD_LIST_PERMISSION = "eons.keyword.list";
export const KEYWORD_GET_PERMISSION = "eons.keyword.get";
export const KEYWORD_CREATE_PERMISSION = "eons.keyword.create";
export const KEYWORD_UPDATE_PERMISSION = "eons.keyword.update";
export const KEYWORD_DELETE_PERMISSION = "eons.keyword.delete";

export const ADMIN_LIST_PERMISSION = "eons.admin.list";
export const ADMIN_GET_PERMISSION = "eons.admin.get";
export const ADMIN_CREATE_PERMISSION = "eons.admin.create";
export const ADMIN_UPDATE_PERMISSION = "eons.admin.update";
export const ADMIN_DELETE_PERMISSION = "eons.admin.delete";

export default useAuthorized;
