import { LIST_KEYWORDS, CLEAR_KEYWORDS } from "../actions/keywords";

export default function keywordReducer(
  keywordList = null,
  { type, keywordList: newKeywordList }
) {
  switch (type) {
    case LIST_KEYWORDS:
      return newKeywordList;
    case CLEAR_KEYWORDS:
      return null;
    default:
      return keywordList;
  }
}
