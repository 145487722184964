import React, { useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setStorageFields } from "../../actions/storage";
import { deleteRecipientList } from "../../actions/recipientList";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "../../components";

function RecipientListAbortConfirmDialog({
  onClose,
  onDelete,
  open,
  name,
  ...rest
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // TODO: Need to take a look at this feature, might not be relevant anymore
  // const abortedRecipientList = useSelector(
  //   (state) => !state.newRecipientList && state.storage.savingRecipientList
  // );
  const abortedRecipientList = false;

  const onDeleteConfirm = useCallback(async () => {
    await dispatch(deleteRecipientList(abortedRecipientList));
    dispatch(setStorageFields({ savingRecipientList: undefined }));
  }, [dispatch, abortedRecipientList]);

  return (
    <Dialog
      {...rest}
      open={Boolean(abortedRecipientList)}
      onClose={onDeleteConfirm}
    >
      <DialogTitle>{t("Aborted Recipient List")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            // eslint-disable-next-line max-len
            "You closed your tab while uploading {{name}}. The incomplete list will be deleted now, please start the process again.",
            { name: abortedRecipientList?.name ?? t("a recipient list") }
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-test-id="recipientList-abort-upload-confirm-button"
          color="secondary"
          onClick={onDeleteConfirm}
        >
          {t("Okay")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(RecipientListAbortConfirmDialog);
