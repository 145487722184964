import React, { useCallback, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  Paper,
  TextField,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Button,
  InputAdornment,
  IconButton,
} from "../../../../../components";
import HeaderSelectorItem from "./HeaderSelectorItem";
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  select: {
    margin: theme.spacing(1),
  },
  listIcon: {
    minWidth: 0,
  },
  error: {
    whiteSpace: "normal",
    color: theme.palette.error.main,
  },
  toggleButton: {
    marginTop: "auto",
  },
}));

function HeaderSelector({
  header,
  headerMapping,
  requiredHeaders,
  optionalHeaders,
  rows,
  disabled,
  onToggle,
  onHeaderChange,
  errors,
}) {
  const { t } = useTranslation();
  const classes = useStyles({ disabled });
  const mappedHeader = headerMapping[header];

  const onToggleClick = useCallback(() => onToggle(header), [onToggle, header]);
  const selectedValues = Object.values(headerMapping);
  const onHeaderSelect = useCallback(
    (ev) => onHeaderChange({ header, mappedHeader: ev.target.value }),
    [header, onHeaderChange]
  );

  const error = errors[header];
  const hasError = Boolean(error);
  const onClearClick = () => onHeaderChange({ header, mappedHeader: null });

  return (
    <Paper error={hasError} className={classes.root}>
      <TextField
        disabled={disabled}
        select
        label={header}
        size="small"
        variant="outlined"
        className={classes.select}
        value={mappedHeader || ""}
        name={header}
        onChange={onHeaderSelect}
        error={hasError}
        helperText={error}
        InputProps={{
          endAdornment: (
            mappedHeader &&
            <InputAdornment style={{ paddingRight: 20 }} position="end">
              <IconButton
                aria-label="clear selection"
                onClick={onClearClick}
                edge="end"
                size="sm"
                disabled={disabled}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}

      >
        <ListSubheader>{t("Original Header")}</ListSubheader>
        <MenuItem value="">
          <em>{header}</em>
        </MenuItem>
        <ListSubheader>{t("Required Headers")}</ListSubheader>
        {requiredHeaders
          .filter((requiredHeader) =>
            !selectedValues.includes(requiredHeader.name) ||
            requiredHeader.name === mappedHeader
          )
          .map((requiredHeader) => (
            <MenuItem key={requiredHeader.name} value={requiredHeader.name}>
              <HeaderSelectorItem
                header={requiredHeader}
                error={errors[requiredHeader.name]}
              />
            </MenuItem>
          ))}
        <ListSubheader>{t("Optional Headers")}</ListSubheader>
        {optionalHeaders
          .filter((requiredHeader) =>
            !selectedValues.includes(requiredHeader.name) ||
            requiredHeader.name === mappedHeader
          )
          .map((optionalHeader) => (
            <MenuItem key={optionalHeader.name} value={optionalHeader.name}>
              <HeaderSelectorItem
                header={optionalHeader}
                error={errors[optionalHeader.name]}
              />
            </MenuItem>
          ))}
      </TextField>
      <List
        dense
        className={classNames(classes.list, { "Mui-disabled": disabled })}
      >
        {rows.map((row, idx) => (
          <ListItem key={idx}>
            <ListItemText primary={row[header] || "-"} />
          </ListItem>
        ))}
      </List>
      <Button
        onClick={onToggleClick}
        size="small"
        color="primary"
        className={classes.toggleButton}
      >
        {disabled ? "Include" : "Exclude"}
      </Button>
    </Paper>
  );
}

export default memo(HeaderSelector);
