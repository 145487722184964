import { omit } from "lodash";
import {
  CREATE_NEW_RECIPIENT_LIST,
  BUILD_NEW_RECIPIENT_LIST,
  CLEAR_NEW_RECIPIENT_LIST,
  MAP_RECIPIENT_HEADER,
} from "../actions/newRecipientList";

export default function (
  newRecipientList = null,
  {
    type,
    newRecipientListData,
    originalHeader,
    mappedHeader,
    forceCreate,
    keepRedirect,
  }
) {
  switch (type) {
    case CREATE_NEW_RECIPIENT_LIST: {
      if (forceCreate) {
        return {
          ...newRecipientListData,
        };
      }
      return {
        ...newRecipientList,
        ...newRecipientListData,
      };
    }
    case BUILD_NEW_RECIPIENT_LIST:
      return { ...newRecipientList, ...newRecipientListData };
    case CLEAR_NEW_RECIPIENT_LIST: {
      if (keepRedirect) {
        return { redirect: true };
      }
      return null;
    }
    case MAP_RECIPIENT_HEADER:
      return {
        ...newRecipientList,
        errors: omit(newRecipientList.errors, originalHeader, mappedHeader),
        headerMapping: {
          ...newRecipientList.headerMapping,
          [originalHeader]: mappedHeader,
        },
      };
    default:
      return newRecipientList;
  }
}
