import React, {
  useMemo,
  useCallback,
  useEffect,
  useImperativeHandle,
  memo,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sub, endOfDay, startOfDay, format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { SmartTable } from "../../../components";
import { remove } from "lodash";
import {
  listMasterTemplates,
  clearMasterTemplates,
  listMasterTemplatesWithCompletedLockedStatus,
} from "../../../actions/masterTemplates";
import { setParams } from "../../../actions/params";
import Filters from "./Filters";
import ChannelIndicator from "./ChannelIndicator";

const getColumns = ({ t, extraColumns, isInboundTemplate = false }) => {
  let columns = [
    {
      name: "name",
      getter: (list) => list.name || "--",
      label: t("Name"),
      sortable: true,
      testId: "template-name",
      width: 2,
    },
    {
      name: "createdByName",
      label: t("Created By"),
      testId: "template-createdBy",
      sortable: true,
    },
    {
      name: "createdDate",
      getter: (list) => format(new Date(list.createdDate), "MM/dd/yyyy"),
      label: t("Created At"),
      testId: "template-createdAt",
      sortable: true,
    },
    {
      name: "updatedDate",
      getter: (list) =>
        list.updatedDate
          ? format(new Date(list.updatedDate), "MM/dd/yyyy")
          : "-",
      label: t("Last Updated"),
      testId: "template-updatedAt",
      sortable: true,
    },
    {
      name: "permission",
      label: t("Permission"),
      testId: "template-permission",
      sortable: true,
    },
  ];
  let templateIndicatorCol = [
    {
      name: "emailTemplateId",
      label: t("Email"),
      getter: (masterTemplate) => (
        <ChannelIndicator
          subTemplateId={masterTemplate.emailTemplateId}
          testId={`${masterTemplate._id}`}
        ></ChannelIndicator>
      ),
      sortable: true,
      testId: "template-email",
      width: 0.7,
      align: "center",
    },
    {
      name: "smsTemplateId",
      label: t("SMS"),
      getter: (masterTemplate) => (
        <ChannelIndicator
          subTemplateId={masterTemplate.smsTemplateId}
          testId={`${masterTemplate._id}`}
        ></ChannelIndicator>
      ),
      sortable: true,
      testId: "template-sms",
      width: 0.7,
      align: "center",
    },
    {
      name: "voiceTemplateId",
      label: t("Voice"),
      getter: (masterTemplate) => (
        <ChannelIndicator
          subTemplateId={masterTemplate.voiceTemplateId}
          testId={`${masterTemplate._id}`}
        ></ChannelIndicator>
      ),
      sortable: true,
      testId: "template-voice",
      width: 0.7,
      align: "center",
    },
    {
      name: "ttyTemplateId",
      label: t("TTY"),
      getter: (masterTemplate) => (
        <ChannelIndicator
          subTemplateId={masterTemplate.ttyTemplateId}
          testId={`${masterTemplate._id}`}
        ></ChannelIndicator>
      ),
      sortable: true,
      testId: "template-tty",
      width: 0.7,
      align: "center",
    },
    {
      name: "inboundTemplateId",
      label: t("Inbound"),
      getter: (masterTemplate) => (
        <ChannelIndicator
          subTemplateId={masterTemplate.inboundTemplateId}
          testId={`${masterTemplate._id}`}
        ></ChannelIndicator>
      ),
      sortable: true,
      testId: "template-inbound",
      width: 0.7,
      align: "center",
    },
    // {
    //   name: "customTemplateId",
    //   label: t("Custom"),
    //   getter: (masterTemplate) => (
    //     <ChannelIndicator
    //       subTemplateId={masterTemplate.customTemplateId}
    //       testId={`${masterTemplate._id}`}
    //     ></ChannelIndicator>
    //   ),
    //   sortable: true,
    //   testId: "template-custom",
    //   width: 0.7,
    //   align: "center",
    // },
  ];

  if (isInboundTemplate) {
    return [...columns, ...extraColumns];
  } else {
    return [...columns, ...templateIndicatorCol, ...extraColumns];
  }
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1190,
  },
  smallCell: {
    minWidth: 110,
    maxWidth: 150,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

function getParams(queryParams, activeDivisionId, status, onlyType) {
  // pick the usable params, do not include extra params that are not used by this page
  const {
    search,
    fromDate,
    toDate,
    page,
    itemsPerPage,
    orderBy,
    order,
    permission,
    headerFields = [],
    searchContent,
    searchType,
  } = queryParams;

  // add default values to the usable params
  // the end of the current day to include the whole day as the user would expect
  const defaultToDate = endOfDay(Date.now()).getTime();
  // the start of the day to include the whole day as the user would expect
  const defaultFromDate = startOfDay(
    sub(defaultToDate, { months: 12 })
  ).getTime();

  return {
    search: search ?? "",
    searchContent: searchContent ?? "",
    searchType: searchType ?? "",
    permission,
    fromDate: fromDate ?? defaultFromDate,
    toDate: toDate ?? defaultToDate,
    page: page ?? 0,
    itemsPerPage: itemsPerPage ?? 20,
    orderBy: orderBy ?? "createdDate",
    order: order ?? -1,
    activeDivisionId,
    status,
    headerFields,
    templateType: onlyType
      ? onlyType
      : ["voice", "sms", "inbound", "tty", "email", "custom"],
  };
}

function MasterTemplatesPageBase(
  {
    extraColumns = [],
    onlyStatus,
    hideCreate,
    disableTable,
    isInboundTemplate = false,
    onlyType,
  },
  ref
) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const masterTemplates = useSelector((state) => state.masterTemplates);
  const queryParams = useSelector((state) => state.params);
  const { vendorConfig: eonsConfig } = useSelector((state) => state.eonsConfig);
  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );

  // fill in missing query params with default values and the active division id
  // the default values are not persisted in the query string for a better user experience
  const params = useMemo(
    () => getParams(queryParams, activeDivisionId, onlyStatus, onlyType),
    [queryParams, activeDivisionId, onlyStatus, onlyType]
  );

  // clean up global data and params when the user leaves the page
  useEffect(
    // return a function which runs at component unmount
    () => () => dispatch(clearMasterTemplates()),
    [dispatch]
  );

  // memoize the column getter for a perf boost
  const columns = useMemo(
    () => getColumns({ t, extraColumns, isInboundTemplate }),
    [t, extraColumns, isInboundTemplate]
  );

  //Removing/Showing TTY Channel column as per feature flag
  if (!eonsConfig.tty) {
    const ttyColumn = remove(columns, (item) => {
      return item.name === "ttyTemplateId";
    });
  }

  // refetch the master templates whenever the params change
  useEffect(() => {
    dispatch(listMasterTemplatesWithCompletedLockedStatus(params));
  }, [dispatch, params]);

  useImperativeHandle(ref, () => ({
    listMasterTemplates: () => dispatch(listMasterTemplates(params)),
  }));

  // keep master templates defining primitive params in state.params which is reflected in the URL query params
  const onPageChange = useCallback(
    (ev, page) => dispatch(setParams({ page })),
    [dispatch]
  );

  const onItemsPerPageChange = useCallback(
    (ev) => dispatch(setParams({ itemsPerPage: ev.target.value })),
    [dispatch]
  );

  // reset params.page to 0 whenever a none page related param is modified
  // this is the usual expected behavior by users
  const onSearchChange = useCallback(
    (search) => dispatch(setParams({ search, page: 0 })),
    [dispatch]
  );

  const onSearchContentChange = useCallback(
    (searchContent) => dispatch(setParams({ searchContent, page: 0 })),
    [dispatch]
  );

  const onSearchTypeChange = useCallback(
    (ev) => dispatch(setParams({ searchType: ev.target.value })),
    [dispatch]
  );

  const onPermissionChange = useCallback(
    (ev) => dispatch(setParams({ permission: ev.target.value, page: 0 })),
    [dispatch]
  );

  const onFromDateChange = useCallback(
    (fromDate) =>
      dispatch(setParams({ fromDate: fromDate.getTime(), page: 0 })),
    [dispatch]
  );

  const onToDateChange = useCallback(
    (toDate) => dispatch(setParams({ toDate: toDate.getTime(), page: 0 })),
    [dispatch]
  );

  const onSortChange = useCallback(
    (orderBy, order) => dispatch(setParams({ orderBy, order, page: 0 })),
    [dispatch]
  );

  const onHeaderFieldsChange = useCallback(
    (headerFields = []) => dispatch(setParams({ headerFields, page: 0 })),
    [dispatch]
  );

  return (
    <SmartTable
      filters={
        <Filters
          params={params}
          onSearchChange={onSearchChange}
          onPermissionChange={onPermissionChange}
          onFromDateChange={onFromDateChange}
          onToDateChange={onToDateChange}
          onHeaderFieldsChange={onHeaderFieldsChange}
          hideCreate={hideCreate}
          onSearchContentChange={onSearchContentChange}
          onSearchTypeChange={onSearchTypeChange}
        />
      }
      className={classes.table}
      columns={columns}
      data={masterTemplates?.data}
      totalCount={masterTemplates?.totalCount}
      notFoundMessage={t("No master templates found for your filters")}
      orderBy={params.orderBy}
      order={params.order}
      page={params.page}
      itemsPerPage={params.itemsPerPage}
      onSortChange={onSortChange}
      onPageChange={onPageChange}
      onItemsPerPageChange={onItemsPerPageChange}
      testId="template"
      style={{ display: disableTable ? "none" : "table" }}
    />
  );
}

export default memo(forwardRef(MasterTemplatesPageBase));
