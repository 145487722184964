import React, { memo, useMemo, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { SmartTable, ActionButton, Grid, Search } from "../../../components";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { getParams } from "../../../utils";

import { Link, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1190,
  },
  smallCell: {
    minWidth: 110,
    maxWidth: 150,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

const getColumns = ({ t, extraColumns = [], type }) => {
  let typeBasedColumns = [
    {
      name: "to",
      label: t("To"),
      sortable: true,
      width: 2,
      testId: "suppressionList-to",
    },
    {
      name: "shortcode",
      label: t("Shortcode"),
      sortable: true,
      align: "center",
      testId: "suppressionList-shortCode",
    },
  ];
  if (type == "email") {
    typeBasedColumns = [
      {
        name: "domainAddress",
        label: t("From Email"),
        sortable: true,
        width: 2,
        testId: "suppressionList-from",
      },
      {
        name: "to",
        label: t("To Email"),
        sortable: true,
        align: "center",
        testId: "suppressionList-to-email",
      },
    ];
  }
  if (type == "voice") {
    typeBasedColumns = [
      {
        name: "to",
        label: t("To"),
        sortable: true,
        width: 2,
        testId: "suppressionList-to",
      },
      {
        name: "callerId",
        label: t("Caller ID"),
        sortable: true,
        align: "center",
        testId: "suppressionList-callerId",
      },
    ];
  }
  return [
    ...typeBasedColumns,
    {
      name: "timestamp",
      label: t("Created At"),
      getter: (list) =>
        list.timestamp
          ? format(new Date(list.timestamp), "MM/dd/yyyy HH:mm")
          : "-",
      sortable: true,
      align: "center",
      testId: "suppressionList-createAt",
    },
    {
      name: "createdBy",
      label: t("Created By"),
      sortable: true,
      getter: (list) => (list.createdBy ? list.createdBy : "-"),
      align: "center",
      testId: "suppressionList-createBy",
    },
    ...extraColumns,
  ];
};

function SuppressionListsPageBase(
  {
    extraColumns,
    onlyStatus,
    disableTable,
    suppressionList,
    canCreate,
    onItemsPerPageChange,
    onSearchChange,
    onPageChange,
    suppressionCount,
    type,
  },
  ref
) {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const queryParams = useSelector((state) => state.params);

  const columns = useMemo(() => getColumns({ t, extraColumns, type }), [
    t,
    extraColumns,
    type,
  ]);

  const params = useMemo(() => getParams(queryParams), [queryParams]);

  const totalCount = suppressionCount;
  const suppressTxtMap = {
    email: {
      addBtnTxt: "Suppress New Email",
      noResultTxt: "No suppressed email found for your filters",
    },
    voice: {
      addBtnTxt: "Suppress New Phone",
      noResultTxt: "No suppressed phone found for your filters",
    },
    sms: {
      addBtnTxt: "Suppress New Number",
      noResultTxt: "No suppressed number found for your filters",
    },
  };

  return (
    <SmartTable
      filters={
        <Grid container spacing={2} justify="space-between">
          <Grid item>
            <Grid container spacing={2}>
              <ActionButton
                text={t(suppressTxtMap[type]?.addBtnTxt)}
                disabled={!canCreate}
                component={Link}
                to={`${match.path}/create?type=${type}`}
                data-test-id={"suppression-create-new"}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Search
                  onSearch={onSearchChange}
                  variant="outlined"
                  size="small"
                  label={t("Search")}
                  defaultValue={params.search}
                  debounce={300}
                  fullWidth
                  testId="searchSuppression"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      className={classes.table}
      columns={columns}
      data={suppressionList}
      totalCount={totalCount}
      notFoundMessage={t(suppressTxtMap[type]?.noResultTxt)}
      // orderBy={params.orderBy}
      // order={params.order}
      page={params.page}
      itemsPerPage={params.itemsPerPage}
      onSortChange={() => {}}
      onPageChange={onPageChange}
      onItemsPerPageChange={onItemsPerPageChange}
      testId="suppressionList"
      style={{ display: disableTable ? "none" : "table" }}
    />
  );
}

export default memo(forwardRef(SuppressionListsPageBase));
