import { SET_USER } from "../actions/user";

export default function userReducer(user = null, { type, newUser }) {
  switch (type) {
    case SET_USER:
      return newUser;
    default:
      return user;
  }
}
