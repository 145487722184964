import React, { useState, useCallback, useMemo, memo } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Search,
  DateTimePicker,
  TextField,
  MenuItem,
  ActionButton,
  useMediaQuery,
} from "../../../components";
import { deleteRecipientList } from "../../../actions/recipientList";
import { listRecipientLists } from "../../../actions/recipientLists";
import OverwriteDialog from "./OverwriteDialog";
import useAuthorized, {
  RECIPIENTLIST_CREATE_PERMISSION,
} from "../../../hooks/useAuthorized";
import { Refresh } from "../../../icons";
import CustomizedMenus from "../../../components/CustomMenu";

const getPermissions = (t) => [
  {
    label: t("All"),
    value: "all",
  },
  {
    label: t("User"),
    value: "user",
  },
  {
    label: t("Division"),
    value: "division",
  },
  {
    label: t("Company"),
    value: "company",
  },
];

const useStyles = makeStyles((theme) => ({
  primaryContainer: {
    flexGrow: 1,
    maxWidth: 1000,
  },
  createButton: {
    height: "100%",
  },
  searchContainer: {
    flex: 2,
    minWidth: 140,
    maxWidth: 800,
  },
  permissionContainer: {
    flex: 1,
    minWidth: 140,
    maxWidth: 220,
  },
}));

function RecipientListsFilters({
  params,
  onSearchChange,
  onPermissionChange,
  onFromDateChange,
  onToDateChange,
  onClickCreate,
  isCampaign,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const newRecipientList = useSelector((state) => state.newRecipientList);
  const permissions = useMemo(() => getPermissions(t), [t]);
  const { customConfig } = useSelector((state) => state.eonsConfig);
  const newListConfig = customConfig?.newListConfig
    ? JSON.parse(customConfig.newListConfig)
    : null;

  const onOpenConfirmDialog = useCallback(() => setShowConfirmDialog(true), [
    setShowConfirmDialog,
  ]);

  const onCloseConfirmDialog = useCallback(() => setShowConfirmDialog(false), [
    setShowConfirmDialog,
  ]);

  const onCreateList = useCallback(
    (ev) => {
      // prevent the navigation to /recipient-lists/create if there is already a new recipient list in progress
      // show a confirmation dialog which deletes the current recipient list before navigating to the create page
      if (newRecipientList) {
        ev.preventDefault();
        onOpenConfirmDialog();
      } else if (onClickCreate) {
        onClickCreate(isCampaign);
      }
    },
    [newRecipientList, onOpenConfirmDialog, onClickCreate]
  );

  const onRefreshList = useCallback(async () => {
    await dispatch(listRecipientLists(params));
  }, [dispatch, params]);

  const onOverwriteList = useCallback(async () => {
    await dispatch(deleteRecipientList(newRecipientList));
    if (onClickCreate) {
      onClickCreate(isCampaign);
    }
    history.push("/recipient-lists/create");
  }, [dispatch, history, newRecipientList, onClickCreate]);

  const canCreateList = useAuthorized(RECIPIENTLIST_CREATE_PERMISSION);
  const isCustomizedMenu = newListConfig?.map || newListConfig?.masterList;
  const isNewList =
    newListConfig?.upload && !newListConfig?.map && !newListConfig?.masterList;

  return (
    <>
      <Grid container spacing={2} justify="space-between">
        <Grid item className={classes.primaryContainer}>
          <Grid container spacing={2}>
            {isCustomizedMenu && (
              <CustomizedMenus
                isCampaign={isCampaign}
                newListConfig={newListConfig}
              />
            )}
            {isNewList && (
              <ActionButton
                classes={{ desktop: classes.createButton }}
                component={Link}
                to="/recipient-lists/create"
                data-test-id="list-create-new"
                text={t("New List")}
                onClick={onCreateList}
                disabled={!canCreateList}
              />
            )}
            <ActionButton
              color="default"
              text={t("Refresh")}
              onClick={onRefreshList}
              desktopIcon={<Refresh />}
            />
            <Grid item className={classes.searchContainer}>
              <Search
                variant="outlined"
                size="small"
                label={
                  isSmallScreen ? t("Search") : t("Search by name and creator")
                }
                onSearch={onSearchChange}
                defaultValue={params.search}
                debounce={300}
                fullWidth
              />
            </Grid>
            <Grid item className={classes.permissionContainer}>
              <TextField
                select
                value={params.permission || ""}
                onChange={onPermissionChange}
                label={t("Permission")}
                size="small"
                variant="outlined"
                fullWidth
              >
                {permissions.map((permission) => (
                  <MenuItem key={permission.value} value={permission.value}>
                    {permission.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} wrap="nowrap">
            <Grid item>
              <DateTimePicker
                label={t("Created after")}
                disableFuture
                defaultValue={params.fromDate}
                maxDate={params.toDate}
                onAccept={onFromDateChange}
              />
            </Grid>
            <Grid item>
              <DateTimePicker
                label={t("Created before")}
                disableFuture
                defaultValue={params.toDate}
                minDate={params.fromDate}
                onAccept={onToDateChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <OverwriteDialog
        open={showConfirmDialog}
        onClose={onCloseConfirmDialog}
        onOverwriteList={onOverwriteList}
        name={newRecipientList?.name}
      />
    </>
  );
}

export default memo(RecipientListsFilters);
