import { makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import React, { memo, useMemo, useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { notify } from "../../../actions/notification";
import { Save, DeleteForever } from "../../../icons";
import { saveSuppressionList } from "../../../actions/suppression";
import {
  Page,
  Box,
  Grid,
  TextField,
  MenuItem,
  PhoneInput,
  Button,
} from "../../../components";
import SuppressionToggle from "../SuppressionToggle";
import Upload from "./Upload/File/Upload/index";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    overflow: "auto",
  },
  root: {
    padding: `0 ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.grey[200],
    marginTop: "auto",
    marginBottom: 0,
  },
}));

const getShortCodes = ({
  sinchShortCodes = [],
  xmlShortCodes = [],
  jasminShortCodes = [],
}) => [...sinchShortCodes, ...xmlShortCodes, ...jasminShortCodes];

const getEmailDomains = ({ domainAddress = [] }) => [domainAddress];

function CreateSuppressionNumber() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useSelector((state) => state.params);
  const [suppressionTab, setSuppressionTab] = useState("sms");
  const [isUpload, setIsUpload] = useState(false);
  // const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const { smsConfig, emailConfig, vendorConfig } = useSelector(
    (state) => state.eonsConfig
  );
  const { errors = {} } = useSelector((state) =>
    state.suppression ? state.suppression : {}
  );

  const suppressionList = useSelector((state) => {
    return state.newSuppressionList;
  });

  const [suppressNumber, setSuppressNumber] = useState({});
  const [suppressEmail, setSuppressEmail] = useState({
    toEmail: "",
    fromEmail: "",
  });
  const [suppressVoice, setSuppressVoice] = useState({ callerId: "", to: "" });

  const { shortCode = "", to } = suppressNumber;
  const shortCodes = useMemo(() => getShortCodes(smsConfig), [smsConfig]);
  const emailDomain = useMemo(() => getEmailDomains(emailConfig), [
    emailConfig,
  ]);
  // All emails were coming as one string.. so array had 1 value only as below
  // console.log(emailDomain) // ["hb.himant@mb.com, test@gmail.com"]
  // So splitting the string into array
  const emailAddresses =
    emailDomain[0] && emailDomain[0].length ? emailDomain[0].split(",") : [];

  const callerIds = useMemo(() => {
    return vendorConfig.callerId;
  }, [vendorConfig]);

  const onInputTabChange = (_, activeTab) => {
    if (activeTab) {
      history.push(`/suppression?type=${activeTab}`);
    }
  };

  const onChange = (ev) => {
    const { name, value } = ev.target;
    const { type } = queryParams;
    switch (type) {
      case "sms":
        {
          setSuppressNumber({
            ...suppressNumber,
            [name]: value,
          });
        }
        break;
      case "email":
        {
          setSuppressEmail({
            ...suppressEmail,
            [name]: value,
          });
        }
        break;
      case "voice": {
        setSuppressVoice({
          ...suppressVoice,
          [name]: value,
        });
      }
    }
  };

  const onSave = useCallback(async () => {
    try {
      const { type } = queryParams;
      if (type === "sms") {
        if (suppressionList?.preview?.length > 0) {
          await dispatch(saveSuppressionList(type, suppressionList.preview));
        } else {
          await dispatch(
            saveSuppressionList(type, [{ to, shortcode: shortCode }])
          );
        }
      } else if (type === "email") {
        if (suppressionList?.preview?.length > 0) {
          await dispatch(saveSuppressionList(type, suppressionList.preview));
        } else {
          await dispatch(
            saveSuppressionList(type, [
              {
                to: suppressEmail.toEmail,
                domainAddress: suppressEmail.fromEmail,
              },
            ])
          );
        }
      } else if (type === "voice") {
        if (suppressionList?.preview?.length > 0) {
          await dispatch(saveSuppressionList(type, suppressionList.preview));
        } else {
          await dispatch(
            saveSuppressionList(type, [
              {
                to: suppressVoice.to,
                callerId: suppressVoice.callerId,
              },
            ])
          );
        }
      }

      history.replace(`/suppression?type=${type}`);
    } catch (err) {
      dispatch(
        notify({
          type: "error",
          message: err.message,
        })
      );
    }
  }, [
    dispatch,
    to,
    shortCode,
    history,
    suppressEmail,
    suppressVoice,
    suppressionList,
  ]);

  const onCancel = useCallback(() => history.replace("/suppression"), [
    history,
  ]);

  useEffect(() => {
    const { type } = queryParams;
    setSuppressionTab(type);
  }, [queryParams]);

  const addSMSToSuppressionList = (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextField
          data-test-id="suppression-create-shortcode-field"
          select
          label={t("Short Code")}
          variant="outlined"
          size="small"
          fullWidth
          name="shortCode"
          value={shortCode} // todo
          onChange={onChange} // todo
          error={Boolean(errors["shortCode"])} //todo
          helperText={
            Boolean(errors["shortCode"]) ? t("must be a valid shortcode") : ""
          }
        >
          {shortCodes.map((shortCode) => (
            <MenuItem key={shortCode} value={shortCode}>
              {shortCode}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item>
        <PhoneInput
          data-test-id="suppression-create-sms-number-field"
          label={t("Phone Number")}
          country={"us"}
          value={to}
          name="to"
          onChange={(value) => onChange({ target: { name: "to", value } })}
          variant="outlined"
          size="small"
          fullWidth
          error={Boolean(errors["sms"])}
          helperText={
            Boolean(errors["sms"]) ? t("must be a valid phone number") : ""
          }
        />
      </Grid>
      {/* ECS-2200 Commented out to remove this feature while being investigated */}
      {/*<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>*/}
      {/*  <TextField*/}
      {/*    data-test-id="suppression-upload"*/}
      {/*    size="small"*/}
      {/*    name="toEmail"*/}
      {/*    value={suppressEmail.toEmail}*/}
      {/*    onChange={() => setIsUpload(true)}*/}
      {/*    helperText={*/}
      {/*      Boolean(errors["toEmail"]) ? t("must be a valid Email") : ""*/}
      {/*    }*/}
      {/*    type="checkbox"*/}
      {/*  />{" "}*/}
      {/*  Uploads*/}
      {/*</Grid>*/}
    </>
  );
  const addEmailToSuppressionList = (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextField
          data-test-id="suppression-create-from-email"
          select
          label={t("From Email")}
          variant="outlined"
          size="small"
          fullWidth
          name="fromEmail"
          value={suppressEmail.fromEmail}
          onChange={onChange}
          helperText={
            Boolean(errors["fromEmail"]) ? t("must be a valid Email") : ""
          }
        >
          {emailAddresses.map((domain) => (
            <MenuItem key={domain} value={domain}>
              {domain}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextField
          data-test-id="suppression-create-to-email"
          label={t("To Email")}
          variant="outlined"
          size="small"
          fullWidth
          name="toEmail"
          value={suppressEmail.toEmail}
          onChange={onChange}
          helperText={
            Boolean(errors["toEmail"]) ? t("must be a valid Email") : ""
          }
        />
      </Grid>
      {/* ECS-2200 Commented out to remove this feature while being investigated */}
      {/*<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>*/}
      {/*  <TextField*/}
      {/*    data-test-id="suppression-upload"*/}
      {/*    size="small"*/}
      {/*    name="toEmail"*/}
      {/*    value={suppressEmail.toEmail}*/}
      {/*    onChange={() => setIsUpload(true)}*/}
      {/*    helperText={*/}
      {/*      Boolean(errors["toEmail"]) ? t("must be a valid Email") : ""*/}
      {/*    }*/}
      {/*    type="checkbox"*/}
      {/*  />{" "}*/}
      {/*  Uploads*/}
      {/*</Grid>*/}
    </>
  );
  const addVoiceToSuppressionList = (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextField
          data-test-id="suppression-create-callerid-field"
          select
          label={t("Caller Id")}
          variant="outlined"
          size="small"
          fullWidth
          name="callerId"
          value={suppressVoice.callerId}
          onChange={onChange}
          error={Boolean(errors["callerId"])}
          helperText={
            Boolean(errors["callerId"]) ? t("must be a valid callerId") : ""
          }
        >
          {callerIds.map((callerId) => (
            <MenuItem key={callerId} value={callerId.toString()}>
              {callerId}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item>
        <PhoneInput
          data-test-id="suppression-create-voice-number-field"
          label={t("Phone Number")}
          country={"us"}
          value={suppressVoice.to}
          name="to"
          onChange={(value) => onChange({ target: { name: "to", value } })}
          variant="outlined"
          size="small"
          fullWidth
          error={Boolean(errors["voice"])}
          helperText={
            Boolean(errors["voice"]) ? t("must be a valid phone number") : ""
          }
        />
      </Grid>
      {/* ECS-2200 Commented out to remove this feature while being investigated */}
      {/*<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>*/}
      {/*  <TextField*/}
      {/*    data-test-id="suppression-upload"*/}
      {/*    size="small"*/}
      {/*    name="toEmail"*/}
      {/*    value={suppressEmail.toEmail}*/}
      {/*    onChange={() => setIsUpload(true)}*/}
      {/*    helperText={*/}
      {/*      Boolean(errors["toEmail"]) ? t("must be a valid Email") : ""*/}
      {/*    }*/}
      {/*    type="checkbox"*/}
      {/*  />{" "}*/}
      {/*  Uploads*/}
      {/*</Grid>*/}
    </>
  );
  return (
    <Page screenSized data-test-id="single-master-template-page">
      <Grid direction="column" spacing={2}>
        <SuppressionToggle
          onTabChange={onInputTabChange}
          activeTab={suppressionTab}
          errors={""}
          readOnly={false}
        />
      </Grid>

      <Box p={2} className={classes.content}>
        {!isUpload && (
          <Grid
            container
            spacing={2}
            data-test-id="suppression-create"
            wrap="nowrap"
          >
            {suppressionTab == "email"
              ? addEmailToSuppressionList
              : suppressionTab == "voice"
              ? addVoiceToSuppressionList
              : addSMSToSuppressionList}
          </Grid>
        )}
        {isUpload && <Upload />}
      </Box>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justify="flex-end"
        className={classes.root}
      >
        <Grid item>
          <Button
            data-test-id="suppression-create-cancel-button"
            variant="contained"
            color="secondary"
            startIcon={<DeleteForever />}
            onClick={onCancel}
            disabled={false}
          >
            {t("Cancel")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            data-test-id="suppression-create-save-button"
            variant="contained"
            color="primary"
            startIcon={<Save />}
            onClick={onSave}
            disabled={false}
            error={false}
          >
            {t("Save")}
          </Button>
        </Grid>
      </Grid>
    </Page>
  );
}

export default memo(CreateSuppressionNumber);
