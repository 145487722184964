import { LIST_APPROVERS, CLEAR_APPROVERS } from "../actions/approvers";

export default function approversReducer(
  approvers = null,
  { type, approvers: newApprovers }
) {
  switch (type) {
    case LIST_APPROVERS:
      return newApprovers;
    case CLEAR_APPROVERS:
      return null;
    default:
      return approvers;
  }
}
