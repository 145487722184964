import {
  CLEAR_PENDING_APPROVAL_CAMPAIGNS,
  LIST_PENDING_APPROVAL_CAMPAIGNS,
} from "../actions/pendingApprovalCampaigns";

export default function pendingApprovalCampaignsReducer(
  campaigns = null,
  { type, campaigns: newCampaigns }
) {
  switch (type) {
    case LIST_PENDING_APPROVAL_CAMPAIGNS:
      return newCampaigns;
    case CLEAR_PENDING_APPROVAL_CAMPAIGNS:
      return null;
    default:
      return campaigns;
  }
}
