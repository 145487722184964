import {
  CLEAR_CAMPAIGN_RECIPIENTS,
  SET_CAMPAIGN_RECIPIENTS,
  SET_CUSTOM_REPORTS,
  SET_EMAIL_REPORT,
  SET_SMS_REPORT,
  SET_VOICE_REPORT,
  SET_TTY_REPORT,
} from "../actions/report";

export default function reportsReducer(
  report = {
    email: null,
    sms: null,
    voice: null,
    recipients: null,
    customReports: null,
  },
  { type, report: payload }
) {
  switch (type) {
    case SET_EMAIL_REPORT:
      return { ...report, email: payload };
    case SET_SMS_REPORT:
      return { ...report, sms: payload };
    case SET_VOICE_REPORT:
      return { ...report, voice: payload };
    case SET_TTY_REPORT:
      return { ...report, tty: payload };
    case SET_CAMPAIGN_RECIPIENTS:
      return { ...report, recipients: payload };
    case SET_CUSTOM_REPORTS:
      return { ...report, custom_channel: payload };
    case CLEAR_CAMPAIGN_RECIPIENTS:
      return { ...report, recipients: null };
    default:
      return report;
  }
}
