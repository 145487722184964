import React, { useState, useMemo, useCallback, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Dialog, DialogTitle, Typography } from "@material-ui/core";
import { DragIndicator } from "../../icons";
import DraggablePaper from "./DraggablePaper";

const useStyles = makeStyles((theme) => ({
  noClick: {
    pointerEvents: "none",
  },
  paper: {
    pointerEvents: "auto",
  },
  container: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  title: ({ dragging }) => ({
    cursor: dragging ? "grabbing" : "grab",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }),
  titleText: {
    marginRight: theme.spacing(4),
  },
}));

let incrId = 0;
const getId = () => `draggable-handle-${incrId++}`;

function DialogWidget({
  title,
  onModalClose,
  modal,
  className,
  children,
  ...rest
}) {
  const dragHandleId = useMemo(getId);
  const [dragging, setDragging] = useState();

  const onDragStart = useCallback(() => setDragging(true), [setDragging]);
  const onDragStop = useCallback(() => setDragging(false), [setDragging]);

  const classes = useStyles({ dragging, modal });

  return (
    <Dialog
      {...rest}
      className={classNames({
        [classes.noClick]: !modal,
      })}
      classes={{ container: classes.container, paper: classes.paper }}
      PaperComponent={DraggablePaper}
      PaperProps={{ dragHandleId, onDragStart, onDragStop, className }}
      hideBackdrop={!modal}
      disableScrollLock={!modal}
      disableEnforceFocus={!modal}
      onClose={onModalClose}
    >
      <DialogTitle
        id={dragHandleId}
        disableTypography
        className={classes.title}
      >
        <Typography variant="h6" className={classes.titleText}>
          {title}
        </Typography>
        <DragIndicator />
      </DialogTitle>
      {children}
    </Dialog>
  );
}

export default memo(DialogWidget);
