import React, { useCallback, memo } from "react";
import { Link } from "react-router-dom";
import { IconButton, Tooltip } from "../../../components";
import { useSelector } from "react-redux";
import { Visibility, Edit, DeleteForever } from "../../../icons";
import {
  APPROVER_GET_PERMISSION,
  APPROVER_DELETE_PERMISSION,
  APPROVER_UPDATE_PERMISSION,
  useAuthorized,
} from "../../../hooks/useAuthorized";
import { useTranslation } from "react-i18next";

function ApproverActions({ approver, onDelete }) {
  const { _id } = approver;
  const { t } = useTranslation();

  const canEdit = useAuthorized(APPROVER_UPDATE_PERMISSION);
  const canView = useAuthorized(APPROVER_GET_PERMISSION);
  const canDelete = useAuthorized(APPROVER_DELETE_PERMISSION);

  const EditIcon = canEdit ? Edit : Visibility;

  const _onDelete = useCallback(() => onDelete(approver), [onDelete, approver]);
  const { advancedApproverManagement = false } = useSelector(
    (state) => state.eonsConfig.vendorConfig
  );

  const advancedApproverUrl =
    advancedApproverManagement === true ? "/admin/approvers" : "/approvers";

  return (
    <>
      {canEdit || canView ? (
        <Tooltip title={t("Edit approver")}>
          <IconButton
            component={Link}
            to={`${advancedApproverUrl}/${_id}`}
            size="small"
            data-test-id={`approver-action-edit-${_id}`}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : null}
      {canDelete ? (
        <Tooltip title={t("Delete approver")}>
          <IconButton
            size="small"
            color="secondary"
            onClick={_onDelete}
            data-test-id={`approver-action-delete-${_id}`}
          >
            <DeleteForever fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );
}

export default memo(ApproverActions);
