import * as api from "../api/keywords";
import { notify } from "./notification";

export const LIST_KEYWORDS = "LIST_KEYWORDS";
export const CLEAR_KEYWORDS = "CLEAR_KEYWORDS";

export function clearKeywords() {
  return { type: CLEAR_KEYWORDS };
}

export function saveKeyword(body) {
  return async (dispatch, getState) => {
    const { user } = getState();
    const { email } = user;
    const st = getState();
    try {
      await api.saveKeyword({
        ...body,
        timestamp: new Date().toISOString(),
        createdBy: email,
      });
      dispatch(
        notify({
          type: "success",
          message: "Keyword saved successfully",
        })
      );
    } catch (err) {
      const data = err.response?.data;
      const message = data ? data.details[0]?.message : "";
      dispatch({
        type: LIST_KEYWORDS,
        keywordList: {
          errors: {
            shortCode: message.indexOf("short") === -1 ? false : true,
            keyword: message.indexOf("keyword") === -1 ? false : true,
            value: message.indexOf("value") === -1 ? false : true,
          },
        },
      });
      throw err;
    }
    dispatch(clearKeywords());
  };
}

export function listKeywords({ shortCodes = [], params }) {
  return async (dispatch, _) => {
    let query = {
      shortcodes: shortCodes,
    };

    const keywords = await api.getKeywordList({
      ...query,
      ...params,
    });
    const count = await api.getKeywordList({
      ...query,
      ...params,
      getCount: true,
    });

    dispatch({
      type: LIST_KEYWORDS,
      keywordList: { list: keywords, count },
    });
  };
}

export function deleteKeyword(keywordInfo) {
  return async (dispatch, getState) => {
    await api.deleteKeyword(keywordInfo);
  };
}

export function deleteKeywordById(id) {
  return async (dispatch, getState) => {
    await api.deleteKeywordById(id);
    dispatch(
      notify({
        type: "success",
        message: "Keyword deleted successfully",
      })
    );
  };
}

export function updateKeywordById(keywordInfo) {
  return async (dispatch, getState) => {
    try {
      await api.updateKeywordById(keywordInfo);
      dispatch(
        notify({
          type: "success",
          message: "Keyword updated successfully",
        })
      );
    } catch (err) {
      const data = err.response?.data;
      const message = data ? data.details[0]?.message : "";
      dispatch({
        type: LIST_KEYWORDS,
        keywordList: {
          errors: {
            shortCode: message.indexOf("short") === -1 ? false : true,
            keyword: message.indexOf("keyword") === -1 ? false : true,
            value: message.indexOf("value") === -1 ? false : true,
          },
        },
      });
      throw err;
    }
  };
}
