import React, { memo } from "react";
import {
  Paper,
  Grid,
  Box,
  Slide,
  Typography,
  useScrollTrigger,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import TablePaginator from "../TablePaginator";
import DataRow from "./DataRow";

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    flex: 1,
  },
  notFound: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    textAlign: "center",
    color: theme.palette.text.hint,
  },
  item: {
    flex: 1,
    minWidth: 280,
    maxWidth: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    height: "100%",
  },
  filters: {
    position: "sticky",
    top: theme.custom.topbarHeight,
    zIndex: 200,
    backgroundColor: "#fafafa",
  },
  paginator: {
    alignSelf: "flex-start",
  },
  elevated: {
    boxShadow: theme.shadows[2],
  },
}));

function MobileTable({
  columns,
  data,
  totalCount,
  orderBy,
  order,
  onSortChange,
  itemsPerPage,
  page,
  onPageChange,
  onItemsPerPageChange,
  children,
  className,
  notFound,
  notFoundMessage,
  loading,
  columnsLoading,
  filters,
  ...rest
}) {
  const classes = useStyles();
  const scrollingDown = useScrollTrigger({ threshold: 100 });
  const scrolling = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <>
      {filters && (
        <Slide appear={false} direction="down" in={!scrollingDown}>
          <Box
            mb={0}
            m={-2}
            p={2}
            className={classNames(classes.filters, {
              [classes.elevated]: scrolling,
            })}
          >
            {filters}
          </Box>
        </Slide>
      )}
      <Grid
        {...rest}
        container
        direction="column"
        spacing={2}
        className={classes.fullHeight}
      >
        {notFound ? (
          <Grid
            item
            className={classNames(classes.notFound, classes.fullHeight)}
          >
            <Typography variant="h6">{notFoundMessage}</Typography>
          </Grid>
        ) : (
          data.map((row, idx) => (
            <Grid item key={row?._id || idx} className={classes.item}>
              <Paper className={classes.paper}>
                {columns.map((column, idx) => (
                  <DataRow
                    loading={loading}
                    columnsLoading={columnsLoading}
                    key={column.name || idx}
                    row={row}
                    column={column}
                    onSortChange={onSortChange}
                    orderBy={orderBy}
                    order={order}
                  />
                ))}
              </Paper>
            </Grid>
          ))
        )}
        <Grid item component={Paper} className={classes.paginator}>
          <TablePaginator
            count={totalCount}
            page={page}
            rowsPerPage={itemsPerPage}
            onChangePage={onPageChange}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default memo(MobileTable);
