import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { CloudUpload } from "../../../../../icons";

const useStyles = makeStyles((theme) => ({
  cloudIcon: {
    color: "inherit",
  },
}));

function UploadIcon({ className, ...rest }) {
  const classes = useStyles();

  return (
    <CloudUpload
      {...rest}
      className={classNames(className, classes.cloudIcon)}
    />
  );
}

export default memo(UploadIcon);
