import React, { useCallback, useEffect, memo } from "react";
import { omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  getApprover,
  setApprover,
  updateApprover,
  clearApprover,
} from "../../../actions/approver";
import BaseApproversPage from "./Base";
import useAuthorized, {
  APPROVER_GET_PERMISSION,
  APPROVER_UPDATE_PERMISSION,
} from "../../../hooks/useAuthorized";

function EditApproverPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { approverId } = useParams();

  const approver = useSelector((state) => state.approver);
  const userCompanyId = useSelector((state) => state.user.companyId);
  const { errors = {}, companyId: approverCompanyId } = approver || {};

  const canEdit = useAuthorized(APPROVER_UPDATE_PERMISSION);
  const canView = useAuthorized(APPROVER_GET_PERMISSION);
  const userHasOnlyViewPermissions = canView && !canEdit;
  const isUserScopedToApproverCompany = approverCompanyId === userCompanyId;
  const readOnly = userHasOnlyViewPermissions || !isUserScopedToApproverCompany;

  const { advancedApproverManagement = false } = useSelector(
    (state) => state.eonsConfig.vendorConfig
  );

  const historyPush = advancedApproverManagement ? "/admin?" : "/approvers";

  useEffect(() => {
    dispatch(getApprover(approverId));
    return () => dispatch(clearApprover());
  }, [dispatch, approverId]);

  const onFieldChange = useCallback(
    (ev) =>
      dispatch(
        setApprover({
          [ev.target.name]: ev.target.value,
          errors: omit(errors, ev.target.name),
          modified: true,
        })
      ),
    [dispatch, errors]
  );

  const onSave = useCallback(async () => {
    await dispatch(updateApprover());
    history.replace(historyPush);
  }, [dispatch, history]);

  return (
    <BaseApproversPage
      approver={approver}
      onFieldChange={onFieldChange}
      onSave={onSave}
      readOnly={readOnly}
    />
  );
}

export default memo(EditApproverPage);
