import React, { useCallback, memo } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell, TableSortLabel, Typography } from "@material-ui/core";
import { KeyboardArrowDown } from "../../../icons";

const useStyles = makeStyles((theme) => ({
  root: ({ width }) => ({
    width: `${width * 100}%`,
  }),
  sortable: {
    // sortable cells should not have right padding
    // because their sort arrow already adds a lot of space to their right
    paddingRight: 0,
  },
  sortLabel: {
    width: "100%",
    whiteSpace: "inherit",
  },
  center: {
    justifyContent: "center",
  },
}));

function SortableTableCell({
  sortable,
  onSortChange,
  orderBy,
  order,
  name,
  children,
  noWrap,
  width,
  align,
  className,
  testId,
  ...rest
}) {
  const classes = useStyles({ width });

  const _onSortChange = useCallback(() => onSortChange(name), [
    onSortChange,
    name,
  ]);

  if (noWrap) {
    children = (
      <Typography noWrap variant="inherit">
        {children}
      </Typography>
    );
  }

  if (sortable) {
    children = (
      <TableSortLabel
        className={classNames(classes.sortLabel, {
          [classes.center]: align === "center",
        })}
        data-test-id={`sortable-header-${testId}`}
        IconComponent={KeyboardArrowDown}
        active={orderBy === name}
        direction={order === -1 ? "desc" : "asc"}
        onClick={_onSortChange}
      >
        {children}
      </TableSortLabel>
    );
  }

  return (
    <TableCell
      {...rest}
      align={align}
      className={classNames(className, classes.root, {
        [classes.sortable]: sortable,
      })}
    >
      {children}
    </TableCell>
  );
}

export default memo(SortableTableCell);
