import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Paper, Grid } from "../../../components";
import Count from "./Count";
import { useSelector } from "react-redux";

function RecipientListCounts({
  failedCount,
  totalCount,
  smsCount,
  emailCount,
  voiceCount,
  ttyCount,
  customCount,
  loading,
  ...rest
}) {
  const { t } = useTranslation();

  let recipientList = useSelector((state) => state.recipientList);

  let data = [];
  if (recipientList && recipientList.failedRecipients) {
    const failedRecipients = recipientList.failedRecipients;
    if (failedRecipients.length > 0) {
      const keys = Object.keys(failedRecipients[0]);
      data.push(keys);
      failedRecipients.map((recipient) => {
        const values = Object.values(recipient);
        data.push(values);
      });
    }
  }

  return (
    <Paper {...rest} p={2}>
      <Grid container spacing={1} direction="column">
        <Grid item>
          <Count
            testId="total"
            label={t("Total Customers")}
            value={totalCount}
            color="success"
            loading={loading}
          />
        </Grid>
        <Grid item>
          <Count
            testId="failed"
            label={t("Failed Customers")}
            value={failedCount}
            color="error"
            loading={loading}
            failedRecipients={data}
          />
        </Grid>
        <Grid item>
          <Count
            testId="email"
            label={t("Email Eligible")}
            value={emailCount}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <Count
            testId="sms"
            label={t("SMS Eligible")}
            value={smsCount}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <Count
            testId="voice"
            label={t("Voice Eligible")}
            value={voiceCount}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <Count
            testId="tty"
            label={t("TTY Eligible")}
            value={ttyCount}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <Count
            testId="custom"
            label={t("Custom Eligible")}
            value={customCount}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default memo(RecipientListCounts);
