import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { isEmpty, keysIn, map, reduce } from "lodash";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from ".";

const TextInput = ({ fieldName, initialValue, handleInputChange }) => (
  <TextField
    margin="dense"
    name={fieldName}
    id={`edit-recipient-${fieldName}`}
    label={fieldName}
    type="text"
    fullWidth
    variant="standard"
    onChange={handleInputChange}
    value={initialValue}
    key={fieldName}
  />
);

const DropDown = ({ fieldName, initialValue, schema, handleInputChange }) => (
  <FormControl fullWidth>
    <InputLabel>{fieldName}</InputLabel>
    <Select
      margin="dense"
      name={fieldName}
      fullWidth
      variant="standard"
      label={fieldName}
      value={initialValue}
      onChange={handleInputChange}
    >
      {schema.items.enum.map((e) => (
        <MenuItem value={e}>{e}</MenuItem>
      ))}
    </Select>
  </FormControl>
);

function FormDialog({
  onClose,
  onSave,
  fields,
  data,
  open,
  resource,
  ...rest
}) {
  const { t } = useTranslation();
  const fieldNames = keysIn(fields);
  const defaultValues = reduce(
    fieldNames,
    (values, fieldName) => {
      return { ...values, [fieldName]: "" };
    },
    {}
  );
  const [formValues, setFormValues] = useState(defaultValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const title = isEmpty(data)
    ? t("Add {{resource}}", { resource })
    : t("Edit {{resource}}", { resource });

  useEffect(() => {
    setFormValues(data ?? defaultValues);
  }, [data]);

  const handleClose = () => {
    setFormValues(defaultValues);
    onClose && onClose();
  };
  const handleSave = () => {
    onSave(formValues).then(() => setFormValues(defaultValues));
  };

  return (
    <Dialog {...rest} open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {map(fieldNames, (fieldName) => {
          const params = {
            fieldName,
            initialValue: formValues[fieldName],
            handleInputChange,
          };
          switch (fields[fieldName].type) {
            case "string":
            case "number":
              return TextInput(params);
            case "array":
              return DropDown({ ...params, schema: fields[fieldName] });
            default:
              return null;
          }
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} data-test-id="form-dialog-close-button">
          {t("Cancel")}
        </Button>

        <Button
          onClick={handleSave}
          color="secondary"
          data-test-id="form-dialog-save-button"
        >
          {t("Save {{resource}}", { resource })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(FormDialog);
