import React, { memo } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: `calc(100vh - ${theme.custom.topbarHeight}px)`,
    display: "flex",
    flexDirection: "column",
  },
  screenSizedContent: {
    maxHeight: `calc(100vh - ${theme.custom.topbarHeight}px)`,
    overflow: "scroll",
  },
  screenSizedStrict: {
    height: `calc(100vh - ${theme.custom.topbarHeight}px)`,
    overflow: "hidden",
  },
}));

function Page({ screenSized, children, className, ...rest }) {
  const classes = useStyles();

  return (
    <Box
      {...rest}
      className={classNames(className, classes.content, {
        [classes.screenSizedContent]: screenSized,
        [classes.screenSizedStrict]: screenSized === "strict",
      })}
    >
      {children}
    </Box>
  );
}

export default memo(Page);
