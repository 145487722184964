import React, { useMemo, memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Paper,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
  TextField,
} from "../../../components";

function RecipientListFields({
  name,
  permission,
  errors = {},
  readOnly,
  onChange,
  ...rest
}) {
  const { t } = useTranslation();
  const readOnlyProps = useMemo(() => ({ readOnly }), [readOnly]);
  const {
    vendorConfig: { disableUserPermissions = [] },
  } = useSelector((state) => state.eonsConfig);

  const shouldDisplayOnlyMe = !disableUserPermissions?.includes(
    "recipientList"
  );

  return (
    <Paper {...rest} p={2}>
      <Grid container spacing={2} justify="space-between" alignItems="center">
        <Grid item xs={12}>
          {/* TextFields sometimes have an issue with overlapping label and value, which happens here.
        Setting `InputLabelProps={{ shrink: Boolean(value) }}` is the official fix for the issue, see more here:
        https://material-ui.com/components/text-fields/#limitations */}
          <TextField
            data-test-id="recipientList-edit-name-input-field"
            name="name"
            value={name}
            onChange={onChange}
            label={t("List Name")}
            variant="outlined"
            fullWidth
            error={Boolean(errors.name)}
            helperText={errors.name}
            InputLabelProps={{ shrink: Boolean(name) }}
            InputProps={readOnlyProps}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" error={Boolean(errors.permission)}>
            <FormLabel component="legendd">{t("Visibility Level")}</FormLabel>
            <RadioGroup
              row
              name="permission"
              value={permission}
              onChange={onChange}
            >
              {shouldDisplayOnlyMe ? (
                <FormControlLabel
                  value="user"
                  control={
                    <Radio
                      data-test-id="recipientList-edit-radio-user"
                      color="primary"
                      disabled={readOnly}
                    />
                  }
                  label={t("Only Me")}
                  InputProps={readOnlyProps}
                />
              ) : (
                ""
              )}
              <FormControlLabel
                value="division"
                control={
                  <Radio
                    data-test-id="recipientList-edit-radio-division"
                    color="primary"
                    disabled={readOnly}
                  />
                }
                label={t("My Division")}
                InputProps={readOnlyProps}
              />
              <FormControlLabel
                value="company"
                control={
                  <Radio
                    data-test-id="recipientList-edit-radio-company"
                    color="primary"
                    disabled={readOnly}
                  />
                }
                label={t("My Company")}
                InputProps={readOnlyProps}
              />
            </RadioGroup>
            {errors.permission && (
              <FormHelperText>{errors.permission}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default memo(RecipientListFields);
