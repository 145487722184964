import React, { memo } from "react";
import { MobileStepper, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Save,
  DeleteForever,
  InsertDriveFile as Draft,
} from "../../icons";
import { Grid } from "../../components";
import NavButton from "./NavButton";

const useStyles = makeStyles((theme) => ({
  stepper: {
    backgroundColor: "inherit",
    padding: 0,
  },
  nextControls: {
    flex: 0,
  },
}));

function MobileWizard({
  steps,
  children,
  className,
  disableNext,
  errors,
  onSave,
  onSaveAsDraft,
  onAbort,
  activeStepIdx,
  activeStep,
  nextStep,
  prevStep,
  pushHistory,
  ...rest
}) {
  const classes = useStyles();

  return (
    <MobileStepper
      {...rest}
      className={classes.stepper}
      steps={steps.length}
      position="static"
      variant="dots"
      activeStep={activeStepIdx}
      backButton={
        <NavButton
          component={IconButton}
          disabled={!prevStep}
          to={prevStep?.path}
          pushHistory={pushHistory}
        >
          <KeyboardArrowLeft />
        </NavButton>
      }
      nextButton={
        <Grid
          container
          spacing={2}
          wrap="nowrap"
          className={classes.nextControls}
        >
          {onAbort && (
            <Grid item>
              <NavButton
                component={IconButton}
                color="secondary"
                onClick={onAbort}
              >
                <DeleteForever />
              </NavButton>
            </Grid>
          )}
          {onSaveAsDraft && (
            <Grid item>
              <NavButton
                color="default"
                component={IconButton}
                onClick={onSaveAsDraft}
              >
                <Draft />
              </NavButton>
            </Grid>
          )}
          <Grid item>
            {nextStep ? (
              <NavButton
                component={IconButton}
                disabled={disableNext}
                to={nextStep?.path}
                validate={activeStep.validate}
                error={Object.keys(errors || {}).length}
                pushHistory={pushHistory}
              >
                <KeyboardArrowRight />
              </NavButton>
            ) : (
              <NavButton
                component={IconButton}
                disabled={disableNext}
                validate={activeStep.validate}
                onClick={onSave}
                error={Object.keys(errors || {}).length}
              >
                <Save />
              </NavButton>
            )}
          </Grid>
        </Grid>
      }
    />
  );
}

export default memo(MobileWizard);
