import { url } from "../utils";

export const SET_PARAMS = "SET_PARAMS";

export function setParams(
  newParams,
  { overwrite = false, updateUrl = true } = {}
) {
  return (dispatch, getState) => {
    if (!overwrite) {
      const { params } = getState();
      newParams = { ...params, ...newParams };
    }
    if (updateUrl) {
      url.setParams(newParams);
    }
    dispatch({ type: SET_PARAMS, newParams });
  };
}
