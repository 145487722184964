import React, { useMemo, memo, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Email, Sms, Hearing } from "../../../icons";
import {
  Grid,
  Paper,
  TextField,
  Chip,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
  PhoneInput,
} from "../../../components";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  phoneInput: {
    width: "100% !important",
  },
}));

const CHANNEL_TYPES = {
  email: "email",
  sms: "sms",
  voice: "voice",
  tty: "tty",
};

function createChipData(channelData, type) {
  return channelData.map((channelInfo) => ({
    type,
    label: channelInfo,
  }));
}

function createChips(chipData, errors, classes, readOnly, onChipDeletion) {
  return (
    <ul className={classes.root}>
      {chipData.map((chipInfo, idx) => {
        let icon;

        switch (chipInfo.type) {
          case CHANNEL_TYPES.email:
            icon = <Email />;
            break;
          case CHANNEL_TYPES.sms:
            icon = <Sms />;
            break;
          case CHANNEL_TYPES.voice:
            icon = <Hearing />;
            break;
          case CHANNEL_TYPES.tty:
            icon = <Hearing />;
            break;
          default:
            icon = null;
        }

        const chipInvalidMessage = errors[`${chipInfo.type}.${idx}`];

        return (
          <li key={idx}>
            <Chip
              icon={icon}
              color={chipInvalidMessage && "secondary"}
              label={chipInfo.label}
              onDelete={!readOnly && (() => onChipDeletion(chipInfo.type, idx))}
              className={classes.chip}
            />
          </li>
        );
      })}
    </ul>
  );
}

function SeedFields({
  name = "",
  sms = [],
  email = [],
  voice = [],
  tty = [],
  permission = "",
  errors = {},
  readOnly,
  onChange,
  onArrayFieldChange,
  onChipDeletion,
  ...rest
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const readOnlyProps = useMemo(() => ({ readOnly }), [readOnly]);

  const [emailField, setEmailField] = useState("");
  const [smsField, setSmsField] = useState("");
  const [voiceField, setVoiceField] = useState("");
  const [ttyField, setTTYField] = useState("");

  const {
    vendorConfig: { disableUserPermissions = [] },
  } = useSelector((state) => state.eonsConfig);

  const shouldDisplayOnlyMe = !disableUserPermissions?.includes("seed");

  const onChangeEmail = useCallback(
    (event) => setEmailField(event.target.value),
    [setEmailField]
  );
  const onChangeSms = useCallback((value) => setSmsField(value), [setSmsField]);
  const onChangeVoice = useCallback((value) => setVoiceField(value), [
    setVoiceField,
  ]);
  const onChangeTTY = useCallback((value) => setTTYField(value), [setTTYField]);

  const emailChipData = useMemo(
    () => createChipData(email, CHANNEL_TYPES.email),
    [email]
  );
  const smsChipData = useMemo(() => createChipData(sms, CHANNEL_TYPES.sms), [
    sms,
  ]);
  const voiceChipData = useMemo(
    () => createChipData(voice, CHANNEL_TYPES.voice),
    [voice]
  );
  const ttyChipData = useMemo(() => createChipData(tty, CHANNEL_TYPES.tty), [
    tty,
  ]);

  const emailChips = useMemo(
    () => createChips(emailChipData, errors, classes, readOnly, onChipDeletion),
    [emailChipData, classes, readOnly, onChipDeletion, errors]
  );
  const smsChips = useMemo(
    () => createChips(smsChipData, errors, classes, readOnly, onChipDeletion),
    [smsChipData, classes, readOnly, onChipDeletion, errors]
  );
  const voiceChips = useMemo(
    () => createChips(voiceChipData, errors, classes, readOnly, onChipDeletion),
    [voiceChipData, classes, readOnly, onChipDeletion, errors]
  );
  const ttyChips = useMemo(
    () => createChips(ttyChipData, errors, classes, readOnly, onChipDeletion),
    [ttyChipData, classes, readOnly, onChipDeletion, errors]
  );

  const onAddChip = useCallback(
    (event) => {
      const type = event.target.name;
      const value = event.target.value;

      if (value && 4 < value.length) {
        switch (type) {
          case CHANNEL_TYPES.email:
            onArrayFieldChange(emailField, type);
            setEmailField("");
            break;
          case CHANNEL_TYPES.sms:
            onArrayFieldChange(smsField, type);
            // 1 === US country code
            setSmsField("1");
            // we can not use event.target.value directly here because it is not the same as smsField
            // ev.target.value is a phone number formatted field while smsField is a raw number
            break;
          case CHANNEL_TYPES.voice:
            onArrayFieldChange(voiceField, type);
            // 1 === US country code
            setVoiceField("1");
            break;
          case CHANNEL_TYPES.tty:
            onArrayFieldChange(ttyField, type);
            // 1 === US country code
            setTTYField("1");
            break;
          default:
            break;
        }
      }
    },
    [
      onArrayFieldChange,
      emailField,
      smsField,
      voiceField,
      ttyField,
      setEmailField,
      setSmsField,
      setVoiceField,
      setTTYField,
    ]
  );

  const onPressEnter = useCallback(
    (event) => {
      if (event.key === "Enter") {
        onAddChip(event);
      }
    },
    [onAddChip]
  );

  return (
    <Paper {...rest} p={2}>
      <Grid
        container
        spacing={4}
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={12} md={6}>
          {/* TextFields sometimes have an issue with overlapping label and value, which happens here.
        Setting `InputLabelProps={{ shrink: Boolean(value) }}` is the official fix for the issue, see more here:
        https://material-ui.com/components/text-fields/#limitations */}
          <TextField
            name="name"
            value={name}
            onChange={onChange}
            label={t("Seed Name")}
            variant="outlined"
            fullWidth
            data-test-id="seed-name-input-field"
            error={Boolean(errors.name)}
            helperText={errors.name}
            InputLabelProps={{ shrink: Boolean(name) }}
            InputProps={readOnlyProps}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl component="fieldset" error={Boolean(errors.permission)}>
            <FormLabel component="legend">{t("Visibility Level")}</FormLabel>
            <RadioGroup
              row
              name="permission"
              onChange={!readOnly && onChange}
              value={permission}
            >
              {shouldDisplayOnlyMe ? (
                <FormControlLabel
                  value="user"
                  control={
                    <Radio
                      data-test-id="seed-radio-button-me"
                      color="primary"
                    />
                  }
                  label={t("Only Me")}
                />
              ) : (
                ""
              )}
              <FormControlLabel
                value="division"
                control={
                  <Radio
                    data-test-id="seed-radio-button-division"
                    color="primary"
                  />
                }
                label={t("My Division")}
              />
              <FormControlLabel
                value="company"
                control={
                  <Radio
                    data-test-id="seed-radio-button-company"
                    color="primary"
                  />
                }
                label={t("My Company")}
              />
            </RadioGroup>
            {errors.permission && (
              <FormHelperText>{errors.permission}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {/* TextFields sometimes have an issue with overlapping label and value, which happens here.
        Setting `InputLabelProps={{ shrink: Boolean(value) }}` is the official fix for the issue, see more here:
        https://material-ui.com/components/text-fields/#limitations */}
          <TextField
            type="email"
            name="email"
            value={emailField}
            onChange={onChangeEmail}
            label={t("Email")}
            variant="outlined"
            fullWidth
            onKeyUp={onPressEnter}
            onBlur={onAddChip}
            error={Boolean(errors.email)}
            helperText={errors.email}
            InputLabelProps={{ shrink: true }}
            InputProps={readOnlyProps}
            data-test-id="seed-input-email"
          />
          {emailChips}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <PhoneInput
            inputProps={{
              name: "sms",
              onKeyUp: onPressEnter,
              onBlur: onAddChip,
              "data-test-id": "seed-input-sms",
            }}
            readOnly={readOnly}
            fullWidth
            value={smsField}
            onChange={onChangeSms}
            label={t("SMS")}
            variant="outlined"
            error={Boolean(errors.sms)}
            helperText={errors.sms}
            country="us"
            countryCodeEditable={false}
          />
          {smsChips}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <PhoneInput
            inputProps={{
              name: "voice",
              onKeyUp: onPressEnter,
              onBlur: onAddChip,
              "data-test-id": "seed-input-voice",
            }}
            readOnly={readOnly}
            fullWidth
            value={voiceField}
            onChange={onChangeVoice}
            label={t("Voice")}
            variant="outlined"
            error={Boolean(errors.voice)}
            helperText={errors.voice}
            country="us"
            countryCodeEditable={false}
          />
          {voiceChips}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <PhoneInput
            inputProps={{
              name: "tty",
              onKeyUp: onPressEnter,
              onBlur: onAddChip,
              "data-test-id": "seed-input-tty",
            }}
            readOnly={readOnly}
            fullWidth
            value={ttyField}
            onChange={onChangeTTY}
            label={t("TTY")}
            variant="outlined"
            error={Boolean(errors.tty)}
            helperText={errors.tty}
            country="us"
            countryCodeEditable={false}
          />
          {ttyChips}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default memo(SeedFields);
