import React, { memo } from "react";
import { Page } from "../../components";
import Map from "./Map";

function MapPage() {
  return (
    <Page screenSized="strict" p={2}>
      <Map />
    </Page>
  );
}

export default memo(MapPage);
