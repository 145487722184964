import React, { useState, useCallback, useEffect, memo } from "react";
import _omit from "lodash/omit";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { featureCollection } from "@turf/helpers";
import { useSelector, useDispatch } from "react-redux";
import { buildMap } from "../../actions/map";

import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Checkbox,
  Paper,
  Skeleton,
  Tooltip,
} from "../../components";
import {
  PanTool,
  RadioButtonUnchecked as Circle,
  CheckBoxOutlineBlank as Rectangle,
  Gesture as Polygon,
  AddCircle,
  Save,
  DeleteForever,
} from "../../icons";
import * as mapApi from "../../api/mapLayer";
import shapeToGeoJson from "./shapeToGeoJson";
import SaveLayerModal from "./SaveLayerModal";
import useAuthorized, {
  MAP_CREATE_PERMISSION,
  MAP_DELETE_PERMISSION,
  RECIPIENTLIST_MAP_PERMISSION,
} from "../../hooks/useAuthorized";

const useStyles = makeStyles((theme) => ({
  drawingTools: {
    backgroundColor: theme.palette.background.paper,
  },
  createListButton: {
    height: "100%",
  },
  layers: {
    minHeight: 0,
  },
  layersPaper: {
    height: "100%",
    overflow: "scroll",
  },
  listItemText: {
    maxWidth: 220,
    whiteSpace: "pre-wrap",
  },
}));

function MapControls({ drawingManager, showFilterButton, map, maps }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [drawingMode, _setDrawingMode] = useState(null);
  const [layers, setLayers] = useState();
  const [saveLayerModalOpen, setSaveLayerModalOpen] = useState(false);

  const user = useSelector((state) => state.user);
  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );
  const { shapes = [], enabledLayers = {} } = useSelector(
    (state) => state.map || {}
  );
  const [selectedShape, setSelectedShape] = useState();
  const hasShapes = 0 < shapes.length;

  const onSaveLayerModalOpen = useCallback(() => setSaveLayerModalOpen(true), [
    setSaveLayerModalOpen,
  ]);

  const onSaveLayerModalClose = useCallback(
    () => setSaveLayerModalOpen(false),
    [setSaveLayerModalOpen]
  );

  const selectShape = useCallback(
    (shape) =>
      setSelectedShape((selectedShape) => {
        if (shape !== selectedShape) {
          if (selectedShape) {
            selectedShape.overlay.setEditable(false);
            selectedShape.overlay.setDraggable(false);
          }
          if (shape) {
            shape.overlay.setEditable(true);
            shape.overlay.setDraggable(true);
          }
        }

        return shape;
      }),
    [setSelectedShape]
  );

  const setDrawingMode = useCallback(
    (drawingMode) => {
      _setDrawingMode(drawingMode);
      drawingManager.setDrawingMode(drawingMode);
    },
    [_setDrawingMode, drawingManager]
  );

  const onDrawingModeChange = useCallback(
    (ev, drawingMode) => setDrawingMode(drawingMode),
    [setDrawingMode]
  );

  const onDeleteSelectedShape = useCallback(() => {
    if (selectedShape) {
      selectedShape.overlay.setMap(null);
      selectShape(null);
      dispatch(
        buildMap({
          shapes: shapes.filter((shape) => shape !== selectedShape),
        })
      );
    }
  }, [selectedShape, selectShape, dispatch, shapes]);

  const fetchMapLayers = useCallback(async () => {
    const mapLayers = await mapApi.listMapLayers({ activeDivisionId });
    setLayers(mapLayers.data);
  }, [activeDivisionId]);

  const onNewShape = useCallback(
    (shape) => {
      setDrawingMode(null);
      dispatch(buildMap({ shapes: [...shapes, shape] }));
      selectShape(shape);
      shape.listener = shape.overlay.addListener("click", () =>
        // issue: this is the old selectshape!!!!! with the old closure
        selectShape(shape)
      );
    },
    [dispatch, shapes, selectShape, setDrawingMode]
  );

  const styleFeature = useCallback(
    (feature, { color }) => {
      map.data.overrideStyle(feature, {
        fillColor: color,
        fillOpacity: 0.3,
        strokeColor: color,
        strokeOpacity: 1,
        strokeWidth: 2,
      });
    },
    [map]
  );

  const addLayerToMap = useCallback(
    (layer) => {
      const nonePointFeatures = layer.data.features.filter(
        (feature) => feature.geometry.type !== "Point"
      );
      const features = map.data.addGeoJson({
        ...layer.data,
        features: nonePointFeatures,
      });
      features.forEach((feature) => styleFeature(feature, layer.style));
      return features;
    },
    [map, styleFeature]
  );

  const removeLayerFromMap = useCallback(
    (layer) => layer.shapes.forEach((shape) => map.data.remove(shape)),
    [map]
  );

  const onToggleLayer = useCallback(
    (layer) => {
      const layerId = layer._id;
      const enabledLayer = enabledLayers[layerId];

      if (enabledLayer) {
        removeLayerFromMap(enabledLayer);
        dispatch(
          buildMap({
            enabledLayers: _omit(enabledLayers, layerId),
          })
        );
      } else {
        const shapes = addLayerToMap(layer);
        dispatch(
          buildMap({
            enabledLayers: { ...enabledLayers, [layerId]: { shapes, layer } },
          })
        );
      }
    },
    [enabledLayers, removeLayerFromMap, addLayerToMap, dispatch]
  );

  const onSaveMapLayer = useCallback(
    async ({ color, name }) => {
      const layer = await mapApi.createMapLayer(
        {
          name: name,
          data: featureCollection(shapes.map(shapeToGeoJson)),
          style: {
            color,
          },
        },
        { activeDivisionId }
      );
      await fetchMapLayers();

      shapes.forEach((shape) => shape.overlay.setMap(null));
      dispatch(buildMap({ shapes: [] }));
      onToggleLayer(layer);
    },
    [shapes, onToggleLayer, fetchMapLayers, activeDivisionId, dispatch]
  );

  const onDeleteLayer = useCallback(
    async (layer) => {
      await mapApi.deleteMapLayer(layer._id);
      const enabledLayer = enabledLayers[layer._id];
      if (enabledLayer) {
        removeLayerFromMap(enabledLayer);
      }
      await fetchMapLayers();
    },
    [fetchMapLayers, removeLayerFromMap, enabledLayers]
  );

  useEffect(() => {
    if (maps) {
      const listener = maps.event.addListener(
        drawingManager,
        "overlaycomplete",
        onNewShape
      );
      return () => maps.event.removeListener(listener);
    }
  }, [maps, onNewShape, setDrawingMode, drawingManager]);

  useEffect(() => {
    fetchMapLayers();
  }, [fetchMapLayers]);

  useEffect(() => {
    const listener = maps.event.addListener(map, "click", () =>
      selectShape(null)
    );
    return () => maps.event.removeListener(listener);
  }, [maps, map, selectShape]);

  useEffect(() => {
    if (map) {
      Object.keys(enabledLayers).forEach((key) => {
        const { layer, shapes } = enabledLayers[key];
        shapes.forEach((feature) => {
          styleFeature(feature, layer.style);
          map.data.add(feature);
        });
      });
      shapes.forEach((shape) => {
        shape.overlay.setMap(map);
        shape.overlay.setDraggable(false);
        shape.overlay.setEditable(false);
        shape.listener = shape.overlay.addListener("click", () =>
          selectShape(shape)
        );
      });

      return () =>
        shapes.forEach((shape) => {
          maps.event.removeListener(shape.listener);
          shape.listener = undefined;
        });
    }
    // eslint-disable-next-line
  }, [map, styleFeature]);

  const canCreateRecipientListFromMap = useAuthorized(
    RECIPIENTLIST_MAP_PERMISSION
  );

  const canCreateMap = useAuthorized(MAP_CREATE_PERMISSION);
  const canDeleteMap = useAuthorized(MAP_DELETE_PERMISSION);

  return (
    <>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        spacing={2}
        className={classes.container}
      >
        <Grid item>
          <Grid container spacing={2} alignItems="stretch" justify="flex-start">
            <Grid item>
              <Paper
                component={ToggleButtonGroup}
                size="small"
                value={drawingMode}
                onChange={onDrawingModeChange}
                exclusive
                className={classes.drawingTools}
              >
                <ToggleButton value={null}>
                  <PanTool color="primary" />
                </ToggleButton>
                <ToggleButton value="circle">
                  <Circle color="primary" />
                </ToggleButton>
                <ToggleButton value="rectangle">
                  <Rectangle color="primary" />
                </ToggleButton>
                <Tooltip
                  // eslint-disable-next-line max-len
                  title="Click on the map to place the vertices of the desired polygon and close it by clicking onto the first point again"
                >
                  <ToggleButton value="polygon">
                    <Polygon color="primary" />
                  </ToggleButton>
                </Tooltip>
                <ToggleButton
                  value={null}
                  selected={false}
                  onClick={onDeleteSelectedShape}
                  disabled={!selectedShape}
                >
                  <DeleteForever
                    color={selectedShape ? "secondary" : "default"}
                  />
                </ToggleButton>
              </Paper>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={onSaveLayerModalOpen}
                startIcon={<Save />}
                className={classes.createListButton}
                disabled={!hasShapes || !canCreateMap}
              >
                {t("Save Drawing")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.layers}>
          <Paper className={classes.layersPaper} component={List} dense>
            {layers
              ? layers.map((layer) => (
                  <ListItem
                    key={layer._id}
                    role={undefined}
                    dense
                    button
                    onClick={() => onToggleLayer(layer)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        style={{ color: layer.style?.color }}
                        color="primary"
                        edge="start"
                        checked={enabledLayers[layer._id] !== undefined}
                        tabIndex={-1}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={layer.name}
                      className={classes.listItemText}
                    />
                    {canDeleteMap && (
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          color="secondary"
                          onClick={() => onDeleteLayer(layer)}
                          disabled={layer.createdById !== user.id}
                        >
                          <DeleteForever />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))
              : Array(30)
                  .fill(null)
                  .map(() => (
                    <ListItem>
                      <ListItemIcon>
                        <Checkbox edge="start" tabIndex={-1} />
                      </ListItemIcon>
                      <Skeleton width="100%" height={25} />
                    </ListItem>
                  ))}
          </Paper>
        </Grid>
        {canCreateRecipientListFromMap && showFilterButton && (
          <Grid item>
            <Button
              component={Link}
              to="/recipient-lists/create/map"
              variant="contained"
              color="primary"
              startIcon={<AddCircle />}
              className={classes.createListButton}
              fullWidth
            >
              {t("Create List")}
            </Button>
          </Grid>
        )}
      </Grid>
      <SaveLayerModal
        open={saveLayerModalOpen}
        onClose={onSaveLayerModalClose}
        onSave={onSaveMapLayer}
      />
    </>
  );
}

export default memo(MapControls);
