import React, { useState, useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { CirclePicker as ColorPicker } from "react-color";
import _omit from "lodash/omit";
import {
  Modal,
  Fade,
  Paper,
  Grid,
  Typography,
  IconButton,
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormHelperText,
} from "../../components";
import { Close, Save } from "../../icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "80vw",
    maxWidth: 420,
    maxHeight: "90vh",
    margin: "auto",
    marginTop: 100,
    padding: theme.spacing(2),
    outline: "none !important",
  },
  colorPicker: {
    width: "100% !important",
    alignItems: "center",
  },
  colorLabel: {
    marginBottom: theme.spacing(1),
  },
  colorError: {
    marginLeft: theme.spacing(2),
  },
}));

function SaveMapLayerModal({ open, onClose, onSave }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [state, setState] = useState({});
  const [errors, setErrors] = useState({});

  const { color, name } = state;
  const hasErrors = 0 < Object.keys(errors).length;

  const onModalClose = useCallback(() => {
    setState({});
    setErrors({});
    onClose();
  }, [onClose, setErrors, setState]);

  const onChange = useCallback(
    ({ target }) => {
      setState({
        ...state,
        [target.name]: target.value,
      });
      setErrors(_omit(errors, target.name));
    },
    [setState, state, setErrors, errors]
  );

  const onColorChange = useCallback(
    (color) => {
      setState({ ...state, color: color.hex });
      setErrors(_omit(errors, "style.color"));
    },
    [setState, state, setErrors, errors]
  );

  const onSaveLayer = useCallback(async () => {
    try {
      await onSave({ color, name });
      onModalClose();
    } catch (err) {
      const errors = err?.response?.data;
      if (errors) {
        setErrors(errors);
      }
    }
  }, [setErrors, onSave, color, name, onModalClose]);

  return (
    <Modal
      open={open}
      onClose={onModalClose}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper className={classes.modal}>
          <Grid container spacing={2} wrap="nowrap" direction="column">
            <Grid item>
              <Grid container alignItems="center" justify="space-between">
                <Typography variant="h6">{t("Save Map Layer")}</Typography>
                <IconButton onClick={onModalClose} color="secondary">
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                label={t("Name")}
                onChange={onChange}
                name="name"
                value={name}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            </Grid>
            <Grid item>
              <FormControl error={Boolean(errors["style.color"])} fullWidth>
                <FormLabel className={classes.colorLabel}>
                  {t("Color")}
                </FormLabel>
                <ColorPicker
                  color={color}
                  onChangeComplete={onColorChange}
                  className={classes.colorPicker}
                />
                {errors["style.color"] && (
                  <FormHelperText className={classes.colorError}>
                    {errors["style.color"]}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item>
              <Grid container spacing={2} justify="flex-end">
                <Grid item>
                  <Button variant="contained" onClick={onModalClose}>
                    {t("Cancel")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Save />}
                    onClick={onSaveLayer}
                    disabled={hasErrors}
                  >
                    {t("Save Layer")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  );
}

export default memo(SaveMapLayerModal);
