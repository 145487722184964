import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  ActionButton,
  Grid,
  Search,
  DateTimePicker,
  useMediaQuery,
} from "../../../components";
import {
  useAuthorized,
  SEED_CREATE_PERMISSION,
} from "../../../hooks/useAuthorized";

const useStyles = makeStyles((theme) => ({
  primaryContainer: {
    flexGrow: 1,
    maxWidth: 1000,
  },
  createButton: {
    height: "100%",
  },
  searchContainer: {
    flex: 2,
    minWidth: 140,
    maxWidth: 800,
  },
  permissionContainer: {
    flex: 1,
    minWidth: 140,
    maxWidth: 220,
  },
}));

function SeedsFilters({
  params,
  onSearchChange,
  onFromDateChange,
  onToDateChange,
  testId,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const canCreate = useAuthorized(SEED_CREATE_PERMISSION);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  return (
    <>
      <Grid container spacing={2} justify="space-between">
        <Grid item className={classes.primaryContainer}>
          <Grid container spacing={2}>
            {canCreate ? (
              <ActionButton
                classes={{ desktop: classes.createButton }}
                component={Link}
                to="/seeds/create"
                text={t("New Seed")}
                data-test-id={`${testId}-create-new`}
              />
            ) : null}
            <Grid item className={classes.searchContainer}>
              <Search
                variant="outlined"
                size="small"
                label={
                  isSmallScreen ? t("Search") : t("Search by name/creator")
                }
                onSearch={onSearchChange}
                defaultValue={params.search}
                debounce={300}
                fullWidth
                testId={testId}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} wrap="nowrap">
            <Grid item>
              <DateTimePicker
                label={t("Created after")}
                disableFuture
                defaultValue={params.fromDate}
                maxDate={params.toDate}
                onAccept={onFromDateChange}
                testId={`${testId}-after`}
              />
            </Grid>
            <Grid item>
              <DateTimePicker
                label={t("Created before")}
                disableFuture
                defaultValue={params.toDate}
                minDate={params.fromDate}
                onAccept={onToDateChange}
                testId={`${testId}-before`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default memo(SeedsFilters);
