import * as approverApi from "../api/approver";

export const LIST_APPROVERS = "LIST_APPROVERS";
export const CLEAR_APPROVERS = "CLEAR_APPROVERS";

export function clearApprovers() {
  return { type: CLEAR_APPROVERS };
}

export function listApprovers(params) {
  return async (dispatch) => {
    const approvers = await approverApi.listApprovers(params);
    dispatch({ type: LIST_APPROVERS, approvers });
  };
}
