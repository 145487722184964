import * as api from "../api/eons";
import { batch } from "react-redux";
import { setStorageFields } from "./storage";
import { setParams } from "./params";

const EONS_TYPE = "EONS";
const EMAIL_TYPE = "EMAIL";
const SMS_TYPE = "SMS";
const VOICE_TYPE = "VOICE";
const INBOUND_TYPE = "INBOUND";
const CUSTOM_TYPE = "CUSTOM";
const URL_TYPE = "URL";
const CPP_TYPE = "CPP";
const LOGIC_TYPE = "Logic";
const UNSUB_TYPE = "UNSUBSCRIBE";

export const SET_EONS_CONFIG = "SET_EONS_CONFIG";

export function setEonsConfig(
  activeDivisionId,
  companyId,
  { resetParams = true } = {}
) {
  return async (dispatch) => {
    // fetch the related eons config for the activeDivision before changing the active id
    // divisions which doesn't have a valid eons config can not be active divisions inside eons
    const fetchedConfig = await api.getEonsConfig({ activeDivisionId });
    const newConfig = fetchedConfig.reduce((config, channelConfig) => {
      switch (channelConfig.messageType) {
        case EONS_TYPE:
          return {
            ...config,
            vendorConfig: channelConfig.vendorConfig,
            logoImage: channelConfig.logoImage,
          };
        case EMAIL_TYPE:
          return { ...config, emailConfig: channelConfig.vendorConfig };
        case VOICE_TYPE:
          return { ...config, voiceConfig: channelConfig.vendorConfig };
        case SMS_TYPE:
          return { ...config, smsConfig: channelConfig.vendorConfig };
        case CUSTOM_TYPE:
          return { ...config, customConfig: channelConfig.vendorConfig };
        case INBOUND_TYPE:
          return { ...config, inboundConfig: channelConfig.vendorConfig };
        case URL_TYPE:
          return { ...config, urlConfig: channelConfig.vendorConfig };
        case CPP_TYPE:
          return { ...config, cppConfig: channelConfig.vendorConfig };
        case LOGIC_TYPE:
          return { ...config, logicConfig: channelConfig.vendorConfig };
        case UNSUB_TYPE:
          return { ...config, unsubscribeConfig: channelConfig.vendorConfig };
        default:
          return { ...config };
      }
    }, {});
    if (newConfig.vendorConfig && newConfig.vendorConfig.callerId) {
      const callerIdDetails = await api.getCalleridDetail(
        newConfig.vendorConfig.callerId
      );
      newConfig.callerIdDetail = callerIdDetails;
    }

    const paid = fetchedConfig[0].projectAppId;
    batch(() => {
      // active division id is stored in the localStorage to let the user automatically log into
      // their recently used division upon login
      dispatch(setStorageFields({ activeDivisionId }));
      dispatch(setStorageFields({ companyId }));
      dispatch(setStorageFields({ paid }));
      dispatch({ type: SET_EONS_CONFIG, newConfig });

      // reset the current query params when the user switches division
      // the current query params probably wouldn't make sense to the new division
      // for example the new division may have a different amount of tabular data for the current page
      if (resetParams) {
        dispatch(setParams({}, { overwrite: true }));
      }
    });
  };
}
