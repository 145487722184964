import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Cloud, Error } from "../../../../../../icons";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    color: "inherit",
  },
  cloudIcon: {
    width: "100%",
    height: "100%",
  },
  errorIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -40%)",
    color: theme.palette.error.contrastText,
    width: "auto",
    height: "45%",
  },
}));

function UploadErrorIcon({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div {...rest} className={classNames(className, classes.root)}>
      <Cloud className={classes.cloudIcon} />
      <Error className={classes.errorIcon} />
    </div>
  );
}

export default memo(UploadErrorIcon);
