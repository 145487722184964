import React, { useRef, useMemo, useCallback, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Wizard } from "../../../../components";
import {
  saveNewRecipientList,
  uploadNewRecipientChunks,
  validateRecipientsFile,
  validateRecipientsHeaderMapping,
  buildNewRecipientList,
} from "../../../../actions/newRecipientList";
import Upload from "./Upload";
import MapHeaders from "./MapHeaders";
import Save from "../Save";
import NavigationPrompt from "react-router-navigation-prompt";
import { NavigationDialog } from "../../../../components";

const useStyles = makeStyles((theme) => ({
  wizard: {
    // marginTop: "auto",
  },
}));

const getSteps = ({ t, validateFile, validateMapping, headersMappingRef }) => [
  {
    label: t("Upload File"),
    path: "/recipient-lists/create/file/upload",
    component: Upload,
    validate: validateFile,
  },
  {
    label: t("Map Headers"),
    path: "/recipient-lists/create/file/map-headers",
    component: () => <MapHeaders scrollerRef={headersMappingRef} />,
    validate: validateMapping,
  },
  {
    label: t("Save"),
    path: "/recipient-lists/create/file/save",
    component: Save,
  },
];

function CreateRecipientListFromFile({ onAbort, afterSave, children }) {
  const headersMappingRef = useRef();
  const dispatch = useDispatch();
  const newRecipientList = useSelector((state) => state.newRecipientList);
  const { doneStepIdx, redirect } = newRecipientList;
  const classes = useStyles();
  const { t } = useTranslation();

  const validateFile = useCallback(() => dispatch(validateRecipientsFile()), [
    dispatch,
  ]);

  const validateMapping = useCallback(() => {
    const isValid = dispatch(validateRecipientsHeaderMapping());
    if (!isValid && headersMappingRef.current) {
      headersMappingRef.current.scrollTop = 0;
    }
    return isValid;
  }, [dispatch, headersMappingRef]);

  const steps = useMemo(
    () =>
      getSteps({
        t,
        validateFile,
        validateMapping,
        headersMappingRef,
      }),
    [t, validateFile, validateMapping]
  );

  const onStepChange = useCallback(
    (activeStepIdx) =>
      dispatch(buildNewRecipientList({ activeStepIdx, errors: {} })),
    [dispatch]
  );

  const onSave = useCallback(async () => {
    const recipientListId = await dispatch(saveNewRecipientList());
    const onComplete = async () => {
      if (afterSave) {
        afterSave(recipientListId);
      }
    };

    await dispatch(uploadNewRecipientChunks(onComplete, redirect));
  }, [dispatch, afterSave]);

  return (
    <>
      <NavigationPrompt
        when={(crntLocation, nextLocation, _action) =>
          !nextLocation || !nextLocation.pathname.startsWith("/recipient-lists")
        }
      >
        {({ onConfirm, onCancel, isActive }) => (
          <NavigationDialog
            onConfirm={onConfirm}
            onCancel={onCancel}
            open={isActive}
          />
        )}
      </NavigationPrompt>
      <Wizard
        steps={steps}
        className={classes.wizard}
        doneStepIdx={doneStepIdx}
        onStepChange={onStepChange}
        disableNext={
          Object.keys(newRecipientList.errors).length ||
          newRecipientList.loading
        }
        errors={newRecipientList.errors}
        onSave={onSave}
        onAbort={onAbort}
      />
      {children}
      <Switch>
        {steps.slice(0, doneStepIdx + 2).map((step) => (
          <Route key={step.path} path={step.path} component={step.component} />
        ))}
        <Redirect to={steps[newRecipientList.doneStepIdx + 1].path} />
      </Switch>
    </>
  );
}

export default memo(CreateRecipientListFromFile);
