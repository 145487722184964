import api from "./api";

const keywordEndpoint = `/api/v1/sms/keyword`;

export async function getKeywordList(params) {
  const { data } = await api.get(`${keywordEndpoint}`, { params });
  return data;
}

export async function deleteKeyword(params) {
  await api.delete(`${keywordEndpoint}`, { params });
}

export async function deleteKeywordById(id) {
  await api.delete(`${keywordEndpoint}/${id}`);
}

export async function saveKeyword(body) {
  await api.post(`${keywordEndpoint}`, body);
}

export async function updateKeywordById(body) {
  const id = body._id;
  const { paid, keyword, value, shortcode } = body;
  await api.patch(`${keywordEndpoint}/${id}`, { keyword, value, shortcode });
}
