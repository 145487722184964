import React, { useState, useCallback, memo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Typography,
} from "../../../components";
import { ExpandMore } from "../../../icons";

function MasterTemplateSection({
  title,
  enabled,
  onToggle,
  children,
  readOnly,
  error,
  isEmpty,
  ...rest
}) {
  const [isExpanded, setExpanded] = useState(enabled);

  const onExpandToggle = useCallback(
    (ev, isExpanded) => {
      setExpanded(isExpanded);
      if (!readOnly && isEmpty) {
        onToggle();
      }
    },
    [setExpanded, readOnly, isEmpty, onToggle]
  );

  const stopPropagation = useCallback(
    (ev) => {
      if (!readOnly && enabled !== isExpanded) {
        ev.stopPropagation();
      }
    },
    [enabled, isExpanded, readOnly]
  );

  return (
    <Accordion {...rest} expanded={isExpanded} onChange={onExpandToggle}>
      <AccordionSummary expandIcon={<ExpandMore />} error={error}>
        {readOnly ? (
          <Typography>{title}</Typography>
        ) : (
          <FormControlLabel
            onClick={stopPropagation}
            onFocus={stopPropagation}
            data-test-id={"master-template-toggle-" + title}
            control={
              <Checkbox
                data-test-id={"master-template-" + title + "-checkbox"}
                color="primary"
                checked={enabled}
                onChange={!readOnly && onToggle}
              />
            }
            label={title}
          />
        )}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

export default memo(MasterTemplateSection);
