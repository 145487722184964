import api from "./api";

const reportsBasePath = "/api/v1/reports";

export async function getReport(campaignId, params) {
  const { data } = await api.get(`${reportsBasePath}/${campaignId}`, {
    params,
  });
  const formattedData = Object.keys(data).reduce((acc, key) => {
    acc[key.toLowerCase()] = data[key];
    return acc;
  }, {});
  return formattedData;
}

export async function getCampaignRelatedRecipients(campaignId, params) {
  const { data } = await api.get(`${reportsBasePath}/search/${campaignId}`, {
    params,
  });
  return data;
}

export async function getCampaignStatusPercentages(campaignId, params) {
  const { data } = await api.get(`${reportsBasePath}/count/${campaignId}`, {
    params,
  });
  return data;
}

export async function getCampaignReportFileList(campaignId, params) {
  const { data } = await api.get(
    `${reportsBasePath}/${campaignId}/reportFileList`,
    {
      params,
    }
  );
  const response = await api.get(
    `${reportsBasePath}/${campaignId}/reportFileList`,
    {
      params,
    }
  );
  return data;
}

export async function generateCampaignReportFile(campaignId, params) {
  const { activeDivisionId, identifier } = params;
  const {
    data,
  } = await api.post(
    `${reportsBasePath}/${campaignId}/generate?activeDivisionId=${activeDivisionId}`,
    { identifier }
  );
  return data;
}

export async function patchCampaignReportFile(campaignId, params) {
  const { activeDivisionId, identifier } = params;
  const {
    data,
  } = await api.post(
    `${reportsBasePath}/${campaignId}/generate?activeDivisionId=${activeDivisionId}`,
    { identifier }
  );
  return data;
}

export async function downloadCampaignReportFile(campaignId, params) {
  const { activeDivisionId, identifier } = params;

  // const response = await api.get(
  //   `${reportsBasePath}/${campaignId}/download`, {params } )
  // console.log(response.data)
  const response = await api.get(`${reportsBasePath}/${campaignId}/download`, {
    params,
    responseType: "blob",
  });

  const blob = new Blob([response.data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  // console.log(url)

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${campaignId}_${identifier}.csv`);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
}
