import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { CircularProgress } from "../../../../../../components";
import { Cloud } from "../../../../../../icons";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  icon: {
    width: "100%",
    height: "100%",
  },
  loaderContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -35%)",
    height: "auto",
    width: "30%",
  },
  loader: {
    color: theme.palette.common.white,
    width: "100% !important",
    height: "100% !important",
  },
}));

function UploadLoaderIcon({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div className={classNames(className, classes.root)} {...rest}>
      <Cloud className={classes.icon} />
      <div className={classes.loaderContainer}>
        <CircularProgress className={classes.loader} />
      </div>
    </div>
  );
}

export default memo(UploadLoaderIcon);
