import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CheckCircleOutline, HighlightOff } from "../../../icons";

const useStyles = makeStyles((theme) => ({
  configured: {
    color: theme.palette.success.main,
  },
  notConfigured: {
    color: theme.palette.text.disabled,
  },
}));

function MasterTemplateChannelIndicator({ subTemplateId, testId, ...rest }) {
  const classes = useStyles();

  if (!subTemplateId) {
    return (
      <HighlightOff
        size={20}
        className={classes.notConfigured}
        data-test-id={`template-indicator-notconfigured-${testId}`}
      />
    );
  } else {
    return (
      <CheckCircleOutline
        className={classes.configured}
        data-test-id={`template-indicator-${testId}-${subTemplateId}`}
      />
    );
  }
}

export default memo(MasterTemplateChannelIndicator);
