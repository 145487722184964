export const libraries = ["drawing", "places"];

export function initDrawing({ maps, map, theme }) {
  const { OverlayType, DrawingManager } = maps.drawing;

  const shapeStyle = {
    fillColor: theme.palette.primary.main,
    fillOpacity: 0.2,
    strokeColor: theme.palette.primary.main,
    strokeWeight: 2,
    clickable: true,
    zIndex: 1,
  };

  const drawingModes = [
    OverlayType.CIRCLE,
    OverlayType.RECTANGLE,
    OverlayType.POLYGON,
  ];

  const drawingManager = new DrawingManager({
    drawingControl: false,
    drawingControlOptions: {
      drawingModes,
    },
    circleOptions: shapeStyle,
    rectangleOptions: shapeStyle,
    polygonOptions: shapeStyle,
  });

  drawingManager.setMap(map);
  return drawingManager;
}

export function initSearch({ maps, map, input, t }) {
  const searchBox = new maps.places.SearchBox(input);
  input.placeholder = t("Enter a place");

  // Bias the SearchBox results towards current map's viewport.
  map.addListener("bounds_changed", () => searchBox.setBounds(map.getBounds()));
  // Listen for the event fired when the user selects a prediction and retrieve
  // more details for that place.
  searchBox.addListener("places_changed", () => {
    input.blur();
    const places = searchBox.getPlaces();

    if (places.length === 0) {
      return;
    }
    // For each place, get the icon, name and location.
    const bounds = new maps.LatLngBounds();
    places.forEach((place) => {
      if (!place.geometry) {
        // eslint-disable-next-line no-console
        console.log("Returned place contains no geometry");
        return;
      }
      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    map.fitBounds(bounds);
  });
}
