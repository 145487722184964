import React, { useMemo, useCallback, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ListMasterTemplates from "../../../MasterTemplates/List/Base";
import ViewMasterTemplate from "../../../MasterTemplates/Single/Base";
import { setCampaign } from "../../../../actions/campaign";
import * as templateApi from "../../../../api/masterTemplate";
import {
  Alert,
  Modal,
  Paper,
  Typography,
  IconButton,
  Grid,
  Fade,
} from "../../../../components";
import { Close } from "../../../../icons";
import Actions from "./Actions";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(2),
  },
  viewTemplate: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2),
    maxHeight: `calc(100vh - ${theme.spacing(4)}px)`,
    backgroundColor: theme.palette.background.default,
  },
  viewTemplateHeader: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    paddingBottom: 0,
  },
}));

const getActions = ({
  t,
  selectedTemplateId,
  onSelectTemplate,
  onOpenViewTemplate,
}) => [
  {
    name: "actions",
    getter: (masterTemplate) => (
      <Actions
        masterTemplate={masterTemplate}
        selectedTemplateId={selectedTemplateId}
        onSelectTemplate={onSelectTemplate}
        onOpenViewTemplate={onOpenViewTemplate}
      />
    ),
    label: t("Select"),
    sortable: false,
    width: 0.8,
    align: "center",
  },
];

function SelectCampaignMasterTemplate() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const campaign = useSelector((state) => state.campaign);
  const activeDivisionId = useSelector(
    (state) => state.storage?.activeDivisionId
  );
  const paid = useSelector((state) => state.storage?.paid);
  const { templateId, errors = {} } = campaign || {};
  const [viewTemplate, setViewTemplate] = useState();

  const onSelectTemplate = useCallback(
    (masterTemplate) => {
      if (masterTemplate) {
        const { _id: templateId, inboundTemplateId } = masterTemplate;
        dispatch(
          setCampaign({
            templateId,
            inboundTemplateId,
            errors: omit(errors, "templateId"),
          })
        );
      } else {
        dispatch(
          setCampaign({
            errors: omit(errors, "templateId"),
          })
        );
      }
    },
    [dispatch, errors]
  );

  const onCloseViewTemplate = useCallback(() => setViewTemplate(null), [
    setViewTemplate,
  ]);

  const onOpenViewTemplate = useCallback(
    async (masterTemplateId) => {
      const viewTemplate = await templateApi.getMasterTemplate(
        masterTemplateId,
        { activeDivisionId, paid }
      );
      setViewTemplate(viewTemplate);
    },
    [setViewTemplate, activeDivisionId]
  );

  const actions = useMemo(
    () =>
      getActions({
        t,
        onSelectTemplate,
        onOpenViewTemplate,
        selectedTemplateId: templateId,
      }),
    [t, onSelectTemplate, onOpenViewTemplate, templateId]
  );

  const allowedStatus = ["COMPLETED", "LOCKED"];
  return (
    <>
      <Grid container spacing={2} justify="center">
        <ListMasterTemplates
          extraColumns={actions}
          onlyStatus={allowedStatus}
          hideCreate
          data-test-id="campaign-template-table"
          isInboundTemplate={true}
          onlyType={["inbound"]}
        />
        {errors.templateId && (
          <Alert
            severity="error"
            variant="filled"
            className={classes.alert}
            data-test-id="campaign-template-error-alert"
          >
            {t("You must select a template")}
          </Alert>
        )}
        <Modal
          open={Boolean(viewTemplate)}
          onClose={onCloseViewTemplate}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={Boolean(viewTemplate)}>
            <Paper
              className={classes.viewTemplate}
              data-test-id="campaign-template-view-modal"
            >
              <Grid
                container
                alignItems="center"
                justify="space-between"
                className={classes.viewTemplateHeader}
              >
                <Typography variant="h6">{t("View Template")}</Typography>
                <IconButton onClick={onCloseViewTemplate} color="secondary">
                  <Close />
                </IconButton>
              </Grid>
              <ViewMasterTemplate masterTemplate={viewTemplate} readOnly />
            </Paper>
          </Fade>
        </Modal>
      </Grid>
    </>
  );
}

export default memo(SelectCampaignMasterTemplate);
