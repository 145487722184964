import React, { useCallback, memo, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Paper,
  Search,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  IconButton,
  Skeleton,
  Divider,
} from "../../../../../components";
import { Close, DoubleArrow } from "../../../../../icons";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  list: {
    overflow: "auto",
    height: 200,
    position: "relative",
  },
  hint: {
    position: "absolute",
    top: "30%",
    transform: "translateY(-50%)",
    width: "100%",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.hint,
  },
  error: {
    color: theme.palette.error.main,
  },
  listIcon: {
    minWidth: 0,
  },
  reverseIcon: {
    transform: "rotate(180deg)",
  },
  skeleton: {
    width: "100%",
  },
  header: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    lineHeight: 1.2,
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  listHeader: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    lineHeight: 1.2,
  },
  input: {
    padding: 0,
  },
  listItemText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: theme.spacing(1),
  },
}));

function MasterListValuePicker({
  header,
  options,
  error,
  onSearch,
  onToggle,
  onValueToggle,
  ...rest
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const _onSearch = useCallback((value) => onSearch(header, value), [
    header,
    onSearch,
  ]);

  const _onToggle = useCallback(() => onToggle(header), [onToggle, header]);

  const _onValueToggle = useCallback((value) => onValueToggle(header, value), [
    onValueToggle,
    header,
  ]);

  const { recipientFilters } = useSelector((state) => state.newRecipientList);

  useEffect(() => {
    onSearch(header, "");
  }, [header, onSearch]);

  let loading = false;
  let { values, selectedValues } = recipientFilters[header];
  if (!values) {
    loading = true;
    values = Array(10)
      .fill(null)
      .map((_, idx) => idx);
    selectedValues = [];
  }
  values = values.filter((value) => selectedValues.indexOf(value) === -1);

  const hasError = Boolean(error);

  return (
    <Paper {...rest} className={classes.root} error={hasError}>
      <div className={classes.header}>
        <Typography variant="button">{header}</Typography>
        <IconButton color="secondary" size="small" onClick={_onToggle}>
          <Close fontSize="small" />
        </IconButton>
      </div>
      <Divider />
      <Grid container spacing={0} wrap="nowrap">
        <Grid item xs={6} xl={6}>
          <Search
            size="small"
            debounce={300}
            onSearch={_onSearch}
            fullWidth
            placeholder={t("Search values ...")}
            className={classes.listHeader}
            inputProps={{ className: classes.input }}
            InputProps={{ disableUnderline: true }}
          />
          <List dense className={classes.list}>
            {values.length ? (
              values.map((value) => (
                <ListItem
                  key={value}
                  button
                  onClick={() => _onValueToggle(value)}
                >
                  {loading ? (
                    <Skeleton className={classes.skeleton} />
                  ) : (
                    <ListItemText
                      primary={value}
                      classes={{ primary: classes.listItemText }}
                    />
                  )}
                  <ListItemIcon className={classes.listIcon}>
                    <DoubleArrow fontSize="small" color="primary" />
                  </ListItemIcon>
                </ListItem>
              ))
            ) : (
              <Typography variant="button" className={classes.hint}>
                {t("No values found")}
              </Typography>
            )}
          </List>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={6} xl={6}>
          <Typography className={classes.listHeader}>
            {t("Selected values")}
          </Typography>
          <List dense className={classes.list}>
            {error ? (
              <Typography
                variant="button"
                className={classNames(classes.hint, classes.error)}
              >
                {error}
              </Typography>
            ) : selectedValues.length ? (
              selectedValues.map((value) => (
                <ListItem
                  key={value}
                  button
                  onClick={() => _onValueToggle(value)}
                >
                  <ListItemText
                    primary={value}
                    classes={{ primary: classes.listItemText }}
                  />
                  <ListItemIcon className={classes.listIcon}>
                    <DoubleArrow
                      fontSize="small"
                      color="secondary"
                      className={classes.reverseIcon}
                    />
                  </ListItemIcon>
                </ListItem>
              ))
            ) : (
              <Typography variant="button" className={classes.hint}>
                {t("No items added yet")}
              </Typography>
            )}
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default memo(MasterListValuePicker);
