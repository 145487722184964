import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  memo,
  useCallback,
} from "react";
import i18n from "i18next";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import _debounce from "lodash/debounce";
import { buildMap } from "../../actions/map";
import { Grid, TextField } from "../../components";
import MapControls from "./MapControls";
import Marker from "./Marker";
import { libraries, initSearch, initDrawing } from "./mapServices";
import "./Map.css";
import { notify } from "../../actions/notification";
import useSupercluster from "use-supercluster";

const ClusterMarker = ({ children }) => children;
const mapKey = {
  key: process.env.REACT_APP_MAP_API_KEY,
  libraries,
};

// the default is 30
const hoverDistance = 20;

const getPoints = (enabledLayers) =>
  Object.keys(enabledLayers).reduce((points, key) => {
    const layer = enabledLayers[key].layer;
    const { style = {}, data } = layer;
    const newPoints = data.features
      .filter((feature) => feature.geometry.type === "Point")
      .map((point) => ({
        type: point.type,
        geometry: {
          ...point.geometry,
          coordinates: [
            point.geometry.coordinates[1], // supercluster lib have the coordinates swapped
            point.geometry.coordinates[0],
          ],
        },
        properties: {
          color: style.color,
          icon: style.icon,
          ...point.properties,
          cluster: false,
          id: point.properties.identifier,
          category: key,
          info: point.properties.info,
        },
      }));

    return [...points, ...newPoints];
  }, []);

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    minHeight: 0,
  },
  map: {
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    width: "100%",
    height: "100%",
    position: "relative",
  },
  mapSearch: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1),
    zIndex: 100,
    backgroundColor: theme.palette.background.paper,
  },
  mapControls: {
    maxHeight: "100%",
    display: "flex",
  },
  clusterMarker: {
    color: "#fff",
    background: "#1978c8",
    borderRadius: "50%",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function MapPageMap({ showFilterButton = true }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const searchRef = useRef();

  const { vendorConfig: eonsConfig } = useSelector((state) => state.eonsConfig);
  const defaultCenter = useMemo(() => {
    const defaultCenter = {
      lat: eonsConfig.centerLatitude
        ? Number(eonsConfig.centerLatitude)
        : 59.95,
      lng: eonsConfig.centerLongitude
        ? Number(eonsConfig.centerLongitude)
        : 30.33,
    };
    return defaultCenter;
  }, [eonsConfig]);

  const dispatch = useDispatch();
  const { center = defaultCenter, enabledLayers = {}, bounds = [] } =
    useSelector((state) => state.map) || {};

  let { zoom = eonsConfig.defaultMapZoom ? eonsConfig.defaultMapZoom : 7 } =
    useSelector((state) => state.map) || {};

  if (isNaN((zoom = parseInt(zoom)))) {
    dispatch(
      notify({
        type: "error",
        message: i18n.t("Something's wrong in Eons's Map configuration"),
      })
    );
    zoom = 7;
  }

  const [{ map, maps, drawingManager }, setState] = useState({});

  const onMapLoaded = useCallback(
    ({ map, maps }) => {
      mapRef.current = map;
      const drawingManager = initDrawing({ map, maps, theme });
      initSearch({ map, maps, input: searchRef.current, t });

      setState({ map, maps, drawingManager });
    },
    [setState, theme, searchRef, t]
  );

  const mapRef = useRef();

  useEffect(() => {
    if (map && maps) {
      const listener = map.addListener(
        "bounds_changed",
        // update the map position in the store with a debounce on position change
        // this is needed to continue from the last map position when the user switches
        // between the map on the maps page and the map on the filter recipient page
        _debounce(
          () =>
            dispatch(
              buildMap({
                center: map?.getCenter()?.toJSON(),
                zoom: map?.getZoom(),
              })
            ),
          500
        )
      );
      return () => maps.event.removeListener(listener);
    }
  }, [map, maps, dispatch]);

  const points = useMemo(() => getPoints(enabledLayers), [enabledLayers]);
  const { clusters, supercluster } = useSupercluster({
    points: points,
    bounds,
    zoom,
    options: {
      radius: 100,
      maxZoom: 20,
      map: (props) => ({ color: props.color }),
      reduce: (accumulated, props) => {
        accumulated.color = props.color;
      },
    },
  });

  return (
    <>
      <Grid container spacing={2} wrap="nowrap" className={classes.container}>
        <Grid item xs>
          <div className={classes.map}>
            <TextField
              inputRef={searchRef}
              className={classes.mapSearch}
              size="small"
              variant="outlined"
            />
            {eonsConfig && (
              <GoogleMapReact
                bootstrapURLKeys={mapKey}
                defaultCenter={center}
                defaultZoom={zoom}
                hoverDistance={hoverDistance}
                onChange={({ zoom, bounds }) => {
                  dispatch(
                    buildMap({
                      bounds: [
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat,
                      ],
                    })
                  );
                }}
                onGoogleApiLoaded={onMapLoaded}
                yesIWantToUseGoogleMapApiInternals
              >
                {map &&
                  clusters.map((cluster) => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const {
                      cluster: isCluster,
                      point_count: pointCount,
                      info,
                      color,
                    } = cluster.properties;
                    if (isCluster) {
                      return (
                        <ClusterMarker
                          key={`cluster-${cluster.id}`}
                          lat={latitude}
                          lng={longitude}
                        >
                          <div
                            className={classes.clusterMarker}
                            style={{
                              width: `${
                                10 + (pointCount / points.length) * 20
                              }px`,
                              height: `${
                                10 + (pointCount / points.length) * 20
                              }px`,
                              background: color,
                            }}
                            onClick={() => {
                              const expansionZoom = Math.min(
                                supercluster.getClusterExpansionZoom(
                                  cluster.id
                                ),
                                20
                              );
                              mapRef.current.setZoom(expansionZoom);
                              mapRef.current.panTo({
                                lat: latitude,
                                lng: longitude,
                              });
                            }}
                          >
                            {pointCount}
                          </div>
                        </ClusterMarker>
                      );
                    } else {
                      return (
                        <Marker
                          key={`point-${cluster.properties.id}`}
                          lat={latitude}
                          lng={longitude}
                          $hover={true}
                          label={info}
                          color={color}
                        ></Marker>
                      );
                    }
                  })}
              </GoogleMapReact>
            )}
          </div>
        </Grid>
        {map && (
          <Grid item className={classes.mapControls}>
            <MapControls
              drawingManager={drawingManager}
              maps={maps}
              map={map}
              showFilterButton={showFilterButton}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default memo(MapPageMap);
