import React, { useMemo, Fragment, memo } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import {
  Paper,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Link,
  Typography,
  Skeleton,
} from "../../../../components";

const useStyles = makeStyles((theme) => ({
  countsTable: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 2,
  },
  recipientsTitle: {
    marginBottom: theme.spacing(1),
  },
  infoHeader: {
    marginRight: theme.spacing(1),
  },
  infoSkeleton: {
    width: 140,
    display: "inline-block",
  },
}));

function CampaignInfo({
  name = "-",
  schedule = "-",
  permission = "-",
  recipientLists = [],
  masterTemplate = {},
  loading,
  counts = {},
  bypassDndDns,
  bypassSuppression,
  isHighPriority,
  ...rest
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper {...rest} p={2}>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justify="space-between"
      >
        <Grid item xs={12} sm={6}>
          <Grid container direction="column" spacing={1}>
            <Grid
              item
              xs={12}
              component={Typography}
              data-test-id="campaign-title"
            >
              <b className={classes.infoHeader}>{t("Title")}:</b>
              {loading ? (
                <Skeleton className={classes.infoSkeleton} />
              ) : (
                <span>{name}</span>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              component={Typography}
              data-test-id="campaign-permission"
            >
              <b className={classes.infoHeader}>{t("Permission")}:</b>
              {loading ? (
                <Skeleton className={classes.infoSkeleton} />
              ) : (
                <span>{permission}</span>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              component={Typography}
              data-test-id="campaign-recipient-lists"
            >
              <b className={classes.infoHeader}>{t("Recipient Lists")}:</b>
              {loading ? (
                <Skeleton className={classes.infoSkeleton} />
              ) : recipientLists.length ? (
                recipientLists.map((recipientList, idx) => (
                  <Fragment key={recipientList._id}>
                    <RouterLink
                      component={Link}
                      to={`/recipient-lists/${recipientList._id}`}
                    >
                      {recipientList.name}
                    </RouterLink>
                    {idx < recipientLists.length - 1 && ", "}
                  </Fragment>
                ))
              ) : (
                <span style={{ color: "red" }}>
                  {"Retention Period Expired"}
                </span>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              component={Typography}
              data-test-id="campaign-template"
            >
              <b className={classes.infoHeader}>{t("Template")}:</b>
              {loading ? (
                <Skeleton className={classes.infoSkeleton} />
              ) : (
                <RouterLink
                  component={Link}
                  to={`/master-templates/${masterTemplate._id}`}
                >
                  {masterTemplate.name}
                </RouterLink>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              component={Typography}
              data-test-id="campaign-schedule"
            >
              <b className={classes.infoHeader}>{t("Scheduled Launch")}:</b>
              {loading ? (
                <Skeleton className={classes.infoSkeleton} />
              ) : (
                <span>{format(new Date(schedule), "MMMM do hh:mm aa")}</span>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              component={Typography}
              data-test-id="campaign-bypass-dnd"
            >
              <b className={classes.infoHeader}>{t("Bypass DND")}:</b>
              {loading ? (
                <Skeleton className={classes.infoSkeleton} />
              ) : bypassDndDns ? (
                "Yes"
              ) : (
                "No"
              )}
            </Grid>
            <Grid
              item
              xs={12}
              component={Typography}
              data-test-id="campaign-bypass-suppression"
            >
              <b className={classes.infoHeader}>{t("Bypass Suppression")}:</b>
              {loading ? (
                <Skeleton className={classes.infoSkeleton} />
              ) : bypassSuppression ? (
                "Yes"
              ) : (
                "No"
              )}
            </Grid>
            <Grid
              item
              xs={12}
              component={Typography}
              data-test-id="campaign-priority"
            >
              <b className={classes.infoHeader}>{t("High Priority")}:</b>
              {loading ? (
                <Skeleton className={classes.infoSkeleton} />
              ) : isHighPriority ? (
                "Yes"
              ) : (
                "No"
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.recipientsTitle}>
            <b>{t("Recipients count")}</b>
          </Typography>
          <TableContainer
            className={classes.countsTable}
            data-test-id="campaign-recipient-counts"
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{t("Total")}</TableCell>
                  <TableCell align="center">{t("SMS")}</TableCell>
                  <TableCell align="center">{t("Email")}</TableCell>
                  <TableCell align="center">{t("Voice")}</TableCell>
                  <TableCell align="center">{t("TTY")}</TableCell>
                  <TableCell align="center">{t("Custom")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    {loading ? (
                      <Skeleton />
                    ) : (
                      counts.smsCount +
                      counts.emailCount +
                      counts.voiceCount +
                      counts.ttyCount +
                      counts.customCount
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {loading ? <Skeleton /> : counts.smsCount}
                  </TableCell>
                  <TableCell align="center">
                    {loading ? <Skeleton /> : counts.emailCount}
                  </TableCell>
                  <TableCell align="center">
                    {loading ? <Skeleton /> : counts.voiceCount}
                  </TableCell>
                  <TableCell align="center">
                    {loading ? <Skeleton /> : counts.ttyCount}
                  </TableCell>
                  <TableCell align="center">
                    {loading ? <Skeleton /> : counts.customCount}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default memo(CampaignInfo);
