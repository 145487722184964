import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "../actions/notification";

export default function notificationsReducer(
  notification = null,
  { type, newNotification }
) {
  switch (type) {
    case ADD_NOTIFICATION:
      return newNotification;
    case REMOVE_NOTIFICATION:
      return null;
    default:
      return notification;
  }
}
