import api from "./api";

export async function listMapLayers(params) {
  const { data } = await api.get("/api/v1/map-layer", {
    params,
  });
  return data;
}

export async function createMapLayer(body, params) {
  const { data } = await api.post("/api/v1/map-layer", body, {
    params,
  });
  return data;
}

export async function deleteMapLayer(layerId, params) {
  const { data } = await api.delete(`/api/v1/map-layer/${layerId}`, {
    params,
  });
  return data;
}
