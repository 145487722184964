import React, { memo } from "react";
import { DropZone } from "../../../../components";
import { makeStyles } from "@material-ui/core/styles";
import { createFilePath } from "../../../Campaigns/Single/VoicePreview";

const useStyles = makeStyles((theme) => ({
  fileSelector: {
    width: "100%",
    height: 120,
  },
}));
function VoiceFileInput({
  idx,
  readOnly,
  onUpload,
  onReject,
  error,
  label,
  dataTestId,
  filePath,
}) {
  const classes = useStyles();
  return (
    <>
      <DropZone
        dataTestId={dataTestId}
        className={classes.fileSelector}
        color={readOnly ? null : error ? "error" : "primary"}
        accept=".mp3,.wav,.m4a,.ogg"
        multiple={false}
        onDropAccepted={(file) => onUpload(file, idx)}
        onDropRejected={() => onReject(idx)}
        label={label}
        variant="outlined"
        disabled={readOnly}
      />
      {filePath ? (
        <audio
          controls
          className="my-2"
          style={{ height: "30px", width: "100%" }}
        >
          <source src={createFilePath(filePath)} type="audio/mpeg" />
          Your browser does not support the HTML audio
        </audio>
      ) : null}
    </>
  );
}
export default memo(VoiceFileInput);
