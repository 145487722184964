import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { IconButton } from "../../../components";
import { Tooltip } from "@material-ui/core";
import { Visibility } from "../../../icons";

function CampaignsActions({ campaign }) {
  const { t } = useTranslation();
  const { _id } = campaign;
  const history = useHistory();
  const location = useLocation();

  const redirectCondition = () => {
    history.push(`/campaign/${_id}/review${location?.search}`);
  };

  return (
    <>
      <Tooltip title={t("Review campaign")}>
        <IconButton
          to={`/campaign/${_id}/review`}
          size="small"
          onClick={redirectCondition}
        >
          <Visibility fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default memo(CampaignsActions);
