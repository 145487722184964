import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Page,
  Paper,
  useMediaQuery,
  Grid,
  Button,
  Typography,
} from "../components";
import { AccountTree, FormatListNumbered, PieChart, Event } from "../icons";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import useAuthorized, {
  RECIPIENTLIST_LIST_PERMISSION,
  REPORT_LIST_PERMISSION,
  TEMPLATE_CREATE_PERMISSION,
  CAMPAIGN_LIST_PERMISSION,
  CAMPAIGN_CREATE_PERMISSION,
} from "../hooks/useAuthorized";

const useStyles = makeStyles((theme) => ({
  tile: {
    backgroundColor: "#E6E6E6",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: 16,
    textDecoration: "none",
  },
  icon: { fontSize: 220, padding: 16 },
  text: {
    padding: 12,
    fontSize: "large",
    fontWeight: "bold",
    textAlign: "center",
  },
  gridContainer: {
    minHeight: 800,
    minWidth: 900,
  },
}));

function Tile({ icon, link, buttonText, disabled }) {
  const classes = useStyles();
  return (
    <Box>
      <Paper className={classes.tile} component={NavLink} to={link}>
        {icon}
        <Button variant="contained" color="primary" disabled={disabled}>
          {buttonText}
        </Button>
      </Paper>
    </Box>
  );
}

function Homepage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const canCreateTemplates = useAuthorized(TEMPLATE_CREATE_PERMISSION);
  const canListReports = useAuthorized(REPORT_LIST_PERMISSION);
  const canListRecipientLists = useAuthorized(RECIPIENTLIST_LIST_PERMISSION);

  const canListCampaign = useAuthorized(CAMPAIGN_LIST_PERMISSION);
  const canCreateCampaign = useAuthorized(CAMPAIGN_CREATE_PERMISSION);
  const hasAccessToCampaignFeature = canListCampaign || canCreateCampaign;

  return (
    <Page
      title={t("Homepage")}
      p={2}
      screenSized={!isSmallScreen}
      justifyContent="center"
      alignItems="center"
    >
      <Box p={2}>
        <Paper elevation={3}>
          <Box p={6} pt={3} className={classes.gridContainer}>
            <Typography className={classes.text}>
              {t("Welcome To EONS! What Would You Like To Do?")}
            </Typography>
            <Grid container spacing={8}>
              {hasAccessToCampaignFeature && (
                <Grid item xs={6}>
                  <Tile
                    link="/campaigns/create"
                    buttonText={t("Create Campaign")}
                    icon={<Event color="primary" className={classes.icon} />}
                  />
                </Grid>
              )}
              {canListRecipientLists && (
                <Grid item xs={6}>
                  <Tile
                    link="/recipient-lists"
                    buttonText={t("Select Recipient List")}
                    icon={
                      <FormatListNumbered
                        color="primary"
                        className={classes.icon}
                      />
                    }
                  />
                </Grid>
              )}
              {canCreateTemplates && (
                <Grid item xs={6}>
                  <Tile
                    link="/master-templates/create"
                    buttonText={t("Build Template")}
                    icon={
                      <AccountTree color="primary" className={classes.icon} />
                    }
                  />
                </Grid>
              )}
              {canListReports && (
                <Grid item xs={6}>
                  <Tile
                    link="/reports"
                    buttonText={t("View Reports")}
                    icon={<PieChart color="primary" className={classes.icon} />}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Page>
  );
}

export default memo(Homepage);
