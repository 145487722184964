import { SET_DOWNLOAD_LIST } from "../actions/downloadReport";

export default function downloadReportReducer(
  downloadReportList = {},
  { type, list }
) {
  switch (type) {
    case SET_DOWNLOAD_LIST:
      return { list };
    default:
      return downloadReportList;
  }
}
