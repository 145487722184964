import React, { useCallback, useState, useMemo, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  PhoneInput,
  Tooltip,
  Typography,
  TextBoxWithValidation,
} from "../../../components";
import { sendTestMessage } from "../../../actions/masterTemplate";
import { notify } from "../../../actions/notification";
import RecipientFieldSelector from "./RecipientFieldSelector";
import { Info as InfoIcon } from "../../../icons";
import splitter from "split-sms";
import { getPlaceHolders } from "../../Campaigns/Single/FillData";
// import useAuthorized, {
//   TEMPLATE_CREATE_CUSTOMER_DATA_PERMISSION,
// } from "../../../hooks/useAuthorized";
import CustomFieldsDialog from "../../../components/CustomFieldsDialog";

const useStyles = makeStyles({
  testButton: {
    height: 40,
  },
  flexDivider: {
    marginLeft: "auto",
  },
  testSms: {
    height: 40,
  },
  smsParts: {
    margin: 10,
    float: "right",
  },
});

const getShortCodes = ({
  sinchShortCodes = [],
  xmlShortCodes = [],
  jasminShortCodes = [],
}) =>
  [...sinchShortCodes, ...xmlShortCodes, ...jasminShortCodes]
    .map(Number)
    .sort();

function SmsTemplateSection({
  smsTemplate = {},
  smsConfig,
  onSmsFieldChange,
  errors = {},
  readOnly,
}) {
  const [templateRef, setTemplateRef] = useState(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { template = "", shortCode = "", _id: templateId } = smsTemplate;
  const readOnlyProps = useMemo(() => ({ readOnly }), [readOnly]);
  const [testSms, setTestSms] = useState("");
  const [smsParts, setSmsParts] = useState(0);
  const [selectedString, setSelectedString] = useState("");

  const shortCodes = useMemo(() => getShortCodes(smsConfig), [smsConfig]);

  const currentSelection = (e) => {
    const str = e.target.value.substring(
      e.target.selectionStart,
      e.target.selectionEnd
    );
    setSelectedString(str);
  };

  const setRef = (ref) => {
    setTemplateRef(ref);
  };

  const replaceText = () => {
    if (selectedString) {
      const newValue = templateRef.current.value.replace(
        selectedString,
        "<^URL_SHORTNER " + selectedString + "^>"
      );
      templateRef.current.value = newValue;

      const ev = {
        target: {
          name: "template",
          value: newValue,
        },
      };
      onSmsFieldChange(ev);
    }
  };

  const onAddRecipientField = useCallback(
    (header) => {
      const { current: input } = templateRef;
      const { selectionEnd } = input;
      const textToInsert = `<%= ${header} %> `;

      onSmsFieldChange({
        target: {
          name: "template",
          value:
            template.slice(0, selectionEnd) +
            textToInsert +
            template.slice(selectionEnd),
        },
      });
      setTimeout(() => {
        input.focus();
        const newCursorIdx = selectionEnd + textToInsert.length;
        input.setSelectionRange(newCursorIdx, newCursorIdx);
      });
    },
    [template, onSmsFieldChange, templateRef]
  );

  const onTestSmsChange = useCallback((testSms) => setTestSms(testSms), [
    setTestSms,
  ]);
  const smsPlaceHolders = useMemo(() => getPlaceHolders(template), [template]);
  const [smsTargetFieldsSetup, setSmsTargetFieldsSetup] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const onSendTestSms = useCallback(async () => {
    setOpenModal(false);
    let testTemplate = template;
    for (let x = 0; x < smsPlaceHolders.length; x++) {
      try {
        if (smsTargetFieldsSetup[smsPlaceHolders[x]]?.value) {
          testTemplate = testTemplate.replaceAll(
            new RegExp(`<%=\\s*${smsPlaceHolders[x]}\\s*%>`, "g"),
            smsTargetFieldsSetup[smsPlaceHolders[x]].value
          );
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("SMS Template Error", err);
        dispatch(
          notify({
            type: "error",
            message: t("Please provide free form values."),
          })
        );
        return false;
      }
    }

    try {
      await dispatch(
        sendTestMessage({
          channelType: "sms",
          data: {
            from: String(shortCode),
            to: String(testSms),
            testMessage: testTemplate || "A Test Sms",
          },
        })
      );
    } catch (err) {
      dispatch(
        notify({
          type: "error",
          message: t(
            "You must provide a valid test phone number and short code."
          ),
        })
      );
      throw err;
    }
  }, [dispatch, testSms, shortCode, template, smsTargetFieldsSetup, t]);

  // const canUseCustomerData = useAuthorized(
  //   TEMPLATE_CREATE_CUSTOMER_DATA_PERMISSION
  // );
  const {
    vendorConfig: { createCustomerTemplateData, urlShortener = false },
  } = useSelector((state) => state.eonsConfig);

  useEffect(() => {
    if (template) {
      const { parts } = splitter.split(template);
      setSmsParts(parts.length);
    } else {
      setSmsParts(0);
    }
  }, [template]);

  // const canUseCustomerData = createCustomerTemplateData;
  const canUseCustomerData = true;

  const handleSmsChange = (event, templateValue, freeForm) => {
    const recipientHeaderValue = event.target.value;
    setSmsTargetFieldsSetup({
      ...smsTargetFieldsSetup,
      [templateValue]: {
        freeForm,
        value: recipientHeaderValue,
      },
    });
  };

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      data-test-id="master-template-sms-section"
    >
      <Grid item>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              data-test-id="master-template-sms-shortcode-field"
              select
              label={t("Short Code")}
              variant="outlined"
              size="small"
              fullWidth
              name="shortCode"
              value={shortCode}
              onChange={onSmsFieldChange}
              error={Boolean(errors["smsTemplate.shortCode"])}
              helperText={errors["smsTemplate.shortCode"]}
              InputProps={readOnlyProps}
              disabled={readOnly}
            >
              {shortCodes.map((shortCode) => (
                <MenuItem
                  key={shortCode}
                  value={shortCode}
                  data-test-id={`shortcodes-first-item`}
                >
                  {shortCode}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {urlShortener && (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Button
                data-test-id="master-template-url-shortner-button"
                variant="contained"
                className={classes.testButton}
                onClick={replaceText}
                disabled={readOnly}
              >
                {t("URL Shortener")}
              </Button>
            </Grid>
          )}
          {canUseCustomerData && (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <RecipientFieldSelector
                onAddRecipientField={onAddRecipientField}
                data-test-id="master-template-sms-variable-section"
                disabled={readOnly}
              />
            </Grid>
          )}
          <Grid item className={classes.flexDivider}>
            <div data-test-id="master-template-test-sms-number-field">
              <PhoneInput
                data-test-id="master-template-test-sms-number-field"
                inputClass={classes.testSms}
                label={t("Test Phone Number")}
                country={"us"}
                value={testSms}
                onChange={onTestSmsChange}
                variant="outlined"
                size="small"
                fullWidth
                disabled={readOnly}
              />
            </div>
          </Grid>
          <Grid item>
            <Button
              data-test-id="master-template-send-test-sms-button"
              variant="contained"
              color="primary"
              className={classes.testButton}
              onClick={() => {
                if (smsPlaceHolders.length === 0) {
                  onSendTestSms();
                } else {
                  setOpenModal(true);
                }
              }}
              disabled={readOnly}
            >
              {t("Send Test")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <CustomFieldsDialog
          onClose={() => setOpenModal(false)}
          onSave={onSendTestSms}
          emailPlaceHolders={smsPlaceHolders}
          handleEmailChange={handleSmsChange}
          emailTargetFieldsSetup={smsTargetFieldsSetup}
          open={openModal}
          errors={errors}
          classes={classes}
          type={"SMS"}
        />
      </Grid>
      <Grid item>
        <TextBoxWithValidation
          template={template}
          onChange={onSmsFieldChange}
          name="template"
          useCharacterCount={true}
          error={Boolean(errors["smsTemplate.template"])}
          helperText={errors["smsTemplate.template"]}
          InputProps={readOnlyProps}
          setRef={setRef}
          onSelect={currentSelection}
          useUnicodeDetection={true}
          disabled={readOnly}
        />
      </Grid>
      {templateId && (
        <Grid item className={classes.flexDivider}>
          <Tooltip title={templateId} placement="right" interactive>
            <InfoIcon color="disabled" style={{ height: "auto" }} />
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
}

export default memo(SmsTemplateSection);
