import api from "./api";

const approverBasePath = "/api/v1/approver";

export async function getApprover(id, params) {
  const { data } = await api.get(`${approverBasePath}/${id}`, { params });
  return data;
}

export async function listApprovers(params) {
  const { data } = await api.get(approverBasePath, {
    params,
  });
  return data;
}

export async function createApprover(approver, params) {
  const { data } = await api.post(approverBasePath, approver, {
    params,
  });
  return data;
}

export async function updateApprover(approverId, update, params) {
  const { data } = await api.patch(
    `${approverBasePath}/${approverId}`,
    update,
    { params }
  );
  return data;
}

export async function deleteApprover(id, params) {
  const { data } = await api.delete(`${approverBasePath}/${id}`, {
    params,
  });
  return data;
}
