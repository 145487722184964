import Papa from "papaparse";
import { buildNewSuppressionList } from "./index";

export function parseSuppressionsPreview({ file, errorMessage }) {
  return (dispatch) => {
    // use the first 6 recipients od the file as a preview for the mapping and the save screen
    const previewSize = 6;

    dispatch(
      buildNewSuppressionList({
        errors: {},
        loading: true,
        file,
      })
    );

    Papa.parse(file, {
      header: true,
      preview: previewSize,
      error() {
        dispatch(
          buildNewSuppressionList({
            errors: {
              file: errorMessage,
            },
            loading: false,
            file: null,
          })
        );
      },
      complete({ meta, data }) {
        dispatch(
          buildNewSuppressionList({
            headers: meta.fields,
            preview: data,
            loading: false,
          })
        );
      },
    });
  };
}
