import React, { memo } from "react";
import { Button, Badge } from "@material-ui/core";

function CustomButton({ error, children, className, ...rest }) {
  if (error) {
    return (
      <Badge color="error" badgeContent={<b>!</b>} className={className}>
        <Button {...rest} className={className}>
          {children}
        </Button>
      </Badge>
    );
  }
  return (
    <Button className={className} {...rest}>
      {children}
    </Button>
  );
}

export default memo(CustomButton);
