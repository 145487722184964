import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "../../../components";
import { Save } from "../../../icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0 ${theme.spacing(2)}px`,
    marginTop: "auto",
    marginBottom: 0,
  },
}));

function CreateApproverControls({ onSave, disabled, hasErrors, ...rest }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid
      {...rest}
      container
      spacing={2}
      alignItems="center"
      justify="flex-end"
      className={classes.root}
    >
      <Grid item>
        <Button
          data-test-id="approver-save-button"
          variant="contained"
          color="primary"
          startIcon={<Save />}
          onClick={onSave}
          disabled={hasErrors || disabled}
        >
          {t("Save")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default memo(CreateApproverControls);
