import React, { useMemo, useEffect, memo } from "react";
import { NavLink, matchPath, useLocation } from "react-router-dom";
import { Tabs, Tab, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tab: ({ numOfTabs }) => ({
    minWidth: `${100 / numOfTabs}%`,
    whiteSpace: "nowrap",
  }),
}));

const getActiveTabIdx = ({ tabs, location }) =>
  Math.max(
    0,
    tabs
      .map((tab) => matchPath(location.pathname, { path: tab.path }))
      .findIndex(Boolean)
  );

function LinkTabs({ tabs, onTabChange, variant = "scrollable", ...rest }) {
  const classes = useStyles({ numOfTabs: tabs.length });
  const location = useLocation();

  const activeTabIdx = useMemo(() => getActiveTabIdx({ tabs, location }), [
    tabs,
    location,
  ]);

  useEffect(() => {
    if (onTabChange) {
      onTabChange(activeTabIdx);
    }
  }, [onTabChange, activeTabIdx]);

  return (
    <Tabs
      {...rest}
      component={Paper}
      square
      value={activeTabIdx}
      variant={variant}
      scrollButtons="off"
    >
      {tabs.map((tab) =>
        tab.disabled ? (
          <Tab
            key={tab.path}
            replace
            label={tab.label}
            className={classes.tab}
            disabled
            activeClassName="Mui-selected"
          />
        ) : (
          <Tab
            key={tab.path}
            to={tab.path}
            component={NavLink}
            replace
            label={tab.label}
            className={classes.tab}
            activeClassName="Mui-selected"
          />
        )
      )}
    </Tabs>
  );
}

export default memo(LinkTabs);
