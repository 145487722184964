import React, { memo } from "react";
import { Box, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LargeDrawer from "./LargeDrawer";
import MediumDrawer from "./MediumDrawer";
import SmallDrawer from "./SmallDrawer";

const useStyles = makeStyles((theme) => ({
  largeContent: ({ width }) => ({
    marginLeft: width,
  }),
  mediumContent: {
    marginLeft: theme.spacing(7),
  },
}));

function ResponsiveDrawer({ width, content, ...rest }) {
  const classes = useStyles({ width });

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  if (isLargeScreen) {
    return (
      <>
        <LargeDrawer {...rest} width={width} />
        <Box className={classes.largeContent}>{content}</Box>
      </>
    );
  }
  if (isMediumScreen) {
    return (
      <>
        <MediumDrawer {...rest} width={width} />
        <Box className={classes.mediumContent}>{content}</Box>
      </>
    );
  }
  return (
    <>
      <SmallDrawer {...rest} width={width} />
      <Box>{content}</Box>
    </>
  );
}

export default memo(ResponsiveDrawer);
