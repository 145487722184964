import { te } from "date-fns/locale";
import api from "./api";

const ttsBasePath = "/api/v1/tts/freeswitch";
const ttsGetAudioPath = "/api/v1/templates/tts/generateVoice";
const masterTemplateBasePath = "/api/v1/master-template";
const voiceServiceBasePath = "api/v1/voice_service";
const templateBasePath = "api/v1/templates";

export async function getMasterTemplate(id, params) {
  const { data } = await api.get(`${masterTemplateBasePath}/${id}`, { params });
  return data;
}

export async function listMasterTemplates(params) {
  const { data } = await api.get(masterTemplateBasePath, {
    params,
  });
  return data;
}

export async function createMasterTemplate(template, params) {
  const { data } = await api.post(masterTemplateBasePath, template, {
    params,
  });
  return data;
}

export async function updateMasterTemplate(templateId, update, params) {
  const { data } = await api.patch(
    `${masterTemplateBasePath}/${templateId}`,
    update,
    { params }
  );
  return data;
}

export async function updateMasterTemplateStatus({ masterTemplateId, status }) {
  const {
    data,
  } = await api.patch(
    `${masterTemplateBasePath}/${masterTemplateId}/update-status`,
    { masterTemplateId, status }
  );
  return data;
}

export async function deleteMasterTemplate(id, params) {
  const { data } = await api.delete(`${masterTemplateBasePath}/${id}`, {
    params,
  });
  return data;
}

export async function cloneMasterTemplate(id, params) {
  const { data } = await api.post(`${masterTemplateBasePath}/${id}`, null, {
    params,
  });
  return data;
}

export async function sendTestMessage(testMessage, params) {
  const { data } = await api.post(
    `${masterTemplateBasePath}/test`,
    testMessage,
    {
      params,
    }
  );
  return data;
}

export async function uploadWavFile(file, { companyId, templateId }) {
  const formData = new FormData();
  formData.append("wav", file, file.name);
  if (!templateId) {
    templateId = Date.now().toString();
  }

  const {
    data,
  } = await api.post(
    `${voiceServiceBasePath}/assets?companyId=${companyId}&campaignId=${templateId}&filename=${file.name}`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );

  // data - json containing file path
  return data;
}

export async function transferFiles({ fileName, companyId, templateId }) {
  const { data } = await api.get(
    `${voiceServiceBasePath}/assets/upload/${companyId}/${templateId}/${fileName}`
  );
  return data;
}

export async function createTTS(voiceItem, params) {
  const { data } = await api.post(ttsBasePath, voiceItem, {
    params,
  });
  return data;
}

export async function getAudioForTTsApi(stepInput) {
  const { data } = await api.post(ttsGetAudioPath, stepInput);
  return data;
}

const STATUS = { LOCKED: "LOCKED" };
export async function setCallerIdForInboundTemplate(payload) {
  const { data } = await api.patch(`${templateBasePath}/setCallerId`, payload);
  if (data) {
    await api.patch(
      `${masterTemplateBasePath}/${payload.templateId}/update-status`,
      { masterTemplateId: payload.templateId, status: STATUS.LOCKED }
    );
  }
  return data;
}

export async function getTemplateForCallerId(callerIds) {
  const { data } = await api.get(`${templateBasePath}/callerId`, {
    params: { callerIds },
  });
  return data;
}

export async function unlockSubTemplate(payload) {
  const { id, isLocked, paid } = payload;
  const { data } = await api.patch(`${templateBasePath}/lockUnlock/${id}`, {
    isLocked,
    paid,
  });
  return data;
}
