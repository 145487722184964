import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "../../../components";
import {
  Edit,
  Unarchive,
  Visibility,
  MoveToInbox,
  DeleteForever,
} from "../../../icons";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
// todo: disable delete on permission

function FolderListActions({ onMove, onDelete, template }) {
  const { _id, userId, status } = template;
  const { t } = useTranslation();
  const canEdit = useSelector((state) => state.user.id === userId);
  const isTemplateLocked = status === "LOCKED";
  const EditIcon = canEdit && !isTemplateLocked ? Edit : Visibility;
  const history = useHistory();
  const _onMove = useCallback(() => onMove(template), [onMove, template]);
  const _onDelete = useCallback(() => onDelete(template), [onDelete, template]);

  return (
    <>
      <Tooltip
        title={
          canEdit && !isTemplateLocked ? t("Edit template") : t("View template")
        }
      >
        <IconButton
          data-test-id={`template-action-edit-${_id}`}
          component={Link}
          to={`/master-templates/${_id}`}
          size="small"
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("Move Template to a folder")}>
        <IconButton
          size="small"
          color="secondary"
          onClick={_onMove}
          data-test-id={`folder-page-move-button-${template._id}`}
        >
          <MoveToInbox fontSize="small" />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("Delete template")}>
        <IconButton
          data-test-id={`template-action-delete-${_id}`}
          size="small"
          color="secondary"
          onClick={_onDelete}
        >
          <DeleteForever fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default memo(FolderListActions);
