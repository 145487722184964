import * as api from "../api/masterTemplate";
import { notify } from "./notification";
import i18n from "i18next";
import { CLEAR_CAMPAIGN } from "./campaign";

export const LIST_MASTER_TEMPLATES = "LIST_MASTER_TEMPLATES";
export const CLEAR_MASTER_TEMPLATES = "CLEAR_MASTER_TEMPLATES";

export function clearMasterTemplates() {
  return { type: CLEAR_MASTER_TEMPLATES };
}

export function listMasterTemplates(params) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { paid } = storage;
    const masterTemplates = await api.listMasterTemplates({ ...params, paid });
    dispatch({ type: LIST_MASTER_TEMPLATES, masterTemplates });
  };
}

export function listMasterTemplatesWithCompletedLockedStatus(params) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { paid } = storage;
    let status = ["DRAFT", "COMPLETED", "LOCKED"];
    if (params.status) {
      status = params.status;
    }
    const masterTemplates = await api.listMasterTemplates({
      ...params,
      status: status,
      paid,
    });
    dispatch({
      type: LIST_MASTER_TEMPLATES,
      masterTemplates: {
        totalCount: masterTemplates.totalCount,
        data: [...masterTemplates.data],
      },
    });
  };
}

export function setCallerIdForInboundTemplate(payload) {
  return async (dispatch) => {
    const response = await api.setCallerIdForInboundTemplate(payload);
    dispatch(
      notify({
        type: "success",
        message: i18n.t("Successfully created inbound campaign"),
      })
    );
    dispatch({
      type: CLEAR_CAMPAIGN,
    });
    return response;
  };
}

export async function getTemplateForCallerId(callerIds) {
  return await api.getTemplateForCallerId(callerIds);
}
