// import the necessary polyfills for IE11 before anything else
import "./polyfills";

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFns from "@date-io/date-fns";
import { Provider as ReduxProvider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider, CssBaseline } from "./components";
import { getMe } from "./actions/user";
import { getApprovalData } from "./actions/approvalData";
import * as i18n from "./i18n";
import * as api from "./api/api";
import store from "./store";
import App from "./App";
import { listPendingApprovalCampaigns } from "./actions/pendingApprovalCampaigns";

/**
 * ~~ APP INITIALIZATION ~~
 * Do not render the app until getMe returns and decides if the user is logged in
 *  and the correct language translations are downloaded
 *  doing otherwise would create unnecessary flickering
 * [Special Condition]: User Open an approval page
 *  - Opening Approval Page does not require authentication
 *  - Therefore; getMe will be skipped and proceed to rendering the App
 */
store
  .dispatch(listPendingApprovalCampaigns())
  .then(async () => {
    const pendingApprovalCampaigns = store.getState().pendingApprovalCampaigns;
    if (!pendingApprovalCampaigns) await store.dispatch(getMe());
    return store;
  })
  .then(renderApp, renderApp)
  .then(() => api.initInterceptors(store));

async function getDateLocale() {
  try {
    return (await import(`date-fns/locale/${i18n.getLanguage()}`)).default;
  } catch (err) {
    return (await import("date-fns/locale/en-US")).default;
  }
}

async function renderApp() {
  await i18n.init();
  const dateLocale = await getDateLocale();

  ReactDOM.render(
    <Suspense fallback={null}>
      <ThemeProvider>
        <CssBaseline />
        <ReduxProvider store={store}>
          <BrowserRouter>
            <MuiPickersUtilsProvider utils={DateFns} locale={dateLocale}>
              <App />
            </MuiPickersUtilsProvider>
          </BrowserRouter>
        </ReduxProvider>
      </ThemeProvider>
    </Suspense>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
