import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Tooltip } from "../../../components";
import { Check, Lock } from "../../../icons";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  done: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
}));

function RecipientListStatusIndicator({ status, ...rest }) {
  const { t } = useTranslation();
  const classes = useStyles();

  if (status === "DRAFT") {
    return <CircularProgress size={20} color="warning" />;
  }
  if (status === "LOCKED") {
    return (
      <Tooltip title={t("Locked")}>
        <Lock {...rest} className={classes.warning} />
      </Tooltip>
    );
  }
  return (
    <Tooltip title={t("Completed")}>
      <Check className={classes.done} />
    </Tooltip>
  );
}

export default memo(RecipientListStatusIndicator);
