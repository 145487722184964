import React, { useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Search, useMediaQuery } from "../../../components";

const useStyles = makeStyles((theme) => ({
  primaryContainer: {
    flexGrow: 1,
    maxWidth: 1000,
  },
  createButton: {
    height: "100%",
  },
  searchContainer: {
    flex: 2,
    minWidth: 140,
    maxWidth: 800,
  },
  permissionContainer: {
    flex: 1,
    minWidth: 140,
    maxWidth: 220,
  },
}));

function CampaignsFilters({ params, onSearchChange, testId }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  return (
    <>
      <Grid container spacing={2} justify="space-between">
        <Grid item className={classes.primaryContainer}>
          <Grid container spacing={2}>
            <Grid item className={classes.searchContainer}>
              <Search
                variant="outlined"
                size="small"
                label={
                  isSmallScreen
                    ? t("Search")
                    : t("Search by name, creator, or tag")
                }
                onSearch={onSearchChange}
                defaultValue={params.search}
                debounce={300}
                fullWidth
                testId={testId}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default memo(CampaignsFilters);
