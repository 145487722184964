import { SET_FOLDER, CLEAR_FOLDER } from "../actions/folderTemplate";

export default function folderReducer(
  folder = null,
  { type, folder: newFolder }
) {
  switch (type) {
    case CLEAR_FOLDER:
      return null;
    case SET_FOLDER:
      return newFolder;
    default:
      return folder;
  }
}
