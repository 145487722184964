import { SET_STORAGE_FIELDS, STORAGE_KEY } from "../actions/storage";
import { SET_USER } from "../actions/user";

export default function storageReducer(
  storage = null,
  { type, newFields, newUser }
) {
  switch (type) {
    case SET_STORAGE_FIELDS:
      return { ...storage, ...newFields };
    case SET_USER:
      // return a user scoped storage when the user is logged in
      return getDefaultFields(newUser.id);
    default:
      return storage;
  }
}

function getDefaultFields(userId) {
  try {
    // scope the localStorage per user with their id
    const storageKey = `${STORAGE_KEY}:${userId}`;
    return JSON.parse(localStorage.getItem(storageKey)) || {};
  } catch (err) {
    return {};
  }
}
