import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { IconButton } from "../../../components";
import { Visibility, Edit } from "../../../icons";
import { Tooltip } from "@material-ui/core";
import useAuthorized, {
  INBOUND_CREATE_PERMISSION,
} from "../../../hooks/useAuthorized";
import { useDispatch } from "react-redux";

function CampaignsActions({ callerInfo, testId }) {
  const history = useHistory();
  const { t } = useTranslation();
  const canCreateInbound = useAuthorized(INBOUND_CREATE_PERMISSION);
  const _viewCampaign = () => {
    history.push(`/inbound/${callerInfo.templateId}/view/`);
  };

  return (
    <>
      {callerInfo &&
        callerInfo.inboundTemplate &&
        callerInfo.inboundTemplate.length && (
          <Tooltip title={t("View campaign")}>
            <IconButton
              data-test-id={`${testId}-action-view`}
              onClick={_viewCampaign}
              size="small"
            >
              <Visibility fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      {canCreateInbound && (
        <Tooltip title={t("Edit campaign")}>
          <IconButton
            data-test-id={`${testId}-action-edit`}
            component={Link}
            to={`/inbound/${callerInfo.callerId}`}
            size="small"
          >
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

export default memo(CampaignsActions);
