import React, {
  memo,
  useRef,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Page, DeleteDialog, useMediaQuery, Box } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import useAuthorized, {
  SUPPRESSION_CREATE_PERMISSION,
  SUPPRESSION_DELETE_PERMISSION,
  SUPPRESSION_LIST_PERMISSION,
} from "../../../hooks/useAuthorized";
import Actions from "./Actions";
import SuppressionBase from "./Base";
import { deleteSuppression } from "../../../actions/suppression";
import { listSuppressions } from "../../../actions/suppression";
import { clearSuppressions } from "../../../actions/suppression";
import { setParams } from "../../../actions/params";
import SuppressionToggle from "../SuppressionToggle";
import { Grid } from "../../../components";

const getExtraColumns = ({ t, onOpenDeleteDialog, canDelete }) => [
  {
    name: "actions",
    getter: (suppressed) => (
      <Actions
        canDelete={canDelete}
        onDelete={onOpenDeleteDialog}
        suppressed={suppressed}
      />
    ),
    label: t("Actions"),
    sortable: false,
    width: 0.7,
    align: "center",
  },
];

const getShortCodes = ({
  sinchShortCodes = [],
  xmlShortCodes = [],
  jasminShortCodes = [],
}) =>
  [...sinchShortCodes, ...xmlShortCodes, ...jasminShortCodes]
    .map(Number)
    .sort();

const getEmailDomains = ({ domainAddress = [] }) => [domainAddress];

const suppressionTypeMapMsg = {
  email: "Suppression Email",
  voice: "Suppression Voice",
  sms: "Suppression Number",
};
function SuppressionListsPage({ type }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const listsRef = useRef();
  const { smsConfig, emailConfig, vendorConfig } = useSelector(
    (state) => state.eonsConfig
  );
  const shortCodes = useMemo(() => getShortCodes(smsConfig), [smsConfig]);
  const emailAddresses = useMemo(() => getEmailDomains(emailConfig), [
    emailConfig,
  ]);
  const domainAddresses =
    emailAddresses[0] && emailAddresses[0].length
      ? emailAddresses[0].split(",")
      : [];
  const callerIds = useMemo(() => {
    return vendorConfig.callerId;
  }, [vendorConfig]);

  const suppressionList = useSelector((state) => state.suppression);
  const queryParams = useSelector((state) => state.params);

  const canDelete = useAuthorized(SUPPRESSION_DELETE_PERMISSION);
  const canCreate = useAuthorized(SUPPRESSION_CREATE_PERMISSION);
  const canList = useAuthorized(SUPPRESSION_LIST_PERMISSION);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const [suppressionTab, setSuppressionTab] = useState("sms");
  const history = useHistory();

  const [suppressionToDelete, setSuppressionToDelete] = useState();

  const authorizedToDoAnyAction = canDelete || canCreate;
  // const canView = useAuthorized;
  const canOnlyCreate = canCreate && !canList && !canDelete;

  const onOpenDeleteDialog = useCallback(
    (suppressed) => setSuppressionToDelete(suppressed),
    [setSuppressionToDelete]
  );

  const onCloseDeleteDialog = useCallback(() => setSuppressionToDelete(null), [
    setSuppressionToDelete,
  ]);

  const onItemsPerPageChange = useCallback(
    (ev) => dispatch(setParams({ itemsPerPage: ev.target.value })),
    [dispatch]
  );

  // reset params.page to 0 whenever a none page related param is modified
  // this is the usual expected behavior by users
  const onSearchChange = useCallback(
    (search) => dispatch(setParams({ search, page: 0 })),
    [dispatch]
  );

  const onPageChange = useCallback(
    (ev, page) => dispatch(setParams({ page })),
    [dispatch]
  );

  const onDelete = useCallback(async () => {
    const { _id } = suppressionToDelete;
    await dispatch(deleteSuppression({ _id, type }));

    await getSuppressed();
    onCloseDeleteDialog();
  }, [
    dispatch,
    suppressionToDelete,
    shortCodes,
    onCloseDeleteDialog,
    queryParams,
  ]);

  // fetch suppression list
  useEffect(() => {
    getSuppressed();
  }, [dispatch, shortCodes, queryParams, callerIds]);

  const getSuppressed = () => {
    if (queryParams.type === "sms") {
      dispatch(listSuppressions({ shortCodes, params: queryParams }));
    } else if (queryParams.type === "email") {
      dispatch(listSuppressions({ domainAddresses, params: queryParams }));
    } else if (queryParams.type === "voice") {
      dispatch(listSuppressions({ callerIds, params: queryParams }));
    }
  };
  const extraColumns = useMemo(
    () =>
      getExtraColumns({
        t,
        onOpenDeleteDialog,
        authorizedToDoAnyAction,
        canDelete,
      }),
    [t, onOpenDeleteDialog, authorizedToDoAnyAction, canDelete]
  );

  const onInputTabChange = (_, activeTab) => {
    if (activeTab) {
      history.push(`/suppression?type=${activeTab}`);
    }
  };

  useEffect(() => {
    let { type } = queryParams;
    dispatch(clearSuppressions());
    if (!type) {
      history.push(`/suppression?type=sms`);
    }
    setSuppressionTab(type);
  }, [queryParams]);

  return (
    <Page screenSized={!isSmallScreen}>
      <Grid direction="column" spacing={2}>
        <SuppressionToggle
          onTabChange={onInputTabChange}
          activeTab={suppressionTab}
          errors={""}
          readOnly={false}
        />
      </Grid>

      <Box p={2}>
        <SuppressionBase
          disableTable={canOnlyCreate}
          ref={listsRef}
          extraColumns={extraColumns}
          suppressionList={suppressionList ? suppressionList.list : []}
          canCreate={canCreate}
          onItemsPerPageChange={onItemsPerPageChange}
          onSearchChange={onSearchChange}
          onPageChange={onPageChange}
          suppressionCount={suppressionList ? suppressionList.count : 0}
          type={type}
        />
        <DeleteDialog
          open={Boolean(suppressionToDelete)}
          onClose={onCloseDeleteDialog}
          onDelete={onDelete}
          name={suppressionToDelete?.to}
          resource={t(suppressionTypeMapMsg[queryParams.type])}
        ></DeleteDialog>
      </Box>
    </Page>
  );
}

export default memo(SuppressionListsPage);
