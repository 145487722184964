import React, { useState, memo, useEffect } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import useAuthorized, {
  KEYWORD_CREATE_PERMISSION,
  KEYWORD_LIST_PERMISSION,
  KEYWORD_UPDATE_PERMISSION,
} from "../../hooks/useAuthorized";
import ListKeywords from "./List";
import CreateKeywords from "./Create";
import UpdateKeyword from "./Update";
import { Grid } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { clearKeywords } from "../../actions/keywords";

function KeywordsPage() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const queryParams = useSelector((state) => state.params);
  const canCreate = useAuthorized(KEYWORD_CREATE_PERMISSION);
  const canList = useAuthorized(KEYWORD_LIST_PERMISSION);
  const canUpdate = useAuthorized(KEYWORD_UPDATE_PERMISSION);

  useEffect(() => {
    dispatch(clearKeywords());
  }, [queryParams]);

  return (
    <>
      <Switch>
        {canCreate && (
          <Route path={`${match.path}/create`}>
            <CreateKeywords />
          </Route>
        )}
        {canUpdate && (
          <Route path={`${match.path}/update`}>
            <UpdateKeyword />
          </Route>
        )}
        {(canList || canCreate) && (
          <Route path={`${match.path}`}>
            <ListKeywords />
          </Route>
        )}

        <Redirect to={`${match.path}`} />
      </Switch>
    </>
  );
}

export default memo(KeywordsPage);
