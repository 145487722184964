import { unescape } from "lodash";

function getEscapePlaceHolders(template) {
  const regex = /&lt;%=\s*\w+\s*%&gt;/gi;
  const results = [];
  let result;

  // tslint:disable-next-line: no-conditional-assignment
  while ((result = regex.exec(template))) {
    if (result && Array.isArray(result)) {
      results.push(result[0].trim());
    }
  }

  return results;
}

export default function formatEscape(emailTemplate) {
  const placeHolders = getEscapePlaceHolders(emailTemplate);
  if (!placeHolders.length) {
    return emailTemplate;
  }

  let escapeFreeTemplate = emailTemplate;
  placeHolders.forEach((placeHolder) => {
    const toBeReplacedPlaceHolder = placeHolder
      .replace("&lt;", "<")
      .replace("&gt;", ">");
    escapeFreeTemplate = escapeFreeTemplate.replace(
      placeHolder,
      toBeReplacedPlaceHolder
    );
  });

  return escapeFreeTemplate;
}

/**
 * Clean up stored html string for rendering (in html render box)
 * @param {*} htmlString
 * @param {*} alt
 * @returns
 */
export const cleanUpHtmlStringForTemplate = (htmlString, alt = "") => {
  return htmlString &&
    (htmlString.replace(/<(.|\n)*?>/g, "").length > 0 ||
      htmlString.includes("<img"))
    ? unescapedHtmlString(htmlString)
    : alt;
};

export const unescapedHtmlString = (htmlString = "") => {
  return unescape(htmlString);
  // return htmlString;
};
