import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IconButton, Tooltip } from "../../../components";
import { DeleteForever, Edit } from "../../../icons";

function EditMasterRecipientActions({ onEdit, onDelete }) {
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t("Edit Recipient")}>
        <IconButton component={Link} size="small" onClick={onEdit}>
          <Edit fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Delete Recipient")}>
        <IconButton
          component={Link}
          size="small"
          color="secondary"
          onClick={onDelete}
        >
          <DeleteForever fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default memo(EditMasterRecipientActions);
