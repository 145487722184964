import api from "./api";

export async function getMe() {
  const { data: user } = await api.get("/api/v1/users/me");
  const { data: divisions } = await api.get("/api/v1/divisions", {
    params: { divisionIds: user.divisionIds, eons: true },
    limit: 100,
  });
  user.divisions = divisions.data;
  return user;
}

export async function login(loginData) {
  const resp = await api.post("/api/v1/users/login", loginData);
  return resp.data;
}

export async function logout() {
  const resp = await api.post("/api/v1/users/logout");
  return resp.data;
}

export async function loginSSO(loginData) {
  const resp = await api.post("/api/v1/users/loginSSO", loginData);
  return resp.data;
}
