import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fixBottom: {
    position: "fixed",
    bottom: "0%",
    left: "50%",
  },
}));

const VersioningTag = () => {
  const classes = useStyles();
  return (
    <small className={classes.fixBottom}>
      v{process.env.REACT_APP_VERSION}
    </small>
  );
};
export default VersioningTag;
