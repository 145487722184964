import React, { useCallback, useMemo, memo, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Grid, IconButton, MenuItem, TextField } from "../../../../components";
import { DeleteForever, DragIndicator } from "../../../../icons";
import {
  FILE_TYPE,
  CUSTOMER_DATA_TYPE,
  DTMF_DATA_TYPE,
  DIRECTION_DATA_TYPE,
  TTS_DATA_TYPE,
} from "../constants";
import VoiceFileInput from "./VoiceFileInput";
import VoiceCustomerDataInput from "./VoiceCustomerDataInput";
import VoiceDTMFInput from "./VoiceDTMF";
import { ErrorWrapper } from "./VoiceStepButtons";
import { getAudioForTTs } from "../../../../actions/masterTemplate";
import VoiceTTSInput from "./VoiceTTSInput";

const useStyles = makeStyles((theme) => ({
  fileSelector: {
    width: "100%",
    height: 120,
  },
  customerDataSelector: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const getOrderRecipientHeaders = (recipientHeaders) =>
  Object.keys(recipientHeaders).sort();

function VoiceItems({
  voiceItems,
  errors,
  readOnly,
  onDeleteVoiceItem,
  onUploadVoiceFile,
  onRejectVoiceFile,
  onSetCustomerData,
  setVoiceCustomerData,
  onSetVoiceItems,
  onSetDTMFData,
  onSetDirectionData,
  onDTMFFilePathChange,
  onSaveAsDraftInitial,
  activeTab,
  onSetTTSData,
  testSection,
  selectedTtsVoice,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const recipientHeaders = useSelector(
    (state) => state.eonsConfig.vendorConfig.recipientHeaders
  );

  const orderedRecipientHeaders = useMemo(
    () => getOrderRecipientHeaders(recipientHeaders),
    [recipientHeaders]
  );

  const readOnlyProps = useMemo(() => ({ readOnly }), [readOnly]);

  const onDragEnd = useCallback(
    (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const sourceIdx = result.source.index;
      const destIdx = result.destination.index;

      let newVoiceItems = [...voiceItems];
      const [removedItem] = newVoiceItems.splice(sourceIdx, 1);
      newVoiceItems.splice(destIdx, 0, removedItem);

      const sourceErrorKey = `voiceTemplate.${activeTab}.${sourceIdx}.transcript`;
      const destErrorKey = `voiceTemplate.${activeTab}.${destIdx}.transcript`;
      const sourceError = errors[sourceErrorKey];
      const destError = errors[destErrorKey];
      const newErrors = { ...errors };

      if (sourceError) {
        newErrors[destErrorKey] = sourceError;
      }
      if (destError) {
        newErrors[sourceErrorKey] = destError;
      }

      // rearrange order of item idx
      newVoiceItems = newVoiceItems.map((e, index) => ({
        ...e,
        name: `${index}`,
      }));

      onSetVoiceItems(newVoiceItems, newErrors);
    },
    [voiceItems, errors, onSetVoiceItems, activeTab]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="voiceItems">
        {(provided) => (
          <Grid
            container
            direction="column"
            spacing={2}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {voiceItems.map((voiceItem, idx) => (
              <Draggable
                key={voiceItem._id}
                draggableId={voiceItem?._id ?? voiceItem.name}
                index={idx}
              >
                {(provided) => (
                  <Grid
                    item
                    key={idx}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <Grid
                      container
                      spacing={2}
                      wrap="nowrap"
                      alignItems="flex-start"
                    >
                      <HandleBar
                        readOnly={readOnly}
                        {...provided.dragHandleProps}
                      />
                      <Grid item>{`${idx}`}</Grid>
                      <Grid item xs>
                        {(() => {
                          const { stepType } = voiceItem;
                          switch (stepType) {
                            case FILE_TYPE:
                              return (
                                <VoiceFileInput
                                  dataTestId={`Upload-audio-file-${testSection}-${idx}`}
                                  idx={idx}
                                  readOnly={readOnly}
                                  error={
                                    errors[
                                      `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.path`
                                    ]
                                  }
                                  onUpload={onUploadVoiceFile}
                                  onReject={onRejectVoiceFile}
                                  filePath={voiceItem.path}
                                  label={
                                    errors[
                                      `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.path`
                                    ]
                                      ? t(
                                          "Upload a valid mp3, wav, m4a or ogg file"
                                        )
                                      : truncateLongString(
                                          parseFileName(voiceItem.path)
                                        ) ||
                                        t(
                                          "Upload an audio file by clicking or drag-and-drop"
                                        )
                                  }
                                />
                              );
                            case CUSTOMER_DATA_TYPE:
                              return (
                                <VoiceCustomerDataInput
                                  idx={idx}
                                  readOnly={readOnly}
                                  options={orderedRecipientHeaders.map(
                                    (header) => ({ title: header })
                                  )}
                                  label={t("Add Customer Data")}
                                  value={
                                    voiceItem.variableName
                                      ? voiceItem.variableName
                                      : ""
                                  }
                                  onChange={onSetCustomerData}
                                  readOnlyProps={readOnlyProps}
                                  error={Boolean(
                                    errors[
                                      `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.variableName`
                                    ]
                                  )}
                                  helperText={
                                    errors[
                                      `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.variableName`
                                    ]
                                  }
                                />
                              );
                            case DTMF_DATA_TYPE:
                              return (
                                <ErrorWrapper
                                  error={
                                    Object.keys(errors).filter((e) =>
                                      e.startsWith(
                                        `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}`
                                      )
                                    ).length > 0
                                  }
                                >
                                  <VoiceDTMFInput
                                    idx={idx}
                                    gotoOptions={voiceItems.map(
                                      (_, idx) => `${idx}`
                                    )}
                                    readOnly={readOnly}
                                    value={voiceItem.keys}
                                    onChange={onSetDTMFData}
                                    onDTMFFilePathChange={onDTMFFilePathChange}
                                    customerData={orderedRecipientHeaders}
                                    onSaveAsDraftInitial={onSaveAsDraftInitial}
                                    path={voiceItem.path}
                                  ></VoiceDTMFInput>
                                </ErrorWrapper>
                              );
                            case DIRECTION_DATA_TYPE:
                              return (
                                <TextField
                                  disabled={readOnly}
                                  data-test-id={`master-template-voice-dtmf-goto-${idx}`}
                                  select
                                  label={t("Go to Step")}
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={voiceItem.step ? voiceItem.step : " "}
                                  onChange={(ev) =>
                                    onSetDirectionData(idx, ev.target.value)
                                  }
                                  InputProps={readOnlyProps}
                                  error={null}
                                  helperText={null}
                                >
                                  {voiceItems.map((_, subIdx) => {
                                    if (idx != subIdx)
                                      return (
                                        <MenuItem key={subIdx} value={subIdx}>
                                          {subIdx}
                                        </MenuItem>
                                      );
                                    if (subIdx == 0 && voiceItems.length < 2)
                                      return (
                                        <MenuItem
                                          key={subIdx}
                                          value={subIdx}
                                          disabled
                                        >
                                          {"Add more steps to select"}
                                        </MenuItem>
                                      );
                                  })}
                                </TextField>
                              );
                            case TTS_DATA_TYPE:
                              return (
                                <VoiceTTSInput
                                  selectedTtsVoice={selectedTtsVoice}
                                  idx={idx}
                                  readOnly={readOnly}
                                  voiceItem={voiceItem}
                                  errors={errors}
                                  activeTab={activeTab}
                                  onSetTTSData={onSetTTSData}
                                  readOnlyProps={readOnlyProps}
                                  testSection={testSection}
                                />
                              );
                            default:
                              return "";
                          }
                        })()}
                      </Grid>
                      {voiceItem.stepType === CUSTOMER_DATA_TYPE && (
                        <Grid item xs>
                          <TextField
                            fullWidth
                            disabled={
                              voiceItem.variableName && !readOnly ? false : true
                            }
                            size="small"
                            data-test-id="voice-free-form-test"
                            label={`${
                              voiceItem.variableName
                                ? voiceItem.variableName
                                : "Input"
                            } free form`}
                            variant="outlined"
                            onChange={(ev) =>
                              setVoiceCustomerData(idx, ev.target.value)
                            }
                            error={Boolean(
                              errors[
                                `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.variableValue`
                              ]
                            )}
                            helperText={
                              errors[
                                `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.variableValue`
                              ]
                            }
                            value={
                              voiceItem.variableValue
                                ? voiceItem.variableValue
                                : ""
                            }
                          ></TextField>
                        </Grid>
                      )}
                      <DeleteButton
                        readOnly={readOnly}
                        onDelete={onDeleteVoiceItem}
                        idx={idx}
                        testSection={testSection}
                      />
                    </Grid>
                  </Grid>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
}

function DeleteButton({ readOnly, onDelete, idx, testSection }) {
  return (
    !readOnly && (
      <Grid item>
        <IconButton
          data-test-id={`master-template-delete-${testSection}-item-button-${idx}`}
          size="small"
          color="secondary"
          onClick={() => onDelete(idx)}
        >
          <DeleteForever />
        </IconButton>
      </Grid>
    )
  );
}

function HandleBar({ readOnly, ...props }) {
  return (
    !readOnly && (
      <Grid item>
        <IconButton
          data-test-id="master-template-drag-voice-item-button"
          size="small"
          color="default"
          {...props}
        >
          <DragIndicator />
        </IconButton>
      </Grid>
    )
  );
}

function truncateLongString(str) {
  if (!str) {
    return false;
  }
  const maxLength = 50;
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
}

function parseFileName(path) {
  if (!path) return false;

  const arr = path.split("/");
  return arr[arr.length - 1];
}

export default memo(VoiceItems);
