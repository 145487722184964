import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, ToggleButtonGroup, ToggleButton } from "../../../../components";

const useStyles = makeStyles({
  toggleButton: {
    flex: 1,
  },
  buttonGroup: {
    width: "100%",
  },
  badge: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});

function VoiceInputToggle({ readOnly, onTabChange, activeTab }) {
  const { t } = useTranslation();
  const classes = useStyles();

  if (readOnly) return "";

  const handleChange = (_, tab) => {
    if (tab !== null) {
      // MUI: tab is null when clicking already active tab
      onTabChange(null, tab);
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <ToggleButtonGroup
          data-test-id="master-template-voice-active-tab-toggle"
          size="small"
          color="primary"
          onChange={handleChange}
          value={activeTab}
          exclusive
          fullWidth
          className={classes.buttonGroup}
        >
          <ToggleButton
            color="primary"
            value={"simple"}
            className={classes.toggleButton}
          >
            {t("Simple")}
          </ToggleButton>
          <ToggleButton value={"advance"} className={classes.toggleButton}>
            {t("Advance")}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
}

export default memo(VoiceInputToggle);
