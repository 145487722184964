import React, { useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  ActionButton,
  Grid,
  Search,
  DateTimePicker,
  TextField,
  MenuItem,
  useMediaQuery,
} from "../../../components";

const getPermissions = (t) => [
  {
    label: t("All"),
    value: "all",
  },
  {
    label: t("User"),
    value: "user",
  },
  {
    label: t("Division"),
    value: "division",
  },
  {
    label: t("Company"),
    value: "company",
  },
];

const useStyles = makeStyles((theme) => ({
  primaryContainer: {
    flexGrow: 1,
    maxWidth: 1000,
  },
  createButton: {
    height: "100%",
  },
  searchContainer: {
    flex: 2,
    minWidth: 140,
    maxWidth: 800,
  },
  permissionContainer: {
    flex: 1,
    minWidth: 140,
    maxWidth: 220,
  },
}));

function CampaignsFilters({
  params,
  onSearchChange,
  onPermissionChange,
  onFromDateChange,
  onToDateChange,
  testId,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const permissions = useMemo(() => getPermissions(t), [t]);

  return (
    <>
      <Grid container spacing={2} justify="space-between">
        <Grid item className={classes.primaryContainer}>
          <Grid container spacing={2}>
            <ActionButton
              classes={{ desktop: classes.createButton }}
              component={Link}
              to="/campaigns/create"
              text={t("New Campaign")}
              data-test-id={`${testId}-create-new`}
            />
            <Grid item className={classes.searchContainer}>
              <Search
                variant="outlined"
                size="small"
                label={
                  isSmallScreen
                    ? t("Search")
                    : t("Search by name, creator, or tag")
                }
                onSearch={onSearchChange}
                defaultValue={params.search}
                debounce={300}
                fullWidth
                testId={testId}
              />
            </Grid>
            <Grid item className={classes.permissionContainer}>
              <TextField
                select
                value={params.permission || ""}
                onChange={onPermissionChange}
                label={t("Permission")}
                size="small"
                variant="outlined"
                fullWidth
                data-test-id={`${testId}-permission-select`}
              >
                {permissions.map((permission) => (
                  <MenuItem
                    data-test-id={`${testId}-permission-select-${permission.value}`}
                    key={permission.value}
                    value={permission.value}
                  >
                    {permission.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} wrap="nowrap">
            <Grid item>
              <DateTimePicker
                label={t("Created after")}
                disableFuture
                defaultValue={params.fromDate}
                maxDate={params.toDate}
                onAccept={onFromDateChange}
                testId={`${testId}-after`}
              />
            </Grid>
            <Grid item>
              <DateTimePicker
                label={t("Created before")}
                disableFuture
                defaultValue={params.toDate}
                minDate={params.fromDate}
                onAccept={onToDateChange}
                testId={`${testId}-before`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <OverwriteDialog
        open={showConfirmDialog}
        onClose={onCloseConfirmDialog}
        onOverwriteList={onOverwriteList}
        name={newRecipientList?.name}
      /> */}
    </>
  );
}

export default memo(CampaignsFilters);
