/**
 * Campaign Approver's Page
 * - Accessible via Link
 *
 * Description: This page does not require log in, but require jwt token authentication (which attached to the link).
 * This page is use for approving campaign via approvers
 *
 */

import React, { memo, useCallback, useState } from "react";
import Overview from "../components/ApproversPage/Overview";
import Preview from "../components/ApproversPage/Preview";
import Test from "../components/ApproversPage/Test";
import Approval from "../components/ApproversPage/Approval";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  AppBar,
  Typography,
  makeStyles,
  Box,
  Paper,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import {
  approveCampaign,
  rejectCampaign,
  sendApprovalTest,
} from "../actions/approvalData";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    padding: 15,
    fontWeight: "bolder",
    color: theme.palette.primary.main,
  },
}));

function ApproversPage(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const approvalData = useSelector((state) => state.approvalData);
  const [rejectDialog, setRejectDialog] = useState(false);
  const [approveConfirmDialog, setApproveConfirmDialog] = useState(false);
  const [comment, setComment] = useState(undefined);

  const {
    name,
    schedule,
    masterTemplateData,
    recipientLists,
    subTemplates,
    approversData,
    comments,
    acceptedApprovers,
    rejectedApprovers,
    eonsConfig,
    divisionId,
    status,
    bypassDndDns,
    bypassSuppression,
    isHighPriority,
  } = approvalData;

  const onSendTest = useCallback(
    async (testChips) => dispatch(sendApprovalTest(testChips, subTemplates)),
    [dispatch, subTemplates]
  );

  const onApprove = async () => {
    if (new Date() > new Date(schedule)) {
      // if launch time already passed
      setApproveConfirmDialog(true);
    } else {
      dispatch(approveCampaign());
      goBack();
    }
  };

  const onReject = () => {
    dispatch(rejectCampaign(comment));
    setRejectDialog(false);
    goBack();
  };

  const onConfirm = () => {
    dispatch(approveCampaign());
    setApproveConfirmDialog(false);
    goBack();
  };

  const goBack = () => {
    history.goBack();
  };

  const approvedPercent = Math.floor(
    (acceptedApprovers.length / approversData.length) * 100
  );
  const overWriteApproved = Boolean(
    approvedPercent < eonsConfig.autoLaunchPercentage && status === "COMPLETED"
  );

  return (
    <>
      <AppBar position="static" color="inherit">
        <Typography variant="h5" className={classes.title}>
          {t(`Approver Dashboard`)}
        </Typography>
      </AppBar>
      <Box my={3} m="auto" maxWidth={1400} component={Paper} elevation={3}>
        <Box p={4} component={Grid} container direction="column" spacing={3}>
          {overWriteApproved && (
            <Grid container item>
              <Grid item xs={12} md={12} component={Typography} variant="h5">
                <Alert severity="warning" style={{ width: "100%" }}>
                  <AlertTitle>Warning</AlertTitle>
                  {t(
                    "Campaign was approved by overwrite option. You may not make change to the campaign"
                  )}
                </Alert>
              </Grid>
            </Grid>
          )}
          <Grid container item>
            <Grid item xs={12} md={2} component={Typography} variant="h5">
              <b>{t("Overview")}</b>
            </Grid>
            <Grid item xs={12} md={10}>
              <Overview
                name={name}
                schedule={schedule}
                recipientLists={recipientLists}
                masterTemplate={masterTemplateData}
                subTemplates={subTemplates}
                data-test-id="approverspage-overview"
                bypassDndDns={bypassDndDns}
                bypassSuppression={bypassSuppression}
                isHighPriority={isHighPriority}
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={12} md={2} component={Typography} variant="h5">
              <b>{t("Preview")}</b>
            </Grid>
            <Grid item xs={12} md={10}>
              <Preview
                masterTemplate={subTemplates}
                data-test-id="approverspage-preview"
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={12} md={2} component={Typography} variant="h5">
              <b>{t("Test")}</b>
            </Grid>
            <Grid item xs={12} md={10}>
              <Test
                mb={2}
                onSendTest={onSendTest}
                activeDivisionId={divisionId}
                data-test-id="approverspage-test"
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={12} md={2} component={Typography} variant="h5">
              <b>{t("Approval")}</b>
            </Grid>
            <Grid item xs={12} md={10}>
              <Approval
                approvers={approversData}
                comments={comments}
                acceptedApprovers={acceptedApprovers}
                rejectedApprovers={rejectedApprovers}
                neededPercent={eonsConfig.autoLaunchPercentage}
                data-test-id="approverspage-approval"
              />
            </Grid>
          </Grid>
          <Box component={Grid} container item justify="flex-end">
            <Grid item component={ButtonGroup}>
              <Button
                color="secondary"
                size="large"
                variant="contained"
                onClick={() => setRejectDialog(true)}
                disabled={overWriteApproved}
              >
                {t("Reject")}
              </Button>
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={onApprove}
                disabled={overWriteApproved}
              >
                {t("Approve")}
              </Button>
            </Grid>
          </Box>
        </Box>
      </Box>
      {/* Past Scheduled Campaign Approve Dialog */}
      <Dialog
        open={approveConfirmDialog}
        onClose={() => setApproveConfirmDialog(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          {t("Scheduled Launch Time Already Passed")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {t(
              "This campaign's scheduled launch time had passed, would you like to approve this campaign anyway?"
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setApproveConfirmDialog(false)}
            variant="contained"
            size="large"
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
            size="large"
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Reject Dialog */}
      <Dialog
        open={rejectDialog}
        onClose={() => setRejectDialog(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">{t("Reject Campaign")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {t("Please write a comment in order to reject this campaign:")}
          </DialogContentText>
          <TextField
            variant="outlined"
            label="Comment"
            autoFocus
            margin="dense"
            multiline={true}
            rows={3}
            rowsMax={5}
            fullWidth
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onReject}
            color="secondary"
            variant="contained"
            size="large"
          >
            {t("Reject")}
          </Button>
          <Button
            onClick={() => setRejectDialog(false)}
            color="primary"
            variant="contained"
            size="large"
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default memo(ApproversPage);
