import React, { useCallback, useState, useEffect, useMemo, memo } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  LinkTabs,
  Page,
  useMediaQuery,
  DeleteDialog,
} from "../../../components";
import {
  createNewRecipientList,
  buildNewRecipientList,
} from "../../../actions/newRecipientList";
import { deleteRecipientList } from "../../../actions/recipientList";
import { buildCampaign } from "../../../actions/campaign";
import File from "./File";
import Filter from "./Filter";
import Map from "./Map";
import useAuthorized, {
  RECIPIENTLIST_MAP_PERMISSION,
  RECIPIENTLIST_MASTERDATA_PERMISSION,
} from "../../../hooks/useAuthorized";
import * as api from "../../../api/recipientLists";

const getTabs = ({
  isSmallScreen,
  t,
  canUseMap,
  canUseMasterData,
  enableFilterFromMaster,
}) => {
  const tabs = [
    {
      label: isSmallScreen ? t("Upload") : t("File Upload"),
      path: "/recipient-lists/create/file",
      Component: File,
    },
  ];

  if (canUseMap) {
    tabs.push({
      label: isSmallScreen ? t("Map") : t("Select Region from Map"),
      path: "/recipient-lists/create/map",
      Component: Map,
    });
  }

  if (canUseMasterData) {
    tabs.push({
      label: isSmallScreen ? t("Filter") : t("Filter from Master List"),
      path: "/recipient-lists/create/filter",
      Component: Filter,
      disabled: !enableFilterFromMaster,
    });
  }

  return tabs;
};

const getResource = (t) => t("Recipient List");

function CreateRecipientListPage() {
  const history = useHistory();
  const divisionId = useSelector((state) => state.storage.activeDivisionId);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const [showDeleteDialog, setShowDeleteDialog] = useState();
  const [enableFilterFromMaster, setEnableFilterFromMaster] = useState(false);

  // only show the tabs for list creation selection type at the wizards first page
  const newRecipientList = useSelector((state) => state.newRecipientList);
  const resource = useMemo(() => getResource(t), [t]);

  // const canUseMap = useAuthorized(RECIPIENTLIST_MAP_PERMISSION);
  // const canUseMasterData = useAuthorized(RECIPIENTLIST_MASTERDATA_PERMISSION);

  const {
    vendorConfig: { createRecipientListMap, createRecipientListMasterDate },
  } = useSelector((state) => state.eonsConfig);
  const canUseMap = createRecipientListMap;
  const canUseMasterData = createRecipientListMasterDate;

  const tabs = useMemo(
    () =>
      getTabs({
        t,
        isSmallScreen,
        canUseMap,
        canUseMasterData,
        enableFilterFromMaster,
      }),
    [t, isSmallScreen, canUseMap, canUseMasterData, enableFilterFromMaster]
  );

  const onTabChange = useCallback(
    (activeTabIdx) =>
      dispatch(buildNewRecipientList({ activeTabIdx, errors: {} })),
    [dispatch]
  );

  useEffect(() => {
    // dispatch(createNewRecipientList());
    if (canUseMasterData) {
      getRecipientCount();
      const interval = setInterval(() => getRecipientCount(), 30000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [dispatch, canUseMasterData]);

  const getRecipientCount = async () => {
    const result = await api.getMasterRecipientsCount(divisionId);
    if (result.totalRecipientCount) {
      setEnableFilterFromMaster(true);
    } else {
      setEnableFilterFromMaster(false);
    }
  };

  const onOpenDeleteDialog = useCallback(() => setShowDeleteDialog(true), [
    setShowDeleteDialog,
  ]);
  const onCloseDeleteDialog = useCallback(() => setShowDeleteDialog(false), [
    setShowDeleteDialog,
  ]);

  const onDelete = useCallback(async () => {
    await dispatch(deleteRecipientList(newRecipientList));
    history.replace("/recipient-lists");
  }, [dispatch, history, newRecipientList]);

  const afterSave = useCallback(
    (recipientListId) => {
      if (newRecipientList?.redirect) {
        dispatch(
          buildCampaign(
            {
              lists: [recipientListId],
            },
            { overwrite: true }
          )
        );
      }
    },
    [dispatch, history, newRecipientList]
  );

  if (!newRecipientList) {
    return null;
  }
  const { _id, activeTabIdx, activeStepIdx, name } = newRecipientList;

  // redirect to the view/edit page if the list already has an id
  // it means that it is already saved to the api and should not be 'created' anymore
  if (_id) {
    return <Redirect to={`/recipient-lists/${_id}`} />;
  }

  return (
    <Page screenSized>
      <Switch>
        {tabs.map(({ Component, path }) => (
          <Route key={path} path={path}>
            <Component onAbort={onOpenDeleteDialog} afterSave={afterSave}>
              {activeStepIdx === 0 && (
                <LinkTabs
                  scrollButtons="auto"
                  indicatorColor="primary"
                  textColor="primary"
                  tabs={tabs}
                  onTabChange={onTabChange}
                />
              )}
            </Component>
          </Route>
        ))}
        <Redirect to={tabs[activeTabIdx].path} />
      </Switch>
      <DeleteDialog
        open={showDeleteDialog}
        onClose={onCloseDeleteDialog}
        onDelete={onDelete}
        name={name}
        resource={resource}
      />
    </Page>
  );
}

export default memo(CreateRecipientListPage);
