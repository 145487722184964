import { Grid, TextField } from "../../components";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "../";

const _typeInRegexValidation = /^[A-Za-z0-9\@\$\.\:\-\_\,\'\"\s\*\!\;\/\?\&]*$/;
const _pasteInRegexValidation = /[^A-Za-z0-9\@\$\.\:\-\_\,\'\"\s\*\!\;\/\?\&]*/g;

function CustomFieldsDialog({
  onClose,
  onSave,
  fields,
  data,
  open,
  resource,
  emailSubjectPlaceHolders = [],
  emailPlaceHolders,
  handleEmailChange,
  handleEmailSubjectChange,
  classes,
  title,
  emailSubjectTargetFieldsSetup,
  emailTargetFieldsSetup,
  formValues,
  type,
  errors,
  ...rest
}) {
  const { t } = useTranslation();
  const [fieldError, setFieldError] = useState({});
  const freeFormValidation = (prefix, index, message) => {
    setFieldError((prev) => ({ ...prev, [`${prefix}_${index}`]: message }));
  };

  return (
    <Dialog {...rest} open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item>
            {(0 < emailPlaceHolders.length ||
              0 < emailSubjectPlaceHolders.length) && (
              <section>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  {type === "EMAIL" && <h2>Fill Email Target Fields</h2>}
                  {type === "SMS" && <h2>Fill SMS Target Fields</h2>}
                  <h3 className={classes.greyColor}>
                    You may provide sample values for the Customer Data
                    placeholders within the test message. If not provided they
                    will be filled with values like variableName-testValue
                  </h3>
                </Grid>
                {0 < emailSubjectPlaceHolders.length && (
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Grid container direction="row" spacing={2}>
                      {emailSubjectPlaceHolders.map((templateValue, index) => (
                        <Grid
                          key={templateValue}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          xl={12}
                        >
                          <Grid container direction="row" spacing={1}>
                            <Grid item xs={5} sm={5} md={5} xl={5}>
                              <TextField
                                fullWidth
                                size="small"
                                data-test-id="email-subject-free-form"
                                label={`${templateValue} free form`}
                                variant="outlined"
                                onChange={(event) =>
                                  handleEmailSubjectChange(
                                    event,
                                    templateValue,
                                    true
                                  )
                                }
                                value={
                                  emailSubjectTargetFieldsSetup?.[templateValue]
                                    ?.freeForm
                                    ? emailSubjectTargetFieldsSetup?.[
                                        templateValue
                                      ]?.value
                                    : ""
                                }
                                inputProps={{ maxLength: 60 }}
                                onKeyDown={(event) => {
                                  if (!_typeInRegexValidation.test(event.key)) {
                                    freeFormValidation(
                                      "email_sub_placeholder",
                                      index,
                                      `Character "${event.key}" not allowed`
                                    );
                                    event.preventDefault();
                                  } else if (fieldError) {
                                    freeFormValidation(
                                      "email_sub_placeholder",
                                      index,
                                      ""
                                    );
                                  }
                                }}
                                onPaste={(event) => {
                                  const content = event.clipboardData.getData(
                                    "text"
                                  );
                                  const invalidCharacters = content
                                    .match(_pasteInRegexValidation)
                                    .join("");
                                  if (invalidCharacters) {
                                    freeFormValidation(
                                      "email_sub_placeholder",
                                      index,
                                      `Character(s) not supported: "${invalidCharacters}"`
                                    );
                                    event.preventDefault();
                                  } else {
                                    freeFormValidation(
                                      "email_sub_placeholder",
                                      index,
                                      ""
                                    );
                                  }
                                }}
                                error={Boolean(
                                  fieldError[`email_sub_placeholder_${index}`]
                                )}
                                helperText={
                                  fieldError[`email_sub_placeholder_${index}`]
                                }
                              ></TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Grid container direction="row" spacing={2}>
                    {emailPlaceHolders.map((templateValue, index) => (
                      <Grid
                        key={templateValue}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                      >
                        <Grid container direction="row" spacing={1}>
                          <Grid item xs={5} sm={5} md={5} xl={5}>
                            <TextField
                              fullWidth
                              size="small"
                              data-test-id="email-free-form"
                              label={`${templateValue} free form`}
                              variant="outlined"
                              onChange={(event) =>
                                handleEmailChange(event, templateValue, true)
                              }
                              value={
                                emailTargetFieldsSetup?.[templateValue]
                                  ?.freeForm
                                  ? emailTargetFieldsSetup?.[templateValue]
                                      ?.value
                                  : ""
                              }
                              inputProps={{ maxLength: 60 }}
                              onKeyDown={(event) => {
                                if (!_typeInRegexValidation.test(event.key)) {
                                  freeFormValidation(
                                    "email_content_placeholder",
                                    index,
                                    `Character "${event.key}" not allowed`
                                  );
                                  event.preventDefault();
                                } else if (fieldError) {
                                  freeFormValidation(
                                    "email_content_placeholder",
                                    index,
                                    ""
                                  );
                                }
                              }}
                              onPaste={(event) => {
                                const content = event.clipboardData.getData(
                                  "text"
                                );
                                const invalidCharacters = content
                                  .match(_pasteInRegexValidation)
                                  .join("");
                                if (invalidCharacters) {
                                  freeFormValidation(
                                    "email_content_placeholder",
                                    index,
                                    `Character(s) not supported: "${invalidCharacters}"`
                                  );
                                  event.preventDefault();
                                } else {
                                  freeFormValidation(
                                    "email_content_placeholder",
                                    index,
                                    ""
                                  );
                                }
                              }}
                              error={Boolean(
                                fieldError[`email_content_placeholder_${index}`]
                              )}
                              helperText={
                                fieldError[`email_content_placeholder_${index}`]
                              }
                            ></TextField>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </section>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onSave(formValues)}
          color="secondary"
          data-test-id="form-dialog-save-button"
        >
          {t("Continue {{resource}}", { resource })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(CustomFieldsDialog);
