import React, { memo } from "react";
import { AccordionSummary, Badge } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
  },
  badge: {
    position: "absolute",
    right: 0,
    top: 0,
  },
});

function CustomAccordionSummary({ error, children, className, ...rest }) {
  const classes = useStyles();

  if (error) {
    return (
      <AccordionSummary
        {...rest}
        className={classNames(classes.fullWidth, className)}
      >
        <Badge
          className={classes.badge}
          color="error"
          badgeContent={<b>!</b>}
        />
        {children}
      </AccordionSummary>
    );
  }
  return (
    <AccordionSummary className={className} {...rest}>
      {children}
    </AccordionSummary>
  );
}

export default memo(CustomAccordionSummary);
