import React, { memo, useEffect, useState, useCallback, Fragment } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { DeleteDialog, Grid, Box } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { getDnd, saveDnd, deleteDnd, updateDnd } from "../../../actions/dnd";
import { notify } from "../../../actions/notification";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { KeyboardTimePicker } from "@material-ui/pickers";

function ManageDnd() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { paid } = useSelector((state) => state.storage);
  const { email: createdBy } = useSelector((state) => state.user);
  const { errors = {} } = useSelector((state) => (state.dnd ? state.dnd : {}));
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [openDeleteDailog, setOpenDeleteDailog] = useState(false);
  const [localState, setLocalState] = useState({
    oldDnd: {},
    newDnd: {},
  });
  const dnd = useSelector((state) => state.dnd);

  useEffect(() => {
    setStartTime(dnd?.startAt);
    setEndTime(dnd?.endAt);
    setLocalState({ ...localState, oldDnd: dnd, newDnd: dnd });
  }, [dispatch, dnd, paid]);

  const handleStartTimeChange = (date) => {
    setStartTime(date);
    setLocalState({
      ...localState,
      newDnd: { ...localState.newDnd, startAt: date },
    });
  };
  const handleEndTimeChange = (date) => {
    setEndTime(date);
    setLocalState({
      ...localState,
      newDnd: { ...localState.newDnd, endAt: date },
    });
  };

  const setDnd = useCallback(async () => {
    if (
      startTime &&
      endTime &&
      _.isEqual(localState.oldDnd, localState.newDnd)
    ) {
      dispatch(
        notify({
          type: "error",
          message: "You haven't made any changes!",
        })
      );
    } else {
      try {
        if (localState.newDnd?._id) {
          await dispatch(
            updateDnd({ startAt: startTime, endAt: endTime, paid, createdBy })
          );
        } else {
          await dispatch(
            saveDnd({
              startAt: startTime,
              endAt: endTime,
              paid,
              createdBy,
            })
          );
        }
        await dispatch(getDnd(paid));
      } catch (err) {
        if (err.message?.indexOf("400") === -1) {
          dispatch(
            notify({
              type: "error",
              message: err.message,
            })
          );
        }
      }
    }
  }, [dispatch, startTime, endTime]);

  const removeDnd = useCallback(async () => {
    try {
      await dispatch(deleteDnd(paid));
      setStartTime(null);
      setEndTime(null);
      setOpenDeleteDailog(false);
      await dispatch(getDnd(paid));
    } catch (err) {
      if (err.message?.indexOf("400") === -1) {
        dispatch(
          notify({
            type: "error",
            message: err.message,
          })
        );
      }
    }
  }, [dispatch, startTime, endTime]);

  return (
    <Fragment>
      {dnd && (
        <Grid container spacing={4} direction="row" align="center">
          <Grid item>
            <Box sx={{ width: "100%" }}>
              <Typography
                gutterBottom
                variant="h4"
                style={{ color: "#3f51b5" }}
                align="center"
                fullWidth
              >
                Voice
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Box sx={{ width: "100%" }}>
              <KeyboardTimePicker
                label={t("Start Time")}
                format="hh:mm a"
                value={startTime ? startTime : null}
                onChange={handleStartTimeChange}
                fullWidth
                style={{ marginBottom: "1rem" }}
                error={Boolean(errors["startAt"])}
                helperText={
                  Boolean(errors["startAt"]) ? t("must not be empty") : ""
                }
                name="dnd-start-time"
              />
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ width: "100%" }}>
              <KeyboardTimePicker
                label={t("End Time")}
                format="hh:mm a"
                value={endTime ? endTime : null}
                onChange={handleEndTimeChange}
                fullWidth
                style={{ marginBottom: "1rem" }}
                error={Boolean(errors["endAt"])}
                helperText={
                  Boolean(errors["endAt"]) ? t("must not be empty") : ""
                }
                name="dnd-end-time"
              />
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ width: "100%" }}>
              <Button
                color="secondary"
                onClick={() => {
                  setOpenDeleteDailog(true);
                }}
                disabled={!startTime || !endTime}
                variant="outlined"
                fullWidth
                data-test-id="remove-dnd-button"
              >
                Remove Voice
              </Button>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ width: "100%" }}>
              <Button
                color="primary"
                onClick={setDnd}
                variant="contained"
                data-test-id="set-dnd-button"
                fullWidth
              >
                Update Voice
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
      <DeleteDialog
        open={openDeleteDailog}
        onClose={() => {
          setOpenDeleteDailog(false);
        }}
        onDelete={removeDnd}
        name={`the DND setting`}
      />
    </Fragment>
  );
}

export default memo(ManageDnd);
