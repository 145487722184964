import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "../../../components";
import {
  Save,
  DeleteForever,
  InsertDriveFile as Draft,
  FileCopy,
} from "../../../icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0 ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.grey[200],
    marginTop: "auto",
    marginBottom: 0,
  },
}));

function CreateMasterTemplateControls({
  onSave,
  onSaveAsDraft,
  onDelete,
  disabled,
  hasErrors,
  isNewTemplate,
  onClone,
  ...rest
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid
      {...rest}
      container
      spacing={2}
      alignItems="center"
      justify="flex-end"
      className={classes.root}
    >
      {!isNewTemplate && (
        <Grid item>
          <Button
            data-test-id="master-template-delete-button"
            variant="contained"
            startIcon={<FileCopy fontSize="small" />}
            onClick={onClone}
            disabled={disabled}
          >
            {t("Clone")}
          </Button>
        </Grid>
      )}
      <Grid item>
        <Button
          data-test-id="master-template-delete-button"
          variant="contained"
          color="secondary"
          startIcon={<DeleteForever />}
          onClick={onDelete}
          disabled={disabled}
        >
          {isNewTemplate ? t("Abort") : t("Delete")}
        </Button>
      </Grid>
      <Grid item>
        <Button
          data-test-id="master-template-save-draft-button"
          variant="contained"
          startIcon={<Draft />}
          onClick={onSaveAsDraft}
          disabled={disabled}
        >
          {isNewTemplate ? t("Save as Draft") : t("Update as Draft")}
        </Button>
      </Grid>
      <Grid item>
        <Button
          data-test-id="master-template-save-button"
          variant="contained"
          color="primary"
          startIcon={<Save />}
          onClick={onSave}
          disabled={hasErrors || disabled}
          error={hasErrors}
        >
          {isNewTemplate ? t("Save") : t("Update")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default memo(CreateMasterTemplateControls);
