export const TEXT_TYPE = "text";
export const FILE_TYPE = "file";
export const CUSTOMER_DATA_TYPE = "customerData";
export const TTS_DATA_TYPE = "ttsData";
export const DTMF_DATA_TYPE = "dtmf";
export const DIRECTION_DATA_TYPE = "goto";

// Voice Constant Variable
export const LIVE_TYPE = "person";
export const MACHINE_TYPE = "machine";
export const ADVANCE_VOICE_INPUT = "advance";
export const SIMPLE_VOICE_INPUT = "simple";

// Email Constant Variable
export const EMAIL_ADVANCE_MODE = "advance";
export const EMAIL_SIMPLE_MODE = "simple";
export const EMAIL_ADVANCE_LABEL = "Advance";
export const EMAIL_SIMPLE_LABEL = "Simple";
