import axios from "axios";
import i18n from "i18next";
import { startLoading, stopLoading } from "../actions/loading";
import { notify } from "../actions/notification";
import { resetStore } from "../actions/user";

export const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL,
  timeout: 60000,
  withCredentials: true,
});

let openReqs = 0;

export function initInterceptors(store) {
  api.interceptors.request.use((config) => {
    if (config.hideLoader) {
      return config;
    }
    if (openReqs === 0) {
      store.dispatch(startLoading());
    }
    openReqs++;
    return config;
  });

  api.interceptors.response.use(
    (resp) => {
      openReqs--;
      if (openReqs === 0) {
        store.dispatch(stopLoading());
      }
      if (resp && resp.data && resp.data.message) {
        store.dispatch(
          notify({
            type: "success",
            message: resp.data.message,
          })
        );
      }
      return resp;
    },
    (err) => {
      openReqs--;
      if (openReqs === 0) {
        store.dispatch(stopLoading());
      }

      // Display the error message from the API or a fallback message in case the API returns with no message
      const data = err.response?.data;
      const hasData = data && Object.keys(data).length;
      const message = hasData
        ? data.message
        : i18n.t("Oops, something bad happened!");

      if (hasData) {
        // the isJoi field is returned from the backend for historic reasons
        // we only need to know which fields are invalid, we don't need to know if it was validated by joi
        delete err.response.data.isJoi;
      }

      // return early if the API returned with a bunch of field-level, specific error messages
      // there is no need to show a generic fallback error message in this case
      if (!message) {
        throw err;
      }

      if (message.indexOf("not found in Db") !== -1) {
        throw err;
      }

      // Log out if the session is expired (they API send a "Please log in" message in this case)
      if (message.indexOf("Please log in") !== -1) {
        // this simply removed the user from the store to redirect to the login screen
        // a fully API logout is unnecessary since the session is already expired
        // it would also lead to an async infinite loop since it would throw a 400 type "Please login" error
        store.dispatch(resetStore());
        store.dispatch(
          notify({
            type: "error",
            message: i18n.t("Your session expired! Please log in again."),
          })
        );
      } else {
        // display the error message as a notification
        store.dispatch(
          notify({
            type: "error",
            message,
          })
        );
      }
      throw err;
    }
  );
}

export default api;
