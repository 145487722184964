import React, { useMemo, memo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  SmartTable,
  ActionButton,
  Search,
  DeleteDialog,
  FormDialog,
  SuccessDialog,
} from "../../../components";
import Counts from "./Counts";
import Fields from "./Fields";
import Status from "./Status";
import Actions from "./Actions";
import { notify } from "../../../actions/notification";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(() => ({
  root: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    height: "100%",
  },
}));

export function getColumns(headers, t) {
  // headers might be undefine until they are fethced from the API
  // let the smart table handle this case with a loader state
  if (!headers) {
    return headers;
  }

  // separate the searchable and encrypted headers
  // and show the searchable ones before he encyrpted ones
  // for a cleaner representation on the UI
  return [
    ...headersToColumns({ headers, searchable: true, t }),
    ...headersToColumns({ headers, searchable: false, t }),
  ];
}

export const headersToColumns = ({ headers, searchable, t }) =>
  Object.keys(headers)
    // extract the header names
    .filter((name) => headers[name].searchable === searchable)
    // sort the headers alphabetically by name
    .sort()
    // map the column config the the matching header
    .map((name) => headerToColumn({ name, searchable, t }));

export const headerToColumn = ({ name, searchable, t }) => ({
  name,
  label: name,
  getter: (recipient) => (searchable ? recipient.fields[name] : t("Encrypted")),
  sortable: false,
  testId: `recipient-${name}`,
});

function EditOrViewRecipientList({
  recipientList,
  onChange,
  onEditRecipient,
  onDeleteRecipient,
  onSearchRecipient,
  recipientsPage = 0,
  recipientsItemsPerPage = 20,
  onRecipientsPageChange,
  onRecipientsItemsPerPageChange,
  readOnly,
  isEditing,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    loading,
    status,
    progress,
    notFound,
    errors,
    name,
    permission,
    totalCount,
    failedCount,
    emailCount,
    smsCount,
    voiceCount,
    ttyCount,
    customCount,
    recipientHeaders,
    recipients,
  } = recipientList;
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState({});
  const [action, setAction] = useState("");
  const onEdit = useCallback((recipient) => {
    setSelectedRecipient(recipient);
    setOpen(!open);
  });

  const validateInputs = (result) => {
    const nameRegex = /^[a-zA-Z0-9_-\s]{0,100}$/g;
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const smsRegex = /^[0-9]{0,10}$/g;
    const { contactType, contact, name } = result;
    if (!nameRegex.test(name)) {
      return { valid: false, error: "name" };
    }
    switch (contactType) {
      case "email":
        return { valid: emailRegex.test(contact), error: "contact type" };
      case "voice":
      case "tty":
      case "sms":
        return { valid: smsRegex.test(contact), error: "contact type" };
      default:
        return { valid: false, error: "" };
    }
  };

  const onConfirmEdit = async (result) => {
    const { valid, error } = await validateInputs(result);
    if (!valid) {
      dispatch(
        notify({
          type: "error",
          message: `Invalid value for ${error} ${
            error !== "name" ? result.contactType : ""
          }!`,
        })
      );
      return false;
    }
    setOpen(false);
    selectedRecipient.fields = result;
    await onEditRecipient(selectedRecipient);
    setAction(selectedRecipient?._id ? "updated" : "added");
    setSuccessOpen(true);
  };
  const onDelete = useCallback((recipient) => {
    setSelectedRecipient(recipient);
    setDeleteOpen(true);
  });
  const onConfirmDelete = useCallback(() => {
    setDeleteOpen(false);
    onDeleteRecipient(selectedRecipient);
    setAction("deleted");
    setSuccessOpen(true);
  });
  const columns = useMemo(() => {
    const columns = getColumns(recipientHeaders, t);

    if (isEditing && columns) {
      columns.push({
        name: "actions",
        getter: (recipient) => (
          <Actions
            recipient={recipient}
            onEdit={() => onEdit(recipient)}
            onDelete={() => onDelete(recipient)}
          />
        ),
        label: t("Actions"),
        sortable: false,
        align: "center",
      });
    }
    return columns;
  }, [recipientHeaders, t]);
  const formFields = useMemo(() => {
    if (recipientHeaders && selectedRecipient._id) {
      delete recipientHeaders["ssn"];
    }
    return {
      ...recipientHeaders,
      contactType: {
        type: "array",
        items: {
          type: "string",
          enum: ["sms", "voice", "email", "tty"],
        },
      },
      // Earlier contactType was not allowed to be edited
      // contactType: !selectedRecipient._id
      //   ? {
      //       type: "array",
      //       items: {
      //         type: "string",
      //         enum: ["sms", "voice", "email", "tty"],
      //       },
      //     }
      //   : {},
    };
  }, [recipientHeaders, selectedRecipient]);

  if (notFound) {
    return (
      <Typography variant="h6">{t("Recipient list not found")}</Typography>
    );
  }

  return (
    <Box p={2} className={classes.root}>
      <Grid container spacing={2} justify="stretch" alignItems="stretch">
        <Grid item sm={12} md={6}>
          <Fields
            name={name}
            permission={permission}
            errors={errors}
            readOnly={readOnly}
            onChange={onChange}
            className={classes.paper}
          />
        </Grid>
        <Grid item xs={12} sm={6} md>
          <Counts
            failedCount={failedCount}
            totalCount={totalCount}
            smsCount={smsCount}
            emailCount={emailCount}
            voiceCount={voiceCount}
            ttyCount={ttyCount}
            customCount={customCount}
            loading={loading}
            className={classes.paper}
          />
        </Grid>
        <Grid item xs={12} sm={6} md>
          <Status
            loading={loading}
            status={status}
            progress={progress}
            name={name}
            className={classes.paper}
          />
        </Grid>
        <FormDialog
          open={open}
          resource="Recipient"
          onClose={() => setOpen(false)}
          onSave={onConfirmEdit}
          fields={formFields}
          data={selectedRecipient.fields}
        />
        <DeleteDialog
          open={Boolean(deleteOpen)}
          onClose={() => setDeleteOpen(false)}
          onDelete={onConfirmDelete}
          name={selectedRecipient?.fields?.name}
          resource={"Recipient"}
        />
        <SuccessDialog
          open={Boolean(successOpen)}
          onClose={() => setSuccessOpen(false)}
          name={selectedRecipient?.fields?.name}
          action={action}
          resource={"Recipient"}
        />
        {isEditing && (
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <ActionButton
                onClick={() => {
                  setOpen(!open);
                  setSelectedRecipient({ fields: {} });
                }}
                text={t("Add Recipient")}
                data-test-id="add-recipient-button"
              />
              <Grid item xs={12} sm={6} md>
                <Search
                  variant="outlined"
                  size="small"
                  label={t("Search")}
                  onSearch={onSearchRecipient}
                  defaultValue={""}
                  debounce={300}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} xl={12}>
          <SmartTable
            columns={columns}
            data={recipients.data}
            totalCount={recipients.totalCount}
            notFoundMessage={t("No recipients found")}
            page={recipientsPage}
            hidePagination={isEditing ? false : true}
            itemsPerPage={recipientsItemsPerPage}
            onPageChange={onRecipientsPageChange}
            onItemsPerPageChange={onRecipientsItemsPerPageChange}
            testId="recipient"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(EditOrViewRecipientList);
