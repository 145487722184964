import { url } from "../utils";
import { SET_PARAMS } from "../actions/params";

export default function paramsReducer(
  params = url.getParams(),
  { type, newParams }
) {
  switch (type) {
    case SET_PARAMS:
      return newParams;
    default:
      return params;
  }
}
