import React, { useCallback, useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Alert, Button } from "../../../../../components";
import { mapRecipientHeader } from "../../../../../actions/newRecipientList";
import HeaderSelector from "./HeaderSelector";

function sortHeaders(headers) {
  const requiredHeaders = [];
  const optionalHeaders = [];

  Object.keys(headers).forEach((headerName) => {
    const header = { ...headers[headerName], name: headerName };
    if (header.required) {
      requiredHeaders.push(header);
    } else {
      optionalHeaders.push(header);
    }
  });

  return { requiredHeaders, optionalHeaders };
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
  },
  description: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  item: {
    flex: "1 1 0",
    minWidth: 280,
    maxWidth: 600,
  },
}));

function MapRecipientListHeaders({ scrollerRef }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const eonsHeaders = useSelector(
    (state) => state.eonsConfig.vendorConfig.recipientHeaders
  );
  const newRecipientList = useSelector((state) => state.newRecipientList);
  const { headerMapping = {}, errors, headers } = newRecipientList;
  const invalidFields = Object.keys(errors);

  const { requiredHeaders, optionalHeaders } = useMemo(
    () => sortHeaders(eonsHeaders),
    [eonsHeaders]
  );

  const onHeaderToggle = useCallback(
    (header) => {
      dispatch(
        mapRecipientHeader(header, headerMapping[header] === false ? "" : false)
      );
    },
    [dispatch, headerMapping]
  );

  const onExcludeAllHeaders = useCallback(() => {
    for (const header of headers) {
      const headerWasNeverSet = headerMapping[header] === undefined;
      const headerWasCleared = headerMapping[header] === "";
      if (headerWasNeverSet || headerWasCleared) {
        dispatch(mapRecipientHeader(header, false));
      }
    }
  }, [dispatch, headerMapping, headers]);

  const onHeaderChange = useCallback(
    ({ header, mappedHeader }) =>
      dispatch(mapRecipientHeader(header, mappedHeader)),
    [dispatch]
  );

  return (
    <Box p={2} className={classes.root} ref={scrollerRef}>
      <Alert
        severity={invalidFields.length ? "error" : "info"}
        variant="filled"
        className={classes.description}
      >
        {invalidFields.length
          ? t(
              // eslint-disable-next-line max-len
              "Please correct your mapping errors before continuing. The following fields have invalid mappings: {{invalidFields}}.",
              { invalidFields: invalidFields.join(", ") }
            )
          : t(
              "Please match the columns from your dataset to the provided columns or exclude them."
            )}
      </Alert>
      <Grid container xs={12} justify="center">
        <Grid item>
          <Box ml={2}>
            <Button
              onClick={onExcludeAllHeaders}
              variant="contained"
              color="primary"
              data-test-id="button-exclude-all"
            >
              {t("Exclude all")}
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        direction="row"
        justify="space-between"
        alignItems="stretch"
      >
        {newRecipientList.headers.map((header) => (
          <Grid item key={header} className={classes.item}>
            <HeaderSelector
              header={header}
              headerMapping={headerMapping}
              onHeaderChange={onHeaderChange}
              requiredHeaders={requiredHeaders}
              optionalHeaders={optionalHeaders}
              rows={newRecipientList.preview}
              onToggle={onHeaderToggle}
              disabled={headerMapping[header] === false}
              errors={errors}
            />
          </Grid>
        ))}
        {Array(10)
          .fill(null)
          .map((_, idx) => (
            <div key={idx} className={classes.item} />
          ))}
      </Grid>
    </Box>
  );
}

export default memo(MapRecipientListHeaders);
