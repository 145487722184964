import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "../../../components";
import { DeleteForever } from "../../../icons";

// todo: disable delete on permission

function SuppressionListActions({ onDelete, suppressed, canDelete }) {
  const { t } = useTranslation();

  const _onDelete = useCallback(() => onDelete(suppressed), [
    onDelete,
    suppressed,
  ]);

  return (
    <>
      <Tooltip title={t("Delete suppression number")}>
        <IconButton
          size="small"
          color="secondary"
          onClick={_onDelete}
          disabled={!canDelete}
        >
          <DeleteForever fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default memo(SuppressionListActions);
