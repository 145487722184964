import React, { useMemo, useState, useCallback, useRef, memo } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch } from "react-redux";
import { Page, DeleteDialog, useMediaQuery } from "../../../components";
import {
  deleteRecipientList,
  dipRecipientList,
  generateRecipientListCSV,
  downloadList,
  deDuplicateList,
  cloneRecipientList,
} from "../../../actions/recipientList";
import { getDippingStatus } from "../../../actions/recipientLists";
import RecipientListsBase from "./Base";
import Actions from "./Actions";
import StatusIndicator from "./StatusIndicator";
import useAuthorized, {
  RECIPIENTLIST_CREATE_PERMISSION,
  RECIPIENTLIST_GET_PERMISSION,
  RECIPIENTLIST_UPDATE_PERMISSION,
  RECIPIENTLIST_DELETE_PERMISSION,
  RECIPIENTLIST_LIST_PERMISSION,
} from "../../../hooks/useAuthorized";
import DedupingDialog from "./DedupingDialog";
import { createNewRecipientList } from "../../../actions/newRecipientList";

const getExtraColumns = ({
  t,
  onOpenDeleteDialog,
  onClone,
  onDippingList,
  onDownloadList,
  onScheduleDownload,
  onOpenDedupeDialog,
}) => [
  {
    name: "status",
    getter: (list) => <StatusIndicator status={list.status} />,
    label: t("Status"),
    sortable: true,
    width: 0.5,
    align: "center",
  },
  {
    name: "actions",
    getter: (list) => (
      <Actions
        recipientList={list}
        onDelete={onOpenDeleteDialog}
        onClone={onClone}
        onDipping={onDippingList}
        onDownload={onDownloadList}
        onScheduleDownload={onScheduleDownload}
        onOpenDedupeDialog={onOpenDedupeDialog}
      />
    ),
    label: t("Actions"),
    sortable: false,
    width: 0.7,
    align: "center",
  },
];

function RecipientListsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const [listToDelete, setListToDelete] = useState();
  const [listToDeDupe, setListToDeDupe] = useState();
  const listsRef = useRef();

  const onOpenDeleteDialog = useCallback((list) => setListToDelete(list), [
    setListToDelete,
  ]);

  const onClickCreate = (isCampaign) => {
    dispatch(createNewRecipientList({ redirect: isCampaign }));
  };
  const onCloseDeleteDialog = useCallback(() => setListToDelete(null), [
    setListToDelete,
  ]);
  const onOpenDedupeDialog = useCallback(
    (list) => {
      return setListToDeDupe(list);
    },
    [setListToDeDupe]
  );

  const onCloseDedupeDialog = useCallback(() => {
    return setListToDeDupe(null);
  }, [setListToDeDupe]);

  const onDelete = useCallback(async () => {
    await dispatch(deleteRecipientList(listToDelete));
    await listsRef.current.listRecipientLists();
    onCloseDeleteDialog();
  }, [dispatch, onCloseDeleteDialog, listToDelete, listsRef]);

  const onClone = useCallback(
    async (recipientList) => {
      await dispatch(cloneRecipientList(recipientList));
      await listsRef.current.listRecipientLists();
      onCloseDeleteDialog();
    },
    [dispatch, onCloseDeleteDialog, listToDelete, listsRef]
  );

  const onDippingList = useCallback(
    async (list) => {
      // set dipping list
      batch(async () => {
        await dispatch(dipRecipientList(list));
        dispatch(getDippingStatus(list));
      });
    },
    [dispatch]
  );

  const onScheduleDownload = useCallback(
    async (list) => {
      dispatch(generateRecipientListCSV(list));
      await listsRef.current.listRecipientLists();
    },
    [dispatch]
  );

  const onDownloadList = useCallback(
    async (list) => {
      dispatch(downloadList(list));
    },
    [dispatch]
  );
  const onDeduplicateList = useCallback(
    async ({ _id, recipientHeaders }) => {
      await dispatch(deDuplicateList({ _id, recipientHeaders }));
      await listsRef.current.listRecipientLists();
      onCloseDedupeDialog();
    },
    [dispatch]
  );
  const canView = useAuthorized(RECIPIENTLIST_GET_PERMISSION);
  const canCreate = useAuthorized(RECIPIENTLIST_CREATE_PERMISSION);
  const canEdit = useAuthorized(RECIPIENTLIST_UPDATE_PERMISSION);
  const canDelete = useAuthorized(RECIPIENTLIST_DELETE_PERMISSION);
  const canList = useAuthorized(RECIPIENTLIST_LIST_PERMISSION);

  const authorizedToDoAnyAction = canEdit || canDelete || canView;

  const canOnlyCreate =
    canCreate && !canView && !canEdit && !canDelete && !canList;

  const extraColumns = useMemo(
    () =>
      getExtraColumns({
        t,
        onOpenDeleteDialog,
        onOpenDedupeDialog,
        onClone,
        authorizedToDoAnyAction,
        onDippingList,
        onDownloadList,
        onScheduleDownload,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, onOpenDeleteDialog, onOpenDedupeDialog, authorizedToDoAnyAction]
  );

  return (
    <Page p={2} screenSized={!isSmallScreen}>
      <RecipientListsBase
        extraColumns={extraColumns}
        ref={listsRef}
        disableTable={canOnlyCreate}
        onClickCreate={onClickCreate}
      />
      <DeleteDialog
        open={Boolean(listToDelete)}
        onClose={onCloseDeleteDialog}
        onDelete={onDelete}
        name={listToDelete?.name}
        resource={t("Recipient List")}
      />
      <DedupingDialog
        open={Boolean(listToDeDupe)}
        onClose={onCloseDedupeDialog}
        onDeduplicateList={onDeduplicateList}
        listToDeDupe={listToDeDupe}
      />
    </Page>
  );
}

export default memo(RecipientListsPage);
