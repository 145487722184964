import { LIST_SEEDS, CLEAR_SEEDS } from "../actions/seeds";

export default function seedsReducer(seeds = null, { type, seeds: newSeeds }) {
  switch (type) {
    case LIST_SEEDS:
      return newSeeds;
    case CLEAR_SEEDS:
      return null;
    default:
      return seeds;
  }
}
