import React, { useCallback, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import classNames from "classnames";
import { KeyboardArrowDown } from "../../../icons";

const useStyles = makeStyles((theme) => ({
  textContainer: {
    flex: 1,
  },
  label: {
    textAlign: "start",
    minWidth: "40%",
  },
  value: {
    flex: 1,
    wordBreak: "break-all",
  },
  faded: {
    opacity: 0.3,
  },
  hidden: {
    opacity: 0,
    pointerEvents: "none",
  },
  sortIcon: {
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
  },
  sortDesc: {
    transform: "rotate(180deg)",
  },
}));

function MobileDataRow({
  row,
  column,
  onSortChange,
  orderBy,
  order,
  loading,
  columnsLoading,
}) {
  const classes = useStyles();
  const sortActive = orderBy === column.name;

  const _onSortChange = useCallback(() => onSortChange(column.name), [
    onSortChange,
    column,
  ]);

  return (
    <Grid
      container
      spacing={2}
      justify="space-between"
      alignItems="center"
      wrap="nowrap"
    >
      <Grid item className={classes.textContainer}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item className={classes.label}>
            {columnsLoading ? (
              <Skeleton />
            ) : (
              <Typography variant="button">{column.label}:</Typography>
            )}
          </Grid>
          <Grid item className={classes.value}>
            {loading ? (
              <Skeleton />
            ) : (
              <Typography>
                {column.getter ? column.getter(row) : row[column.name]}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <IconButton
          onClick={_onSortChange}
          size="small"
          color="primary"
          className={classNames({
            [classes.hidden]: !column.sortable,
            [classes.faded]: !sortActive,
          })}
        >
          <KeyboardArrowDown
            className={classNames(classes.sortIcon, {
              [classes.sortDesc]: !sortActive || order === 1,
            })}
          />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default memo(MobileDataRow);
