import React, { useCallback, useMemo, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Tabs,
  Tab,
  Box,
  Grid,
  TextField,
  TextEditor,
  Typography,
} from "../../../../components";
import VoicePreview from "../VoicePreview";
import TTYPreview from "../TTYPreview";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: 120,
    background: theme.palette.background.default,
  },
  tab: {
    minWidth: 120,
  },
  previewTitle: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
}));

const TabPanel = memo(({ children, value, index, ...rest }) => (
  <div {...rest} role="tabpanel" hidden={value !== index}>
    {value === index && children}
  </div>
));

function CampaignPreview({ masterTemplate = {}, ...rest }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [currTab, setCurrTab] = useState(0);
  const {
    emailTemplate,
    smsTemplate,
    voiceTemplate,
    ttyTemplate,
    customTemplate,
  } = masterTemplate;

  const onTabChange = useCallback((ev, tab) => setCurrTab(tab), [setCurrTab]);
  const readOnlyProps = useMemo(() => ({ readOnly: true }), []);
  let tabCounter = 0;

  return (
    <Paper {...rest}>
      <Grid container wrap="nowrap" alignItems="stretch">
        <Grid item className={classes.tabs}>
          <Tabs
            orientation="vertical"
            indicatorColor="primary"
            textColor="primary"
            value={currTab}
            onChange={onTabChange}
          >
            {Boolean(emailTemplate) && (
              <Tab
                label={t("Email")}
                className={classes.tab}
                data-test-id="campaign-preview-email-tab"
              />
            )}
            {Boolean(smsTemplate) && (
              <Tab
                label={t("SMS")}
                className={classes.tab}
                data-test-id="campaign-preview-sms-tab"
              />
            )}
            {Boolean(voiceTemplate?.freeswitch?.dialPlan?.person) && (
              <Tab
                label={t("Live")}
                className={classes.tab}
                data-test-id="campaign-preview-voice-live-tab"
              />
            )}
            {Boolean(voiceTemplate?.freeswitch?.dialPlan?.machine) && (
              <Tab
                label={t("Answering Machine")}
                className={classes.tab}
                data-test-id="campaign-preview-voice-machine-tab"
              />
            )}
            {Boolean(ttyTemplate?.freeswitch?.dialPlan?.person) && (
              <Tab
                label={t("TTY")}
                className={classes.tab}
                data-test-id="campaign-preview-tty-tab"
              />
            )}
            {Boolean(customTemplate) && (
              <Tab
                label={t("Custom")}
                className={classes.tab}
                data-test-id="campaign-preview-custom-tab"
              />
            )}
          </Tabs>
        </Grid>
        <Grid item xs>
          <Box p={2} className={classes.tabContent}>
            {Boolean(emailTemplate) && (
              <TabPanel value={currTab} index={tabCounter++}>
                <h4 className={classes.greyColor}>Email Subject:</h4>
                <TextField
                  fullWidth
                  size="small"
                  data-test-id="fill-data-email-subject-preview"
                  value={emailTemplate.subjectEmail}
                  readOnly
                  variant="outlined"
                />
                <h4 className={classes.greyColor}>Email Body:</h4>
                <TextEditor
                  data-test-id="campaign-preview-email"
                  value={emailTemplate.template}
                  readOnly
                />
              </TabPanel>
            )}
            {Boolean(smsTemplate) && (
              <TabPanel value={currTab} index={tabCounter++}>
                <TextField
                  data-test-id="campaign-preview-sms"
                  multiline
                  rows={3}
                  rowsMax={5}
                  variant="outlined"
                  fullWidth
                  value={smsTemplate.template}
                  InputProps={readOnlyProps}
                />
              </TabPanel>
            )}
            {Boolean(voiceTemplate?.freeswitch?.dialPlan?.person) && (
              <TabPanel value={currTab} index={tabCounter++}>
                <VoicePreview
                  voiceArray={
                    voiceTemplate?.freeswitch?.dialPlan?.person?.steps
                  }
                  data-test-id="campaign-preview-voice-live"
                />
              </TabPanel>
            )}
            {Boolean(voiceTemplate?.freeswitch?.dialPlan?.machine) && (
              <TabPanel value={currTab} index={tabCounter++}>
                <VoicePreview
                  voiceArray={
                    voiceTemplate?.freeswitch?.dialPlan?.machine?.steps
                  }
                  data-test-id="campaign-preview-voice-machine"
                />
              </TabPanel>
            )}
            {Boolean(ttyTemplate?.freeswitch?.dialPlan?.person) && (
              <TabPanel value={currTab} index={tabCounter++}>
                <TTYPreview
                  tty={
                    ttyTemplate?.freeswitch?.dialPlan?.person?.steps[0]?.path
                  }
                  data-test-id="campaign-preview-tty"
                />
                <Typography>
                  Teletype is a message format for people who are deaf, hard of
                  hearing, or speech-impaired.
                </Typography>
                <Typography style={{ color: "blue" }}>
                  {(() => {
                    const path =
                      ttyTemplate?.freeswitch?.dialPlan?.person?.steps[0].path;
                    return _.last(path.split("/"));
                  })()}
                </Typography>
              </TabPanel>
            )}
            {Boolean(customTemplate) && (
              <TabPanel value={currTab} index={tabCounter++}>
                <TextField
                  data-test-id="campaign-preview-sms"
                  multiline
                  rows={3}
                  rowsMax={5}
                  variant="outlined"
                  fullWidth
                  value={customTemplate.template}
                  InputProps={readOnlyProps}
                />
              </TabPanel>
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default memo(CampaignPreview);
