import React, { memo, useCallback } from "react";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "../../icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
  },
}));

function TablePaginator({ count, page, rowsPerPage, onChangePage }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const numOfPages = Math.max(0, Math.ceil(count / rowsPerPage) - 1);

  const onFirstPageClick = useCallback((ev) => onChangePage(ev, 0), [
    onChangePage,
  ]);

  const onPrevPageClick = useCallback((ev) => onChangePage(ev, page - 1), [
    onChangePage,
    page,
  ]);

  const onNextPageClick = useCallback((ev) => onChangePage(ev, page + 1), [
    onChangePage,
    page,
  ]);

  const onLastPageClick = useCallback((ev) => onChangePage(ev, numOfPages), [
    onChangePage,
    numOfPages,
  ]);

  return (
    <div className={classes.root}>
      <IconButton
        onClick={onFirstPageClick}
        disabled={page <= 0}
        aria-label={t("first page")}
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={onPrevPageClick}
        disabled={page <= 0}
        aria-label={t("previous page")}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={onNextPageClick}
        disabled={page >= numOfPages}
        aria-label={t("next page")}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={onLastPageClick}
        disabled={page >= numOfPages}
        aria-label={t("last page")}
      >
        <LastPage />
      </IconButton>
    </div>
  );
}

export default memo(TablePaginator);
