import api from "./api";

const campaignBasePath = "/api/v1/campaign";

export async function stopCampaign(id, params) {
  const { data } = await api.post(`${campaignBasePath}/${id}/stop`, null, {
    params,
  });
  return data;
}

export async function resumeCampaign(id, params) {
  const { data } = await api.post(`${campaignBasePath}/${id}/resume`, null, {
    params,
  });
  return data;
}

export async function getCampaign(id, params) {
  const { data } = await api.get(`${campaignBasePath}/${id}`, { params });
  return data;
}

export async function listCampaigns(params) {
  const { data } = await api.get(campaignBasePath, { params });
  return data;
}

export async function createCampaign(campaign, params) {
  const { data } = await api.post(campaignBasePath, campaign, {
    params,
  });
  return data;
}

export async function updateCampaign(campaignId, update, params) {
  const { data } = await api.patch(
    `${campaignBasePath}/${campaignId}`,
    update,
    { params }
  );
  return data;
}

export async function deleteCampaign(id, params) {
  const { data } = await api.delete(`${campaignBasePath}/${id}`, {
    params,
  });
  return data;
}

export async function cloneCampaign(id, params) {
  const { data } = await api.post(`${campaignBasePath}/${id}`, null, {
    params,
  });
  return data;
}

export async function launchCampaign(params, campaignId, comment) {
  const { data } = await api.post(
    `${campaignBasePath}/launch`,
    { campaignId, comment },
    {
      params,
    }
  );
  return data;
}
