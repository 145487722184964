import React, { useEffect, memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMasterTemplate,
  clearMasterTemplate,
  saveMasterTemplate,
} from "../../../../actions/masterTemplate";
import CreateMasterTemplate from "../../../MasterTemplates/Single/Base";

function CreateAdhocMasterTemplate() {
  const dispatch = useDispatch();
  const adhocTemplateId = useSelector((state) => state.campaign?.templateId);
  const adhocTemplate = useSelector((state) => state.masterTemplate);

  const saveWithStatus = useCallback(
    (status, initial) => {
      return new Promise((resolve) => {
        setTimeout(async () => {
          const data = await dispatch(saveMasterTemplate({ status, initial }));
          resolve(data);
        }, 200);
      });
    },
    [dispatch]
  );

  const onSaveAsDraftInitial = useCallback(async () => {
    return await saveWithStatus("DRAFT");
  }, [saveWithStatus]);

  useEffect(() => {
    dispatch(getMasterTemplate(adhocTemplateId));
    return () => dispatch(clearMasterTemplate());
  }, [dispatch, adhocTemplateId]);

  return (
    <CreateMasterTemplate
      masterTemplate={adhocTemplate}
      onSaveAsDraftInitial={onSaveAsDraftInitial}
      adhoc
    />
  );
}

export default memo(CreateAdhocMasterTemplate);
