import React, { useMemo, useCallback, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import RecipientList from "../../../RecipientLists/List/Base";
import { buildCampaign } from "../../../../actions/campaign";
import { createNewRecipientList } from "../../../../actions/newRecipientList";
import { Alert } from "../../../../components";
import Actions from "./Actions";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(2),
  },
}));

const getActions = ({ t, selectedLists, onToggleList }) => [
  {
    name: "actions",
    getter: (list) => (
      <Actions
        recipientList={list}
        selectedLists={selectedLists}
        onToggleList={onToggleList}
      />
    ),
    label: t("Select"),
    sortable: false,
    width: 0.7,
    align: "center",
  },
];

function SelectCampaignRecipientList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const campaign = useSelector((state) => state.campaign);
  const {
    lists: selectedLists = [],
    listDetails: selectedListDetails = [],
    errors = {},
  } = campaign || {};
  const onToggleList = useCallback(
    (recipientList, listId) =>
      dispatch(
        buildCampaign({
          lists:
            selectedLists.indexOf(listId) === -1
              ? [...selectedLists, listId]
              : selectedLists.filter((id) => id !== listId),
          listDetails: !selectedListDetails.find((item) => item._id === listId)
            ? [...selectedListDetails, recipientList]
            : selectedListDetails.filter((item) => item._id !== listId),
          errors: omit(errors, "lists"),
        })
      ),
    [dispatch, selectedLists, selectedListDetails, errors]
  );

  const onCreateNewList = (isCampaign) =>
    dispatch(createNewRecipientList({ redirect: isCampaign }));

  useEffect(() => {
    if (!selectedLists) {
      dispatch(buildCampaign({ lists: [], listDetails: [] }));
    }
  }, [selectedLists, selectedListDetails, dispatch]);

  const actions = useMemo(
    () => getActions({ t, selectedLists, onToggleList }),
    [t, selectedLists, onToggleList]
  );

  return (
    <>
      <RecipientList
        isCampaign={true}
        data-test-id="campaign-recipient-list-table"
        extraColumns={actions}
        // onlyStatus={allowedStatus}
        onClickCreate={onCreateNewList}
      />
      {errors.lists && (
        <Alert
          severity="error"
          variant="filled"
          className={classes.alert}
          data-test-id="campaign-recipient-list-error-alert"
        >
          {t("You must select at least one recipient list")}
        </Alert>
      )}
    </>
  );
}

export default memo(SelectCampaignRecipientList);
