import React, { memo } from "react";
import { Paper, Badge, Box } from "@material-ui/core";

function CustomPaper({ error, children, className, ...rest }) {
  if (error) {
    return (
      <Badge color="error" badgeContent={<b>!</b>} className={className}>
        <Paper component={Box} {...rest} className={className}>
          {children}
        </Paper>
      </Badge>
    );
  }
  return (
    <Paper component={Box} className={className} {...rest}>
      {children}
    </Paper>
  );
}

export default memo(CustomPaper);
