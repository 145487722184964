import { batch } from "react-redux";
import * as api from "../api/foldersTemplates";
import { clearFolder } from "./folderTemplate";
import { notify } from "./notification";

export const LIST_FOLDERS = "LIST_FOLDERS";
export const CLEAR_FOLDERS = "CLEAR_FOLDERS";

export function clearFolders() {
  return { type: CLEAR_FOLDERS };
}

export function createFolder(body, params) {
  return async (dispatch, getState) => {
    // const st = getState();
    // console.log(st)
    try {
      const response = await api.createFolder(body, params);
      dispatch(
        notify(
          response.error
            ? {
                type: "error",
                message: `${response.message}`,
              }
            : {
                type: "success",
                message: "Folder created successfully",
              }
        )
      );
    } catch (error) {
      dispatch(
        notify({
          type: "error",
          message: "Error creating folder",
        })
      );
    }
  };
}

export function listFolders(params) {
  return async (dispatch, _) => {
    const folders = await api.getFolders({
      ...params,
    });

    dispatch({
      type: LIST_FOLDERS,
      folders,
    });
  };
}

export function deleteFolderById({ id, ...rest }) {
  return async (dispatch, getState) => {
    await api.deleteFolders(id, rest);
    dispatch(
      notify({
        type: "success",
        message: "Folder deleted successfully",
      })
    );
  };
}

export function updateFolder({ _id: folderId, name, paid, activeDivisionId }) {
  return async (dispatch, getState) => {
    try {
      const response = await api.updateFolder(
        { folderId, name, paid },
        { activeDivisionId }
      );
      dispatch(
        notify(
          response.error
            ? {
                type: "error",
                message: `${response.message}`,
              }
            : {
                type: "success",
                message: "Folder updated successfully",
              }
        )
      );
    } catch (error) {
      dispatch(
        notify({
          type: "error",
          message: "Failed updating folder",
        })
      );
    }
  };
}

export function moveTemplateToFolder({
  folder,
  template,
  paid,
  activeDivisionId,
}) {
  return async (dispatch) => {
    try {
      await api.moveTemplate(
        { templateId: template._id, folderId: folder._id },
        { paid, activeDivisionId }
      );
      dispatch(
        notify({
          type: "success",
          message: `Master Template moved to ${folder.name}`,
        })
      );
    } catch (error) {
      dispatch(
        notify({
          type: "error",
          message: "Failed moving master template",
        })
      );
    }
  };
}
