import React, { useCallback, useEffect, memo } from "react";
import { omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getSeed, setSeed, updateSeed, clearSeed } from "../../../actions/seed";
import BaseSeedsPage from "./Base";
import useAuthorized, {
  SEED_GET_PERMISSION,
  SEED_UPDATE_PERMISSION,
} from "../../../hooks/useAuthorized";

function EditSeedPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { seedId } = useParams();

  const seed = useSelector((state) => state.seed);
  const userCompanyId = useSelector((state) => state.user.companyId);
  const { errors = {}, companyId: seedCompanyId } = seed || {};

  const canEdit = useAuthorized(SEED_UPDATE_PERMISSION);
  const canView = useAuthorized(SEED_GET_PERMISSION);
  const userHasOnlyViewPermissions = canView && !canEdit;
  const isUserScopedToSeedCompany = seedCompanyId === userCompanyId;
  const readOnly = userHasOnlyViewPermissions || !isUserScopedToSeedCompany;

  useEffect(() => {
    dispatch(getSeed(seedId));
    return () => dispatch(clearSeed());
  }, [dispatch, seedId]);

  const onFieldChange = useCallback(
    (ev) => {
      dispatch(
        setSeed({
          [ev.target.name]: ev.target.value,
          errors: omit(errors, ev.target.name),
          modified: true,
        })
      );
    },
    [dispatch, errors]
  );

  const onArrayFieldChange = useCallback(
    (channelInfo, type) => {
      dispatch(
        setSeed({
          [type]: [...seed[type], channelInfo],
          errors: omit(errors, "email", "sms", "voice", "tty"),
          modified: true,
          [`${type}Modified`]: true,
        })
      );
    },
    [dispatch, errors, seed]
  );

  const onChipDeletion = useCallback(
    (channelType, index) => {
      const filteredSeeds = seed[channelType].filter((_, idx) => idx !== index);
      dispatch(
        setSeed({
          [channelType]: filteredSeeds,
          errors: omit(errors, [channelType, `${channelType}.${index}`]),
          modified: true,
          [`${channelType}Modified`]: true,
        })
      );
    },
    [dispatch, errors, seed]
  );

  const onSave = useCallback(async () => {
    await dispatch(updateSeed());
    history.replace("/seeds");
  }, [dispatch, history]);

  return (
    <BaseSeedsPage
      seed={seed}
      onFieldChange={onFieldChange}
      onSave={onSave}
      readOnly={readOnly}
      onChipDeletion={onChipDeletion}
      onArrayFieldChange={onArrayFieldChange}
    />
  );
}

export default memo(EditSeedPage);
