import React, { memo } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import ListCampaigns from "./List";
import CreateEditViewCampaign from "./Single";

function CampaignsPage() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/create`}>
        <CreateEditViewCampaign />
      </Route>
      <Route path={`${match.path}/:campaignId`}>
        <CreateEditViewCampaign />
      </Route>
      <Route path={`${match.path}/:campaignId/view`}>
        <CreateEditViewCampaign />
      </Route>
      <Route path={`${match.path}`}>
        <ListCampaigns />
      </Route>
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}

export default memo(CampaignsPage);
