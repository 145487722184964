import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { ListItemText, ListItemIcon } from "../../../../../components";
import { Error } from "../../../../../icons";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
  icon: {
    minWidth: 0,
  },
}));

function HeaderSelectorItem({ header, error, ...rest }) {
  const classes = useStyles();

  if (error) {
    return (
      <>
        <ListItemText
          classes={{ secondary: classes.error }}
          primary={header.name}
          secondary={error}
        />
        <ListItemIcon className={classNames(classes.error, classes.icon)}>
          <Error />
        </ListItemIcon>
      </>
    );
  }

  return header.name;
}

export default memo(HeaderSelectorItem);
