import React, { memo } from "react";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#424FBF",
    },
    info: {
      main: "#424FBF",
    },
    text: {
      primary: "#393939",
    },
  },
  custom: {
    topbarHeight: 52,
    sidebarWidth: 200,
  },
});

const overrides = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 961,
      lg: 1281,
      xl: 1920,
    },
  },
  props: {
    MuiIconButton: {
      color: "primary",
    },
    MuiListSubheader: {
      disableSticky: true,
    },
  },
  typography: {
    fontFamily: "Nunito, Arial, Helvetica Neue, sans-serif",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        ".Mui-disabled": {
          cursor: "not-allowed !important",
          pointerEvents: "auto !important",
          color: `${theme.palette.text.disabled} !important`,
        },
        ".Mui-error": {
          color: theme.palette.error.main,
        },
        "@font-face": [
          {
            fontFamily: "Nunito",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 400,
            unicodeRange:
              // eslint-disable-next-line max-len
              "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
          },
        ],
      },
    },
    MuiStepLabel: {
      root: {
        color: theme.palette.primary.light,
      },
      label: {
        fontWeight: 500,
      },
    },
    MuiStepIcon: {
      root: {
        color: "inherit",
      },
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: theme.palette.background.paper,
      },
      body: {
        wordBreak: "break-word",
      },
    },
    MuiButtonBase: {
      root: {
        whiteSpace: "nowrap",
      },
    },
    MuiSelect: {
      icon: {
        color: theme.palette.primary.main,
      },
    },

    MuiTableSortLabel: {
      root: {
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
      active: {
        color: `${theme.palette.primary.main} !important`,
      },
      icon: {
        color: `${theme.palette.primary.main} !important`,
      },
    },
    MuiAutocomplete: {
      popper: {
        zIndex: 10001,
      },
    },
  },
});

function CustomThemeProvider({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <ThemeProvider theme={overrides}>{children}</ThemeProvider>
    </ThemeProvider>
  );
}

export default memo(CustomThemeProvider);
