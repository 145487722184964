import React, { useCallback, memo } from "react";
import { Checkbox, Tooltip } from "../../../../components";

const notAllowedForCampaignStatus = ["PENDING", "DELETED"];

function SelectCampaignRecipientListActions({
  recipientList,
  selectedLists,
  onToggleList,
}) {
  const onChange = useCallback(
    () => onToggleList(recipientList, recipientList._id),
    [onToggleList, recipientList]
  );
  const {
    emailCount,
    smsCount,
    voiceCount,
    ttyCount,
    customCount,
    totalCount,
  } = recipientList;
  const isStatusNotAllowed = notAllowedForCampaignStatus.includes(
    recipientList.status
  );
  const isCountValid =
    emailCount ||
    smsCount ||
    voiceCount ||
    ttyCount ||
    customCount ||
    totalCount;
  const listInValid = isStatusNotAllowed || !isCountValid;

  const toolTipTitle = isStatusNotAllowed
    ? "List status not valid"
    : isCountValid
    ? "The count for the status is invalid"
    : "List not valid";

  return listInValid ? (
    <Tooltip title={toolTipTitle}>
      <Checkbox
        data-test-id="campaign-recipient-list-checkbox"
        color="primary"
        checked={selectedLists.indexOf(recipientList._id) !== -1}
      />
    </Tooltip>
  ) : (
    <Checkbox
      data-test-id="campaign-recipient-list-checkbox"
      color="primary"
      disabled={listInValid}
      checked={selectedLists.indexOf(recipientList._id) !== -1}
      onChange={onChange}
    />
  );
}

export default memo(SelectCampaignRecipientListActions);
