import * as api from "../api/foldersTemplates";
export const SET_FOLDER = "SET_FOLDER";
export const CLEAR_FOLDER = "CLEAR_FOLDER";

export function getFolder({ folderId, activeDivisionId, paid, ...rest }) {
  return async (dispatch, getState) => {
    const { folder: existingFolder } = getState();
    if (!folderId) {
      dispatch({ type: SET_FOLDER, folder: null });
    } else if (existingFolder?.foundFolder?._id !== folderId) {
      try {
        const folder = await api.getFolderById(folderId, {
          activeDivisionId,
          paid,
          ...rest,
        });
        dispatch({ type: SET_FOLDER, folder });
      } catch (error) {
        dispatch({ type: SET_FOLDER, folder: null });
      }
    }
  };
}

export function clearFolder() {
  return { type: CLEAR_FOLDER };
}
