import React, { memo, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getDownloadFileList,
  generateCampaignReportFile,
  updateCampaignReportFile,
  downloadCampaignReportFile,
} from "../../../actions/downloadReport";
import ReportListDialog from "./ReportList/ReportListDialog";
import ReportListTable, {
  CollapsibleReportRow,
  FilesTable,
  FileRow,
} from "./ReportList/ReportListTable";
import {
  generateFileActionButton,
  generateFileDisabledButton,
  downloadFileActionButton,
  updateFileActionButton,
} from "./FileAction/FileActionList";

const fileStatuses = {
  0: "Not Generated",
  1: "Preparing",
  2: "Ready to Download",
  3: "Outdate",
  4: "Failed",
};

function DownloadReport({ state, onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const downloadReport = useSelector((state) => state.downloadReport);
  const campaign = useSelector((state) => state.campaign);

  const { open, campaignId } = state;

  // Dispatch Action to call API to generate Report file with givien campaignId and Identifier
  // After sending a generate API, dispatch action to get a new download file list
  const generateReportFile = useCallback(
    async ({ campaignId, identifier }) => {
      await dispatch(generateCampaignReportFile(campaignId, identifier));
      dispatch(getDownloadFileList(campaignId));
    },
    [campaignId]
  );

  // Dispatch Action to download Report File
  // - This Action will automatically start download process (Window Saving File Option)
  const downloadReportFile = useCallback(
    ({ campaignId, identifier }) => {
      dispatch(downloadCampaignReportFile(campaignId, identifier));
    },
    [campaignId]
  );

  // Dispatch Action to update(generate) a report file with given campaignId and identifier
  // After sending an update API, dispatch action to get a new download file list
  const updateReportFile = useCallback(async ({ campaignId, identifier }) => {
    await dispatch(updateCampaignReportFile(campaignId, identifier));
    dispatch(getDownloadFileList(campaignId));
  }, campaignId);

  useEffect(() => {
    let interval;
    if (campaignId !== undefined) {
      // Fetch List of Files
      dispatch(getDownloadFileList(campaignId));

      // intermittently update download file list
      interval = setInterval(async () => {
        dispatch(getDownloadFileList(campaignId));
      }, 3000);
    }
    return () => {
      // Clear interval upon exiting
      clearInterval(interval);
    };
  }, [campaignId, dispatch]);

  const { list: reportFiles } = downloadReport;
  const reportCategories = reportFiles ? Object.keys(reportFiles) : [];

  return (
    <ReportListDialog
      open={open}
      onClose={onClose}
      close={{
        message: t("Close"),
        onClose: onClose,
      }}
      title={`${t("Report Files")}: ${campaign?.name || "Loading..."}`}
    >
      <ReportListTable>
        {reportCategories.map((category) => {
          const reportCategory = reportFiles[category];
          const fileKeys = Object.keys(reportCategory);
          return (
            <CollapsibleReportRow key={category} type={category}>
              <FilesTable>
                {fileKeys.map((fileKey) => {
                  const {
                    identifier,
                    startTime,
                    endTime,
                    isReady,
                  } = reportCategory[fileKey];
                  let timestamp = 0,
                    fileName = "";
                  if (isReady !== 0) {
                    // File had been generated once before
                    timestamp = isReady >= 2 ? endTime : startTime;
                    fileName = `${identifier}`;
                  } else {
                    fileName = `${fileKey}`;
                  }

                  let actions;
                  switch (isReady) {
                    case 0:
                      actions = generateFileActionButton({
                        onClick: () =>
                          generateReportFile({
                            campaignId,
                            identifier: fileName,
                          }),
                        key: fileName,
                      });
                      break;
                    case 1:
                      actions = generateFileDisabledButton({ key: fileName });
                      break;
                    case 2:
                      actions = [
                        downloadFileActionButton({
                          onClick: () =>
                            downloadReportFile({ campaignId, identifier }),
                          key: identifier,
                        }),
                        updateFileActionButton({
                          onClick: () =>
                            updateReportFile({ campaignId, identifier }),
                          key: `${identifier}-update`,
                        }),
                      ];
                      break;
                    case 3:
                      actions = [
                        updateFileActionButton({
                          onClick: () =>
                            updateReportFile({ campaignId, identifier }),
                          key: `${identifier}-update`,
                        }),
                        downloadFileActionButton({
                          onClick: () =>
                            downloadReportFile({ campaignId, identifier }),
                          key: `${identifier}-download`,
                        }),
                      ];
                      break;
                    case 4:
                      actions = updateFileActionButton({
                        onClick: () =>
                          updateReportFile({ campaignId, identifier }),
                        key: identifier,
                      });
                      break;
                  }

                  const status = fileStatuses[isReady];
                  return (
                    // actions
                    <FileRow
                      key={fileKey}
                      timestamp={timestamp}
                      status={status}
                      name={normalizeReportFileIdentifier(fileKey)}
                      fileName={fileName}
                      actions={actions}
                    />
                  );
                })}
              </FilesTable>
            </CollapsibleReportRow>
          );
        })}
      </ReportListTable>
    </ReportListDialog>
  );
}

export default memo(DownloadReport);

function normalizeReportFileIdentifier(name) {
  const words = name.match(/[a-z]+|\d+/gi);
  return words
    ? words.reduce(
        (acc, cur) =>
          acc + " " + cur.charAt(0).toUpperCase() + cur.slice(1).toLowerCase(),
        ""
      )
    : name;
}
