import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export async function init() {
  return i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
      detection: {
        // do not cache the user language for now, this makes testing easier
        caches: [],
      },
      keySeparator: false,
      nsSeparator: false,
      fallbackLng: "en",
      defaultNS: "translation",
      load: "languageOnly",
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
      returnEmptyString: false,
      interpolation: {
        // not needed for react as it escapes by default
        escapeValue: false,
      },
    });
}

export function getLanguage() {
  return i18n.language;
}
