export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

let timeoutId;

export function notify(newNotification, timeout = 3000) {
  return (dispatch) => {
    dispatch({ type: ADD_NOTIFICATION, newNotification });
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => dispatch(removeNotification()), timeout + 500);
  };
}

export function removeNotification() {
  return { type: REMOVE_NOTIFICATION };
}
