import { LIST_MASTER_RECIPIENTS } from "../actions/masterRecipient";
import { GET_MASTER_LIST_HEADERS } from "../actions/newRecipientList";

export default function masterRecipientsReducer(
  masterRecipients = null,
  { type, masterRecipients: newMasterRecipients, recipientHeaders }
) {
  switch (type) {
    case LIST_MASTER_RECIPIENTS:
      return { ...masterRecipients, ...newMasterRecipients };
    case GET_MASTER_LIST_HEADERS:
      return { ...masterRecipients, ...recipientHeaders };
    default:
      return masterRecipients;
  }
}
