import { GET_DND, CLEAR_DND } from "../actions/dnd";

export default function dndReducer(
  dndData = null,
  { type, dndData: newDndData }
) {
  switch (type) {
    case GET_DND:
      return newDndData;
    case CLEAR_DND:
      return null;
    default:
      return dndData;
  }
}
