import React, { useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Paper, TextField, PhoneInput } from "../../../components";

function ApproverFields({
  name = "",
  sms = "",
  email = "",
  errors = {},
  readOnly,
  onChange,
  ...rest
}) {
  const { t } = useTranslation();
  const readOnlyProps = useMemo(() => ({ readOnly }), [readOnly]);

  return (
    <Grid container spacing={4} justify="space-between" alignItems="center">
      <Grid item xs={12} sm={12} md={6}>
        {/* TextFields sometimes have an issue with overlapping label and value, which happens here.
        Setting `InputLabelProps={{ shrink: Boolean(value) }}` is the official fix for the issue, see more here:
        https://material-ui.com/components/text-fields/#limitations */}
        <TextField
          name="name"
          value={name}
          onChange={onChange}
          label={t("Approver Name")}
          variant="outlined"
          fullWidth
          data-test-id="approver-name-input-field"
          error={Boolean(errors.name)}
          helperText={errors.name}
          InputLabelProps={{ shrink: Boolean(name) }}
          InputProps={readOnlyProps}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <PhoneInput
          data-test-id="approver-sms-input-field"
          label={t("SMS")}
          name="sms"
          country={"us"}
          value={sms}
          onChange={(value) => onChange({ target: { name: "sms", value } })}
          variant="outlined"
          size="small"
          fullWidth
          error={Boolean(errors.sms)}
          helperText={errors.sms}
          InputLabelProps={{ shrink: Boolean(sms) }}
          InputProps={readOnlyProps}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        {/* TextFields sometimes have an issue with overlapping label and value, which happens here.
        Setting `InputLabelProps={{ shrink: Boolean(value) }}` is the official fix for the issue, see more here:
        https://material-ui.com/components/text-fields/#limitations */}
        <TextField
          name="email"
          value={email}
          onChange={onChange}
          label={t("Email")}
          variant="outlined"
          fullWidth
          data-test-id="approver-email-input-field"
          error={Boolean(errors.email)}
          helperText={errors.email}
          InputLabelProps={{ shrink: Boolean(email) }}
          InputProps={readOnlyProps}
        />
      </Grid>
    </Grid>
  );
}

export default memo(ApproverFields);
